import React, { Component } from 'react';
import { translate } from 'react-polyglot';
import Loader from '../Loader';
import ButtonGroup from '../ButtonGroup';
import Button from '../Button';
import Modal from '../Modal';

class DeleteCommentModal extends Component {
    constructor(props) {
        super(props);

        this.state = {
            error: false,
        };
    }
    handleChange = (e) => {
        if (e.target.value === '') this.setState({ error: true });
        else this.setState({ error: false });
    };
    handleSubmit = () => {
        const { onConfirm, onClose } = this.props;
        const inputElm = document.getElementById('deleteCommentModalInput');
        if (inputElm.value === '') {
            this.setState({ error: true });
        } else {
            this.setState({ error: false });
            onConfirm(inputElm.value);
            onClose();
        }
    };
    render() {
        const {
            onClose,
            viewport,
            t,
            isDeleteReportedCommentloading,
        } = this.props;
        const { error } = this.state;
        const footer = (
            <ButtonGroup mergeTop mergeBottom={!viewport.large} mergeSides>
                {isDeleteReportedCommentloading ? (
                    <Loader />
                ) : (
                    <Button
                        label={t('book.submit')}
                        variant="submit"
                        onClick={() => {
                            this.handleSubmit();
                        }}
                    />
                )}
                <Button label={t('global.cancel')} onClick={onClose} />
            </ButtonGroup>
        );

        return (
            <Modal footer={footer} onClose={onClose}>
                <div className="DeleteCommentModalContainer">
                    <input
                        id="deleteCommentModalInput"
                        className="DeleteCommentModalInput"
                        type="text"
                        placeholder={t('comments.reportInputPlaceholder')}
                        onChange={(e) => this.handleChange(e)}
                    />
                    {error && (
                        <p className="DeleteCommentModalError">
                            {t('comments.error')}
                        </p>
                    )}
                </div>
            </Modal>
        );
    }
}

export default translate()(DeleteCommentModal);
