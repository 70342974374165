import React, { Component } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { translate } from 'react-polyglot';
import ReadingListCardContainer from '../ReadingListCardContainer';
import './ThemedReadingList.scss';
import Grid from '../Grid';

class ThemedReadingList extends Component {

    render() {
        const baseClassName = 'pb-themed-reading-list';
        const { parentClassName, themedReadingLists, myLanguage } = this.props;

        const classes = {
            [baseClassName]: true,
            [parentClassName]: parentClassName,
        };



        return (
            <div className={classNames(classes)}>
                <div className="__reading-book-item-container">
                    <Grid variant={window.screen.width <= 767 ? "3up":"4up" }>
                        {themedReadingLists.map(readingListItem => {
                            return (
                                <div key={readingListItem.id}>
                                    <ReadingListCardContainer
                                        myLanguage={myLanguage}
                                        key={readingListItem.id}
                                        readingListItem={readingListItem}
                                        readingListsTitle="themed-reading-lists-title"
                                    />
                                </div>
                            );
                        })}
                    </Grid>
                    
                </div>
            </div>
        );
    }
}

ThemedReadingList.propTypes = {
    parentClassName: PropTypes.string,
};

const mapStateToProps = ({ viewport }) => {
    return {
        viewport,
    };
};

const mapDispatchToProps = {};

export default translate()(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(ThemedReadingList)
);
