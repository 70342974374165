import React from 'react';

import SectionBlock from '../SectionBlock';
import ResourceShelf from '../ResourceShelf';

const SimilarResources = ({ offline, similarResources, t, viewport }) => {
    if (offline || !similarResources || !similarResources.length) {
        return null;
    }

    return (
        <SectionBlock
            background="transparent"
            title={t('Resources.related-videos')}
            videoDetails
        >
            <ResourceShelf
                resources={similarResources}
                viewport={viewport}
                offline={offline}
            />
        </SectionBlock>
    );
};

export default SimilarResources;
