import { createActions } from 'redux-actions';

export const { openMenu } = createActions({
    OPEN_MENU: () => ({}),
});

export const { closeMenu } = createActions({
    CLOSE_MENU: () => ({}),
});
export const { toggleMenuState } = createActions({
    TOGGLE_MENU: () => ({}),
});
