import React, { Component } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { translate } from 'react-polyglot';
import { connect } from 'react-redux';
import DocumentTitle from 'react-document-title';
import './CreateNewReadingList.scss';
import TextField from '../TextField';
import Button from '../Button';
import Link from '../Link';
import { createNewReadingListActionAsync } from '../../redux/readingListsActions';

class CreateNewReadingList extends Component {
    constructor(props) {
        super(props);
        const isEdit = props.edit;

        this.state = {
            readingListName: isEdit ? props.listDetails.title : '',
            readingListDesc: isEdit ? props.listDetails.description : '',
            BtnDisabled: isEdit ? false : true,
            edit: isEdit
        };
    }

    onTitleChange = e => this.setState({ readingListName: e.target.value });

    onDescriptionChange = e =>
        this.setState({ readingListDesc: e.target.value });

    nextPath(path) {
        this.props.history.push(path);
    }

    addBookSubmitButton = (name, desc) => {
        this.props.createNewReadingListActionAsync(name, desc);
    };

    editSubmitButton = () => {
        this.props.editSubmit({
            title: this.state.readingListName,
            description: this.state.readingListDesc
        });
    }

    render() {
        const baseClassName = 'pb-create-new-reading-list';
        const { parentClassName, t } = this.props;

        const classes = {
            [baseClassName]: true,
            [parentClassName]: parentClassName,
        };

        const getButtonJsx = () => {
            const buttonText = this.state.edit ? t('Readinglist.Update') : t('Readinglist.add-books');

            const createReadingListBtn = (
                <div className="__add-book-button">
                    <Link
                        href={'/myreadinglists/addbooks'}
                        isInternal
                        paddingTop
                        fullWidth
                        dataGTM="add-books"
                    >
                        <span
                            className="__action-button-text"
                            onClick={() =>
                                this.addBookSubmitButton(
                                    this.state.readingListName,
                                    this.state.readingListDesc
                                )
                            }
                        >
                            {buttonText}
                        </span>
                    </Link>
                </div>
            );

            const editReadingListBtn = (
                <div className="edit-reading-list-btn">
                    {
                        this.props.listDetails &&
                        <span
                            className="__action-button-text"
                            onClick={() =>
                                this.editSubmitButton()
                            }
                        >
                            {buttonText}
                        </span>
                    }
                </div>
            );

            const disabledBtn = (
                <div className={`${baseClassName}__disabled-button`}>
                    <Button
                        disabled={this.state.BtnDisabled}
                        btnDisabled
                        fullWidth
                        label={buttonText}
                        dataGTM={this.state.edit ? "edit-books" : "add-books"}
                        variant="primary"
                    />
                </div>
            );

            return (
                <div className="__add-book-button-section">
                    {
                        this.state.readingListName && this.state.readingListDesc ? (
                            this.state.edit ? editReadingListBtn : createReadingListBtn
                        ) : disabledBtn
                    }
                </div>

            );
        }

        return (
            <div className={classNames(classes)}>
                <DocumentTitle
                    title={`${t('Readinglist.create-new-reading-list')} - ${t(
                        'global.site-title'
                    )}`}
                />
                <div className="__create-new-readinglist-section">
                    <div className="__create-new-readinglist-header">
                        {this.state.edit ? t('Readinglist.edit-reading-list') : t('Readinglist.create-new-reading-list')}
                    </div>
                    <div className="__create-new-readinglist-fields">
                        <TextField
                            id="search-modal-input"
                            label={t('Readinglist.reading-list-name')}
                            value={this.state.readingListName}
                            size="l"
                            theme="dark"
                            onChange={this.onTitleChange}
                            bordered={false}
                            createNewReadingList
                        />
                        <br />
                        <br />
                        <TextField
                            id="search-modal-input"
                            label={t('Readinglist.reading-list-description')}
                            value={this.state.readingListDesc}
                            size="l"
                            theme="dark"
                            onChange={this.onDescriptionChange}
                            bordered={true}
                            multiline
                            createNewReadingList
                            type="multiline"
                            rows="4"
                            maxLength={120}
                        />
                        <span className="description-length">{this.state.readingListDesc.length}/120</span>
                    </div>

                    {getButtonJsx()}
                </div>
            </div>
        );
    }
}

const mapStateToProps = ({ viewport }) => {
    return {
        viewport
    };
};

const mapDispatchToProps = {
    createNewReadingListActionAsync,
};

CreateNewReadingList.propTypes = {
    parentClassName: PropTypes.string,
    edit: PropTypes.bool
};

export default translate()(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(CreateNewReadingList)
);
