import React from 'react';
import PropTypes from 'prop-types';
import BookCard from '../../BookCard';
import Grid from '../../Grid';
import "../Home.scss"

const BooksGrid = ({ books, horizontal, viewport, t, sectionClicked }) => {
    const myLanguage = localStorage.getItem('locale');

    if (!books || books.length === 0) {
        return null;
    }

    const bookEls = books.slice(0, 4).map((book) => {
        return (
            <BookCard
                myLanguage={myLanguage}
                key={book.slug}
                id={book.id}
                bookLikes={book.likesCount}
                book={book}
                sectionClicked={sectionClicked}
                isFavourite={book.is_favourite}
                isFavouriting={book.isFavouriting}
                enableFavouriting
                isFromHome
                bookImageGTM="recommened-book-image"
                bookTitleGTM="recommened-book-title"
                bookAuthorGTM="recommened-book-author"
                bookIllustratorsGTM="recommened-book-illustrators"
                bookLevelGTM="recommened-book-level"
                padding130={true}
            />
        );
    });

    return <Grid variant="4up">{bookEls}</Grid>;
};

BooksGrid.propTypes = {
    books: PropTypes.arrayOf(
        PropTypes.shape({
            slug: PropTypes.string.isRequired,
            id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
            likesCount: PropTypes.number,
            is_favourite: PropTypes.bool,
            isFavouriting: PropTypes.bool,
        })
    ).isRequired,
    horizontal: PropTypes.bool,
    viewport: PropTypes.object,
    t: PropTypes.func.isRequired,
    sectionClicked: PropTypes.string.isRequired,
};

export default BooksGrid;
