import React, { Component } from 'react';
import classNames from 'classnames';
import { translate } from 'react-polyglot';
import PropTypes from 'prop-types';
import Link from '../Link';
import SvgIcon from '../SvgIcon';
import { sortOptions } from '../../lib/constants';
import './SortOption.scss';

@translate()
class SortOption extends Component {

    static defaultProps = {};
    constructor(props) {
        super(props);

        this.state = {
            optionOnEmpty: null,
            isFocused: false,
            filterValue: null,
            isMostLiked: true,
            isMostRead: true,
            isMostView: true,
            isAscend: true,
        };
    }

    componentDidMount() {
        if (this.props.value === 'Z-A') {
            this.setState({
                isAscend: false
            })
        }
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.options) {
            const optionOnEmpty = nextProps.options.find(o => {
                return o.queryValue === '';
            });

            if (optionOnEmpty) {
                this.setState({
                    optionOnEmpty: optionOnEmpty.name,
                });
            }
        }
    }

    handleOnChange = e => {
        this.props.onChange(e);
    };
    mostLikedSortChange = e => {
        this.props.onChange(e);
        this.setState({
            isMostLiked: false,
        });
    };
    leastLikedSortChange = e => {
        this.props.onChange(e);
        this.setState({
            isMostLiked: true,
        });
    };

    mostReadSortChange = e => {
        this.props.onChange(e);
        this.setState({
            isMostRead: false,
        });
    };

    leastReadSortChange = e => {
        this.props.onChange(e);
        this.setState({
            isMostRead: true,
        });
    };

    mostViewSortChange = e => {
        this.props.onChange(e);
        this.setState({
            isMostView: false,
        });
    };

    leastViewSortChange = e => {
        this.props.onChange(e);
        this.setState({
            isMostView: true,
        });
    };

    ascendingSortChange = e => {
        this.setState({
            isAscend: false,
        });
        this.props.onChange(e);

    };

    descendingSortChange = e => {
        this.setState({
            isAscend: true,
        });
        this.props.onChange(e);

    };

    setValue = e => {
        this.setState({ filterValue: e });
    };

    render() {
        const {
            t,
            id,
            label,
            parentClassName,
            isBookSectionMobile,
            isVideoMobile,
            isResourceMobile,
            isReadingListMobile,
            isIllustrationMobile,
            isTranslate,
            isTranslateMobile,
            isIllustration,
            isResources,
            isAddBook,
            isAddBookMobile,
            isBookSection,
            isVideos,
            isReadingList,
            favoriteSortGTM,
            viewSortGTM,
            alphabetSortGTM,
            readSortGTM,
            isRightToLeftDirection
        } = this.props;

        let labelEl = null;
        if (this.props.label) {
            labelEl = (
                <label className={`${baseClassName}__label`} htmlFor={id}>
                    {label}
                </label>
            );
        }

        const baseClassName = 'pb-sort-option';

        const classes = {
            [baseClassName]: true,
            [parentClassName]: parentClassName,
        };

        const sortVideoEls = [
            <span>
                <Link
                    onClick={
                        this.state.isMostLiked
                            ? () =>
                                this.mostLikedSortChange(
                                    sortOptions.mostLiked
                                )
                            : () =>
                                this.leastLikedSortChange(
                                    sortOptions.leastLiked
                                )
                    }
                    lightBlack
                    dataGTM={favoriteSortGTM}
                >
                    <div
                        className={`${baseClassName}__option-values`}
                        data-gtm={favoriteSortGTM}
                    >
                        {t('sort.most-liked')}
                        <SvgIcon
                            name="sort"
                            size="sort"
                            push={isRightToLeftDirection ? "pushRight" : "pushLeftSmall"}
                            dataGTM={favoriteSortGTM}
                        />
                    </div>
                </Link>
            </span>,
            <span>
                <Link
                    onClick={
                        this.state.isMostView
                            ? () =>
                                this.mostViewSortChange(sortOptions.mostView)
                            : () =>
                                this.leastViewSortChange(
                                    sortOptions.leastView
                                )
                    }
                    lightBlack
                    dataGTM={viewSortGTM}
                >
                    <div
                        className={`${baseClassName}__option-values`}
                        data-gtm={viewSortGTM}
                    >
                        {t('global.list-view', 2)}
                        <SvgIcon
                            name="sort"
                            size="sort"
                            push={isRightToLeftDirection ? "pushRight" : "pushLeftSmall"}
                            dataGTM={viewSortGTM}
                        />
                    </div>
                </Link>
            </span>,
            <span>
                <Link
                    onClick={
                        this.state.isAscend
                            ? () =>
                                this.ascendingSortChange(
                                    sortOptions.descending
                                )
                            : () =>
                                this.descendingSortChange(
                                    sortOptions.ascending
                                )
                    }
                    lightBlack
                    dataGTM={alphabetSortGTM}
                >
                    <div
                        className={`${baseClassName}__option-values`}
                        data-gtm={alphabetSortGTM}
                    >
                        {!this.state.isAscend ? t('global.alphabet-reverse') : t('global.alphabet')}
                        <SvgIcon
                            name="sort"
                            size="sort"
                            push={isRightToLeftDirection ? "pushRight" : "pushLeftSmall"}
                            dataGTM={alphabetSortGTM}
                        />
                    </div>
                </Link>
            </span>,
        ];

        const sortTranslateEls = [
            <span>
                <Link
                    onClick={
                        this.state.isMostLiked
                            ? () =>
                                this.mostLikedSortChange(
                                    sortOptions.mostLiked
                                )
                            : () =>
                                this.leastLikedSortChange(
                                    sortOptions.leastLiked
                                )
                    }
                    lightBlack
                    dataGTM={favoriteSortGTM}
                >
                    <div
                        className={`${baseClassName}__option-values`}
                        data-gtm={favoriteSortGTM}
                    >
                        {t('sort.most-liked')}
                        <SvgIcon
                            name="sort"
                            size="sort"
                            push={isRightToLeftDirection ? "pushRight" : "pushLeftSmall"}
                            dataGTM={favoriteSortGTM}
                        />
                    </div>
                </Link>
            </span>,
            <span>
                <Link
                    onClick={
                        this.state.isMostView
                            ? () =>
                                this.mostViewSortChange(sortOptions.mostView)
                            : () =>
                                this.leastViewSortChange(
                                    sortOptions.leastView
                                )
                    }
                    lightBlack
                    dataGTM={viewSortGTM}
                >
                    <div
                        className={`${baseClassName}__option-values`}
                        data-gtm={viewSortGTM}
                    >
                        {t('global.list-view', 2)}
                        <SvgIcon
                            name="sort"
                            size="sort"
                            push={isRightToLeftDirection ? "pushRight" : "pushLeftSmall"}
                            dataGTM={viewSortGTM}
                        />
                    </div>
                </Link>
            </span>,
        ];

        const sortTranslateMobileEls = [
            <span>
                <Link
                    onClick={
                        this.state.isMostLiked
                            ? () =>
                                this.mostLikedSortChange(
                                    sortOptions.mostLiked
                                )
                            : () =>
                                this.leastLikedSortChange(
                                    sortOptions.leastLiked
                                )
                    }
                    lightBlack
                    dataGTM={favoriteSortGTM}
                >
                    <div
                        className="__option-values-media-sort"
                        data-gtm={favoriteSortGTM}
                    >
                        {t('sort.most-liked')}
                        <SvgIcon
                            name="sort"
                            size="sort"
                            push={isRightToLeftDirection ? "pushRight" : "pushLeftSmall"}
                            dataGTM={favoriteSortGTM}
                        />
                    </div>
                </Link>
            </span>,
            <span>
                <Link
                    onClick={
                        this.state.isMostView
                            ? () =>
                                this.mostViewSortChange(sortOptions.mostView)
                            : () =>
                                this.leastViewSortChange(
                                    sortOptions.leastView
                                )
                    }
                    lightBlack
                    dataGTM={viewSortGTM}
                >
                    <div
                        className="__option-values-media-sort"
                        data-gtm={viewSortGTM}
                    >
                        {t('global.list-view', 2)}
                        <SvgIcon
                            name="sort"
                            size="sort"
                            push={isRightToLeftDirection ? "pushRight" : "pushLeftSmall"}
                            dataGTM={viewSortGTM}
                        />
                    </div>
                </Link>
            </span>,
        ];

        const readingListMobile = [
            <span>
                <Link
                    onClick={
                        this.state.isAscend
                            ? () =>
                                this.ascendingSortChange(
                                    sortOptions.descending
                                )
                            : () =>
                                this.descendingSortChange(
                                    sortOptions.ascending
                                )
                    }
                    lightBlack
                    data-gtm="reading-list-alphabet-sort"
                >
                    <div className="__option-values-media-sort">
                        {!this.state.isAscend ? t('global.alphabet-reverse') : t('global.alphabet')}
                        <SvgIcon name="sort" size="sort" pushLeft />
                    </div>
                </Link>
            </span>,
        ];

        const sortEls = [
            <span>
                <Link
                    onClick={
                        this.state.isMostLiked
                            ? () =>
                                this.mostLikedSortChange(
                                    sortOptions.mostLiked
                                )
                            : () =>
                                this.leastLikedSortChange(
                                    sortOptions.leastLiked
                                )
                    }
                    lightBlack
                    dataGTM={favoriteSortGTM}
                >
                    <div
                        className={`${baseClassName}__option-values`}
                        data-gtm={favoriteSortGTM}
                    >
                        {t('sort.most-liked')}
                        <SvgIcon
                            name="sort"
                            size="sort"
                            push={isRightToLeftDirection ? "pushRight" : "pushLeftSmall"}
                            dataGTM={favoriteSortGTM}
                        />
                    </div>
                </Link>
            </span>,
            <span>
                <Link
                    onClick={
                        this.state.isMostRead
                            ? () =>
                                this.mostReadSortChange(sortOptions.mostRead)
                            : () =>
                                this.leastReadSortChange(
                                    sortOptions.leastRead
                                )
                    }
                    lightBlack
                    dataGTM={readSortGTM}
                >
                    <div
                        className={`${baseClassName}__option-values`}
                        data-gtm={readSortGTM}
                    >
                        {t('global.list-read', 2)}
                        <SvgIcon
                            name="sort"
                            size="sort"
                            push={isRightToLeftDirection ? "pushRight" : "pushLeftSmall"}
                            dataGTM={readSortGTM}
                        />
                    </div>
                </Link>
            </span>,
            <span>
                <Link
                    onClick={
                        this.state.isAscend
                            ? () =>
                                this.ascendingSortChange(
                                    sortOptions.descending
                                )
                            : () =>
                                this.descendingSortChange(
                                    sortOptions.ascending
                                )
                    }
                    lightBlack
                    dataGTM={alphabetSortGTM}
                >
                    <div
                        className={`${baseClassName}__option-values`}
                        data-gtm={alphabetSortGTM}
                    >
                        {!this.state.isAscend ? t('global.alphabet-reverse') : t('global.alphabet')}
                        <SvgIcon
                            name="sort"
                            size="sort"
                            push={isRightToLeftDirection ? "pushRight" : "pushLeftSmall"}
                            dataGTM={alphabetSortGTM}
                        />
                    </div>
                </Link>
            </span>,
        ];

        const sortResourcesEls = [
            <span>
                <Link
                    onClick={
                        this.state.isAscend
                            ? () =>
                                this.ascendingSortChange(
                                    sortOptions.descending
                                )
                            : () =>
                                this.descendingSortChange(
                                    sortOptions.ascending
                                )
                    }
                    lightBlack
                    dataGTM={alphabetSortGTM}
                >
                    <div
                        className={`${baseClassName}__option-values`}
                        data-gtm={alphabetSortGTM}
                    >
                        {!this.state.isAscend ? t('global.alphabet-reverse') : t('global.alphabet')}
                        <SvgIcon
                            name="sort"
                            size="sort"
                            push={isRightToLeftDirection ? "pushRight" : "pushLeftSmall"}
                            dataGTM={alphabetSortGTM}
                        />
                    </div>
                </Link>
            </span>,
        ];

        const sortReadingListEls = [
            <span>
                <Link
                    onClick={
                        this.state.isAscend
                            ? () =>
                                this.ascendingSortChange(
                                    sortOptions.descending
                                )
                            : () =>
                                this.descendingSortChange(
                                    sortOptions.ascending
                                )
                    }
                    lightBlack
                    dataGTM={alphabetSortGTM}
                >
                    <div
                        className={`${baseClassName}__option-values`}
                        data-gtm={alphabetSortGTM}
                    >
                        {!this.state.isAscend ? t('global.alphabet-reverse') : t('global.alphabet')}
                        <SvgIcon
                            name="sort"
                            size="sort"
                            push={isRightToLeftDirection ? "pushRight" : "pushLeftSmall"}
                            dataGTM={alphabetSortGTM}
                        />
                    </div>
                </Link>
            </span>,
        ];

        const sortResourceMobileEls = [
            <span>
                <Link
                    onClick={
                        this.state.isAscend
                            ? () =>
                                this.ascendingSortChange(
                                    sortOptions.descending
                                )
                            : () =>
                                this.descendingSortChange(
                                    sortOptions.ascending
                                )
                    }
                    lightBlack
                    dataGTM={alphabetSortGTM}
                >
                    <div
                        className="__option-values-media-sort"
                        data-gtm={alphabetSortGTM}
                    >
                        {!this.state.isAscend ? t('global.alphabet-reverse') : t('global.alphabet')}
                        <SvgIcon
                            name="sort"
                            size="sort"
                            push={isRightToLeftDirection ? "pushRight" : "pushLeftSmall"}
                            dataGTM={alphabetSortGTM}
                        />
                    </div>
                </Link>
            </span>,
        ];

        const sortVideoMobileEls = [
            <span>
                <Link
                    onClick={
                        this.state.isMostLiked
                            ? () =>
                                this.mostLikedSortChange(
                                    sortOptions.mostLiked
                                )
                            : () =>
                                this.leastLikedSortChange(
                                    sortOptions.leastLiked
                                )
                    }
                    lightBlack
                    dataGTM={favoriteSortGTM}
                >
                    <div
                        className="__option-values-media-sort"
                        data-gtm={favoriteSortGTM}
                    >
                        {t('sort.most-liked')}
                        <SvgIcon
                            name="sort"
                            size="sort"
                            push={isRightToLeftDirection ? "pushRight" : "pushLeftSmall"}
                            dataGTM={favoriteSortGTM}
                        />
                    </div>
                </Link>
            </span>,
            <span>
                <Link
                    onClick={
                        this.state.isMostView
                            ? () =>
                                this.mostViewSortChange(sortOptions.mostView)
                            : () =>
                                this.leastViewSortChange(
                                    sortOptions.leastView
                                )
                    }
                    lightBlack
                    dataGTM={viewSortGTM}
                >
                    <div
                        className="__option-values-media-sort"
                        data-gtm={viewSortGTM}
                    >
                        {t('global.list-view', 2)}
                        <SvgIcon
                            name="sort"
                            size="sort"
                            push={isRightToLeftDirection ? "pushRight" : "pushLeftSmall"}
                            dataGTM={viewSortGTM}
                        />
                    </div>
                </Link>
            </span>,
            <span>
                <Link
                    onClick={
                        this.state.isAscend
                            ? () =>
                                this.ascendingSortChange(
                                    sortOptions.descending
                                )
                            : () =>
                                this.descendingSortChange(
                                    sortOptions.ascending
                                )
                    }
                    lightBlack
                    dataGTM={alphabetSortGTM}
                >
                    <div
                        className="__option-values-media-sort"
                        data-gtm={alphabetSortGTM}
                    >
                        {!this.state.isAscend ? t('global.alphabet-reverse') : t('global.alphabet')}
                        <SvgIcon
                            name="sort"
                            size="sort"
                            push={isRightToLeftDirection ? "pushRight" : "pushLeftSmall"}
                            dataGTM={alphabetSortGTM}
                        />
                    </div>
                </Link>
            </span>,
        ];

        const sortIllustrationMobileEls = [
            <span>
                <Link
                    onClick={
                        this.state.isMostView
                            ? () =>
                                this.mostViewSortChange(sortOptions.mostView)
                            : () =>
                                this.leastViewSortChange(
                                    sortOptions.leastView
                                )
                    }
                    lightBlack
                    dataGTM={viewSortGTM}
                >
                    <div
                        className="__option-values-media-sort"
                        data-gtm={viewSortGTM}
                    >
                        {t('global.list-view', 2)}
                        <SvgIcon
                            name="sort"
                            size="sort"
                            push={isRightToLeftDirection ? "pushRight" : "pushLeftSmall"}
                            dataGTM={viewSortGTM}
                        />
                    </div>
                </Link>
            </span>,
            <span>
                <Link
                    onClick={
                        this.state.isAscend
                            ? () =>
                                this.ascendingSortChange(
                                    sortOptions.descending
                                )
                            : () =>
                                this.descendingSortChange(
                                    sortOptions.ascending
                                )
                    }
                    lightBlack
                    dataGTM={alphabetSortGTM}
                >
                    <div
                        className="__option-values-media-sort"
                        data-gtm={alphabetSortGTM}
                    >
                        {!this.state.isAscend ? t('global.alphabet-reverse') : t('global.alphabet')}
                        <SvgIcon
                            name="sort"
                            size="sort"
                            push={isRightToLeftDirection ? "pushRight" : "pushLeftSmall"}
                            dataGTM={alphabetSortGTM}
                        />
                    </div>
                </Link>
            </span>,
        ];

        const sortBookMobileEls = [
            <span>
                <Link
                    onClick={
                        this.state.isMostLiked
                            ? () =>
                                this.mostLikedSortChange(
                                    sortOptions.mostLiked
                                )
                            : () =>
                                this.leastLikedSortChange(
                                    sortOptions.leastLiked
                                )
                    }
                    lightBlack
                    dataGTM={favoriteSortGTM}
                >
                    <div
                        className="__option-values-media-sort"
                        data-gtm={favoriteSortGTM}
                    >
                        {t('sort.most-liked')}
                        <SvgIcon
                            name="sort"
                            size="sort"
                            push={isRightToLeftDirection ? "pushRight" : "pushLeftSmall"}
                            dataGTM={favoriteSortGTM}
                        />
                    </div>
                </Link>
            </span>,
            <span>
                <Link
                    onClick={
                        this.state.isMostRead
                            ? () =>
                                this.mostReadSortChange(sortOptions.mostRead)
                            : () =>
                                this.leastReadSortChange(
                                    sortOptions.leastRead
                                )
                    }
                    lightBlack
                    dataGTM={readSortGTM}
                >
                    <div
                        className="__option-values-media-sort"
                        data-gtm={readSortGTM}
                    >
                        {t('global.list-read', 2)}
                        <SvgIcon
                            name="sort"
                            size="sort"
                            push={isRightToLeftDirection ? "pushRight" : "pushLeftSmall"}
                            dataGTM={readSortGTM}
                        />
                    </div>
                </Link>
            </span>,
            <span>
                <Link
                    onClick={
                        this.state.isAscend
                            ? () =>
                                this.ascendingSortChange(
                                    sortOptions.descending
                                )
                            : () =>
                                this.descendingSortChange(
                                    sortOptions.ascending
                                )
                    }
                    lightBlack
                    dataGTM={alphabetSortGTM}
                >
                    <div
                        className="__option-values-media-sort"
                        data-gtm={alphabetSortGTM}
                    >
                        {!this.state.isAscend ? t('global.alphabet-reverse') : t('global.alphabet')}
                        <SvgIcon
                            name="sort"
                            size="sort"
                            push={isRightToLeftDirection ? "pushRight" : "pushLeftSmall"}
                            dataGTM={alphabetSortGTM}
                        />
                    </div>
                </Link>
            </span>,
        ];

        const sortAllImagesEls = [
            <span>
                <Link
                    onClick={
                        this.state.isMostView
                            ? () =>
                                this.mostViewSortChange(sortOptions.mostView)
                            : () =>
                                this.leastViewSortChange(
                                    sortOptions.leastView
                                )
                    }
                    lightBlack
                    dataGTM={viewSortGTM}
                >
                    <div
                        className={`${baseClassName}__option-values`}
                        data-gtm={viewSortGTM}
                    >
                        {t('global.list-view', 2)}
                        <SvgIcon
                            name="sort"
                            size="sort"
                            push={isRightToLeftDirection ? "pushRight" : "pushLeftSmall"}
                            dataGTM={viewSortGTM}
                        />
                    </div>
                </Link>
            </span>,
            <span>
                <Link
                    onClick={
                        this.state.isAscend
                            ? () =>
                                this.ascendingSortChange(
                                    sortOptions.descending
                                )
                            : () =>
                                this.descendingSortChange(
                                    sortOptions.ascending
                                )
                    }
                    lightBlack
                    dataGTM={alphabetSortGTM}
                >
                    <div
                        className={`${baseClassName}__option-values`}
                        data-gtm={alphabetSortGTM}
                    >
                        {!this.state.isAscend ? t('global.alphabet-reverse') : t('global.alphabet')}
                        <SvgIcon
                            name="sort"
                            size="sort"
                            push={isRightToLeftDirection ? "pushRight" : "pushLeftSmall"}
                            dataGTM={alphabetSortGTM}
                        />
                    </div>
                </Link>
            </span>,
        ];

        return (
            <div className={classNames(classes)}>
                {isBookSectionMobile ? (
                    <div className={`${baseClassName}__input-wrapper-sort`}>
                        {labelEl}
                        {sortBookMobileEls}
                    </div>
                ) : null}
                {isBookSection ? (
                    <div className={`${baseClassName}__input-wrapper`}>
                        {sortEls}
                    </div>
                ) : null}
                {isResourceMobile ? (
                    <div className={`${baseClassName}__input-wrapper-sort`}>
                        {labelEl}
                        {sortResourceMobileEls}
                    </div>
                ) : null}
                {isResources ? (
                    <div className={`${baseClassName}__input-wrapper`}>
                        {sortResourcesEls}
                    </div>
                ) : null}
                {isAddBookMobile ? (
                    <div className={`${baseClassName}__input-wrapper-sort`}>
                        {labelEl}
                        {sortBookMobileEls}
                    </div>
                ) : null}
                {isAddBook ? (
                    <div className={`${baseClassName}__input-wrapper`}>
                        {sortEls}
                    </div>
                ) : null}
                {isVideoMobile ? (
                    <div className={`${baseClassName}__input-wrapper-sort`}>
                        {labelEl}
                        {sortVideoMobileEls}
                    </div>
                ) : null}
                {isVideos ? (
                    <div className={`${baseClassName}__input-wrapper`}>
                        {sortVideoEls}
                    </div>
                ) : null}
                {isIllustration ? (
                    <div className={`${baseClassName}__input-wrapper`}>
                        {sortAllImagesEls}
                    </div>
                ) : null}
                {isIllustrationMobile ? (
                    <div className={`${baseClassName}__input-wrapper-sort`}>
                        {labelEl}
                        {sortIllustrationMobileEls}
                    </div>
                ) : null}
                {isReadingList ? (
                    <div className={`${baseClassName}__input-wrapper`}>
                        {sortReadingListEls}
                    </div>
                ) : null}
                {isReadingListMobile ? (
                    <div className={`${baseClassName}__input-wrapper-sort`}>
                        {labelEl}
                        {readingListMobile}
                    </div>
                ) : null}
                {isTranslate ? (
                    <div className={`${baseClassName}__input-wrapper`}>
                        {sortTranslateEls}
                    </div>
                ) : null}
                {isTranslateMobile ? (
                    <div className={`${baseClassName}__input-wrapper-sort`}>
                        {labelEl}
                        {sortTranslateMobileEls}
                    </div>
                ) : null}
            </div>
        );
    }
}

SortOption.propTypes = {
    parentClassName: PropTypes.string,
};

export default SortOption;
