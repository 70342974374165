import React from 'react';
import BookCardGroup from '../BookCardGroup';
import Link from '../Link';
import { translate } from 'react-polyglot';
import { links } from '../../lib/constants';
import PropTypes from 'prop-types';
import './ReadingListCardContainer.scss';

const ReadingListCardContainer = ({
    readingListItem,
    isMyReadingList,
    t,
    readingListsTitle
}) => {
    const { title, slug, bookResults, count } = readingListItem;
    const baseClassName = 'pb-readinglist-card-container';

    return (
        <div className="reading-list-card-wrapper">
            <Link
                href={
                    isMyReadingList
                        ? links.myReadinglistsDetail(slug)
                        : links.readinglists(slug)
                }
                parentClassName={`${baseClassName}__link`}
                isInternal={true}
                dataGTM={readingListsTitle}
            >
                <BookCardGroup
                    isReadingList
                    bookResults={bookResults}
                />
            </Link>
            <div className={`${baseClassName}__description-wrapper`}>
                <div className={`${baseClassName}__title`}>
                    <Link
                        readingListCard
                        href={
                            isMyReadingList
                                ? links.myReadinglistsDetail(slug)
                                : links.readinglists(slug)
                        }
                        parentClassName={`${baseClassName}__link`}
                        isInternal={true}
                        dataGTM={readingListsTitle}
                    >
                        {title}
                    </Link>
                </div>
                <div className={`${baseClassName}__books-count`}>
                    <span>{count}</span>
                    <span> {t('Book.book-text')}</span>
                </div>
            </div>
        </div>
    );
};

ReadingListCardContainer.propTypes = {
    readingListItem: PropTypes.shape({
        title: PropTypes.string.isRequired,
        slug: PropTypes.string.isRequired,
        bookResults: PropTypes.array.isRequired,
        count: PropTypes.number.isRequired
    }).isRequired,
    isMyReadingList: PropTypes.bool,
    t: PropTypes.func.isRequired,
    readingListsTitle: PropTypes.string
};

export default translate()(ReadingListCardContainer);
