import React, { Component } from 'react';
import './Book.scss';
import './BookDetails.scss';
import ShareMenu from '../ShareMenu';
import { isMobile } from '../../util';
import SvgIcon from '../SvgIcon';


class LikeAndShareRow extends Component {
    render() {
        const baseClassName = 'pb-book';
        const isRightToLeftDirection = localStorage.getItem('locale') === 'ar' || localStorage.getItem('locale') === 'ur';
        const {
                t,
                favouriteStat,
                readsCount,
                title,
                description,
                coverImage,
                landingPageBook,
                favouriteStatMainBook,
        } = this.props;

        const reviewClickHandler = () => {
            var elmnt = document.getElementById('commentsSectionContainer');

            elmnt.scrollIntoView()
            
        };

        return (
            <div className={`${baseClassName}__stats-share`}>
                {
                    (favouriteStat || favouriteStatMainBook) && 
                    <div className={`${baseClassName}__stats-share-item`}>
                    <div
                        className={
                            isRightToLeftDirection
                                ? `${baseClassName}__stats ${baseClassName}__stats-arabic`
                                : ''
                        }
                    >
                        {landingPageBook && (
                            <div className={`${baseClassName}__favouriteCount`}>
                                {landingPageBook
                                    ? favouriteStatMainBook
                                    : favouriteStat}
                            </div>
                        )}
                    </div>
                    </div>
                }
                {readsCount >= 0 && (
                    <div className={`${baseClassName}__stats-share-item`}>
                        <SvgIcon
                            name="Read" 
                            size="book-detail-read-count"   
                        />
                        <span>{readsCount}</span>
                    </div>
                )}
                <div 
                    className={`${baseClassName}__stats-share-item`} 
                    style={{cursor:'pointer'}}
                    onClick={reviewClickHandler}
                >
                    <SvgIcon
                        name="Comment"
                        size="book-detail-comment"    
                    />
                    <span>{t('Book.review')}</span>
                </div>
                <div className={`${baseClassName}__stats-share-item`}>
                    <ShareMenu
                        title={title}
                        href={window.location.href}
                        desc={description}
                        image={coverImage}
                        landingPageBook={landingPageBook}
                        align={isMobile() 
                            ? isRightToLeftDirection 
                                ? "left" 
                                :"right" 
                            : null}
                        isRightToLeftDirection={isRightToLeftDirection}
                    />
                </div>
            </div>
        );
    }
}

export default LikeAndShareRow;
