import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import './ContentStyler.scss';

const ContentStyler = ({
    children,
    theme,
    offline,
    topspace,
    myFavourite,
    manageReadingList,
}) => {
    const baseClassName = 'pb-content-styler';

    const classes = {
        [baseClassName]: true,
        [`${baseClassName}--${theme}`]: theme,
        [`${baseClassName}--offline`]: offline,
        [`${baseClassName}--topspace`]: topspace,
        [`${baseClassName}--manageReadingList`]: manageReadingList,
        [`${baseClassName}--myFavourite`]: myFavourite,
    };

    return <div className={classNames(classes)}>{children}</div>;
};

ContentStyler.defaultProps = {};

ContentStyler.propTypes = {
    children: PropTypes.node,
    theme: PropTypes.string,
    offline: PropTypes.bool,
    topspace: PropTypes.bool,
    myFavourite: PropTypes.bool,
    manageReadingList: PropTypes.bool,
};

export default ContentStyler;
