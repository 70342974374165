import React, { Component } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import DocumentTitle from 'react-document-title';
import { translate } from 'react-polyglot';
import { connect } from 'react-redux';
import Joyride from 'react-joyride';
import './ResourceDetail.scss';
import ShareMenu from '../ShareMenu';
import Comments from '../comments';
import Link from '../Link';
import BookDetailSummary from '../BookDetailSummary';
import SimilarResources from './SimilarResources';
import { favouriteReferenceWorkflow } from '../../redux/referencesActions';
import DocumentHeader from '../DocumentHeader';
import heartFilled from '../../assets/heart-filled.svg';
import heartUnFilled from '../../assets/heart-unfilled.svg';


import { References } from '../../api';

const mapDispatchToProps = {
    favouriteReferenceWorkflow,
};

@translate()
class ResourceDetail extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showDisqus: false,
            resourceReadsCount: this.props.resourceReadsCount,
            likesCount: this.props.likesCount,
            isFavourite: this.props.isFavourite,
        };
        this.handleToggleClick = this.handleToggleClick.bind(this);
    }

    componentWillReceiveProps = (nextProps) => {
        if (this.state.resourceReadsCount !== nextProps.resourceReadsCount) {
            this.setState({
                resourceReadsCount: nextProps.resourceReadsCount,
                likesCount: nextProps.likesCount,
            });
        }
    };

    handleToggleClick() {
        this.setState({
            showDisqus: true,
        });
    }

    onFavoriteClicked = (resourceID) => {
        const { favouriteReferenceWorkflow } = this.props;
        favouriteReferenceWorkflow(resourceID);
        if (this.state.isFavourite) {
            this.setState({
                likesCount: this.state.likesCount - 1,
                isFavourite: false,
            });
        } else {
            this.setState({
                likesCount: this.state.likesCount + 1,
                isFavourite: true,
            });
        }
    };

    render() {
        const baseClassName = 'pb-resource-detail';
        const {
            t,
            parentClassName,
            online,
            isLoggedIn,
            viewport,
            resourceDetail,
            resourcesResourceThumbnailGTM,
            resourcesResourceDownloadGTM,
            resourcesResourceFavoriteGTM,
            myLanguage,
            roles,
        } = this.props;

        const isRightToLeftDirection = localStorage.getItem('locale') === 'ar' || localStorage.getItem('locale') === 'ur';

        const {
            id,
            slug,
            thumbUrl,
            contentUrl,
            title,
            likesCount,
            similar_resources,
            synopsis,
        } = resourceDetail;

        const classes = {
            [baseClassName]: true,
            [parentClassName]: parentClassName,
        };

        let heartEl = this.state.isFavourite ? (
            <img
                src={heartFilled}
                alt="heart-filled"
                className="__heart-unfilled"
            />
        ) : (
            <img
                src={heartUnFilled}
                alt="heart-unfilled"
                className="__heart-unfilled"
            />
        );

        let favouriteStat = null;
        if (likesCount >= 0 && online) {
            favouriteStat = (
                <div className={parentClassName}>
                    <Link
                        normal
                        favouriteShare
                        onClick={
                            isLoggedIn ? () => this.onFavoriteClicked(id) : null
                        }
                        dataGTM={resourcesResourceFavoriteGTM}
                    >
                        {heartEl}
                        {this.state.isFavourite}
                        <span style={{ marginLeft: '3px', fontSize:'1rem' }}>
                            {this.state.likesCount}
                        </span>
                    </Link>
                </div>
            );
        }

        let steps = [
            {
                target: '.pb-link--fav-share',
                content: t('Resources.favouriteHelpGuide'),
                placementBeacon: 'left',
                disableBeacon: true,
            },
            {
                target: '.__resource-dowload-btn-section',
                content: t('Resources.saveOfflineHelpGuide'),
                placementBeacon: 'left',
            },
            {
                target: '.__comments',
                content: t('Resources.disqusHelpGuide'),
                placementBeacon: 'left',
            },
        ];

        return (
            <div className={classNames(classes)}>
                <DocumentHeader
                    title={title}
                    description={synopsis}
                    keywords={slug}
                    imageUrl={thumbUrl}
                    imgAlt={title}
                />
                <Joyride
                    enabled={true}
                    steps={steps}
                    continuous
                    showProgress
                    run={this.props.helpStatus}
                    scrollToFirstStep
                    scrollOffset={250}
                    disableScrolling={false}
                    locale={{
                        next: t('global.Next'),
                        back: t('global.Back'),
                        last: t('global.Last'),
                    }}
                    styles={{
                        options: {
                            arrowColor: '#e3ffeb',
                            backgroundColor: '#e3ffeb',
                            textColor: '#004a14',
                            overlayColor: 'rgba(0, 0, 0, 0.8)',
                            primaryColor: '#007AA4',
                            spotlightShadow: '0 0 15px rgba(0, 0, 0, 0.8)',
                            zIndex: 1000,
                        },
                    }}
                />
                <DocumentTitle title={`${title} - ${t('global.site-title')}`} />
                <div className="__resource-detail-section">
                    <div className="__resource-detail-left">
                        <div className="__resource-detail-left-content">
                            <Link
                                shouldOpenNewPage
                                parentClassName={`${baseClassName}__link`}
                                download
                                href={contentUrl}
                                dataGTM={resourcesResourceThumbnailGTM}
                                onClick={() =>
                                    References.handleDownloadCount(id)
                                }
                            >
                                <div
                                    className="__thumbnailImg"
                                    dataGTM={resourcesResourceThumbnailGTM}
                                >
                                    <img src={thumbUrl} alt="" />
                                </div>
                            </Link>
                        </div>
                    </div>

                    <div className="__resource-detail-right">
                        <div className="__resource-detail-right-content">
                            <div
                                className={
                                    myLanguage === 'lo'
                                        ? '__resource-detail-lo-title'
                                        : isRightToLeftDirection
                                        ? '__resource-detail-title __align-text-right'
                                        : '__resource-detail-title'
                                }
                            >
                                {title}
                            </div>
                            <div className="__resource-detail-desc">
                                <BookDetailSummary
                                    title={t('Book.summary')}
                                    description={synopsis}
                                    hideSummary
                                />
                            </div>
                            <div className="__status-share">
                                <div className={
                                    isRightToLeftDirection 
                                    ? "__resource-like-count-rtl"
                                    : "__resource-like-count"
                                }>
                                    <div
                                        className={
                                            isRightToLeftDirection 
                                            ? `${baseClassName}__like-count-rtl`
                                            : `${baseClassName}__like-count`                                        }
                                    >
                                        {favouriteStat}
                                    </div>
                                </div>

                                <div className="__share-section">
                                    <div>
                                        <ShareMenu
                                            title={title}
                                            href={window.location.href}
                                            thumbUrl={thumbUrl}
                                            contentUrl={contentUrl}
                                            desc={synopsis}
                                            hideTitle
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="__resource-btn-section">
                                <div className="__resource-dowload-btn-section">
                                    <Link
                                        shouldOpenNewPage
                                        parentClassName={`${baseClassName}__link`}
                                        download
                                        href={contentUrl}
                                        dataGTM={resourcesResourceDownloadGTM}
                                        onClick={() =>
                                            References.handleDownloadCount(id)
                                        }
                                    >
                                        <div
                                            className="__resource-download-btn"
                                            data-gtm={
                                                resourcesResourceDownloadGTM
                                            }
                                        >
                                            {t('global.download', 1)}
                                        </div>
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {isLoggedIn ? (
                    <div className={`${baseClassName}__disqus-section`}>
                        <div className="__disqussion">
                            <Comments
                                {...this.props}
                                source="resource"
                                id={id}
                                roles={roles}
                            />
                        </div>
                    </div>
                ) : null}
                <div>
                    <SimilarResources
                        offline={!online}
                        similarResources={similar_resources}
                        t={t}
                        viewport={viewport}
                    />
                </div>
            </div>
        );
    }
}

ResourceDetail.propTypes = {
    parentClassName: PropTypes.string,
};

const mapStateToProps = (state) => {
    return {
        helpStatus: state.help.helpStatus,
        roles: state.user.profile.roles,
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ResourceDetail);
