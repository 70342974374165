import React from 'react';
import PropTypes from 'prop-types';
import ReadingListCardContainer from '../../ReadingListCardContainer';
import Grid from '../../Grid';

const BooksSmallGrid = ({
    readingLists,
    horizontal,
    viewport,
    t,
    sectionClicked,
}) => {
    const myLanguage = localStorage.getItem('locale');

    if (!readingLists || readingLists.length === 0) {
        return null;
    }

    const bookSmallEls = readingLists.slice(0, 4).map((readingListItem) => {
        return (
            <ReadingListCardContainer
                myLanguage={myLanguage}
                key={readingListItem.id}
                readingListItem={readingListItem}
                readingListsTitle="home-reading-lists-title"
            />
        );
    });

    return (
        <Grid variant={window.screen.width > 1040 ? '4up' : '2up-6up'}>
            {bookSmallEls}
        </Grid>
    );
};

BooksSmallGrid.propTypes = {
    readingLists: PropTypes.arrayOf(PropTypes.object).isRequired,
    horizontal: PropTypes.bool,
    viewport: PropTypes.object,
    t: PropTypes.func.isRequired,
    sectionClicked: PropTypes.string.isRequired,
};

export default BooksSmallGrid;
