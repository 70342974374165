import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { connect } from 'react-redux';
// import { withRouter } from 'react-router';
import {
    fetchBookWorkflow,
    favouriteBookWorkflow,
    getBookDataWorkflow,
    getBookReaderStatusWorkflow,
    setBookReaderStatusWorkflow,
} from '../../redux/bookActions';
import {
    recordLocalization,
    recordBookReadCompleted,
} from '../../redux/googleAnalyticsActions';
import { translate } from 'react-polyglot';
import './RTRBookReaderContainer.scss';
import RTRBookReaderPageHeader from './components/RTRBookReaderPageHeader/RTRBookReaderPageHeader';
import RTRBookReaderPageFooter from './components/RTRBookReaderPageFooter/RTRBookReaderPageFooter';
import RTRBookReaderContent from './components/RTRBookReaderContent/index';
import { videoReadCountWorkflow } from '../../redux/readAloudsActions';
import DataLoader from '../DataLoader/DataLoader';
import withRouter from '../../util/withRouter';

const mapStateToProps = (state) => {
    return {
        viewport: state.viewport,
        profile: state.user.profile,
        isLoggedIn: state.user.isLoggedIn,
        online: state.network.online,
        bookAssets: state.book.assets,
        book: state.book.book,
    };
};

const mapDispatchToProps = {
    recordLocalization,
    recordBookReadCompleted,
    fetchBookWorkflow,
    favouriteBookWorkflow,
    getBookDataWorkflow,
    getBookReaderStatusWorkflow,
    setBookReaderStatusWorkflow,
};

@translate()
@connect(mapStateToProps, mapDispatchToProps)
class RTRBookReaderContainer extends Component {
    static defaultProps = {
        chromeless: false,
    };

    constructor(props) {
        super(props);

        this.state = {
            bookReaderData: {},
            pageNo: window.innerWidth > 1030 ? 1 : 0,
            addAnimation: false,
            totalPageCount: 0,
            isReadToMeModalVisible: false,
            isReadToMeVideoPlay: false,
            readToMeCount: null,
            xDown: null,
            yDown: null,
            language: '',
            bookData: {},
            bookPageStatusRemmembered: false,
            likesCount: 0,
            loader: true,
        };
    }
    getTouches(evt) {
        return (
            evt.touches || // browser API
            evt.originalEvent.touches
        ); // jQuery
    }
    handleTouchStart(evt) {
        const firstTouch = this.getTouches(evt)[0];
        this.setState({ xDown: firstTouch.clientX });
        this.setState({ yDown: firstTouch.clientY });
    }
    handleTouchMove(evt) {
        if (!this.state.xDown || !this.state.yDown) {
            return;
        }

        var xUp = evt.touches[0].clientX;
        var yUp = evt.touches[0].clientY;

        var xDiff = this.state.xDown - xUp;
        var yDiff = this.state.yDown - yUp;

        if (Math.abs(xDiff) > Math.abs(yDiff)) {
            /*most significant*/
            if (xDiff > 0) {
                if (this.state.pageNo < this.state.totalPageCount + 2) {
                    if (
                        (this.state.pageNo !== 0 &&
                            this.state.pageNo % 2 !== 0) || (
                            this.state.pageNo === this.state.totalPageCount + 1
                        )
                    ) {
                        this.props.setBookReaderStatusWorkflow(
                            this.props.location.pathname
                                .split('/')[2]
                                .split('-')[0],
                            (this.state.pageNo + 1) / 2,
                            this.state.pageNo === this.state.totalPageCount + 1
                                ? 'reset'
                                : ''
                        );
                    }
                    this.setState({ pageNo: this.state.pageNo + 1 });
                }
            } else {
                /* left swipe */
                if (this.state.pageNo > 0) {
                    if (
                        this.state.pageNo !== 0 &&
                        this.state.pageNo % 2 === 0
                    ) {
                        this.props.setBookReaderStatusWorkflow(
                            this.props.location.pathname
                                .split('/')[2]
                                .split('-')[0],
                            (this.state.pageNo - 1) / 2,
                            ''
                        );
                    }
                    this.setState({ pageNo: this.state.pageNo - 1 });
                }
            }
        } else {
            if (yDiff > 0) {
                /* down swipe */
            } else {
                /* up swipe */
            }
        }
        /* reset values */
        this.setState({ xDown: null });
        this.setState({ yDown: null });
    }
    prevPage(pageNumber) {
        if (pageNumber > 0) {
            this.props.setBookReaderStatusWorkflow(
                this.props.location.pathname.split('/')[2].split('-')[0],
                pageNumber,
                '',
                this.state.bookData.request_id
            );
            this.setState({
                pageNo: pageNumber,
                addAnimation: true,
            });
        }
    }
    nextPage(pageNumber) {
        if (pageNumber < this.state.totalPageCount) {
            this.props.setBookReaderStatusWorkflow(
                this.props.location.pathname.split('/')[2].split('-')[0],
                pageNumber,
                pageNumber * 2 === this.state.totalPageCount + 3 ||
                    pageNumber * 2 === this.state.totalPageCount + 4
                    ? 'reset'
                    : '',
                this.state.bookData.request_id
            );
            this.setState({
                pageNo: pageNumber,
                addAnimation: true,
            });
        }
    }

    hideAnimation() {
        setTimeout(() => {
            this.setState({
                addAnimation: false,
            });
        }, 1000);
    }

    readAgain() {
        this.setState({ pageNo: window.innerWidth > 1030 ? 1 : 0 });
    }
    onFavoriteClicked = (bookID) => {
        const { favouriteBookWorkflow } = this.props;
        favouriteBookWorkflow(bookID);
        if (this.props.book.isFavourite) {
            this.setState({
                likesCount: this.state.likesCount - 1,
            });
        } else {
            this.setState({
                likesCount: this.state.likesCount + 1,
            });
        }
    };
    componentWillMount() {
        this.props.fetchBookWorkflow(
            this.props.location.pathname.split('/')[2]
        );
        var bookDataStatus = this.props.getBookReaderStatusWorkflow(
            this.props.location.pathname.split('/')[2].split('-')[0]
        );
        bookDataStatus.then((response) => {
            if (
                response &&
                response.read_story_detail &&
                response.read_story_detail.current_page
            ) {
                // let { current_page } = response.read_story_detail;
                this.setState({
                    pageNo:
                        response.read_story_detail.current_page === 0
                            ? 1
                            : response.read_story_detail.current_page,
                    bookPageStatusRemmembered: true,
                });
            }
        });
        var bookData = this.props.getBookDataWorkflow(
            this.props.location.pathname.split('/')[2].split('-')[0]
        );
        bookData.then((resp) => {
            const data = resp.data
            data.pages = data.pages.filter((e) => e.display);
            this.setState({
                bookData: data,
                totalPageCount: data.pages.length,
                likesCount: data.like_count,
                loader: false,
            });
        });
    }

    componentWillReceiveProps(nextProps) {
        if (this.props.params.slug !== nextProps.params.slug) {
            this.props.fetchBookWorkflow(nextProps.params.slug);
        }
    }
    componentDidMount() {
        if (window.innerWidth < 1030) {
            let platform = window.navigator.platform,
                macosPlatforms = [
                    'Macintosh',
                    'MacIntel',
                    'MacPPC',
                    'Mac68K',
                    'iPad',
                    'iPhone',
                ];
            var myImg = document.getElementById('root');
            if (macosPlatforms.indexOf(platform) !== -1) {
                console.log('Apple device');
            } else {
                myImg.requestFullscreen();
            }
        }
    }


    render() {
        const baseClassName = 'pb-app';
        const {
            viewport,
            chromeless,
            isLoggedIn,
            online,
        } = this.props;

        const classes = {
            [baseClassName]: true,
            [`${baseClassName}--fill-height`]: !chromeless,
        };

        return this.state.loader ? (
            <DataLoader />
        ) : (
            Object.keys(this.state.bookData).length > 0 && (
                <div className={classNames(classes)}>
                    {chromeless
                        ? null
                        : window.innerWidth > 1030 && (
                            <RTRBookReaderPageHeader
                                bookData={this.state.bookData}
                                t={this.props.t}
                            />
                        )}
                    <div className={`${baseClassName}__content`}>
                       {this.props.book&& <RTRBookReaderContent
                            handleTouchStart={this.handleTouchStart.bind(this)}
                            handleTouchMove={this.handleTouchMove.bind(this)}
                            prevPage={this.prevPage.bind(this)}
                            online={online}
                            nextPage={this.nextPage.bind(this)}
                            onFavoriteClicked={this.onFavoriteClicked.bind(
                                this
                            )}
                            isLoggedIn={isLoggedIn}
                            bookData={this.bookData}
                            book={this.props.book}
                            {...this.state}
                            viewport={viewport}
                            t={this.props.t}
                            setBookReaderStatusWorkflow={
                                this.props.setBookReaderStatusWorkflow
                            }
                            readAgain={this.readAgain.bind(this)}
                            addAnimation={this.state.addAnimation}
                            hideAnimation={this.hideAnimation.bind(this)}
                            likesCount={this.state.likesCount}
                            history={this.props.history}
                        />}
                    </div>
                    {chromeless
                        ? null
                        : window.innerWidth > 1030 &&this.props.book&& (
                            <RTRBookReaderPageFooter
                                book={this.props.book}
                                readToMeAction={videoReadCountWorkflow}
                                t={this.props.t}
                                {...this.state}
                            />
                        )}
                </div>
            )
        );
    }
}

RTRBookReaderContainer.propTypes = {
    chromeless: PropTypes.bool,
};

export default withRouter(RTRBookReaderContainer);
// export default RTRBookReaderContainer;