import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Overlay from '../Overlay';
import SvgIcon from '../SvgIcon';
import { keyCodes } from '../../lib/constants';
import './Modal.scss';


class Modal extends Component {
    static defaultProps = {
        background: 'default',
        search: false,
    };

    componentDidMount() {
        document.addEventListener('keydown', this.onKeyDown);
    }

    componentWillUnmount() {
        document.removeEventListener('keydown', this.onKeyDown);
    }

    onKeyDown = e => {
        if (e.keyCode === keyCodes.esc && this.props.onClose) {
            this.props.onClose();
        }
    };

    render() {
        const baseClassName = 'pb-modal';

        const {
            children,
            footer,
            header,
            headerTitle,
            contentPadding,
            noContentPadding,
            noContentScroll,
            fillViewport,
            background,
            onClose,
            noDimensionRestrictions,
            overlayTheme,
            search,
            isBookSectionMobile,
            isVideos,
            interviewCard,
            swapStoryModal,
            languageModal
        } = this.props;

        const isRightToLeftDirection = localStorage.getItem("locale") === "ar" || localStorage.getItem('locale') === 'ur';

        const classes = {
            [baseClassName]: true,
            [`${baseClassName}--content-padding`]: contentPadding,
            [`${baseClassName}--no-content-padding`]: noContentPadding,
            [`${baseClassName}--footer`]: footer,
            [`${baseClassName}--header`]: header,
            [`${baseClassName}--header-title`]: headerTitle,
            [`${baseClassName}--fill-viewport`]: fillViewport,
            [`${baseClassName}--no-content-scroll`]: noContentScroll,
            [`${baseClassName}--bg-${background}`]: background,
            [`${baseClassName}--no-dimension-restrictions`]: noDimensionRestrictions,
            [`${baseClassName}--closable`]: onClose,
            [`${baseClassName}--interview-card`]: interviewCard,
            [`${baseClassName}--book-section`]: isBookSectionMobile,
            [`${baseClassName}--book-section`]: isVideos,
            [`${baseClassName}--swap-story-modal`]: swapStoryModal
        };

        const myLanguage = localStorage.getItem('locale')

        const closeClasses = {
            [ languageModal ? `${baseClassName}__language-modal-close`
                : isRightToLeftDirection 
                    ? `${baseClassName}__close-arabic` 
                    : `${baseClassName}__close`
                ]: true,
            [`${baseClassName}__close-search`]: search,
        };

        return (
            <div className={classNames(classes)}>
                <Overlay theme={overlayTheme} />
                <div className={`${baseClassName}__container`}>
                    <div className={`${baseClassName}__bounds`}>
                        <div className={ languageModal ? `${baseClassName}__language-content-wrapper` : `${baseClassName}__content-wrapper`}>
                            <div className={`${baseClassName}__content`}>
                                {children}
                            </div>
                        </div>
                        {header ? (
                            <div className={myLanguage === 'lo' ? `${baseClassName}__header-lo` : `${baseClassName}__header`}>
                                {header}
                            </div>
                        ) : null}
                        {
                            headerTitle ? (
                                <div className={myLanguage === 'lo' ? `${baseClassName}__header-lo-title` : myLanguage === 'ar' ? `${baseClassName}__header-ar-title` : `${baseClassName}__header-title`}>
                                    {headerTitle}
                                </div>
                            ) : null}
                        {footer ? (
                            <div className={`${baseClassName}__footer`}>
                                {footer}
                            </div>
                        ) : null}
                        {onClose ? (
                            <div
                                className={classNames(closeClasses)}
                                onClick={onClose}
                                dataGTM="modal-close"
                                type='button'
                            >
                                <SvgIcon name= {languageModal ? "close-mw" : "close"} dataGTM="modal-close" />
                            </div>
                        ) : null}
                    </div>
                </div>
            </div>
        );
    }
}

Modal.propTypes = {
    children: PropTypes.node,
    footer: PropTypes.node,
    header: PropTypes.node,
    noContentPadding: PropTypes.bool,
    noContentScroll: PropTypes.bool,
    fillViewport: PropTypes.bool,
    noDimensionRestrictions: PropTypes.bool,
    onClose: PropTypes.func,
    /* Default background is default */
    background: PropTypes.oneOf(['default', 'transparent']),
    overlayTheme: PropTypes.shape(Overlay.propTypes.theme),
    search: PropTypes.bool,
};

export default Modal;
