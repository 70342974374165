import React, { Component } from 'react';
import PropTypes from 'prop-types';
import './Caret.scss';

class Caret extends Component {
    static defaultProps = {
        direction: 'down',
        variant: 'default',
    };

    render() {
        const { variant } = this.props;
        const baseClassName = 'pb-caret';
        const classNames = [baseClassName];
        let imageEl = null;
            if(variant === 'white'){
               imageEl = <img
                    className={classNames.join(' ')}
                    src={require('./Caret-white.svg')}
                    alt="caret-white"
                />
            }
            else if (variant === 'deep-charcoal'){
                imageEl = <img
                className={classNames.join(' ')}
                src={require('./Caret-dc.svg')}
                alt="caret-dc"
            />
            }
            else if (variant === 'twilight-lavender'){
                imageEl = <img
                className={classNames.join(' ')}
                src={require('./Caret-tl.svg')}
                alt="caret-tl"
            />
            }
            else{
               imageEl = <img
                       className={classNames.join(' ')}
                       src={require('./Caret-down.svg')}
                       alt="caret"
                   /> 
            }

        if (this.props.direction) {
            classNames.push(`${baseClassName}--${this.props.direction}`);
        }

        if (this.props.parentClassName) {
            classNames.push(this.props.parentClassName);
        }

        return imageEl;
    }
}

Caret.propTypes = {
    direction: PropTypes.oneOf(['down', 'up', 'left', 'right']),
    parentClassName: PropTypes.string,
    variant: PropTypes.oneOf(['default', 'white']),
};

export default Caret;
