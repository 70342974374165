import React, { Component } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import queryString from 'query-string';
import { translate } from 'react-polyglot';
import { replace } from 'react-router-redux';
import u from 'updeep';
import { cloneDeep } from 'lodash';
import './ThemedReadingListContainer.scss';
import ThemedReadingList from '../ThemedReadingList';
import Pagination from '../Pagination';
import LoaderBlock from '../LoaderBlock';
import FiltersBar from '../FiltersBar';
import { applyFilter, resetFilters } from '../../redux/allFiltersActions';
import { sortOptions } from '../../lib/constants';
import {
    fetchReadingListDetailsWorkflow,
    fetchReadingListsFiltersWorkflow,
} from '../../redux/readingListsActions';
import withRouter from '../../util/withRouter';

const defaultFilters = {
    category: [],
    publisher: [],
    role: [],
    style: [],
    language: [],
    sort: sortOptions.position,
    tags: [],
    query: '',
};

class ThemedReadingListContainer extends Component {
    constructor(props) {
        super(props);
        const { t } = props;

        this.state = {
            filtersSearchText: {
                category: '',
                publisher: '',
                style: '',
                language: '',
            },
            isReadingListMobile: false,
        };

        this.filtersBarSortOptions = [
            {
                name: t('sort.relevance'),
                queryValue: sortOptions.relevance,
            },
            {
                name: t('sort.new-arrivals-images'),
                queryValue: sortOptions.newArrivals,
            },
            {
                name: t('sort.most-viewed'),
                queryValue: sortOptions.mostViewed,
            },
            {
                name: t('sort.most-liked'),
                queryValue: sortOptions.mostLiked,
            },
        ];

        this.filtersPanelSortOptions = this.filtersBarSortOptions.map(so => ({
            label: so.name,
            value: so.queryValue,
        }));
    }

    componentDidMount() {
        const {
            fetchReadingListDetailsWorkflow,
            fetchReadingListsFiltersWorkflow,
        } = this.props;
        this.isMobile();

        fetchReadingListsFiltersWorkflow().then(response => {
            let filters = this.getAppliedFilters();
            filters.language = response.data.current_language;
            const searchParams = queryString.stringify(filters);
            this.props.navigate({ search: `?${searchParams}` }, { replace: true });
            fetchReadingListDetailsWorkflow(filters);
        });
    }

    componentDidUpdate(nextProps) {
        if (this.props.location.search !== nextProps.location.search) {
            if (this.props.location.search === '') {
                this.isMobile();
                this.props.fetchReadingListsFiltersWorkflow().then(response => {
                    let filters = this.getAppliedFilters();
                    filters.role = [];
                    filters.role = filters.role.concat(response.data.current_role);
                    filters.language = response.data.current_language;
                    const searchParams = queryString.stringify(filters);
                    this.props.navigate({ search: `?${searchParams}` }, { replace: true });
                    this.props.fetchReadingListDetailsWorkflow(filters);
                });
            }
        }
    }

    isMobile = () => {
        if (
            window.screen.width <= 768 &&
            window.matchMedia('(orientation: portrait)').matches
        ) {
            this.setState({
                isReadingListMobile: true,
            });
        } else {
            this.setState({
                isReadingListMobile: false,
            });
        }
    };

    updateFiltersSearchText = (key, text) => {
        this.setState(
            u(
                {
                    filtersSearchText: {
                        [key]: text,
                    },
                },
                this.state
            )
        );
    };

    updateStyleSearchText = e =>
        this.updateFiltersSearchText('style', e.target.value);
    updatePublisherSearchText = e =>
        this.updateFiltersSearchText('publisher', e.target.value);
    updateCategorySearchText = e =>
        this.updateFiltersSearchText('category', e.target.value);
    updateCategorySearchText = e =>
        this.updateFiltersSearchText('language', e.target.value);

    getAppliedFilters = (props = this.props) => {
        const parsedFilters = queryString.parse(props.location.search);
        Object.entries(parsedFilters).forEach(([k, v]) => {
            if (Array.isArray(defaultFilters[k]) && !Array.isArray(v)) {
                parsedFilters[k] = [v];
            }
        });

        return {
            ...defaultFilters,
            ...parsedFilters,
        };
    };

    applyFilter = (type, value) => {
        const appliedFilters = cloneDeep(this.getAppliedFilters());
        const filterIsArray = Array.isArray(appliedFilters[type]);

        if (filterIsArray) {
            appliedFilters[type].push(value);
        } else {
            appliedFilters[type] = value;
        }

        const searchParams = queryString.stringify(appliedFilters);
        this.props.navigate({ search: `?${searchParams}` }, { replace: true });
        this.props.fetchReadingListDetailsWorkflow(appliedFilters);
    };

    removeFilter = (type, value) => {
        const appliedFilters = cloneDeep(this.getAppliedFilters());

        if (!appliedFilters[type]) {
            return;
        }

        if (Array.isArray(appliedFilters[type])) {
            const index = appliedFilters[type].indexOf(value);
            if (index !== -1) {
                appliedFilters[type].splice(index, 1);
            }
        } else {
            delete appliedFilters[type];
        }

        const searchParams = queryString.stringify(appliedFilters);
        this.props.navigate({ search: `?${searchParams}` }, { replace: true });
        this.props.fetchReadingListDetailsWorkflow(appliedFilters);
    };

    onSortOptionChanged = option => this.applyFilter('sort', option);

    onLoadMore = () =>
        this.props.fetchReadingListDetailsWorkflow(
            this.getAppliedFilters(),
            this.props.slug,
            this.props.loadedPages + 1
        );



    render() {
        const baseClassName = 'pb-themed-reading-list-container';
        const {
            parentClassName,
            themedReadingLists,
            loadedPages,
            totalListsCount,
            isFetchingMoreReadingList,
            filters,
            t,
            isFetchingReadingList,
            myLanguage
        } = this.props;

        const shouldShowLoadMore = loadedPages && loadedPages < totalListsCount;

        const classes = {
            [baseClassName]: true,
            [parentClassName]: parentClassName,
        };

        const { filtersSearchText } = this.state;
        const appliedFilters = this.getAppliedFilters();

        if (!filters || isFetchingReadingList) {
            return <LoaderBlock />;
        }

        let filtersComponent = null;

        filtersComponent = (
            <FiltersBar
                noTopBorder
                pullUp
                filters={filters}
                applyFilter={this.applyFilter}
                removeFilter={this.removeFilter}
                appliedFilters={appliedFilters}
                styleSearchValue={filtersSearchText.style}
                updateStyleSearchValue={this.updateStyleSearchText}
                categorySearchValue={filtersSearchText.category}
                updateCategorySearchValue={this.updateCategorySearchText}
                publisherSearchValue={filtersSearchText.publisher}
                updatePublisherSearchValue={this.updatePublisherSearchText}
                resultsCount={totalListsCount}
                sortOptions={this.filtersBarSortOptions}
                applySortOption={this.onSortOptionChanged}
                appliedSortOption={appliedFilters.sort}
                isReadingList
                isReadingListMobile
                languageFilterGTM="themed-reading-list-language-filter"
                alphabetSortGTM="themed-reading-list-alphabetic-sort"
            />
        );

        const NoResults = translate()(({ t }) => (
            <span>
                <p>{t('Books.no-result-warning-1')}</p>
                <p>{t('Books.no-result-warning-2')}</p>
                <ul>
                    <li>{t('Books.no-result-warning-3')}</li>
                    <li>{t('Books.no-result-warning-4')}</li>
                    <li>{t('Books.no-result-warning-6')}</li>
                </ul>
            </span>
        ));

        return (
            <div className={classNames(classes)}>
                {themedReadingLists && themedReadingLists.length >= 0 ? (
                    <div className="__video-filter-component">
                        {filtersComponent}
                    </div>
                ) : null}
                <div>
                    {
                        themedReadingLists.length <= 0 ?
                            <div className="no-results">
                                <NoResults />
                            </div>
                            : null
                    }
                </div>
                <ThemedReadingList
                    myLanguage={myLanguage}
                    themedReadingLists={themedReadingLists}
                    applyFilter={this.applyFilter}
                    removeFilter={this.removeFilter}
                    onSortOptionChanged={this.onSortOptionChanged}
                    tags={appliedFilters.tags}
                    appliedFilters={{ ...appliedFilters }}
                />
                {shouldShowLoadMore ? (
                    <Pagination
                        onClick={this.onLoadMore}
                        loading={isFetchingMoreReadingList}
                        dataGTM="thematic-reading-list-view-more"
                    />
                ) : null}
            </div>
        );
    }
}

ThemedReadingListContainer.propTypes = {
    parentClassName: PropTypes.string,
};

const mapStateToProps = () => ({});

const mapDispatchToProps = {
    fetchReadingListsFiltersWorkflow,
    fetchReadingListDetailsWorkflow,
    replace,
    applyFilter,
    resetFilters,
};

export default withRouter(translate()(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(ThemedReadingListContainer)
));
