import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import './Block.scss';

const Block = ({
    children,
    background = 'default',
    noHorizontalPadding = false,
    noVerticalPadding = false,
    offlineSection,
    normalPadding,
    noMargin,
    verticalMargin
}) => {
    const baseClassName = 'pb-block';

    const classes = {
        [baseClassName]: true,
        [`${baseClassName}--bg-${background}`]: true,
        [`${baseClassName}--no-horizontal-padding`]: noHorizontalPadding,
        [`${baseClassName}--no-vertical-padding`]: noVerticalPadding,
        [`${baseClassName}--offline-section`]: offlineSection,
        [`${baseClassName}--normal-padding`]: normalPadding,
        [`${baseClassName}--no-margin`]: noMargin,
        [`${baseClassName}--vertical-margin`]: verticalMargin
    };

    return <div className={classNames(classes)}>{children}</div>;
};

Block.propTypes = {
    children: PropTypes.node,
    noHorizontalPadding: PropTypes.bool,
    noVerticalPadding: PropTypes.bool,
    background: PropTypes.oneOf(['default', 'transparent']),
    offlineSection: PropTypes.bool,
    normalPadding: PropTypes.bool,
    noMargin: PropTypes.bool,
    verticalMargin: PropTypes.bool,
};

export default Block;
