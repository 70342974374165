import React, { Component } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { translate } from 'react-polyglot';
import VideoCard from '../VideoCard';
import CardShelf from '../CardShelf';
import { videoReadCountWorkflow } from '../../redux/videoActions';


const renderVideoEls = (
    t,
    videos,
    sectionClicked,
    offline,
    isSimilarReadAloud,
    isSimilarVideo,
    myLanguage,
    isLoggedIn
) => {
    return videos.map(videoItem => {
        return (
            <VideoCard
                isLoggedIn={isLoggedIn}
                myLanguage={myLanguage}
                key={videoItem.id}
                videoID={videoItem.id}
                videoSlug={videoItem.slug}
                videoTitle={videoItem.name}
                videoThumbnail={videoItem.thumbUrl}
                videoUrl={videoItem.materialUrl}
                videoDownloadUrl={videoItem.downloadUrl}
                videoLikes={videoItem.likesCount}
                videoReads={videoItem.readsCount}
                videoTime={videoItem.duration}
                isFavourite={videoItem.is_favourite}
                readCount={videoReadCountWorkflow}
                hideMenu
                videoThumbnailGTM="related-video-thumbnail"
                videoTitleGTM="related-video-title"
                sourceData={isSimilarReadAloud ? 'isSimilarReadAloud' : 'isSimilarVideo'}
                isSimilarVideo={isSimilarVideo}
                isSimilarReadAloud={isSimilarReadAloud}
                youtubeIdentifier={videoItem.youtube_video_identifier}
            />
        );
    });
};

@translate()
class VideoShelf extends Component {
    static defaultProps = {};

    render() {
        const {
            videos,
            viewport,
            t,
            sectionClicked,
            offline,
            isSimilarReadAloud,
            isSimilarVideo,
            myLanguage,
            isLoggedIn
        } = this.props;

        const baseClassName = 'pb-book-shelf';

        const classes = {
            [baseClassName]: true,
        };

        return (
            <div className={classNames(classes)}>
                <CardShelf cellWidth="book-card" viewport={viewport}>
                    {renderVideoEls(
                        t,
                        videos,
                        sectionClicked,
                        offline,
                        isSimilarReadAloud,
                        isSimilarVideo,
                        myLanguage,
                        isLoggedIn
                    )}
                </CardShelf>
            </div>
        );
    }
}

VideoShelf.propTypes = {
    viewport: PropTypes.object.isRequired,
    videos: PropTypes.arrayOf(PropTypes.shape(VideoCard.propTypes)).isRequired,
};

export default VideoShelf;
