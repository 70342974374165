import { createAction } from 'redux-actions';
import { createAsyncActions, runAsyncWorkflow } from '../lib/reduxHelpers';
import { Book } from '../api';

export const [fetchBook, receiveBook, fetchBookError] = createAsyncActions(
    'BOOK',
    slug => ({ slug }),
    ({ data }) => {
        //--- For google analytics
        console.log("book Actions data",data)
        // window.dataLayer.push({
        //     event: 'API Request', // prepend response data with this key/value pair
        //     api_name: 'stories', // prepend response data with this key/value pair and add API path
        //     stories: {
        //         name: data.name,
        //         id: data.id,
        //         language: data.language,
        //         level: data.level,
        //         status: data.status,
        //         liked: data.liked,
        //         isTranslation: data.isTranslation,
        //         readsCount: data.readsCount,
        //         likesCount: data.likesCount,
        //         readingListMembershipCount: data.readingListMembershipCount,
        //         orientation: data.orientation,
        //         recommended: data.recommended,
        //         pagesCount: data.pagesCount,
        //         availableForOfflineMode: data.availableForOfflineMode,
        //         isFavourite: data.isFavourite,
        //         publisher: {
        //             slug: data.publisher.slug,
        //             name: data.publisher.name,
        //             website: data.publisher.website,
        //             logo: data.publisher.logo,
        //         },
        //         publishedTime: data.publishedTime,
        //     },
        // });
        return { book: data };
    }
);

export const fetchBookWorkflow = slug => {
    const fetchPromise = Book.fetch(slug);
    
    return runAsyncWorkflow(
        {
            fetch: fetchBook,
            receive: receiveBook,
            error: fetchBookError,
        },
        fetchPromise
    );
};

export const [
    favouriteBook,
    receiveFavouriteBook,
    favouriteBookError,
] = createAsyncActions(
    'FAV_BOOK',
    ({ bookId }) => ({ bookId }),
    ({ ok, bookId }) => ({ ok, bookId })
);

export const favouriteBookWorkflow = bookId => {
    const favBookPromise = Book.toggleFavourite(bookId);

    return runAsyncWorkflow(
        {
            fetch: favouriteBook,
            receive: receiveFavouriteBook,
            error: favouriteBookError,
        },
        favBookPromise,
        { bookId }
    );
};

export const [
    fetchFavouriteBooks,
    receiveFetchFavouriteBooks,
    fetchFavouriteBooksError,
] = createAsyncActions(
    'FETCH_FAV_BOOKS',
    () => { },
    ({ ok, counts, data, metadata }) => ({
        ok,
        counts,
        favBooks: data,
        metadata,
    })
);

export const fetchFavouriteBooksWorkflow = () => {
    const fetchFavouritePromise = Book.fetchFavouriteBooks();

    return runAsyncWorkflow(
        {
            fetch: fetchFavouriteBooks,
            receive: receiveFetchFavouriteBooks,
            error: fetchFavouriteBooksError,
        },
        fetchFavouritePromise
    );
};


//favourite handling for similar books
export const [
    favouriteSimilarBooks,
    receivefavouriteSimilarBooks,
    favouriteSimilarBooksError,
] = createAsyncActions(
    'FAVOURITE_SIMILAR_BOOKS',
    ({ bookId }) => ({ bookId }),
    ({ ok, bookId }) => ({ ok, bookId })
);

export const favouriteSimilarBooksWorkflow = bookId => {
    const favBookPromise = Book.toggleFavourite(bookId);

    return runAsyncWorkflow(
        {
            fetch: favouriteSimilarBooks,
            receive: receivefavouriteSimilarBooks,
            error: favouriteSimilarBooksError,
        },
        favBookPromise,
        { bookId }
    );
};
//fetch book data
export const [
    rtrGetBookData,
    receiveGetBookData,
    getBookDataError,
] = createAsyncActions(
    'GET_BOOK_DATA',
    ({ bookId }) => ({ bookId }),
    ({ ok, bookId }) => ({ ok, bookId })
);

export const getBookDataWorkflow = bookId => {
    const bookDataPromise = Book.rtrGetBookData(bookId);
    return runAsyncWorkflow(
        {
            fetch: rtrGetBookData,
            receive: receiveGetBookData,
            error: getBookDataError,
        },
        bookDataPromise,
        { bookId }
    );
};
//fetch book data
export const [
    rtrsetBookReaderStatus,
    receivertrsetBookReaderStatus,
    rtrsetBookReaderStatusError,
] = createAsyncActions(
    'SET_BOOK_STATUS',
    ({ slug, current_page, type }) => ({
        slug,
        current_page,
        type
    })
);

export const setBookReaderStatusWorkflow = (
    slug,
    current_page,
    type = ''
) => {
    const rtrsetBookReaderStatusPromise = Book.rtrsetBookReaderStatus(
        slug,
        current_page,
        type
    );

    return runAsyncWorkflow(
        {
            fetch: rtrsetBookReaderStatus,
            receive: receivertrsetBookReaderStatus,
            error: rtrsetBookReaderStatusError,
        },
        rtrsetBookReaderStatusPromise,
        { slug, current_page, type}
    );
};
//fetch book data
export const [
    rtrGetBookReaderStatus,
    receivegetBookReaderStatus,
    getBookReaderStatusError,
] = createAsyncActions(
    'GET_BOOK_DATA',
    ({ bookId }) => ({ bookId }),
    ({ ok, bookId }) => ({ ok, bookId })
);

export const getBookReaderStatusWorkflow = bookId => {
    const rtrgetBookReaderStatusPromise = Book.rtrgetBookReaderStatusWorkflow(bookId);

    return runAsyncWorkflow(
        {
            fetch: rtrGetBookReaderStatus,
            receive:  receivegetBookReaderStatus,
            error:  getBookReaderStatusError,
        },
        rtrgetBookReaderStatusPromise,
        { bookId }
    );
};