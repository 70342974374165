import { handleActions } from 'redux-actions';
import u from 'updeep';
import * as actions from './referencesActions';

const initialState = {
    isFetchingFilters: false,
    filters: null,
    references: [],
    isFetchingReferences: false,
    isFetchingMoreReferences: false, // for pagination
    loadedPages: 0,
    totalReferencesCount: 0,
    resourceHits: 0,
    resourceDetail: {},
    isFetchingResourceDetail: false,

    //favourites related
    isFetchingFavouriteResources: false,
    favouriteResources: [],
    totalFavouriteResourcesCount: 0,
};

export default handleActions(
    {
        [actions.fetchAllReferencesFilters]: (state, action) =>
            u(
                {
                    isFetchingFilters: true,
                },
                state
            ),

        [actions.receiveAllReferencesFilters]: (state, action) =>
            u(
                {
                    isFetchingFilters: false,
                    filters: action.payload.filters,
                },
                state
            ),

        [actions.fetchReferences]: (state, action) =>
            u(
                {
                    isFetchingReferences: true,
                },
                state
            ),

        [actions.receiveReferences]: (state, action) =>
            u(
                {
                    isFetchingReferences: false,
                    totalReferencesCount: action.payload.metadata.totalPages,
                    references: action.payload.references,
                    loadedPages: 1,
                    resourceHits: action.payload.metadata.hits,
                },
                state
            ),

        [actions.fetchMoreReferences]: (state, action) =>
            u(
                {
                    isFetchingMoreReferences: true,
                },
                state
            ),

        [actions.receiveMoreReferences]: (state, action) =>
            u(
                {
                    isFetchingMoreReferences: false,
                    references: state.references.concat(
                        action.payload.references
                    ),
                    loadedPages: state.loadedPages + 1,
                },
                state
            ),


        [actions.favouriteReference]: (state, action) => {
            //Get the associated video and set its isFovouriting state
            //Get the index of the video
            let index = state.references.findIndex(
                reference => reference.id === action.payload.referenceId
            );

            const favResourceIndex = state.favouriteResources.findIndex(
                resource => resource.id === action.payload.referenceId
            );
            if (index >= 0) {
                return u(
                    {
                        references: u.updateIn([index, 'isFavouriting'], true),
                    },
                    state
                );
            } else if (favResourceIndex >= 0) {
                return u(
                    {
                        favouriteResources: u.updateIn(
                            [favResourceIndex, 'isFavouriting'],
                            true
                        ),
                    },
                    state
                );
            } else {
                return state;
            }
        },

        // [actions.favouriteReference]: (state, action) => {
        //     //Get the associated video and set its isFovouriting state
        //     //Get the index of the video
        //     let index = state.references.findIndex(
        //         reference => reference.id === action.payload.referenceId
        //     );

        //     const favResourceIndex = state.favouriteResources.findIndex(
        //         resource => resource.id === action.payload.referenceId
        //     );

        //     if (index >= 0) {
        //         return u(
        //             {
        //                 references: u.updateIn([index, 'isFavouriting'], true),
        //             },
        //             state
        //         );
        //     } else if (favResourceIndex) {
        //         return u(
        //             {
        //                 favouriteResources: u.updateIn(
        //                     [favResourceIndex, 'isFavouriting'],
        //                     true
        //                 ),
        //             },
        //             state
        //         );
        //     } else {
        //         return state;
        //     }
        // },
        [actions.receiveFavouriteReference]: (state, action) => {
            //Get the associated video and set its isFovouriting state
            //Get the index of the video
            let index = state.references.findIndex(
                reference => reference.id === action.payload.referenceId
            );

            const favResourceIndex = state.favouriteResources.findIndex(
                resource => resource.id === action.payload.referenceId
            );

            if (index >= 0) {
                let favourite = state.references[index].is_favourite;
                const newLikes = !favourite
                    ? state.references[index].likesCount + 1
                    : state.references[index].likesCount - 1;
                const likesTemp = u(
                    {
                        references: u.updateIn([index, 'likesCount'], newLikes),
                    },
                    state
                );
                let temp = u(
                    {
                        references: u.updateIn([index, 'isFavouriting'], false),
                    },
                    likesTemp
                );
                return u(
                    {
                        references: u.updateIn([index, 'is_favourite'], !favourite),
                    },
                    temp
                );

                // let favourite = state.references[index].is_favourite;
                // let temp = u(
                //     {
                //         references: u.updateIn([index, 'isFavouriting'], false),
                //     },
                //     state
                // );
                // return u(
                //     {
                //         references: u.updateIn(
                //             [index, 'is_favourite'],
                //             !favourite
                //         ),
                //     },
                //     temp
                // );
            } else if (favResourceIndex >= 0) {
                let favourite =
                    state.favouriteResources[favResourceIndex].is_favourite;
                const newLikes = !favourite
                    ? state.favouriteResources[favResourceIndex].likesCount + 1
                    : state.favouriteResources[favResourceIndex].likesCount - 1;
                const likesTemp = u(
                    {
                        favouriteResources: u.updateIn(
                            [favResourceIndex, 'likesCount'],
                            newLikes
                        ),
                    },
                    state
                );
                let temp = u(
                    {
                        favouriteResources: u.updateIn(
                            [favResourceIndex, 'isFavouriting'],
                            false
                        ),
                    },
                    likesTemp
                );
                return u(
                    {
                        favouriteResources: favourite
                            ? state.favouriteResources.filter(
                                resource =>
                                    resource.id !== action.payload.referenceId
                            )
                            : state.favouriteResources,
                        totalFavouriteResourcesCount: favourite
                            ? state.totalFavouriteResourcesCount - 1
                            : state.totalFavouriteResourcesCount,
                    },
                    temp
                );
            } else {
                return state;
            }
        },

        [actions.fetchFavouriteResources]: (state, action) =>
            u(
                {
                    isFetchingFavouriteResources: true,
                },
                state
            ),

        [actions.receiveFetchFavouriteResources]: (state, action) =>
            u(
                {
                    isFetchingFavouriteResources: false,
                    totalFavouriteResourcesCount: action.payload.metadata.hits,
                    favouriteResources: action.payload.favResources,
                },
                state
            ),
        [actions.fetchReferenceDetail]: (state, action) =>
            u(
                {
                    isFetchingResourceDetail: true,
                },
                state
            ),

        [actions.receivefetchReferenceDetail]: (state, action) =>
            u(
                {
                    isFetchingResourceDetail: false,
                    resourceDetail: action.payload.resourceDetail,
                },
                state
            ),
        [actions.fetchReferenceDetailError]: (state, action) =>
            u(
                {
                    isFetchingResourceDetail: false,
                },
                state
            ),
    },
    initialState
);
