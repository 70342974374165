import React, { Component } from 'react';
import './RTRBookReaderContent.scss';
import RTRBookFavourites from '../../../RTRBookFavourites';
import ShareMenu from '../../../RTRShareMenu';

class RTRBookReadAgain extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        //rtl
        const isRightToLeftDirection =
            localStorage.getItem('locale') === 'ar' ||
            localStorage.getItem('locale') === 'ur';
        return (
            <div className="bookReaderPageWrapper" style={{ height: '80vh' }}>
                <div className="leftBookPage firstPage">
                    <div
                        className="bookCoverPageContainer readAgainPage"
                        style={{ margin: '0px' }}
                    >
                        <div>
                            {' '}
                            <h1 className="bookTitle">
                                {this.props.bookData.title}
                            </h1>
                        </div>
                        <div
                            className="bookFinishReading"
                            onClick={() => {
                                this.props.setBookReaderStatusWorkflow(
                                    this.props.book.id,
                                    this.props.pageNo,
                                    'finish'
                                );
                                window.history.back();
                            }}
                        >
                            <span>
                                {this.props.t('Book.contentFinishReading')}
                            </span>
                        </div>

                        <div
                            className="bookReadAgain readAgainFont"
                            onClick={() => {
                                this.props.setBookReaderStatusWorkflow(
                                    this.props.book.id,
                                    this.props.pageNo,
                                    'finish'
                                );
                                this.props.readAgain();
                                this.props.resetAgain();
                            }}
                        >
                            {this.props.t('Book.contentReadAgain')}
                        </div>

                        <div className={`bookShareAndLikes ${isRightToLeftDirection ? "bookShareAndLikes-rtl" : ""}`}>
                            <div className="bookLikes favouriteCount">
                                {this.props.book &&
                                    this.props.book.likesCount >= 0 &&
                                    this.props.online && (
                                        <div className="d-inline">
                                            {' '}
                                            <RTRBookFavourites
                                                t={this.props.t}
                                                isFavourite={
                                                    this.props.book.isFavourite
                                                }
                                                favouriteToggleHandler={() => {
                                                    this.props.onFavoriteClicked(
                                                        this.props.book.id
                                                    );
                                                }}
                                                isFavouriting={
                                                    this.props.book
                                                        .isFavouriting
                                                }
                                                isLoggedIn={
                                                    this.props.isLoggedIn
                                                }
                                                logInMsg={this.props.t(
                                                    'global.please-log-in',
                                                    {
                                                        action: this.props.t(
                                                            'global.like',
                                                            1
                                                        ),
                                                        content_type:
                                                            this.props.t(
                                                                'global.story',
                                                                1
                                                            ),
                                                    }
                                                )}
                                                likes={this.props.likesCount}
                                                bookSlug={this.props.book.slug}
                                                bookFavorite="book-favorite"
                                            />
                                        </div>
                                    )}
                            </div>
                            <div
                                className="RTRBookShare"
                                style={{
                                    width: '20px',
                                    height: '20px',
                                }}
                            >
                                <ShareMenu
                                    title={''}
                                    href={window.location.href}
                                    desc={''}
                                    landingPageBook={''}
                                    align={() => {
                                        return true;
                                    }}
                                />
                            </div>
                        </div>
                        <h2 className="bookThanksHeading">
                            {this.props.t('Book.contentThankYou')}
                        </h2>
                        <p className="bookDedicationText">
                            {this.props.bookData['dedication_language']}
                        </p>
                        {this.props.bookData.donor != null ?
                            <div className="bookSponserImages">
                                <img
                                    src={this.props.bookData.donor.logo_url}
                                    alt="dedicatorImages"
                                />
                            </div> : ""
                        }
                    </div>
                </div>
            </div>
        );
    }
}

export default RTRBookReadAgain;
