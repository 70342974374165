import React, { Component } from 'react';
import { connect } from 'react-redux';
import { translate } from 'react-polyglot';
import u from 'updeep';
import { isEqual } from 'lodash';
import Joyride from 'react-joyride';
import queryString from 'query-string';
import { cloneDeep } from 'lodash';
import { replace } from 'react-router-redux';
import LoaderBlock from '../LoaderBlock';
import Pagination from '../Pagination';
import BookCard from '../BookCard';
import Grid from '../Grid';
import TitleImage from '../TitleImage';
import FiltersBar from '../FiltersBar';
import SearchModal from '../SearchModal';
import {
    fetchAllBooksFiltersWorkflow,
    fetchAllBooksWorkflow,
    fetchAllRecommendedBooksWorkflow,
    fetchAllTrendingBooksWorkflow
} from '../../redux/allBooksActions';
import { fetchMeWorkflow } from '../../redux/userActions';
import { recordGaEvents } from '../../redux/googleAnalyticsActions';
import { addSlimNotification } from '../../redux/slimNotificationActions';
import Block from '../Block';
import {
    sortOptions,
    sectionClicked,
} from '../../lib/constants';
import HeroCarousel from '../HeroCarousel';
import {Banner} from '../../api';
import './BooksSearchResultsContainer.scss';
import withRouter from '../../util/withRouter';

const defaultFilters = {
    category: [],
    role: [],
    level: [],
    language: [],
    // sort: sortOptions.relevance,
};

const myLanguage = localStorage.getItem('locale');

const BookGridEl = ({
    books,
    t,
    applyFilter,
    getAppliedFilters
}) => {
    const bookEls = books.map(book => {
        return (
            <BookCard
                myLanguage={myLanguage}
                key={book.slug}
                book={book}
                id={book.id}
                bookLikes={book.likesCount}
                isAllBook
                sectionClicked={sectionClicked.allStories}
                isFavourite={book.is_favourite}
                isFavouriting={book.isFavouriting}
                applyFilter={applyFilter}
                enableFavouriting
                bookImageGTM="books-book-image"
                bookTitleGTM="books-book-title"
                bookAuthorGTM="books-book-author"
                bookIllustratorsGTM="books-book-Illustrators"
                bookLevelGTM="books-book-Level"
            />
        );
    });
    return <Grid variant="4up">{bookEls}</Grid>;
};

const NoResults = translate()(({ t }) => (
    <span>
        <p>{t('Books.no-result-warning-1')}</p>
        <p>{t('Books.no-result-warning-2')}</p>
        <ul 
            style={ 
                localStorage.getItem('locale') === "ar" || localStorage.getItem('locale') === "ur" 
                ? {paddingLeft:'0',paddingRight:'0'}
                :null
            }
        >
            <li>{t('Books.no-result-warning-3')}</li>
            <li>{t('Books.no-result-warning-4')}</li>
            <li>{t('Books.no-result-warning-5')}</li>
            <li>{t('Books.no-result-warning-6')}</li>
        </ul>
    </span>
));

const mapDispatchToProps = {
    fetchAllBooksFiltersWorkflow,
    fetchAllBooksWorkflow,
    fetchMeWorkflow,
    recordGaEvents,
    addSlimNotification,
    replace,
    fetchAllRecommendedBooksWorkflow,
    fetchAllTrendingBooksWorkflow
};

const mapStateToProps = ({
    allBooks,
    viewport,
    user: { profile },
    allFilters: { readFilters },
    help,
}) => ({
    ...allBooks,
    viewport,
    appliedFilters: readFilters,
    userEmail: profile.email,
    name: profile.name,
    helpStatus: help.helpStatus,
});

@translate()
@connect(
    mapStateToProps,
    mapDispatchToProps
)
class BooksSearchResultsContainer extends Component {
    constructor(props) {
        super(props);

        const { t } = props;

        this.state = {
            // The filters bar allows searching through available
            // filter values. We store that search text here.
            filtersSearchText: {
                category: '',
                publisher: '',
                level: '',
                language: '',
            },
            isBookSectionMobile: false,
            isSearchModalOpen: false,
            queryValue: '',
            languages: {
                'en': 'English',
                'id': 'Bahasa Indonesia',
                'my': 'Burmese',
                'hi': 'Hindi',
                'vi': 'Vietnamese',
                'sw': 'KiSwahili',
                'mr': 'Marathi',
                'ne': 'Nepali',
                'bn': 'Bengali',
                'si': 'Sinhala',
                'km': 'Khmer',
                'lo': 'Lao',
                'zh_TW': 'Chinese (Traditional)',
                'ta': 'Tamil',
                'fil': 'Filipino',
                'zh_CN': 'Chinese (Simplified)',
                'es': 'Spanish',
                'ar': 'Arabic',
                'lg': 'Luganda',
                'ur': 'Urdu',
                'te': 'Telugu',
                'kn': 'Kannada',
                'nyn': 'Runyankore-Rukiga',
                'gu': 'Gujarati'
            },
            bannerData:[]
        };

        this.filtersBarSortOptions = [
            {
                name: t('sort.most-liked'),
                queryValue: sortOptions.mostLiked,
            },
            {
                name: t('sort.most-read'),
                queryValue: sortOptions.mostRead,
            },
        ];

        // FiltersBar uses a SelectBox to display sort options, whereas
        // FiltersPanel uses a RadioGroup. Both these components expect
        // different props to display options. We do the transformation
        // here, before passing the props down.
        // this.filtersPanelSortOptions = this.props.translateFiltersBarSortOptionsToFiltersPanelSortOptions(
        //     this.filtersBarSortOptions
        // );

    }

    onOpenSearchModal = () => this.setState({ isSearchModalOpen: true });
    onCloseSearchModal = () => this.setState({ isSearchModalOpen: false });

    // Updates filter bar search text for a specified filter.
    updateFiltersSearchText = (key, text) => {
        this.setState(
            u(
                {
                    filtersSearchText: {
                        [key]: text,
                    },
                },
                this.state
            )
        );
    };


    // Convenience methods for updating filter bar search text for
    // our current list of filters.
    updateCategorySearchText = e =>
        this.updateFiltersSearchText('category', e.target.value);
    updatePublisherSearchText = e =>
        this.updateFiltersSearchText('publisher', e.target.value);
    updateLevelSearchText = e =>
        this.updateFiltersSearchText('level', e.target.value);
    updateLanguageSearchText = e =>
        this.updateFiltersSearchText('language', e.target.value);

    onLoadMore = () =>
    {
        if(this.props.recommendedBooks){
            this.props.fetchAllRecommendedBooksWorkflow(
                this.getAppliedFilters(),
                this.props.loadedPages + 1
            )
        }else if(this.props.trendingBooks){
            this.props.fetchAllTrendingBooksWorkflow(
                this.getAppliedFilters(),
                this.props.loadedPages + 1
            )
        }       
        else {
            this.props.fetchAllBooksWorkflow(
                this.getAppliedFilters(),
                this.props.loadedPages + 1
            );
        }
    }


    renderBookEls() {
        const { books } = this.props;

        return books.map(book => {
            return (
                <BookCard
                    key={book.slug}
                    id={book.id}
                    book={book}
                    faded={book.storyDownloaded}
                    isFavourite={book.is_favourite}
                    bookImageGTM="books-book-image"
                    bookTitleGTM="books-book-title"
                    bookAuthorGTM="books-book-author"
                    bookIllustratorsGTM="books-book-Illustrators"
                    bookLevelGTM="books-book-Level"
                />
            );
        });
    }

    componentDidMount() {
        const {
            fetchAllBooksFiltersWorkflow,
            fetchAllBooksWorkflow,
            fetchAllRecommendedBooksWorkflow,
            fetchAllTrendingBooksWorkflow
        } = this.props;
        this.isMobile();
        // Banner.fetchBannerImage("books")
        //     .then((response)=>{
        //         return response.json();
        //     })
        //     .then((response)=>{
        //         this.setState({
        //             bannerData:response.data.bannerImages
        //         })
        //     })
        // window.addEventListener('resize', this.isMobile)
        fetchAllBooksFiltersWorkflow().then(response => {
            let filters = this.getAppliedFilters();

            if (!this.props.searchPage) {

            let currentLanguage = localStorage.getItem('locale')
            
            let translatedLanguage = currentLanguage && this.state.languages[currentLanguage]
            if (!translatedLanguage) {
                translatedLanguage = 'English'
            } else {
                translatedLanguage = this.state.languages[currentLanguage]
            }
            
            let found = filters && filters.language
            let isLangChangedInBooksPage = localStorage.getItem('langChangedInBookPage') 
            if (found) {
                if (isLangChangedInBooksPage === 'true') {
                    filters.language = [translatedLanguage]
                    localStorage.removeItem('langChangedInBookPage')
                }
            } else {
                filters.language = [translatedLanguage]
            }
           
            // filters.language = [...new Set(filters.language)]
            }
            const searchParams = queryString.stringify(filters);
            this.props.navigate({ search: `?${searchParams}` }, { replace: true });

            if(this.props.recommendedBooks){
                fetchAllRecommendedBooksWorkflow(filters)
            }else if(this.props.trendingBooks){
                fetchAllTrendingBooksWorkflow(filters)
            }       
            else {
                fetchAllBooksWorkflow(filters);
            }
        });
    }


    componentDidUpdate(nextProps) {
        if (this.props.location.search !== nextProps.location.search) {
            if (this.props.location.search === '?sort=New%20Arrivals') {
                this.isMobile();
                this.props.fetchAllBooksFiltersWorkflow().then(response => {
                    let filters = this.getAppliedFilters();
                    filters.language = response.data.current_language;
                    const searchParams = queryString.stringify(filters);
                    this.props.navigate({ search: `?${searchParams}` }, { replace: true });
                    this.props.fetchAllBooksWorkflow(filters);
                });
            }
        }

        if (!isEqual(this.props.appliedFilters, nextProps.appliedFilters)) {
            this.props.fetchAllBooksWorkflow(nextProps.appliedFilters);
            // Reset all checked books on changing /applying new filters
        }
    }


    getAppliedFilters = (props = this.props) => {
        const parsedFilters = queryString.parse(window.location.search);
        let currentLanguage = localStorage.getItem('locale')

        Object.entries(parsedFilters).forEach(([k, v]) => {
            if (Array.isArray(defaultFilters[k]) && !Array.isArray(v)) {
                parsedFilters[k] = [v];
            }
        });

        if(!this.props.searchPage){
        let translatedLanguage = currentLanguage && this.state.languages[currentLanguage]

        if (!translatedLanguage) {
            translatedLanguage = 'English'
        } else {
            translatedLanguage = this.state.languages[currentLanguage]
        }

        //WARN: Need to look into a side effect bug here
        //that is suspiciously changes the defaultFilters.role array even though
        //there is not direct mutation of this object. So each time, we ensure
        //role of defaultFilters is reset to empty array.

        defaultFilters.language = [translatedLanguage];
        // console.log('defaultFilters',defaultFilters)
        // console.log('parsedFilters',parsedFilters)
        }

        return {
            ...defaultFilters,
            ...parsedFilters,
        };
    };

    applyFilter = (type, value) => {
        let appliedFilters = cloneDeep(this.getAppliedFilters());
        // appliedFilters.language = [...new Set(appliedFilters.language)]
        const filterIsArray = Array.isArray(appliedFilters[type]);

        if (filterIsArray) {
            appliedFilters[type].push(value);
        } else {
            appliedFilters[type] = value;
        }

        const searchParams = queryString.stringify(appliedFilters);
        this.props.navigate({ search: `?${searchParams}` }, { replace: true });
        //recommended and trending books api check
        if(this.props.recommendedBooks){
            this.props.fetchAllRecommendedBooksWorkflow(appliedFilters)
        }else if (this.props.trendingBooks){
            this.props.fetchAllTrendingBooksWorkflow(appliedFilters)
        }else{
            this.props.fetchAllBooksWorkflow(appliedFilters);
        }
        
    };

    removeFilter = (type, value) => {
        const appliedFilters = cloneDeep(this.getAppliedFilters());

        if (!appliedFilters[type]) {
            return;
        }

        if (Array.isArray(appliedFilters[type])) {
            const index = appliedFilters[type].indexOf(value);
            if (index !== -1) {
                appliedFilters[type].splice(index, 1);
            }
        } else {
            delete appliedFilters[type];
        }

        const searchParams = queryString.stringify(appliedFilters);
        this.props.navigate({ search: `?${searchParams}` }, { replace: true });

        //recommended and trending books api check
        if(this.props.recommendedBooks){
            this.props.fetchAllRecommendedBooksWorkflow(appliedFilters)
        }else if (this.props.trendingBooks){
            this.props.fetchAllTrendingBooksWorkflow(appliedFilters)
        }else{
            this.props.fetchAllBooksWorkflow(appliedFilters);
        }
    };

    isMobile = () => {
        if (
            window.screen.width <= 768 
            // &&
            // window.matchMedia('(orientation: portrait)').matches
        ) {
            this.setState({
                isBookSectionMobile: true,
            });
        } else {
            this.setState({
                isBookSectionMobile: false,
            });
        }
    };

    onBookSearch = event => {
        let appliedFilters = this.getAppliedFilters();
        this.setState({ queryValue: event, isSearchModalOpen: false }, () => {
            appliedFilters.query = this.state.queryValue;
            appliedFilters.sort = 'Relevance';
            const searchParams = queryString.stringify(appliedFilters);
            this.props.navigate({ search: `?${searchParams}` }, { replace: true });
            this.props.fetchAllBooksWorkflow(appliedFilters)
        });
    };

    onSortOptionChanged = option => this.applyFilter('sort', option);

    render() {
        const {
            t,
            isFetchingBooks,
            books,
            totalBooksCount,
            viewport,
            filters,
            isFetchingFilters,
            searchPage,
            searchString,
            bookResults
        } = this.props;
        
        const isRightToLeftDirection = localStorage.getItem("locale") === "ar" || localStorage.getItem("locale") === "ur"

        const { isBookSectionMobile, isSearchModalOpen } = this.state;

        const appliedFilters = this.getAppliedFilters();

        if (!filters || isFetchingFilters) {
            return <LoaderBlock />;
        }

        let filtersComponent = null;
        filtersComponent = (
            <FiltersBar
                viewport={viewport}
                noTopBorder
                pullUp
                filters={filters}
                applyFilter={this.applyFilter}
                removeFilter={this.removeFilter}
                appliedFilters={appliedFilters}
                categorySearchValue={this.state.filtersSearchText.category}
                updateCategorySearchValue={this.updateCategorySearchText}
                publisherSearchValue={this.state.filtersSearchText.publisher}
                updatePublisherSearchValue={this.updatePublisherSearchText}
                levelSearchValue={this.state.filtersSearchText.level}
                updateLevelSearchValue={this.updateLevelSearchText}
                languageSearchValue={this.state.filtersSearchText.language}
                updateLanguageSearchValue={this.updateLanguageSearchText}
                resultsCount={totalBooksCount}
                itemTypeLabel={t('global.story', totalBooksCount)}
                sortOptions={this.filtersBarSortOptions}
                applySortOption={this.onSortOptionChanged}
                appliedSortOption={appliedFilters.sort}
                isBookSection
                isBookSectionMobile={isBookSectionMobile}
                categoryFilterGTM="book-category-filter"
                levelFilterGTM="book-level-filter"
                languageFilterGTM="book-language-filter"
                favoriteSortGTM="book-favorite-sort"
                viewSortGTM="book-view-sort"
                alphabetSortGTM="book-alphabetic-sort"
                displayTypeSelector
            />
        );

        const shouldShowLoadMore = books && books.length < totalBooksCount;
        

        const resourceTitleEl =
            // this.props.searchQuery &&
            window.location.search.search("query") !== -1 ? (
                <h3 className="__search-result-title">
                    {myLanguage !== 'ne'
                        ? `${totalBooksCount} ${t(
                              'Search.results-for-count'
                          )} \u2018${searchString}\u2019`
                        : `\u2018${searchString}\u2019 ${t(
                              'Search.results-for-count-1'
                          )} ${totalBooksCount} ${t(
                              'Search.results-for-count-2'
                          )}`}
                </h3>
            ) : (
                [
                    <div className="__resources-title">
                        <h3>{t('global.book', 2)}</h3>
                        <h3
                            className={
                                isRightToLeftDirection
                                    ? '__total-margin-left'
                                    : '__normal'
                            }
                        >
                            ({totalBooksCount})
                        </h3>
                    </div>,
                ]
            );


        let steps = [
            {
                target: '.__bookFilter',
                content: this.props.t('Book.filterHelpGuide'),
                placementBeacon: 'left',
                disableBeacon: true,
            },
            {
                target: '.pb-filters-bar__actions--secondary',
                content: this.props.t('Book.sortHelpGuide'),
                placementBeacon: 'left',
            },
            {
                target: '.pb-link--normal',
                content: this.props.t('Book.favouriteHelpGuide'),
                placementBeacon: 'left',
                disableBeacon: true,
            },
        ];

        let mobileSteps = [
            myLanguage === "ar" || myLanguage === "ur" ? 
            {
                target: '.pb-filters-bar__filter-option-rtl',
                content: t('Video.filterHelpGuide'),
                placementBeacon: 'left',
                disableBeacon: true,
            }
            :
            {
                target: '.pb-filters-bar__filter-option',
                content: t('Video.filterHelpGuide'),
                placementBeacon: 'left',
                disableBeacon: true,
            },
            {
                target: '.pb-filters-bar__sort-option',
                content: this.props.t('Book.sortHelpGuide'),
                placementBeacon: 'left',
            },
            {
                target: '.pb-link--normal',
                content: this.props.t('Book.favouriteHelpGuide'),
                placementBeacon: 'left',
                disableBeacon: true,
            }
        ];

        return (
            <div>
            {bookResults ? <HeroCarousel banners={this.state.bannerData} padding130={false} /> : null} 
            <Block verticalMargin={searchPage ? false : true } background="transparent">
                <Joyride
                    enabled={true}
                    steps={isBookSectionMobile ? mobileSteps : steps}
                    continuous
                    showProgress
                    run={this.props.helpStatus}
                    scrollToFirstStep
                    scrollOffset={250}
                    disableScrolling={false}
                    // tooltipComponent={Tooltip}
                    locale={{
                        next: t('global.Next'),
                        back: t('global.Back'),
                        last: t('global.Last'),
                    }}
                    styles={{
                        options: {
                            arrowColor: '#e3ffeb',
                            backgroundColor: '#e3ffeb',
                            textColor: '#004a14',
                            overlayColor: 'rgba(0, 0, 0, 0.8)',
                            primaryColor: '#007AA4',
                            spotlightShadow: '0 0 15px rgba(0, 0, 0, 0.8)',
                            zIndex: 1000,
                        },
                    }}
                />
                <div className="book-header">
                    {resourceTitleEl}
                    {searchPage ? null : (
                        <div
                            className={`book-section--menu-search`}
                            onClick={this.onOpenSearchModal}
                        >
                            <TitleImage
                                className={isRightToLeftDirection ? "__profile-images-arabic" : "__profile-images"}
                                svgName={require('../../assets/search_tl.svg')}
                                title={t('books.search')}
                                contentSpecificSearch
                            />
                        </div>
                    )}
                </div>

                <div className="book-section">
                    {books && books.length >= 0 ? (
                        <div className="filter-section">{filtersComponent}</div>
                    ) : null}
                    {isFetchingBooks || !books ? (
                        <LoaderBlock />
                    ) : (
                        <div>
                            {books.length > 0 ? (
                                <div>
                                    <BookGridEl
                                        books={books}
                                        t={t}
                                        applyFilter={this.applyFilter}
                                    />
                                    {shouldShowLoadMore ? (
                                        <Pagination
                                            onClick={this.onLoadMore}
                                            loading={
                                                this.props.isFetchingMoreBooks
                                            }
                                        />
                                    ) : null}
                                </div>
                            ) : null}
                            <div
                                className={
                                    isRightToLeftDirection
                                        ? '__no-result-section-rtl'
                                        : '__no-result-section'
                                }
                            >
                                {totalBooksCount ? null : <NoResults />}
                            </div>
                        </div>
                    )}
                </div>
                {isSearchModalOpen ? (
                    <SearchModal
                        viewport={viewport}
                        onClose={this.onCloseSearchModal}
                        onSubmit={this.onBookSearch}
                    />
                ) : null}
            </Block>
            </div>
        );
    }
}

BooksSearchResultsContainer.propTypes = {};

export default withRouter(BooksSearchResultsContainer);
