import React from 'react';
import './BookDetails.scss';
import { isMobile } from '../../util';

const GovtApproved = (props) => {
    const { title_logos, isRightToLeftDirection } = props;

    return (
        <div
            className={
                (isMobile() || (window.screen.width < 768 && window.matchMedia('(orientation: landscape)').matches))
                    ? 'govt-approved-container-mobile'
                    : isRightToLeftDirection 
                        ? 'govt-approved-container-rtl'
                        : 'govt-approved-container'
            }
        >
            <img
                className="govt-approved"
                src={require('../../assets/govt-approved.svg')}
                alt="Govt approved"
            />
            <p>
                <span>{title_logos[0].name}</span>
            </p>
        </div>
    );
};

export default GovtApproved;
