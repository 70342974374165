import queryString from 'query-string';

export const profileTypes = {
    USER: 'user',
    PUBLISHER: 'publisher',
};

export const collectionLimits = {
    small: 6,
    medium: 20,
};

export const imageSrcsetSizes = {
    grid2up6up: {
        '(min-width: 1048px)': {
            value: 200,
            unit: 'px',
        },
        '(min-width: 768px)': 34, // unit vw
        default: 50, // unit vw
    },
    grid4up: {
        '(min-width: 1048px)': {
            value: 280,
            unit: 'px',
        },
        '(min-width: 768px)': 34, // unit vw
        default: 50, // unit vw
    },
};

export const keyCodes = {
    leftArrow: 37,
    upArrow: 38,
    rightArrow: 39,
    downArrow: 40,
    esc: 27,
    f: 70,
    return: 13,
};



export const pagination = {
    allBooks: 12,
    allLists: 24,
    images: 24,
    allIllustrations: 24,
    usersSearch: 20,
    bookTranslations: 24,
    videos: 8,
    refereces: 12,
    mobileVideos: 3,
    mobileBooks: 5,
    mobileReferences: 5,
    mobileReadingLists: 6,
    readingLists: 12,
    myReadingLists: 12,
};

export const sortOptions = {
    relevance: 'Relevance',
    newArrivals: 'New Arrivals',
    mostRead: 'Most Read',
    leastRead: 'Least Read',
    mostView: 'Most Viewed',
    leastView: 'Least Viewed',
    mostLiked: 'Most Liked',
    leastLiked: 'Least Liked',
    ascending: 'A-Z',
    descending: 'Z-A',
    mostViewed: 'Most Viewed',
    position: 'Position',
    recommended: 'Recommended Books',
    popular: 'Popular Books',
    trending: 'Trending Books',
};


export const links = {
    allBooksPath: () => '/stories',
    levelBooksSort: (level) => `/stories?level=${level}`,
    createReadingList: () => '/myreadinglists/createreadinglist',
    allBooks: (
        sortOption = sortOptions.newArrivals,
        languagePreferences = [],
        readingLevels = []
    ) =>
        `/stories?${queryString.stringify({
            sort: sortOption,
            language: languagePreferences,
            level: readingLevels,
        })}`,
    allReadAlouds: (
        sortOption = sortOptions.relevance,
        languagePreferences = [],
        roles = []
    ) =>
        `/readalouds?${queryString.stringify({
            sort: sortOption,
            language: languagePreferences,
            role: roles,
        })}`,
    // donate:
    //     'https://give.roomtoread.org/give/278628/#!/donation/checkout?c_src=General-Fundraising&c_src2=Literacy-Cloud-Donate-CTA',
    donate: () => 'https://secure.roomtoread.org/page/83444/donate/1?ea.tracking.id=Literacy%20Cloud%20Homepage%20Donate%20Button&en_og_source=Web',
    bookDetails: (slug) => `/stories/${slug}`,
    managereadinglists: (slug) => `/myreadinglists/${slug}`,
    readinglists: (slug) => `/readinglists/${slug}`,
    myReadinglistsDetail: (slug) => `/myreadinglistsdetail/${slug}`,
    upload: () => '/details',
    dashboard: () => '/v0/dashboard',
    home: () => '/',
    lists: () => '/lists',
    downloadList: (slug) =>
        `/v0/lists/${slug}/download?d_format=pdf&high_resolution=false`,
    login: () => '/users/sign-in',
    fetchTabLogin: (path) => `/users/sign-in?redirection_url=/${path}`,
    saveOffLogin: (path) => `/users/sign-in?redirection_url=/stories/${path}`,
    redirectReadAloudSignIn: (path) =>
        `/users/sign-in?redirection_url=/readalouds/${path}`,
    myLists: () => '/me/lists',
    myFavourites: () => '/myfavourites',
    offlineBooks: () => '/offline',
    profile: (type, slug) => `/${type}/${slug}`,
    profileDashboard: () => '/v0/profile',
    signup: () => '/users/sign-up',
    searchBy: (query) => `/search?query=${query}`,
    searchByTag: (tag) => `/search?tags=${tag}`,
    userProfile: (slug) => `/users/${slug}`,
    storiesByCategory: (query) =>
        `/stories?${queryString.stringify({
            category: query,
            isStoryCategory: true,
        })}`,
    termsOfUse: () => `/terms-and-conditions`,
    list: (slug) => `/lists/${slug}`,
    englishPrivacyPolicy: () => 'https://www.roomtoread.org/privacy-policy/',
    reportIssue: () =>
        'https://form.asana.com/?k=-yTkSI8QzcurPVZeRwAC9Q&d=7069750990524',
    aboutUs: () => 'https://www.roomtoread.org/about-us/',
    dataDashboard: () => '/v0/dashboard/details',
    allRecommendedBooks: (
        sortOption = sortOptions.newArrivals,
        languagePreferences = [],
        readingLevels = []
    ) =>
        `/stories/recommended?${queryString.stringify({
            sort: sortOption,
            language: languagePreferences,
            level: readingLevels,
        })}`,
    allPopularBooks: (
        sortOption = sortOptions.popular,
        languagePreferences = [],
        readingLevels = []
    ) =>
        `/stories?${queryString.stringify({
            sort: sortOption,
            language: languagePreferences,
            level: readingLevels,
        })}`,
    allTrendingBooks: (
        // sortOption = sortOptions.newArrivals,
        languagePreferences = [],
        readingLevels = []
    ) =>
        `/stories/trending?${queryString.stringify({
            // sort: sortOption,
            language: languagePreferences,
            level: readingLevels,
        })}`
};


export const roles = {
    CONTENT_MANAGER: 'content_manager',
    PUBLISHER: 'publisher',
    ADMIN: 'admin',
};

export const delays = {
    ListModal: 30000,
    slimNotificationModal: 5000,
};

export const gaEventCategories = {
    list: 'List',
    offline: 'Offline',
    categoryCard: 'CategoryCard',
    book: 'Book',
    bookShelf: 'Bookshelf',
    illustration: 'Illustration',
    bulkDownload: 'bulkDownload',
    profile: 'Profile',
};

export const gaEventActions = {
    download: 'Download',
    read: 'Read',
    add: 'Add',
    delete: 'Delete',
    liked: 'Liked',
    opened: 'Opened',
    shared: 'Shared',
    bookPageOpenedFromList: 'BookDetailsPageOpenedFromList',
};

export const sectionClicked = {
    bookCard: 'Book Card',
    bookDetails: 'Book Details',
    quickViewModal: 'Quick View Modal',
    allStories: 'All Stories',
    similarBooks: 'Similar Books',
    editorPicks: 'Editors picks',
    mostReads: 'Most Read',
    newArrivals: 'Recent Arrivals',
    homeRecommendation: 'Home Recommendation',
    illustrationSearchPage: 'Illustration Search Page',
    allImagesPage: 'All Images Page',
    illustrationDetails: 'Illustration Details',
    illustrationExpandedSearchPage: 'Illustration Expanded Search Page',
};

export const linkType = {
    createdBy: 'Created by',
    translatedBy: 'Translated by',
    writtenBy: 'Written by',
    illustratedBy: 'Illustrated by',
    photographedBy: 'Photograph by',
    originalStory: 'original Story',
};

export const licenses = [
    { name: 'CC BY 4.0', queryValue: 'CC BY 4.0' },
    { name: 'CC BY NC 4.0', queryValue: 'CC BY NC 4.0' },
];
export const editions = [
    { name: '', queryValue: '' },
    { name: 'Original', queryValue: 'original' },
    { name: 'Adaptation', queryValue: 'adaptation' },
];
export const orientations = [
    { name: '', queryValue: '' },
    { name: 'Landscape', queryValue: 'horizontal' },
    { name: 'Portrait', queryValue: 'vertical' },
];

// Sorry, kids!
export const MAX_OFFLINE_BOOKS_COUNT = 12;
export const MAX_BULK_DOWNLOAD_BOOKS_COUNT = 10;


