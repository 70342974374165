import u from 'updeep';
import { handleActions } from 'redux-actions';
import * as actions from './menuToggleActions';

const initialState = {
    ismenuOpen: false,
};

export default handleActions(
    {
        [actions.toggleMenuState]: (state, action) =>
            u(
                {
                    ismenuOpen: !state.ismenuOpen,
                },
                state
            ),
        [actions.closeMenu]: (state, action) =>
            u(
                {
                    ismenuOpen: false,
                },
                state
            ),
        [actions.openMenu]: (state, action) =>
            u(
                {
                    ismenuOpen: true,
                },
                state
            ),
    },
    initialState
);
