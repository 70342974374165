import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Grid from '../Grid';
import './ReadingLists.scss';

class ReadingLists extends Component {
    static defaultProps = {
        lists: [],
        showDescription: false,
    };
    render() {
        const {
            showDescription,
            lists,
        } = this.props;

        const readingLists = lists.map(list => (
            <div className="__readlistCard-container">
                Reading List
            </div>
        ));

        let layoutContainer;
        if (showDescription) {
            layoutContainer = <div>{readingLists}</div>;
        } else {
            layoutContainer = <Grid variant="3up">{readingLists}</Grid>;
        }

        return <div />;
    }
}

ReadingLists.propTypes = {
    // TODO: Add shape of each object
    lists: PropTypes.array,
    showDescription: PropTypes.bool,
};

export default ReadingLists;
