import React, { Component } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import Sizer from '../Sizer';

import './BookDetailSummary.scss';

class BookDetailSummary extends Component {
    // static defaultProps = {}

    render() {
        const baseClassName = 'pb-book-detail-summary';
        const { parentClassName, title, description, isTabletMode, hideSummary } = this.props;

        const classes = {
            [baseClassName]: true,
            [parentClassName]: parentClassName
        };



        return (
            <div className={classNames(classes)}>
                {
                    isTabletMode || hideSummary
                    ? null 
                    : <p className={ 
                        (localStorage.getItem("locale") === "ar" || localStorage.getItem("locale") === "ur") 
                        ? `${baseClassName}__title-arabic` 
                        : `${baseClassName}__title`
                        }
                    >
                        {title}
                    </p>
                }

                <Sizer maxHeight={
                    ((window.screen.width >=800 && window.screen.width <=1047) 
                    || 
                    (window.innerWidth >=1024 && window.innerWidth <=1199))
                     ? "xl" : "lm"
                     } scrollY>
                    {description}
                </Sizer>
            </div>
        );
    }
}

BookDetailSummary.propTypes = {
    parentClassName: PropTypes.string
};

export default BookDetailSummary;
