import React, { Component } from 'react';
import PropTypes from 'prop-types';
import SvgSymbol from '../SvgSymbol';
import './SvgIcon.scss';
const baseClassName = 'pb-svg-icon';

class SvgIcon extends Component {
    static defaultProps = {};

    render() {
        const classNames = [baseClassName];

        const isRightToLeftDirection = localStorage.getItem('locale') === 'ar' || localStorage.getItem('locale') === 'ur';

        const { dataGTM } = this.props;

        if (this.props.size && this.props.size !== 'm') {
            classNames.push(`${baseClassName}--${this.props.size}`);
        }

        classNames.push(`${baseClassName}--type-${this.props.name}`);

        if (this.props.parentClassName) {
            classNames.push(this.props.parentClassName);
        }

        if (this.props.name === 'close') {
            classNames.push(`${baseClassName}--type-close`)
        }

        if (this.props.variant) {
            classNames.push(`${baseClassName}--${this.props.variant}`);
        }

        if (this.props.pushLeft) {
            classNames.push(`${baseClassName}--push-left`);
        }

        if (this.props.pushRight) {
            classNames.push(`${baseClassName}--push-right`);
        }
        if (this.props.push === "pushRight") {
            classNames.push(`${baseClassName}--push-right`);
        }
        if (this.props.pushLeftMedium) {
            classNames.push(`${baseClassName}--push-left-medium`);
        }
        if (this.props.svgIcon) {
            classNames.push(
                isRightToLeftDirection 
                ? `${baseClassName}--svg-icon-rtl`
            : `${baseClassName}--svg-icon`
            );
        }
        if (this.props.pushIcon) {
            classNames.push(`${baseClassName}--push-icon`);
        }
        if (this.props.pushLeftSmall) {
            classNames.push(`${baseClassName}--push-left-small`);
        }
        if (this.props.push === "pushLeftSmall") {
            classNames.push(`${baseClassName}--push-left-small`);
        }
        if (this.props.rightAbsolute) {
            classNames.push(`${baseClassName}--right-absolute`);
        }
        if (this.props.svgIconBooks) {
            classNames.push(
                isRightToLeftDirection 
                ? `${baseClassName}--svg-icon-books-rtl`
                : `${baseClassName}--svg-icon-books`
                );
        }
        if (this.props.bookIcon) {
            classNames.push(`${baseClassName}--book-icon`);
        }
        if (this.props.playButton) {
            classNames.push(`${baseClassName}--play-button`);
        }
        if (this.props.bookCardSvg) {
            classNames.push(`${baseClassName}--book-card-svg`);
        }
        if (this.props.governmentApprovedLogo) {
            classNames.push(`${baseClassName}--government-approved-title`)
        }
        if (this.props.flip) {
            classNames.push(`${baseClassName}--flip-image`)
        }
        if(this.props.favouriteIcon){
            classNames.push(
                isRightToLeftDirection 
                ? `${baseClassName}--favourite-icon-rtl` 
                : `${baseClassName}--favourite-icon`
            )
        }
        if(this.props.shareIcon){
            classNames.push(
                isRightToLeftDirection 
                ? `${baseClassName}--share-icon-rtl` 
                : `${baseClassName}--share-icon`
            )
        }
        if(this.props.replyArrow){
            classNames.push(
                isRightToLeftDirection 
                ? `${baseClassName}--reply-arrow-rtl` 
                : `${baseClassName}--reply-arrow`
            )
        }
        if(this.props.labelText){
            classNames.push(
                `${baseClassName}--label-text`
            )
        }
        if(this.props.infoGrey){
            classNames.push(
                `${baseClassName}--info-grey`
            )
        }
        if(this.props.errorPage){
            classNames.push(`${baseClassName}--error-page`)
        }
        if (this.props.pushCenter) {
            classNames.push(`${baseClassName}--push-center`);
        }
        

        return (
            <SvgSymbol
                name={this.props.name}
                parentClassName={classNames.join(' ')}
                dataGTM={dataGTM}
            />
        );
    }
}

SvgIcon.propTypes = {
    name: PropTypes.string,
    parentClassName: PropTypes.string,
    variant: PropTypes.oneOf(['default', 'accent']),
    pushRight: PropTypes.bool,
    pushLeft: PropTypes.bool,

    /* Default size is m */
    size: PropTypes.oneOf([
        's',
        'm',
        'sm',
        'l',
        'xl',
        's-m',
        'sm',
        'm-l',
        'l-xl',
        'xl-xxl',
        'si',
        'mi',
        'custom',
    ]),
};

export default SvgIcon;



