import React, { Component } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { translate } from 'react-polyglot';
import { connect } from 'react-redux';
import Favourites from '../Favourites';
import './ReadAloudsCard.scss';
import Block from '../Block';
import SvgIcon from '../SvgIcon';
import Link from '../Link';
import videoPlaceholder from '../../assets/placeholder.jpg';

import {
    favouriteReadAloudsWorkflow,
    fetchVideosDetailWorkflow,
} from '../../redux/readAloudsActions';
import { favouriteVideoWorkflowHome } from '../../redux/homeActions';

const mapDispatchToProps = {
    favouriteReadAloudsWorkflow,
    favouriteVideoWorkflowHome,
    fetchVideosDetailWorkflow,
};

const mapStateToProps = (state, ownProps) => {
    console.log('redux state', state);
    let requiredVideos;
    if (ownProps.isFromHome) {
        requiredVideos = state.home.videos;
    } else {
        requiredVideos = state.readAlouds.readAlouds.length
            ? state.readAlouds.readAlouds
            : state.readAlouds.favouriteReadAlouds;
    }

    const associatedindex = requiredVideos.findIndex(
        (video) => video.id === ownProps.videoID
    );
    let associatedVideo = null;
    if (associatedindex >= 0) {
        associatedVideo = requiredVideos[associatedindex];
    }
    return {
        isLoggedIn: state.user.isLoggedIn,
        isFavourite: associatedVideo
            ? associatedVideo.is_favourite
            : ownProps.isFavourite,
        isFavouriting: associatedVideo ? associatedVideo.isFavouriting : null,
    };
};

@translate()
@connect(mapStateToProps, mapDispatchToProps)
class ReadAloudsCard extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isModalVisible: false,
            isVideoPlay: false,
        };
    }

    onFavoriteClicked = (id) => {
        const {
            favouriteReadAloudsWorkflow,
            isSimilarVideo,
            favouriteSimilarVideoWorkflow,
        } = this.props;
        if (isSimilarVideo) {
            favouriteSimilarVideoWorkflow(id);
        } else {
            favouriteReadAloudsWorkflow(id);
        }
    };

    render() {
        const baseClassName = 'pb-readAloud-card';
        const {
            t,
            isLoggedIn,
            isFavourite,
            videoID,
            parentClassName,
            videoReads,
            videoTitle,
            videoSlug,
            videoThumbnail,
            videoLikes,
            videoTime,
            videoTitleGTM,
            videoThumbnailGTM,
            isSimilarVideo,
            isFavouriting,
            myLanguage,
        } = this.props;

        const isRightToLeftDirection =
            localStorage.getItem('locale') === 'ar' ||
            localStorage.getItem('locale') === 'ur';

        const classes = {
            [baseClassName]: true,
            [parentClassName]: parentClassName,
        };

        const thumbnailEl = (
            <div className="__video-image" data-gtm={videoThumbnailGTM}>
                <img
                    src={(videoThumbnail === 'original/missing_video.png') ? videoPlaceholder : videoThumbnail}
                    alt="Video Thumbnail Img"
                    data-gtm={videoThumbnailGTM}
                />
            </div>
        );

        const videoPath = `readaloudsdetail/${videoSlug}`;

        const similarVideoPath = `${videoSlug}`;

        let favStat = (
            <Favourites
                t={t}
                isFavourite={isFavourite}
                favouriteToggleHandler={() => this.onFavoriteClicked(videoID)}
                isFavouriting={isFavouriting}
                isLoggedIn={isLoggedIn}
                logInMsg={t('global.please-log-in', {
                    action: t('global.like', 1),
                    content_type: t('global.story', 1),
                })}
                videoSlug={videoSlug}
                likes={videoLikes}
            />
        );

        return (
            <div className={classNames(classes)}>
                <Block
                    noHorizontalPadding
                    noVerticalPadding
                    background="transparent"
                >
                    <div className="__videocard-section">
                        <Link
                            isInternal
                            href={isSimilarVideo ? similarVideoPath : videoPath}
                            dataGTM={videoThumbnailGTM}
                        >
                            <div data-gtm={videoThumbnailGTM}>
                                <div
                                    className="__video-play"
                                    data-gtm={videoThumbnailGTM}
                                >
                                    <SvgIcon
                                        name="play"
                                        size="l"
                                        data-gtm={videoThumbnailGTM}
                                    />
                                </div>
                                <div data-gtm={videoThumbnailGTM}>
                                    {thumbnailEl}
                                </div>
                            </div>
                        </Link>
                        <div className="__video-timer">{videoTime}</div>
                        <div
                            className={
                                isRightToLeftDirection
                                    ? '__View-like-count-arabic'
                                    : '__View-like-count'
                            }
                        >
                            <div
                                className={
                                    isRightToLeftDirection
                                        ? `${baseClassName}__like-count-arabic`
                                        : `${baseClassName}__like-count`
                                }
                            >
                                {favStat}
                            </div>
                            <div>
                                <span>
                                    <SvgIcon
                                        name="playIcon"
                                        size="sm"
                                        pushRight
                                        svgIcon
                                        playButton
                                    />
                                </span>
                                <span>{videoReads}</span>
                            </div>
                        </div>
                        <Link
                            isInternal
                            href={isSimilarVideo ? similarVideoPath : videoPath}
                            dataGTM={videoTitleGTM}
                        >
                            <div
                                className={
                                    myLanguage === 'lo'
                                        ? '__video-lo-title'
                                        : isRightToLeftDirection
                                        ? '__video-title __text-right-align'
                                        : '__video-title'
                                }
                                data-gtm={videoTitleGTM}
                            >
                                {videoTitle}
                            </div>
                        </Link>
                    </div>
                </Block>
            </div>
        );
    }
}

ReadAloudsCard.propTypes = {
    parentClassName: PropTypes.string,
};

export default ReadAloudsCard;
