import React, { Component } from 'react';
import Modal from '../Modal';


class DetectIE extends Component {
    render() {
        const header = navigator.language === 'id' ?
            <h2 style={{ paddingTop: '8px', paddingLeft: '8px' }}>
                Peringatan:
            </h2>
            :
            <h2 style={{ paddingTop: '8px', paddingLeft: '8px' }}>
                Warning:
            </h2>;

        return (
            <Modal contentPadding header={header} >
                {
                    navigator.language === 'id' ?
                        <p>
                            Internet explorer anda tidak mendukung sehingga beberapa fitur dalam pelantar tidak dapat berfungsi dengan baik. Mohon gunakan peramban lain seperti Chrome, Firefox atau Safari.
                        </p>
                        :
                        <p>
                            Internet Explorer is not supported and some platform functionality may not be stable , Please switch to other browsers like Chrome, FireFox or Safari.
                        </p>

                }
            </Modal>
        )
    }
}

export default DetectIE;