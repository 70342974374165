import u from 'updeep';
import { handleActions } from 'redux-actions';
import * as actions from './userActions';
import * as bookActions from './bookActions';
import { receiveCreateReadingList } from './readingListsActions';

const initialState = {
    isLoggedIn: false,
    isFetchingMe: false,
    isFetchingLogout: false,
    profile: {
        roles: [],
        bookshelf: {
            books: [],
        },
    },
    languageOptions: [],
    isFetchingLists: false,
    isDeletingList: false,
    lists: null,
    language: 'en',
    currentLangauge: '',
    menuList: []
};

export default handleActions(
    {
        [actions.fetchMe]: (state, action) =>
            u(
                {
                    isFetchingMe: true,
                },
                state
            ),

        [actions.receiveMe]: (state, action) =>
            u(
                {
                    isFetchingMe: false,
                    isLoggedIn: action.payload.profile.isLoggedIn,
                    profile: action.payload.profile,
                    currentLangauge: action.payload.profile.locale,
                },
                state
            ),

        [actions.fetchMeError]: (state, _) =>
            u(
                {
                    isFetchingMe: false,
                },
                state
            ),

        [actions.fetchLogout]: (state, action) =>
            u(
                {
                    isFetchingLogout: true,
                },
                state
            ),

        [actions.receiveLogout]: (state, action) =>
            u(
                {
                    isFetchingLogout: false,
                    isLoggedIn: false,
                },
                state
            ),

        [receiveCreateReadingList]: (state, action) =>
            u(
                {
                    lists: state.lists.concat(action.payload.readingList),
                },
                state
            ),

        [actions.receiveChangeLanguage]: (state, action) =>
            u(
                {
                    language: action.payload.newLanguage,
                },
                state
            ),

        [actions.receiveMenuList]: (state, action) =>
            u(
                {
                    menuList: action.payload.data
                },
                state
            ),
    },
    initialState
);