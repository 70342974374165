import React, { Component } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { translate } from 'react-polyglot';
import DocumentTitle from 'react-document-title';
import { connect } from 'react-redux';
import u from 'updeep';
import Joyride from 'react-joyride';
import closeIcon from '../../assets/closeIcon.png';
import Link from '../Link';
import Block from '../Block';
import BookCard from '../BookCard';
import Button from '../Button';
import DocumentClass from '../DocumentClass';
import Modal from '../Modal';
import NoSpaceForMoreOfflineBooksModal from './NoSpaceForMoreOfflineBooksModal';
import DeleteModal from './DeleteModal';
import Translations from './Translations';
import SimilarBooks from './SimilarBooks';
import BookDetailSummary from '../BookDetailSummary';
import BookRelatedCollection from '../BookRelatedCollection';
import Favourites from '../Favourites';
import DocumentHeader from '../DocumentHeader';
import VideoPlayer from '../VideoPlayer';
import { favouriteBookWorkflow } from '../../redux/bookActions';
import Comments from '../comments';
import LikeAndShareRow from './LikeAndShareRow';
import GovtApproved from './GovtApproved';
import BookTags from './BookTags';
import Dropdown from '../Dropdown';
import List from '../List';
import {
    MAX_OFFLINE_BOOKS_COUNT,
    links,
    gaEventCategories,
    gaEventActions,
    sectionClicked,
    linkType,
} from '../../lib/constants';
import { arrayToI18nList } from '../../lib/textUtils.js';
import './Book.scss';
import BookDetails from './BookDetails';
import { isMobile } from '../../util';
import BookTabletView from './BookTabletView';
import { API_URL } from '../../api'
import SvgIcon from '../SvgIcon'

const baseClassName = 'pb-book';

const mapDispatchToProps = {
    favouriteBookWorkflow,
};

@translate()
@connect(mapStateToProps, mapDispatchToProps)
class Book extends Component {
    static defaultProps = {
        illustrators: [],
        authors: [],
        originalAuthors: [],
        tags: [],
    };

    constructor(props) {
        super(props);

        this.state = {
            isiPad: false,
            showDisqus: false,
            isModalVisible: {
                noSpaceForMoreOfflineBooks: false,
                delete: false,
            },
            isClicked: false,
            isReadToMeModalVisible: false,
            isReadToMeVideoPlay: false,
            readToMeCount: null,
            pageNo: 1,
            ReplyPageNo: 1,
            showReply: '',
            showLikedUsers: '',
            isLike: true,
            dimension: 0,
        };

        this.onOpenModal = this.onOpenModal.bind(this);
        this.onCloseModal = this.onCloseModal.bind(this);
        this.handleToggleClick = this.handleToggleClick.bind(this);
    }

    componentDidMount = () => {
        this.iPadCheck();
        this.isMobile();
        this.isTabletMode();
        this.updateDimensions();
        window.addEventListener('resize', this.updateDimensions.bind(this));
    };

    updateDimensions() {
        this.setState({ dimension: window.innerWidth });
    }
    /**
     * Remove event listener
     */
    componentWillUnmount() {
        window.removeEventListener('resize', this.updateDimensions.bind(this));
    }
    handleToggleClick() {
        this.setState((prevState) => ({
            showDisqus: !prevState.showDisqus,
        }));
    }

    isMobile = () => {
        if (
            window.screen.width < 768 &&
            window.matchMedia('(orientation: portrait)').matches
        ) {
            this.setState({
                isBookSectionMobile: true,
            });
        } else {
            this.setState({
                isBookSectionMobile: false,
            });
        }
    };

    iPadCheck = () => {
        if (navigator.userAgent.match(/iPad/i) != null) {
            this.setState({
                isiPad: true,
            });
        }
    };

    isTabletMode = () => { };

    onOpenModal(modalName) {
        this.setState(
            u(
                {
                    isModalVisible: {
                        [modalName]: true,
                    },
                },
                this.state
            )
        );
    }

    onCloseModal(modalName) {
        this.setState(
            u(
                {
                    isModalVisible: {
                        [modalName]: false,
                    },
                },
                this.state
            )
        );
    }

    onReadClicked(slug) {
        if (this.props.book && this.props.book.is_offline_story) {
            // this.props.history.push(`/stories/${slug}/readStoryPage`);
            window.location.href = `/stories/${slug}/readStoryPage`;
        }
    }

    //ReadToMe Modal Action Functions

    readToMeModalOpen = () => {
        this.setState({
            isReadToMeModalVisible: true,
            isReadToMeVideoPlay: true,
            readToMeCount: this.props.book.readAlouds[0].readCount + 1,
        });
        const storyVideoId = this.props.book.readAlouds[0].coachingMaterialId;
        this.props.readToMeAction(storyVideoId);
    };

    readToMeModalClose = () => {
        this.setState({
            isReadToMeModalVisible: false,
            isReadToMeVideoPlay: false,
        });
    };

    //ReadToMe Modal

    readToMeModal = () => {
        return (
            <Modal>
                <div className="__close">
                    <Link onClick={() => this.readToMeModalClose()}>
                        <img
                            className="__profile-images"
                            src={closeIcon}
                            alt=""
                        />
                    </Link>
                </div>
                <div>
                    <VideoPlayer
                        name={this.props.book.readAlouds[0].filename}
                        videoSrc={this.props.book.readAlouds[0].url}
                        videoPlay={this.state.isReadToMeVideoPlay}
                    />
                </div>
            </Modal>
        );
    };

    onAddToOfflineClicked = () => {
        const {
            onClickAddToOffline,
            userEmail,
            book,
            recordGaEvents,
            addSlimNotification,
            t,
        } = this.props;

        onClickAddToOffline()
            .then(() =>
                addSlimNotification({
                    type: 'info',
                    content: t(
                        'BookCard.added-to-offline-library-notification'
                    ),
                })
            )
            .then(() => {
                recordGaEvents({
                    eventCategory: gaEventCategories.offline,
                    eventAction: gaEventActions.add,
                    userEmail: userEmail,
                    dimension2: book.level,
                    dimension3: book.language,
                    dimension5: book.slug,
                    metric2: 1,
                });
            });
    };

    onProfileLinkClicked = (profileSlug, linkType) => {
        const { recordGaEvents, userEmail } = this.props;

        recordGaEvents({
            eventCategory: gaEventCategories.profile,
            eventAction: gaEventActions.opened,
            eventLabel: `${sectionClicked.bookDetails} ${linkType}`,
            userEmail: userEmail,
            dimension5: profileSlug,
        });
    };

    onFavoriteClicked = (bookID) => {
        const { favouriteBookWorkflow } = this.props;
        favouriteBookWorkflow(bookID);
    };


    render() {
        const {
            book,
            t,
            viewport,
            online,
            isLoggedIn,
            onClickRemoveFromOffline,
            availableOffline,
            roles,
            isSavingOffline,
            offlineBooksCount,
            addSlimNotification,
            userEmail,
            recordGaEvents,
            isFavouriting,
            bookStoryDownload,

        } = this.props;

        const isRightToLeftDirection =
            localStorage.getItem('locale') === 'ar' ||
            localStorage.getItem('locale') === 'ur';

        const {
            isBookSectionMobile,
            dimension,
        } = this.state;

        const {
            name: title,
            name,
            coverImage,
            description,
            slug,
            authors,
            illustrators,
            publisher,
            photographers,
            tags,
            similarBooks,
            translations,
            readsCount,
            pagesCount,
            likesCount,
            isTranslation,
            isRelevelled,
            isDownloadable,
            level,
            id,
            downloadLinks,
            readAlouds,
            title_logos,
            storyLevelColour,
            translators,
            edition_type,
            onMicLink
        } = book;

        const {
            originalStory: {
                name: originalTitle,
                slug: originalSlug,
                authors: originalAuthors,
                isPresent: isOriginalStoryPresent
            },
        } = book;

        const myLanguage = localStorage.getItem('locale');

        const classes = {
            [baseClassName]: true,
            [`${baseClassName}--floated-cta`]: !viewport.large,
            [`${baseClassName}--offline`]: !online,
        };

        const titleClasses = {
            [myLanguage === 'lo'
                ? `${baseClassName}__lo__title`
                : isRightToLeftDirection
                    ? `${baseClassName}__title ${baseClassName}__arabic-title`
                    : `${baseClassName}__title`]: true,
            [`${baseClassName}__title--bhoti`]: book.language === 'Bhoti',
        };



        let favouriteStat = null;
        if (likesCount >= 0 && online) {
            favouriteStat = (
                <Favourites
                    t={t}
                    isFavourite={book.isFavourite}
                    favouriteToggleHandler={() =>
                        this.onFavoriteClicked(book.id)
                    }
                    isFavouriting={isFavouriting}
                    isLoggedIn={isLoggedIn}
                    logInMsg={t('global.please-log-in', {
                        action: t('global.like', 1),
                        content_type: t('global.story', 1),
                    })}
                    likes={likesCount}
                    bookSlug={slug}
                    bookFavorite="book-favorite"
                />
            );
        }

        let favouriteStatMainBook = null;
        if (likesCount >= 0 && online) {
            favouriteStatMainBook = (
                <Favourites
                    t={t}
                    isFavourite={book.isFavourite}
                    favouriteToggleHandler={() =>
                        this.onFavoriteClicked(book.id)
                    }
                    isFavouriting={isFavouriting}
                    isLoggedIn={isLoggedIn}
                    logInMsg={t('global.please-log-in', {
                        action: t('global.like', 1),
                        content_type: t('global.story', 1),
                    })}
                    likes={likesCount}
                    bookSlug={slug}
                    bookFavorite="book-favorite"
                    landingPageBook
                />
            );
        }

        const approvedTitles = title_logos.length > 0 && (
            <div className={`${baseClassName}__approvedTitlesContainer`}>
                {title_logos.map((logoText) => {
                    return (
                        <div
                            className={
                                isMobile()
                                    ? 'govt-approved-container-mobile'
                                    : 'govt-approved-container'
                            }
                        >
                            <img
                                className="govt-approved"
                                src={require('../../assets/govt-approved.svg')}
                                alt="Govt approved"
                            />
                            <p>
                                <span>{t('Book.governmentApproved')}</span>
                            </p>
                        </div>
                    );
                })}
            </div>
        );

        const publisherEl = (
            <div
                className={
                    isRightToLeftDirection
                        ? '__publisherSectionRtl'
                        : '__publisherSection'
                }
            >
                <p className="__publisherName">
                    {t('global.publisher', 1)}&nbsp;
                </p>
                <p>
                    <Link
                        normal
                        storyCredit={true}
                        isInternal={true}
                        disabled={!online}
                        href={`/publishers/${publisher.slug}`}
                        dataGTM="book-publisher"
                    >
                        {publisher.name}
                    </Link>{' '}
                </p>
            </div>
        );

        const makeProfileLinks = (collection, sectionClicked) =>
            collection.map((item, i) => (
                <Link
                    normal
                    storyCredit={true}
                    isInternal={true}
                    key={item.slug}
                    disabled={!online}
                    href={links.userProfile(item.slug)}
                    dataGTM="book-author"
                >
                    {item.name}
                </Link>
            ));

        let originalAuthorsEl = null;
        if ((edition_type === "adaptation" && isOriginalStoryPresent) || isRelevelled) {
            const originalAuthorsLinks = makeProfileLinks(
                originalAuthors !== undefined ? originalAuthors : "",
                linkType.originalStory
            );
            originalAuthorsEl = (
                <p
                    className={
                        localStorage.getItem('locale') === 'ar' ||
                            localStorage.getItem('locale') === 'ur'
                            ? `pb-credits__arabic`
                            : `pb-credits`
                    }
                >
                    {t('Book.original-story')}{' '}
                    <Link
                        normal
                        storyCredit={true}
                        isInternal={true}
                        disabled={!online}
                        href={links.bookDetails(originalSlug)}
                        dataGTM="book-author"
                    >
                        {originalTitle}
                    </Link>{' '}
                    {t('global.by')}{' '}
                    {arrayToI18nList(originalAuthorsLinks, t('book.and'))}
                </p>
            );
        }

        let steps = [
            {
                target: '.pb-book__favouriteCount',
                content: t('Book.favouriteHelpGuide'),
                placementBeacon: 'left',
                disableBeacon: true,
            },
            {
                target: '.pb-book__offline-container',
                content: t('Book.saveOfflineHelpGuide'),
                placementBeacon: 'left',
            },
            {
                target: '.__booktags',
                content: t('Book.bookTagsHelpGuide'),
                placementBeacon: 'left',
            },
            {
                target: '.__comments',
                content: t('Book.disqusHelpGuide'),
                placementBeacon: 'left',
            },
        ];

        let mobileSteps = [
            {
                target: '.book-like--count-wrapper',
                content: t('Book.favouriteHelpGuide'),
                placementBeacon: 'left',
                disableBeacon: true,
            },
            {
                target: '.pb-book__offline-container',
                content: t('Book.saveOfflineHelpGuide'),
                placementBeacon: 'left',
            },
            {
                target: '.__booktags',
                content: t('Book.bookTagsHelpGuide'),
                placementBeacon: 'left',
            },
            {
                target: '.__comments',
                content: t('Book.disqusHelpGuide'),
                placementBeacon: 'left',
            },
        ];

        return (
            <div className={classNames(classes)}>
                <DocumentHeader
                    title={name}
                    description={description}
                    keywords={slug}
                    imageUrl={
                        coverImage.isThumbnailEdited ||
                            coverImage.sizes[6] === undefined
                            ? coverImage.sizes[0].url
                            : coverImage.sizes[6].url
                    }
                    imgWidth={
                        coverImage.isThumbnailEdited ||
                            coverImage.sizes[6] === undefined
                            ? coverImage.sizes[0].width
                            : coverImage.sizes[6].width
                    }
                    imgHeight={
                        coverImage.isThumbnailEdited ||
                            coverImage.sizes[6] === undefined
                            ? coverImage.sizes[0].height
                            : coverImage.sizes[6].height
                    }
                    imgAlt={name}
                />
                <Joyride
                    enabled={true}
                    steps={isBookSectionMobile ? mobileSteps : steps}
                    continuous
                    showProgress
                    run={this.props.helpStatus}
                    scrollToFirstStep
                    scrollOffset={250}
                    disableScrolling={false}
                    locale={{
                        next: t('global.Next'),
                        back: t('global.Back'),
                        last: t('global.Last'),
                    }}
                    styles={{
                        options: {
                            arrowColor: '#e3ffeb',
                            backgroundColor: '#e3ffeb',
                            textColor: '#004a14',
                            overlayColor: 'rgba(0, 0, 0, 0.8)',
                            primaryColor: '#007AA4',
                            spotlightShadow: '0 0 15px rgba(0, 0, 0, 0.8)',
                            zIndex: 1000,
                        },
                    }}
                />
                <DocumentTitle title={`${title} - ${t('global.site-title')}`} />
                <DocumentClass
                    className={`${baseClassName}-document--offline`}
                    remove={online}
                />
                {dimension >= 600 && dimension <= 1024 ? (
                    <BookTabletView
                        roles={roles}
                        isLoggedIn={isLoggedIn}
                        t={t}
                        level={level}
                        baseClassName={baseClassName}
                        myLanguage={myLanguage}
                        online={online}
                        book={book}
                        fontSize="l"
                        shouldDisplayMenu={true}
                        noSrcSet={!online}
                        bookDetails={dimension < 600 ? false : true}
                        pagesCount={pagesCount}
                        title={title}
                        storyLevelColour={storyLevelColour}
                        title_logos={title_logos}
                        favouriteStat={favouriteStat}
                        favouriteStatMainBook={favouriteStatMainBook}
                        readsCount={readsCount}
                        isSavingOffline={isSavingOffline}
                        availableOffline={availableOffline}
                        offlineBooksCount={offlineBooksCount}
                        MAX_OFFLINE_BOOKS_COUNT={MAX_OFFLINE_BOOKS_COUNT}
                        bookStoryDownload={bookStoryDownload}
                        onReadClicked={this.onReadClicked.bind(this, slug)}
                        readToMeModalOpen={this.readToMeModalOpen}
                        onAddToOfflineClicked={this.onAddToOfflineClicked}
                        onOpenModal={this.onOpenModal}
                        landingPageBook
                    />
                ) : (
                    <div className={`${baseClassName}__block-wrapper`}>
                        <Block>
                            <div className={`${baseClassName}__wrapper`}>
                                <div
                                    className={
                                        isRightToLeftDirection
                                            ? `${baseClassName}__cover-wrapper-rtl`
                                            : `${baseClassName}__cover-wrapper`
                                    }
                                >
                                    <div
                                        className={
                                            isRightToLeftDirection
                                                ? `${baseClassName}__cover-rtl`
                                                : `${baseClassName}__cover`
                                        }
                                    >
                                        <div className="book-details">
                                            {(window.screen.width < 768 ||
                                                (window.screen.width < 768 &&
                                                    window.matchMedia(
                                                        '(orientation: landscape)'
                                                    ).matches)) &&
                                                title_logos.length > 0 && (
                                                    <GovtApproved
                                                        t={t}
                                                        title_logos={
                                                            title_logos
                                                        }
                                                    />
                                                )}
                                        </div>
                                        <BookCard
                                            myLanguage={myLanguage}
                                            online={online}
                                            book={book}
                                            fontSize="l"
                                            readStory={() =>
                                                this.onReadClicked(
                                                    slug
                                                )
                                            }
                                            shouldDisplayMenu={false}
                                            noSrcSet={!online}
                                            bookDetails={
                                                window.screen.width < 768 ||
                                                    (window.screen.width < 768 &&
                                                        window.matchMedia(
                                                            '(orientation: landscape)'
                                                        ).matches)
                                                    ? false
                                                    : true
                                            }
                                            landingPageBook
                                            pagesCount={pagesCount}
                                            enableFavouriting
                                            mobileViewDetails
                                            isFavourite={book.isFavourite}
                                        />
                                        {(isMobile() ||
                                            (window.screen.width < 768 &&
                                                window.matchMedia(
                                                    '(orientation: landscape)'
                                                ).matches)) && (
                                                <LikeAndShareRow
                                                    {...this.props}
                                                    landingPageBook
                                                />
                                            )}
                                    </div>
                                    {window.screen.width < 768 &&
                                        window.matchMedia(
                                            '(orientation: landscape)'
                                        ).matches
                                        ? publisherEl
                                        : null}
                                    {window.screen.width < 768 &&
                                        window.matchMedia(
                                            '(orientation: landscape)'
                                        ).matches
                                        ? originalAuthorsEl
                                        : null}
                                </div>
                                <div
                                    className={`${baseClassName}__story-wrapper`}
                                >
                                    <div
                                        className={`${baseClassName}__title-row`}
                                    >
                                        <div
                                            className={classNames(titleClasses)}
                                        >
                                            {title}
                                        </div>
                                    </div>
                                    <div
                                        className={`${baseClassName}__summary-collection`}
                                    >
                                        <BookDetailSummary
                                            description={description}
                                        />
                                    </div>
                                    {(window.screen.width >= 1025 &&
                                        window.screen.width <= 1199) ||
                                        (window.innerWidth >= 1025 &&
                                            window.innerWidth <= 1199) ? null : (
                                        <div
                                            className={
                                                isRightToLeftDirection
                                                    ? `${baseClassName}__readStory-wrapper-arabic`
                                                    : `${baseClassName}__readStory-wrapper`
                                            }
                                        >
                                            <div
                                                className={`${baseClassName}__readStory-flex-wrapper`}
                                            >
                                                <div
                                                    className={`${baseClassName}__read-button`}
                                                >
                                                    <Button
                                                        iconLeft="read_white"
                                                        buttonBlue
                                                        fullWidth
                                                        label={t('Book.read')}
                                                        variant="primary"
                                                        onClick={() =>
                                                            this.onReadClicked(slug)
                                                        }
                                                        dataGTM="book-read-story"
                                                        isRightToLeftDirection={
                                                            isRightToLeftDirection
                                                        }
                                                    />
                                                </div>

                                                {/* Read To Me Button */}

                                                {readAlouds.length > 0 && (
                                                    <div
                                                        className={`${baseClassName}__read-to-me-button`}
                                                    >
                                                        <Button
                                                            iconLeft="video"
                                                            buttonBlue
                                                            fullWidth
                                                            label={t(
                                                                'Book.readToMe'
                                                            )}
                                                            variant="primary"
                                                            onClick={() =>
                                                                this.readToMeModalOpen()
                                                            }
                                                            isRightToLeftDirection={
                                                                isRightToLeftDirection
                                                            }
                                                        />
                                                    </div>
                                                )}
                                            </div>
                                            <div
                                                className={`${baseClassName}__readStory-flex-wrapper`}
                                            >
                                                {isLoggedIn ? (
                                                    roles.includes(
                                                        'content_manager'
                                                    ) ||
                                                        roles.includes(
                                                            'sub_admin'
                                                        ) ||
                                                        roles.includes('admin') ? (
                                                        API_URL === 'https://staging.literacycloud.org/api/v1'
                                                            ? null :
                                                            <div
                                                                className={
                                                                    isRightToLeftDirection
                                                                        ? `${baseClassName}__book-download-arabic`
                                                                        : `${baseClassName}__book-download`
                                                                }
                                                            >
                                                                <Dropdown
                                                                    align="left"
                                                                    toggleEl={
                                                                        <Link
                                                                            normal
                                                                        >
                                                                            {t(
                                                                                'global.download',
                                                                                1
                                                                            )}
                                                                        </Link>
                                                                    }
                                                                    bookTabletMode
                                                                >
                                                                    <List nowrap>
                                                                        {downloadLinks &&
                                                                            downloadLinks
                                                                                .slice(
                                                                                    0,
                                                                                    2
                                                                                )
                                                                                .map(
                                                                                    (
                                                                                        {
                                                                                            href,
                                                                                            type,
                                                                                        },
                                                                                        index
                                                                                    ) => {
                                                                                        return (
                                                                                            <Link
                                                                                                parentClassName={`${baseClassName}__link`}
                                                                                                download
                                                                                                href={
                                                                                                    href
                                                                                                }
                                                                                                dataGTM={
                                                                                                    bookStoryDownload
                                                                                                }
                                                                                            >
                                                                                                <div
                                                                                                    className="url_name"
                                                                                                    key={
                                                                                                        index
                                                                                                    }
                                                                                                >
                                                                                                    {
                                                                                                        type
                                                                                                    }
                                                                                                </div>
                                                                                            </Link>
                                                                                        );
                                                                                    }
                                                                                )}
                                                                    </List>
                                                                </Dropdown>
                                                            </div>
                                                    ) : isDownloadable ? (
                                                        API_URL === 'https://staging.literacycloud.org/api/v1'
                                                            ? null :
                                                            <div
                                                                className={
                                                                    isRightToLeftDirection
                                                                        ? `${baseClassName}__book-download-arabic`
                                                                        : `${baseClassName}__book-download`
                                                                }
                                                            >
                                                                <Link
                                                                    parentClassName={`${baseClassName}__link`}
                                                                    download
                                                                    href={
                                                                        downloadLinks[0]
                                                                            .href
                                                                    }
                                                                    dataGTM={
                                                                        bookStoryDownload
                                                                    }
                                                                >
                                                                    <div
                                                                        data-gtm={
                                                                            bookStoryDownload
                                                                        }
                                                                    >
                                                                        {t(
                                                                            'global.download',
                                                                            1
                                                                        )}
                                                                    </div>
                                                                </Link>
                                                            </div>
                                                    ) : null
                                                ) : null}

                                                {process.env
                                                    .REACT_APP_FEATURE_OFFLINE ? (
                                                    <div
                                                        className={`${baseClassName}__offline-container`}
                                                    >
                                                        <Button
                                                            saveOffline
                                                            t={t}
                                                            fullWidth
                                                            isLoggedIn={
                                                                isLoggedIn
                                                            }
                                                            availableOffline={
                                                                availableOffline
                                                            }
                                                            label={t(
                                                                'Book.add-to-offline'
                                                            )}
                                                            variant="white-background"
                                                            isRightToLeftDirection={
                                                                isRightToLeftDirection
                                                            }
                                                            dataGTM={
                                                                bookStoryDownload
                                                            }
                                                            onClickAddToOffline={() => {
                                                                offlineBooksCount <
                                                                    MAX_OFFLINE_BOOKS_COUNT
                                                                    ? this.onAddToOfflineClicked()
                                                                    : this.onOpenModal(
                                                                        'noSpaceForMoreOfflineBooks'
                                                                    );
                                                            }}
                                                            onClickRemoveFromOffline={() =>
                                                                this.onOpenModal(
                                                                    'delete'
                                                                )
                                                            }
                                                            disabled={
                                                                !(
                                                                    'serviceWorker' in
                                                                    navigator
                                                                )
                                                            }
                                                            isSavingOffline={
                                                                isSavingOffline
                                                            }
                                                            bookSlug={slug}
                                                        ></Button>
                                                    </div>
                                                 ) : null}
                                            </div>
                                        </div>
                                    )}
                                </div>
                                <div className={`${baseClassName}__content`}>
                                    <BookDetails
                                        level={level}
                                        title={title}
                                        isTranslation={isTranslation}
                                        isRelevelled={isRelevelled}
                                        authors={authors}
                                        publisher={publisher}
                                        illustrators={illustrators}
                                        photographers={photographers}
                                        originalTitle={originalTitle}
                                        originalSlug={originalSlug}
                                        originalAuthors={originalAuthors}
                                        online={online}
                                        t={t}
                                        onProfileLinkClicked={
                                            this.onProfileLinkClicked
                                        }
                                        favouriteStat={favouriteStat}
                                        favouriteStatMainBook={
                                            favouriteStatMainBook
                                        }
                                        readsCount={readsCount}
                                        description={description}
                                        coverImage={book.coverImage}
                                        viewport={viewport}
                                        isLoggedIn={isLoggedIn}
                                        logInMsg={t('global.please-log-in', {
                                            action: t('global.like', 1),
                                            content_type: t('global.story', 1),
                                        })}
                                        bookSlug={slug}
                                        approvedTitles={approvedTitles}
                                        title_logos={title_logos}
                                        landingPageBook
                                        storyLevelColour={storyLevelColour}
                                        isLandingBookPage
                                        translator={translators}
                                        type={edition_type}
                                        isOriginalStoryPresent={isOriginalStoryPresent}
                                    />

                                    {/* Translated Titles Component for show list of other language the title is published in */}
                                    <div>
                                        {online &&
                                            translations &&
                                            translations.length &&
                                            !isMobile() ? (
                                            <div
                                                className={
                                                    isRightToLeftDirection
                                                        ? `${baseClassName}__translation-rtl`
                                                        : `${baseClassName}__translation`
                                                }
                                            >
                                                <Translations
                                                    translations={translations}
                                                    versionCount={
                                                        book.versionCount
                                                    }
                                                    languageCount={
                                                        book.languageCount
                                                    }
                                                    offline={!online}
                                                    t={t}
                                                />
                                            </div>
                                        ) : null}
                                    </div>
                                </div>
                            </div>
                        </Block>
                        {(window.screen.width >= 1025 &&
                            window.screen.width <= 1199) ||
                            (window.innerWidth >= 1025 &&
                                window.innerWidth <= 1199) ? (
                            <div
                                className={
                                    isRightToLeftDirection
                                        ? `${baseClassName}__readStory-wrapper-arabic`
                                        : `${baseClassName}__readStory-wrapper`
                                }
                            >
                                <div
                                    className={`${baseClassName}__read-button`}
                                >
                                    <Button
                                        iconLeft="read_white"
                                        buttonBlue
                                        fullWidth
                                        label={t('Book.read')}
                                        variant="primary"
                                        onClick={() =>
                                            this.onReadClicked(slug)
                                        }
                                        dataGTM="book-read-story"
                                        isRightToLeftDirection={
                                            isRightToLeftDirection
                                        }
                                    />
                                </div>

                                {/* Read To Me Button */}

                                {readAlouds.length > 0 && (
                                    <div
                                        className={`${baseClassName}__read-to-me-button`}
                                    >
                                        <Button
                                            iconLeft="video"
                                            buttonBlue
                                            fullWidth
                                            label={t('Book.readToMe')}
                                            variant="primary"
                                            onClick={() =>
                                                this.readToMeModalOpen()
                                            }
                                            isRightToLeftDirection={
                                                isRightToLeftDirection
                                            }
                                        />
                                    </div>
                                )}
                                {isLoggedIn ? (
                                    roles.includes('content_manager') ||
                                        roles.includes('sub_admin') ||
                                        roles.includes('admin') ? (
                                        API_URL === 'https://staging.literacycloud.org/api/v1'
                                            ? null :
                                            <div
                                                className={
                                                    isRightToLeftDirection
                                                        ? `${baseClassName}__book-download-laptop-arabic`
                                                        : `${baseClassName}__book-download-laptop`
                                                }
                                            >
                                                <Dropdown
                                                    align="left"
                                                    toggleEl={
                                                        <Link normal>
                                                            {t(
                                                                'global.download',
                                                                1
                                                            )}
                                                        </Link>
                                                    }
                                                    bookTabletMode
                                                >
                                                    <List nowrap>
                                                        {downloadLinks &&
                                                            downloadLinks
                                                                .slice(0, 2)
                                                                .map(
                                                                    (
                                                                        {
                                                                            href,
                                                                            type,
                                                                        },
                                                                        index
                                                                    ) => {
                                                                        return (
                                                                            <Link
                                                                                parentClassName={`${baseClassName}__link`}
                                                                                download
                                                                                href={
                                                                                    href
                                                                                }
                                                                                dataGTM={
                                                                                    bookStoryDownload
                                                                                }
                                                                            >
                                                                                <div
                                                                                    className="url_name"
                                                                                    key={
                                                                                        index
                                                                                    }
                                                                                >
                                                                                    {
                                                                                        type
                                                                                    }
                                                                                </div>
                                                                            </Link>
                                                                        );
                                                                    }
                                                                )}
                                                    </List>
                                                </Dropdown>
                                            </div>
                                    ) : isDownloadable ? (
                                        API_URL === 'https://staging.literacycloud.org/api/v1'
                                            ? null :
                                            <div
                                                className={
                                                    isRightToLeftDirection
                                                        ? `${baseClassName}__book-download-arabic`
                                                        : `${baseClassName}__book-download`
                                                }
                                            >
                                                <Link
                                                    parentClassName={`${baseClassName}__link`}
                                                    download
                                                    href={downloadLinks[0].href}
                                                    dataGTM={bookStoryDownload}
                                                >
                                                    <div
                                                        data-gtm={bookStoryDownload}
                                                    >
                                                        {t('global.download', 1)}
                                                    </div>
                                                </Link>
                                            </div>
                                    ) : null
                                ) : null}

                                {process.env.REACT_APP_FEATURE_OFFLINE ? (
                                    <div
                                        className={`${baseClassName}__offline-container`}
                                    >
                                        <Button
                                            saveOffline
                                            t={t}
                                            fullWidth
                                            isLoggedIn={isLoggedIn}
                                            availableOffline={availableOffline}
                                            label={t('Book.add-to-offline')}
                                            variant="white-background"
                                            isRightToLeftDirection={
                                                isRightToLeftDirection
                                            }
                                            dataGTM={bookStoryDownload}
                                            onClickAddToOffline={() => {
                                                offlineBooksCount <
                                                    MAX_OFFLINE_BOOKS_COUNT
                                                    ? this.onAddToOfflineClicked()
                                                    : this.onOpenModal(
                                                        'noSpaceForMoreOfflineBooks'
                                                    );
                                            }}
                                            onClickRemoveFromOffline={() =>
                                                this.onOpenModal('delete')
                                            }
                                            disabled={
                                                !('serviceWorker' in navigator)
                                            }
                                            isSavingOffline={isSavingOffline}
                                            bookSlug={slug}
                                        ></Button>
                                    </div>
                                    ) : null}
                            </div>
                        ) : null}
                    </div>
                )}

                <div className={`${baseClassName}__block-wrapper`}>
                    <Block>
                        <div className={`${baseClassName}__SecondRowWrapper`}>
                            {tags && tags.length > 0 && !isMobile() && (
                                <div
                                    className={
                                        isRightToLeftDirection
                                            ? `${baseClassName}__BookTagsContainerRtl`
                                            : `${baseClassName}__BookTagsContainer`
                                    }
                                >
                                    <BookTags
                                        tags={tags}
                                        online={online}
                                        t={t}
                                        type={'bookPage'}
                                    />
                                </div>
                            )}
                    
                            {online && book.readingListMembershipCount > 0 ? (
                                <div
                                    className={`${baseClassName}__RelatedReadingListContainer`}
                                >
                                    {book.readingListMembershipCount ? (
                                        <BookRelatedCollection
                                            title={t('Book.related-collection')}
                                            book={book}
                                            bookRelatedReadingLists="related-reading-lists"
                                            isRightToLeftDirection={
                                                isRightToLeftDirection
                                            }
                                        />
                                    ) : null}
                                </div>
                            ) : null}

                            {
                                (localStorage.getItem('locale') === 'vi' || localStorage.getItem('locale') === 'en') && onMicLink &&
                                (
                                    <div className={`${baseClassName}__audio-link-container`}>
                                        <div className={`${baseClassName}__audio-link-container-title`}>
                                            {t('book.audioLinkTitle')}
                                        </div>
                                        <Link isExternal={true} audioLink href={onMicLink} >
                                            <SvgIcon
                                                name="onMicIcon" 
                                                size="onmic-icon"  
                                            />
                                        </Link>
                                    </div>
                                )
                            }

                            {/* Translated Titles Component for show list of other language the title is published in */}
                            {online &&
                                translations &&
                                translations.length &&
                                (isMobile() ||
                                    (dimension >= 600 && dimension <= 1024) ||
                                    (dimension <= 1024 &&
                                        window.matchMedia('(orientation:landscape')
                                            .matches)) ? (
                                <div
                                    className={`${baseClassName}__translation_wrapper`}
                                >
                                    <div
                                        className={
                                            isRightToLeftDirection
                                                ? `${baseClassName}__translation-rtl`
                                                : `${baseClassName}__translation`
                                        }
                                    >
                                        <Translations
                                            translations={translations}
                                            versionCount={book.versionCount}
                                            languageCount={book.languageCount}
                                            offline={!online}
                                            t={t}
                                        />
                                    </div>
                                </div>
                            ) : null}
                            {online && tags && tags.length > 0 && isMobile() && (
                                <div
                                    className={`${baseClassName}__BookTagsContainer`}
                                >
                                    <BookTags
                                        tags={tags}
                                        online={online}
                                        t={t}
                                        type={'bookPage'}
                                    />
                                </div>
                            )}
                        </div>
                    </Block>
                </div>


                <SimilarBooks
                    myLanguage={myLanguage}
                    offline={!online}
                    similarBooks={similarBooks}
                    t={t}
                    viewport={viewport}
                />

                {/*Disqus trigger section*/}
                {
                    <div
                        id="commentsSectionContainer"
                        className={`${baseClassName}__disqus-section`}
                    >
                        <div className="__disqussion">
                            <Comments
                                source="book"
                                id={id}
                                roles={roles}
                                viewport={viewport}
                                isLoggedIn={isLoggedIn}
                            />
                        </div>
                    </div>
                }
                {this.state.isModalVisible.noSpaceForMoreOfflineBooks ? (
                    <NoSpaceForMoreOfflineBooksModal
                        viewport={viewport}
                        onClose={() =>
                            this.onCloseModal('noSpaceForMoreOfflineBooks')
                        }
                        maximum={MAX_OFFLINE_BOOKS_COUNT}
                    />
                ) : null}
                {this.state.isModalVisible.delete ? (
                    <DeleteModal
                        onConfirm={() => {
                            onClickRemoveFromOffline();
                            addSlimNotification({
                                type: 'info',
                                content: t(
                                    'BookCard.removed-from-offline-library-notification'
                                ),
                            });
                        }}
                        myLanguage={myLanguage}
                        onClose={() => this.onCloseModal('delete')}
                        viewport={viewport}
                        count={1}
                        books={[book]}
                        userEmail={userEmail}
                        recordGaEvents={recordGaEvents}
                        baseClassName={baseClassName}
                        addSlimNotification={addSlimNotification}
                    />
                ) : null}
                {this.state.isReadToMeModalVisible && this.readToMeModal()}
            </div>
        );
    }
}

Book.propTypes = {
    title: PropTypes.string.isRequired,
    status: PropTypes.string.isRequired,
    language: PropTypes.string.isRequired,
    level: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    slug: PropTypes.string.isRequired,
    coverImage: PropTypes.object.isRequired,
    recommended: PropTypes.bool,
    online: PropTypes.bool,
    avilableOffline: PropTypes.bool,
    authors: PropTypes.arrayOf(
        PropTypes.shape({
            name: PropTypes.string.isRequired,
            slug: PropTypes.string.isRequired,
        })
    ),
    publisher: PropTypes.object,
    likesCount: PropTypes.number,
    readsCount: PropTypes.number,
    tags: PropTypes.array,
    translations: PropTypes.arrayOf(
        PropTypes.shape({
            language: PropTypes.string.isRequired,
            level: PropTypes.string.isRequired,
            slug: PropTypes.string.isRequired,
        })
    ),
    downloadLinks: PropTypes.arrayOf(
        PropTypes.shape({
            type: PropTypes.string.isRequired,
            href: PropTypes.string.isRequired,
        })
    ),
    similarBooks: PropTypes.arrayOf(PropTypes.shape(BookCard.propTypes)),
    onReadClicked: PropTypes.func,
    onClickAddToOffline: PropTypes.func,
    onClickRemoveFromOffline: PropTypes.func,
    viewport: PropTypes.object,
    isEditable: PropTypes.bool,
    isSavingOffline: PropTypes.bool,
    offlineBooksCount: PropTypes.number,
    externalLink: PropTypes.object,
};

const mapStateToProps = (state) => {
    return {
        isFavourite: state.book.book.isFavourite,
        isFavouriting: state.book.book.isFavouriting,
        helpStatus: state.help.helpStatus,
    };
};

export default translate()(connect(mapStateToProps)(Book));
