import React, { Component } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import './RowSeparatorContainer.scss';

class RowSeparatorContainer extends Component {
    static defaultProps = {
        noPadding: false,
    };

    render() {
        const baseClassName = 'pb-row-separator-container';
        const { parentClassName, children, noPadding } = this.props;

        const classes = {
            [`${baseClassName}__no-padding`]: noPadding,
            [baseClassName]: true,
            [parentClassName]: parentClassName,
        };

        return (
            <div className={classNames(classes)}>
                {children}
                <div className={`${baseClassName}__separator`} />
            </div>
        );
    }
}

RowSeparatorContainer.propTypes = {
    parentClassName: PropTypes.string,
    noPadding: PropTypes.bool,
};

export default RowSeparatorContainer;
