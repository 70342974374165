import { createAction } from 'redux-actions';
import { createAsyncActions, runAsyncWorkflow } from '../lib/reduxHelpers';

import { CommentsAndReply } from '../api';

//main comment section
export const [
    mainComment,
    ReceiveMainComment,
    mainCommentError,
] = createAsyncActions(
    'MAIN_COMMENT',
    ({ ok, data, meta, pageNo }) => ({ ok, data, meta, pageNo }),
    ({ ok, data, meta, pageNo }) => ({ ok, data, meta, pageNo })
);

export const getMainComments = (bookID, pageNo, commentSource) => {
    const fetchPromise = CommentsAndReply.fetchMainComments(
        bookID,
        pageNo,
        commentSource
    );

    return runAsyncWorkflow(
        {
            fetch: mainComment,
            receive: ReceiveMainComment,
            error: mainCommentError,
        },
        fetchPromise,
        { pageNo }
    );
};

export const [
    postMainComment,
    ReceivePostMainComment,
    postMainCommentError,
] = createAsyncActions(
    'POST_MAIN_COMMENT',
    ({ ok, data }) => ({ ok, data }),
    ({ ok, data }) => ({ ok, data })
);

export const postMainComments = (bookID, newComment, commentSource) => {
    const fetchPromise = CommentsAndReply.postMainComment(
        bookID,
        newComment,
        commentSource
    );

    return runAsyncWorkflow(
        {
            fetch: postMainComment,
            receive: ReceivePostMainComment,
            error: postMainCommentError,
        },
        fetchPromise
    );
};

//reply comment

export const [
    replyComment,
    ReceiveReplyComment,
    replyCommentError,
] = createAsyncActions(
    'REPLY_COMMENT',
    ({ ok, data, meta, pageNo }) => ({ ok, data, meta, pageNo }),
    ({ ok, data, meta, pageNo }) => ({ ok, data, meta, pageNo })
);

export const getReplyComments = (commentId, pageNo) => {
    const fetchPromise = CommentsAndReply.fetchReplyComments(commentId, pageNo);

    return runAsyncWorkflow(
        {
            fetch: replyComment,
            receive: ReceiveReplyComment,
            error: replyCommentError,
        },
        fetchPromise,
        { pageNo }
    );
};

export const [
    postReplyComment,
    ReceivePostReplyComment,
    postReplyCommentError,
] = createAsyncActions(
    'POST_REPLY_COMMENT',
    ({ ok, data }) => ({ ok, data }),
    ({ ok, data, commentId }) => ({ ok, data, commentId })
);

export const postReplyComments = (commentId, NewReply) => {
    const fetchPromise = CommentsAndReply.postReplyComment(commentId, NewReply);

    return runAsyncWorkflow(
        {
            fetch: postReplyComment,
            receive: ReceivePostReplyComment,
            error: postReplyCommentError,
        },
        fetchPromise,
        { commentId }
    );
};

export const [
    deleteReplyComment,
    ReceiveDeleteReplyComment,
    deleteReplyCommentError,
] = createAsyncActions(
    'DELETE_REPLY_COMMENT',
    ({ commentId }) => ({ commentId }),
    ({ commentId }) => ({ commentId })
);

export const deleteReplyComments = (commentId) => {
    const fetchPromise = CommentsAndReply.fetchReplyComments(
        commentId,
        100000000000000
    );

    return runAsyncWorkflow(
        {
            fetch: deleteReplyComment,
            receive: ReceiveDeleteReplyComment,
            error: deleteReplyCommentError,
        },
        fetchPromise
    );
};

//empty comment before display new book/video

export const [
    emptyComments,
    ReceiveEmptyComments,
    emptyCommentsError,
] = createAsyncActions(
    'REMOVE_COMMENTS',
    ({}) => ({}),
    ({}) => ({})
);

export const removeComments = (bookID, commentSource) => {
    const fetchPromise = CommentsAndReply.fetchMainComments(
        bookID,
        1000000000000000,
        commentSource
    );

    return runAsyncWorkflow(
        {
            fetch: emptyComments,
            receive: ReceiveEmptyComments,
            error: emptyCommentsError,
        },
        fetchPromise
    );
};

//Like and unlike
export const [postLike, ReceivePostLike, postLikeError] = createAsyncActions(
    'LIKE_COMMENT',
    ({ ok }) => ({ ok }),
    ({ ok, data, commentId, source }) => ({ ok, data, commentId, source })
);

export const postLikeCall = (commentId, source) => {
    const fetchPromise = CommentsAndReply.postLikes(commentId);

    return runAsyncWorkflow(
        {
            fetch: postLike,
            receive: ReceivePostLike,
            error: postLikeError,
        },
        fetchPromise,
        { commentId, source }
    );
};

export const [
    deleteLike,
    ReceiveDeleteLike,
    deleteLikeError,
] = createAsyncActions(
    'UNLIKE_COMMENT',
    ({ ok }) => ({ ok }),
    ({ ok, data, commentId, source }) => ({ ok, data, commentId, source })
);

export const deleteLikeCall = (commentId, source) => {
    const fetchPromise = CommentsAndReply.deleteLike(commentId);

    return runAsyncWorkflow(
        {
            fetch: deleteLike,
            receive: ReceiveDeleteLike,
            error: deleteLikeError,
        },
        fetchPromise,
        { commentId, source }
    );
};

export const [
    fetchReportComment,
    ReceiveReportComment,
    ErrorReportComment,
] = createAsyncActions(
    'REPORT_COMMENT',
    ({ commentId, reason }) => ({ commentId, reason }),
    ({ commentId, reason }) => ({ commentId, reason })
);

export const reportComment = (commentId, reason) => {
    const fetchPromise = CommentsAndReply.reportComment(commentId, reason);
    return runAsyncWorkflow(
        {
            fetch: fetchReportComment,
            receive: ReceiveReportComment,
            error: ErrorReportComment,
        },
        fetchPromise,
        { commentId, reason }
    );
};

export const [
    fetchReportedCommentDelete,
    ReceiveReportedCommentDelete,
    ErrorReportedCommentDelete,
] = createAsyncActions(
    'DELETE_REPORTED_COMMENT',
    ({ commentId }) => ({ commentId }),
    ({ commentId }) => ({ commentId })
);

export const deleteReportedComment = (commentId) => {
    const fetchPromise = CommentsAndReply.deleteComment(commentId);
    return runAsyncWorkflow(
        {
            fetch: fetchReportedCommentDelete,
            receive: ReceiveReportedCommentDelete,
            error: ErrorReportedCommentDelete,
        },
        fetchPromise,
        { commentId }
    );
};
