import { createAsyncActions, runAsyncWorkflow } from '../lib/reduxHelpers';
import { User, Publisher } from '../api';
import { profileTypes } from '../lib/constants';

const mapModelsToNames = {
    [profileTypes.USER]: User,
    [profileTypes.PUBLISHER]: Publisher,
};

export const [
    fetchProfile,
    receiveProfile,
    fetchProfileError,
] = createAsyncActions(
    'PROFILE',
    slug => ({ slug }),
    ({ ok, data, profileType }) => ({ profile: data, profileType })
);

export const fetchProfileWorkflow = (slug, profileType, perPage) => {
    const fetchPromise = mapModelsToNames[profileType].fetchProfile(slug, 24);

    return runAsyncWorkflow(
        {
            fetch: fetchProfile,
            receive: receiveProfile,
            error: fetchProfileError,
        },
        fetchPromise,
        {
            slug,
            profileType,
            perPage,
        },
        true,
        (dispatchFn, error) => {
            // Redirect to legacy 404 page in case of any error
            if (process.env.NODE_ENV === 'production') {
                window.location.href = '/errors/error_404';
            }
        }
    );
};

export const [fetchMore, receiveMore, fetchMoreError] = createAsyncActions(
    'PROFILE_LOAD_MORE',
    (collection, page) => ({ collection, page }),
    ({ data, metadata, profileType, collection }) => ({
        data,
        metadata,
        profileType,
        collection,
    })
);

export const loadMoreWorkflow = (
    profileType,
    profileName,
    slug,
    collection,
    page,
    perPage
) => {
    const fetchPromise = mapModelsToNames[profileType].fetchProfile(
        slug,
        24,
        page
    );

    return runAsyncWorkflow(
        {
            fetch: fetchMore,
            receive: receiveMore,
            error: fetchMoreError,
        },
        fetchPromise,
        {
            profileType,
            collection,
            perPage,
            page,
        }
    );
};


export const [
    fetchfavouriteProfilePageData,
    receivefavouriteProfilePageData,
    fetchfavouriteProfilePageDataError,
] = createAsyncActions(
    'FAVOURITE_PROFILE_PAGE_DATA',
    ({ ok, bookId, type }) => ({ ok, bookId, type }),
    ({ ok, bookId, type }) => ({ ok, bookId, type })
);

export const favouriteProfilePageDataWorkflow = (bookId, type) => {
    const fetchPromise = User.toggleFavourite(bookId);

    return runAsyncWorkflow(
        {
            fetch: fetchfavouriteProfilePageData,
            receive: receivefavouriteProfilePageData,
            error: fetchfavouriteProfilePageDataError,
        },
        fetchPromise,
        {
            bookId,
            type
        }
    );
};

//Publisher Page Filterss
export const [
    fetchPublisherBooksFilters,
    receivePublisherBooksFilters,
    PublisherBooksFiltersError,
] = createAsyncActions(
    'PUBLISHER_BOOKS_FILTERS',
    () => ({}),
    ({ data }) => ({ filters: data  })
);

export const fetchPublisherBooksFiltersWorkflow = () => {
    const fetchPromise = Publisher.fetchAllFilters();

    return runAsyncWorkflow(
        {
            fetch: fetchPublisherBooksFilters,
            receive: receivePublisherBooksFilters,
            error: PublisherBooksFiltersError,
        },
        fetchPromise
    );
};


// Publisher Page Profile
export const [
    fetchPublisherProfile,
    receivePublisherProfile,
    fetchPublisherProfileError,
] = createAsyncActions(
    'PUBLISHER_PROFILE',
    slug => ({ slug }),
    ({ ok, data, profileType }) => ({ profile: data, profileType })
);

export const fetchPublisherProfileWorkflow = (appliedFilters, slug, profileType, perPage) => {
    const fetchPromise = mapModelsToNames[profileTypes.PUBLISHER].fetchPublisherProfile(appliedFilters, slug, 24);

    return runAsyncWorkflow(
        {
            fetch: fetchPublisherProfile,
            receive: receivePublisherProfile,
            error: fetchPublisherProfileError,
        },
        fetchPromise,
        {
            slug,
            profileType,
            perPage,
            appliedFilters
        },
        true,
        (dispatchFn, error) => {
            // Redirect to legacy 404 page in case of any error
            if (process.env.NODE_ENV === 'production') {
                window.location.href = '/errors/error_404';
            }
        }
    );
};
