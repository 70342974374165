import React, {Component} from 'react'
import InputFieldTitle from '../InputFieldTitle';
import TextField from '../TextField';
import Button from '../Button';
import Sizer from '../Sizer';
import { BookPublicationForm } from '../../api';
import './Translators.scss'

class Translators extends Component {
    constructor(props){
        super(props);
        this.timeout = 0;
        this.state={
            translatorSearch:'',
            users:[],
            email:'',
            first_name:'',
            last_name:'',
            bio:'',
            dropDownFlag:false,
            offline_translators:[],
            disableBtn:false
        }
    }

    componentDidMount () {
        if(this.props.offline_translator_data.length > 0){
            let translator = [...new Set(this.props.offline_translator_data.map(JSON.stringify))].map(JSON.parse);
            this.setState({
                offline_translators: translator
            })
        }
    }

    onTranslatorSearch = (e) => {
        const searchvalue = e.target.value
        if(this.timeout) clearTimeout(this.timeout);
        e.persist()
        this.setState({
            translatorSearch: searchvalue,
            email:searchvalue,
            dropDownFlag:true
           });
       if(searchvalue === ''){
           this.setState({
               dropDownFlag:false
           })
       }
        this.timeout = setTimeout(() => {
           BookPublicationForm.contributor(searchvalue)
               .then((response) => {
                   return response.json();
               })
               .then((response) => {
                       this.setState({
                           users: response.users,
                       });
               });
        },500);
   }

    onTranslatorSelect = (e) => {
        this.setState({
            email:e.email,
            first_name:e.first_name,
            last_name:e.last_name,
            bio:e.bio,
            translatorSearch:e.email
        });
    };

    onDescriptionChange = (field,e) => {
        this.setState({
            [field] : e.target.value
        })
    }

    onAddTranslator = () => {
        const data = {
            email:this.state.email,
            first_name:this.state.first_name,
            last_name:this.state.last_name,
            bio:this.state.bio
        }

        let uniqueTranslator = this.state.offline_translators
        if(this && this.state && this.state.email){
            this.setState({disableBtn:true})
            BookPublicationForm.addUser(data)
            .then((response) => {
                return response.json();
            })
            .then((response) => {
                if (!uniqueTranslator.some(e => e.email === response.user.email)) {
                    this.setState( prevState => ({
                        offline_translators: [...prevState.offline_translators,  response.user],
                        email:'',
                        translatorSearch:'',
                        first_name:'',
                        last_name:'',
                        bio:''
                    }));  
                }else{
                    alert('User exists')
                }
                this.setState({disableBtn:false})
                this.props.offline_translator_ids(response.user.id,"add")
            
            });
        }else{
            alert('Fields cannot be empty, Please Select/Add to proceed')
        }
    }

    onRemoveTranslator = (authorIndex,id) => {
        this.setState({
            offline_translators: this.state.offline_translators.filter(
                (author, index) => index !== authorIndex
            )
        });
        this.props.offline_translator_ids(id,"remove")
    };

    render(){
        const {
            isGrey, 
            t, 
            title, 
            subHeading, 
            isRightToLeftDirection,
            btnLabel,
            projectId
        } = this.props;
        const baseClassName = "book-translators"
        return(
            <div style={isGrey ? {backgroundColor:'rgba(228, 236, 239, 0.3)'} : null} onClick={() => this.setState({dropDownFlag:false})}>
                <div className={isRightToLeftDirection 
                    ? `${baseClassName}_title-wrapper-rtl`
                    : `${baseClassName}_title-wrapper`
                    }>
                    <h4 className={isRightToLeftDirection 
                        ? `${baseClassName}_title-rtl`
                        : `${baseClassName}_title`
                    }>
                        {title}
                    </h4>
                    <h4 className={isRightToLeftDirection 
                        ? `${baseClassName}_subheading-rtl`
                        : `${baseClassName}_subheading`
                    }>
                        {subHeading}
                    </h4>
                </div>
                <div>
                    <div className={isRightToLeftDirection 
                        ? `${baseClassName}_input-row-rtl`
                        : `${baseClassName}_input-row`
                    }>
                        <div style={{position:'relative'}}>
                        <InputFieldTitle
                            isRequired={false}
                            value={t('PublicationForm.authorMail')} 
                            isRightToLeftDirection={isRightToLeftDirection}
                        />
                        <TextField
                                id="translated-story-title"
                                type="text"
                                placeholder={t('PublicationForm.typeHere')}
                                autoFocus={false}
                                custom={true}
                                bordered={false}
                                isPublication
                                onChange={(e) => this.onTranslatorSearch(e)}
                                value={this.state.translatorSearch}
                                
                            />
                        {   this.state.dropDownFlag &&
                            <div
                            className={
                                isRightToLeftDirection
                                    ? `${baseClassName}_title-dropdown-rtl`
                                    : `${baseClassName}_title-dropdown`
                            }
                        >
                            <Sizer maxHeight="l" scrollY>
                                {this.state.users.map(
                                    (user, index) => {
                                        return (
                                            <div
                                                onClick={() =>
                                                    this.onTranslatorSelect(
                                                        user
                                                    )
                                                }
                                                value={
                                                    user.email
                                                }
                                            >
                                                {user.email}
                                            </div>
                                        );
                                    }
                                )}
                            </Sizer>
                        </div>
                        }
                        </div>
                        <div>
                        <InputFieldTitle
                            isRequired={false}
                            value={t('PublicationForm.authorFirstName')} 
                            isRightToLeftDirection={isRightToLeftDirection}
                        />
                        <TextField
                                id="translated-story-title"
                                type="text"
                                placeholder={t('PublicationForm.typeHere')}
                                autoFocus={false}
                                custom={true}
                                bordered={false}
                                isPublication
                                onChange={this.onDescriptionChange.bind(this, 'first_name')}
                                value={this.state.first_name}
                            />
                        </div>
                        <div>
                        <InputFieldTitle
                            isRequired={false}
                            value={t('PublicationForm.authorLastName')} 
                            isRightToLeftDirection={isRightToLeftDirection}
                        />
                        <TextField
                                id="translated-story-title"
                                type="text"
                                placeholder={t('PublicationForm.typeHere')}
                                autoFocus={false}
                                custom={true}
                                bordered={false}
                                isPublication
                                onChange={this.onDescriptionChange.bind(this, 'last_name')}
                                value={this.state.last_name}
                            />
                        </div>
                    </div>
                    <div>
                        <InputFieldTitle
                            isRequired={false}
                            value={t('PublicationForm.authorBio')} 
                            isRightToLeftDirection={isRightToLeftDirection}
                        />
                        <div className={
                           isRightToLeftDirection 
                           ? `${baseClassName}_bio-container-rtl`
                           : `${baseClassName}_bio-container`
                        }>
                        <div className={
                            isRightToLeftDirection 
                            ? `${baseClassName}_bio-rtl`
                            : `${baseClassName}_bio`
                        }>
                        <TextField
                                id="translated-story-title"
                                type="text"
                                placeholder={t('PublicationForm.typeHere')}
                                autoFocus={false}
                                custom={true}
                                bordered={false}
                                isPublication
                                onChange={this.onDescriptionChange.bind(this, 'bio')}
                                value={this.state.bio}
                            />
                        </div>
                        <div
                            className={this.state.disableBtn || (projectId && this.state.offline_translators.length > 0)
                                ? `${baseClassName}_disable-translator-btn`
                                : ''
                                } 
                        >
                            <Button
                                label={btnLabel}
                                variant="contributor"
                                onClick={this.onAddTranslator}
                            />
                        </div>
                        </div>
                    </div>
                </div>
                {this.state.offline_translators.length >= 1 &&
                <table>
                    <tr className={
                        isRightToLeftDirection 
                            ? `${baseClassName}_table-header-rtl`
                            : `${baseClassName}_table-header`
                            }>
                        <th>{t('PublicationForm.authorMail')}</th>
                        <th>{t('PublicationForm.authorFirstName')}</th>
                        <th>{t('PublicationForm.authorLastName')}</th>
                        <th>{t('PublicationForm.authorBio')}</th>
                        <th>{t('PublicationForm.actions')}</th>
                    </tr>
                    {
                        this.state.offline_translators.length >= 1 &&
                        this.state.offline_translators.map ((translator,index) => {
                            return(
                            <tr className={
                                isRightToLeftDirection 
                                    ? `${baseClassName}_table-body-rtl`
                                    : `${baseClassName}_table-body`
                                    }>
                                <td>{translator.email}</td>
                                <td>{translator.first_name}</td>
                                <td>{translator.last_name}</td>
                                <td>{translator.bio}</td>
                                <td>
                                    <Button
                                        label={t('PublicationForm.remove')}
                                        variant="remove"
                                        onClick={this.onRemoveTranslator.bind(this,index,translator.id)}
                                    />
                                </td>
                            </tr> 
                            )
                        
                        })
                    }
                </table>}
            </div>
        )
    }
}

export default Translators;