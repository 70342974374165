import React, { useState } from 'react';
import classNames from 'classnames';
import { translate } from 'react-polyglot';
import PropTypes from 'prop-types';
import {
    imageSrcsetSizes,
    MAX_OFFLINE_BOOKS_COUNT,
    links,
} from '../../lib/constants';
import { isRightToLeftDirection } from '../../util/utils';
import BookTile from './components/BookTile';
import BookDetailsCard from './components/BookDetailsCard';
import NoSpaceForMoreOfflineBooksModal from '../Book/NoSpaceForMoreOfflineBooksModal';
import './BookCard.scss';
import Level from './components/Level';

const BookCard = ({
    t,
    fontSize,
    disabled,
    loading,
    recommended,
    faded,
    book,
    hideReadCountStat,
    bookDetails,
    isReadingList,
    offline,
    bookImageGTM,
    landingPageBook,
    myLanguage,
    pagesCount,
    mobileViewDetails,
    onClick,
    readStory,
    isFavourite,
    isFavouriting,
    isLoggedIn,
    id,
    lastPage,
    bookTitleGTM,
    bookAuthorGTM,
    bookIllustratorsGTM,
    viewport,
    languagePreferences,
    bookLevelGTM,
    isFromHome,
    isFromReadingList,
    favouriteBookWorkflow,
    favouriteAllBooksWorkflow,
    favouriteThemedBooksWorkflow,
    favouriteSimilarBooksWorkflow,
    favouriteProfilePageDataWorkflow,
    type,
    isProfilePage,
    isAllBook,
    isThemedList,
    isSimilarBook,
    applyFilter,
}) => {
    const {
        readsCount,
        synopsis,
        coverImage,
        slug,
        storyLevelColour,
        level,
        likesCount,
        illustrators = [],
        authors = [],
    } = book;

    const [isModalVisible, setIsModalVisible] = useState({
        noSpaceForMoreOfflineBooks: false,
    });

    const title = book.name ? book.name : book.title;

    const baseClassName = 'pb-book-card';
    const bookPath = `/stories/${slug}/`;

    const classes = {
        [baseClassName]: true,
        [`${baseClassName}--font-size-${fontSize}`]: fontSize,
        [`${baseClassName}--disabled`]: disabled || loading,
        [`${baseClassName}--loading`]: loading,
        [`${baseClassName}--recommended`]: recommended,
        [`${baseClassName}--faded`]: faded,
        [`${baseClassName}--has-footer`]:
            recommended || (readsCount && !hideReadCountStat),
    };

    const levelStylesReadingList = {
        [`${baseClassName}__level-reading-list`]: true,
    };

    const titleClasses = {
        [`${baseClassName}__title`]: true,
    };

    const levelStyles = {
        [`${baseClassName}--level-${level}`]: level,
        [isRightToLeftDirection
            ? isReadingList
                ? `${baseClassName}__level-listcard-arabic`
                : `${baseClassName}__level-arabic`
            : isReadingList
            ? `${baseClassName}__level-listcard`
            : `${baseClassName}__level`]: true,
        [landingPageBook ? `${baseClassName}__MainBookLevelBottom` : '']: true,
    };

    const handleClick = (e, mobile) => {
        if (disabled || loading) {
            e.preventDefault();
            e.stopPropagation();
        } else if (mobile === 'mobile') {
            readStory(slug);
        } else {
            if (onClick) {
                e.preventDefault();
                onClick(e);
            }
        }
    };

    const onCloseModal = (modalName) => {
        setIsModalVisible((prevState) => ({
            ...prevState,
            [modalName]: false,
        }));
    };

    const levelClicked = (level) => {
        applyFilter('level', level);
    };

    const onFavoriteClicked = (bookID, slug) => {
        if (isProfilePage) {
            favouriteProfilePageDataWorkflow(bookID, type);
        } else if (isAllBook) {
            favouriteAllBooksWorkflow(bookID);
        } else if (isThemedList) {
            favouriteThemedBooksWorkflow(bookID);
        } else if (isSimilarBook) {
            favouriteSimilarBooksWorkflow(bookID);
        } else {
            favouriteBookWorkflow(bookID);
        }
    };

    return (
        <div className={classNames(classes)}>
            <div
                className={
                    isRightToLeftDirection
                        ? `${baseClassName}__container ${baseClassName}__container-arabic`
                        : `${baseClassName}__container`
                }
            >
                {bookDetails ? (
                    <BookDetailsCard
                        baseClassName={baseClassName}
                        bookPath={bookPath}
                        onClick={onClick}
                        readStory={readStory}
                        slug={slug}
                        coverImage={coverImage}
                        title={title}
                        imageSrcsetSizes={imageSrcsetSizes}
                        offline={offline}
                        myLanguage={myLanguage}
                        pagesCount={pagesCount}
                    />
                ) : (
                    <BookTile
                        baseClassName={baseClassName}
                        isReadingList={isReadingList}
                        synopsis={synopsis}
                        coverImage={coverImage}
                        title={title}
                        imageSrcsetSizes={imageSrcsetSizes}
                        offline={offline}
                        bookImageGTM={bookImageGTM}
                        landingPageBook={landingPageBook}
                        myLanguage={myLanguage}
                        pagesCount={pagesCount}
                        mobileViewDetails={mobileViewDetails}
                        onClick={onClick}
                        bookPath={bookPath}
                        handleClick={handleClick}
                        readStory={readStory}
                        slug={slug}
                        storyLevelColour={storyLevelColour}
                        levelStylesReadingList={levelStylesReadingList}
                        level={level}
                        isFavourite={isFavourite}
                        onFavoriteClicked={onFavoriteClicked}
                        id={id}
                        isFavouriting={isFavouriting}
                        isLoggedIn={isLoggedIn}
                        likesCount={likesCount}
                        readsCount={readsCount}
                        lastPage={lastPage}
                        bookTitleGTM={bookTitleGTM}
                        titleClasses={titleClasses}
                        authors={authors}
                        bookAuthorGTM={bookAuthorGTM}
                        illustrators={illustrators}
                        bookIllustratorsGTM={bookIllustratorsGTM}
                    />
                )}

                {isModalVisible.noSpaceForMoreOfflineBooks && (
                    <NoSpaceForMoreOfflineBooksModal
                        viewport={viewport}
                        onClose={() =>
                            onCloseModal('noSpaceForMoreOfflineBooks')
                        }
                        maximum={MAX_OFFLINE_BOOKS_COUNT}
                    />
                )}
                {!bookDetails && !isReadingList && !lastPage && (
                    <Level
                        storyLevelColour={storyLevelColour}
                        levelStyles={levelStyles}
                        baseClassName={baseClassName}
                        onClick={onClick}
                        links={links}
                        languagePreferences={languagePreferences}
                        level={level}
                        bookLevelGTM={bookLevelGTM}
                        isFromHome={isFromHome}
                        isFromReadingList={isFromReadingList}
                        levelClicked={levelClicked}
                    />
                )}
            </div>
        </div>
    );
};

BookCard.propTypes = {
    t: PropTypes.func.isRequired,
    fontSize: PropTypes.string,
    disabled: PropTypes.bool,
    loading: PropTypes.bool,
    recommended: PropTypes.bool,
    faded: PropTypes.bool,
    book: PropTypes.shape({
        readsCount: PropTypes.number,
        synopsis: PropTypes.string,
        coverImage: PropTypes.string,
        slug: PropTypes.string,
        storyLevelColour: PropTypes.string,
        level: PropTypes.number,
        likesCount: PropTypes.number,
        illustrators: PropTypes.arrayOf(PropTypes.string),
        authors: PropTypes.arrayOf(PropTypes.string),
        name: PropTypes.string,
        title: PropTypes.string,
    }).isRequired,
    hideReadCountStat: PropTypes.bool,
    bookDetails: PropTypes.bool,
    isReadingList: PropTypes.bool,
    offline: PropTypes.bool,
    bookImageGTM: PropTypes.string,
    landingPageBook: PropTypes.bool,
    myLanguage: PropTypes.string,
    pagesCount: PropTypes.number,
    mobileViewDetails: PropTypes.bool,
    onClick: PropTypes.func,
    readStory: PropTypes.func.isRequired,
    isFavourite: PropTypes.bool,
    isFavouriting: PropTypes.bool,
    isLoggedIn: PropTypes.bool,
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    lastPage: PropTypes.bool,
    bookTitleGTM: PropTypes.string,
    bookAuthorGTM: PropTypes.string,
    bookIllustratorsGTM: PropTypes.string,
    viewport: PropTypes.string,
    languagePreferences: PropTypes.string,
    bookLevelGTM: PropTypes.string,
    isFromHome: PropTypes.bool,
    isFromReadingList: PropTypes.bool,
    favouriteBookWorkflow: PropTypes.func.isRequired,
    favouriteAllBooksWorkflow: PropTypes.func,
    favouriteThemedBooksWorkflow: PropTypes.func,
    favouriteSimilarBooksWorkflow: PropTypes.func,
    favouriteProfilePageDataWorkflow: PropTypes.func,
    type: PropTypes.string,
    isProfilePage: PropTypes.bool,
    isAllBook: PropTypes.bool,
    isThemedList: PropTypes.bool,
    isSimilarBook: PropTypes.bool,
    applyFilter: PropTypes.func.isRequired,
};

export default translate()(BookCard);
