import React from 'react';
import classNames from 'classnames';
import {translate} from 'react-polyglot';
import PropTypes from 'prop-types';
import ListCardImage from './ListCardImage';
import BookImage from './BookImage';
import { isRightToLeftDirection } from '../../../util/utils';
import Favourites from '../../Favourites';
import SvgIcon from '../../SvgIcon';
import BookTitle from './BookTitle';
import Link from '../../Link';

const BookTile = ({
    t,
    baseClassName,
    isReadingList,
    synopsis,
    coverImage,
    title,
    imageSrcsetSizes,
    offline,
    bookImageGTM,
    landingPageBook,
    myLanguage,
    pagesCount,
    mobileViewDetails,
    onClick,
    bookPath,
    handleClick,
    readStory,
    slug,
    storyLevelColour,
    levelStylesReadingList,
    level,
    isFavourite,
    onFavoriteClicked,
    id,
    isFavouriting,
    isLoggedIn,
    likesCount,
    readsCount,
    lastPage,
    bookTitleGTM,
    titleClasses,
    authors,
    bookAuthorGTM,
    illustrators,
    bookIllustratorsGTM
}) => {
    return (
        <div className={`${baseClassName}__wrapper`}>
            <span className="__bookImage">
                {isReadingList ? (
                    <ListCardImage
                        synopsis={synopsis}
                        coverImage={coverImage}
                        title={title}
                        imageSrcsetSizes={imageSrcsetSizes}
                        offline={offline}
                        bookImageGTM={bookImageGTM}
                        landingPageBook={landingPageBook}
                        baseClassName={baseClassName}
                        myLanguage={myLanguage}
                        pagesCount={pagesCount}
                    />
                ) : (
                    <BookImage
                        mobileViewDetails={mobileViewDetails}
                        onClick={onClick}
                        baseClassName={baseClassName}
                        bookPath={bookPath}
                        handleClick={handleClick}
                        bookImageGTM={bookImageGTM}
                        synopsis={synopsis}
                        coverImage={coverImage}
                        title={title}
                        imageSrcsetSizes={imageSrcsetSizes}
                        offline={offline}
                        landingPageBook={landingPageBook}
                        myLanguage={myLanguage}
                        pagesCount={pagesCount}
                        readStory={readStory}
                        slug={slug}
                    />
                )}
            </span>

            <div className={`${baseClassName}__meta-wrapper`}>
                <div className={`${baseClassName}__meta`}>
                    {isReadingList ? (
                        <div
                            style={{
                                backgroundColor: `${storyLevelColour}`,
                            }}
                            className={classNames(levelStylesReadingList)}
                        >
                            <span className={`${baseClassName}__level-text`}>
                                {t(`PublicationForm.level${level}`)}
                            </span>
                        </div>
                    ) : null}
                    {!isReadingList ? (
                        <div className="__view-like-count">
                            {!mobileViewDetails &&
                            window.screen.width <= 425 ? null : (
                                <div className="book-like--count-wrapper">
                                    <div
                                        className={
                                            isRightToLeftDirection
                                                ? '__book-like-count-arabic'
                                                : '__book-like'
                                        }
                                    >
                                        <Favourites
                                            t={t}
                                            isFavourite={isFavourite}
                                            favouriteToggleHandler={() =>
                                                onFavoriteClicked(id)
                                            }
                                            isFavouriting={isFavouriting}
                                            isLoggedIn={isLoggedIn}
                                            logInMsg={t(
                                                'global.please-log-in',
                                                {
                                                    action: t('global.like', 1),
                                                    content_type: t(
                                                        'global.story',
                                                        1
                                                    ),
                                                }
                                            )}
                                            bookSlug={slug}
                                            likes={likesCount}
                                            bookFavorite="book-favorite"
                                        />
                                    </div>
                                </div>
                            )}
                            <div
                                style={
                                    !mobileViewDetails &&
                                    window.screen.width <= 425
                                        ? {
                                              marginLeft: '0',
                                              display: 'flex',
                                              alignItems: 'center',
                                              color: '#333333',
                                          }
                                        : {
                                              marginLeft: '20px',
                                              display: 'flex',
                                              alignItems: 'center',
                                              color: '#333333',
                                          }
                                }
                            >
                                <SvgIcon
                                    name="Read"
                                    size="book-card-read-count"
                                />

                                <span>{readsCount}</span>
                            </div>
                        </div>
                    ) : null}
                    {lastPage ? (
                        <div
                            style={{
                                backgroundColor: `${storyLevelColour}`,
                            }}
                            className={classNames(levelStylesReadingList)}
                        >
                            <span className={`${baseClassName}__level-text`}>
                                {t(`PublicationForm.level${level}`)}
                            </span>
                        </div>
                    ) : null}
                    {!landingPageBook && isReadingList ? (
                        <BookTitle
                            onClick={onClick}
                            baseClassName={baseClassName}
                            bookPath={bookPath}
                            bookTitleGTM={bookTitleGTM}
                            handleClick={handleClick}
                            titleClasses={titleClasses}
                            myLanguage={myLanguage}
                            title={title}
                            readingListBookCardTitle
                        />
                    ) : landingPageBook ? null : (
                        <BookTitle
                            onClick={onClick}
                            baseClassName={baseClassName}
                            bookPath={bookPath}
                            bookTitleGTM={bookTitleGTM}
                            handleClick={handleClick}
                            titleClasses={titleClasses}
                            myLanguage={myLanguage}
                            title={title}
                        />
                    )}
                    {!isReadingList ? (
                        <div className={`${baseClassName}__book-author`}>
                            {!isRightToLeftDirection && (
                                <span className={`${baseClassName}__author-by`}>
                                    {t('global.author') + ' '}
                                </span>
                            )}
                            {authors &&
                                authors.map((item, i) => (
                                    <span
                                        key={i}
                                        className={`${baseClassName}__author-name`}
                                    >
                                        <Link
                                            href={'/users/' + item.slug}
                                            isInternal
                                            paddingTop
                                            dataGTM={bookAuthorGTM}
                                        >
                                            {item.name}
                                            <span data-gtm={bookAuthorGTM}>
                                                {i < authors.length - 1
                                                    ? ', '
                                                    : ''}
                                            </span>
                                        </Link>
                                    </span>
                                ))}
                            {isRightToLeftDirection && (
                                <span className={`${baseClassName}__author-by`}>
                                    {' ' + t('global.author')}
                                </span>
                            )}
                        </div>
                    ) : null}
                    {!isReadingList ? (
                        <div className={`${baseClassName}__book-illustrated`}>
                            {!isRightToLeftDirection && (
                                <span
                                    className={`${baseClassName}__illustrated-by`}
                                >
                                    {t('global.illustrator', 1) + ' '}
                                </span>
                            )}
                            {illustrators &&
                                illustrators.map((item, i) => (
                                    <span
                                        key={i}
                                        className={`${baseClassName}__illustrated-name`}
                                    >
                                        <Link
                                            href={'/users/' + item.slug}
                                            isInternal
                                            dataGTM={bookIllustratorsGTM}
                                        >
                                            {item.name}
                                            <span
                                                data-gtm={bookIllustratorsGTM}
                                            >
                                                {i < illustrators.length - 1
                                                    ? ', '
                                                    : ''}
                                            </span>
                                        </Link>
                                    </span>
                                ))}
                            {isRightToLeftDirection && (
                                <span
                                    className={`${baseClassName}__illustrated-by`}
                                >
                                    {' ' + t('global.illustrator', 1)}
                                </span>
                            )}
                        </div>
                    ) : null}
                </div>
            </div>
        </div>
    );
};

BookTile.propTypes = {
    t: PropTypes.func.isRequired,
    baseClassName: PropTypes.string.isRequired,
    isReadingList: PropTypes.bool,
    synopsis: PropTypes.string,
    coverImage: PropTypes.string,
    title: PropTypes.string.isRequired,
    imageSrcsetSizes: PropTypes.string,
    offline: PropTypes.bool,
    bookImageGTM: PropTypes.string,
    landingPageBook: PropTypes.bool,
    myLanguage: PropTypes.string,
    pagesCount: PropTypes.number,
    mobileViewDetails: PropTypes.bool,
    onClick: PropTypes.func,
    bookPath: PropTypes.string,
    handleClick: PropTypes.func,
    readStory: PropTypes.func,
    slug: PropTypes.string,
    storyLevelColour: PropTypes.string,
    levelStylesReadingList: PropTypes.string,
    level: PropTypes.number,
    isFavourite: PropTypes.bool,
    onFavoriteClicked: PropTypes.func,
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    isFavouriting: PropTypes.bool,
    isLoggedIn: PropTypes.bool,
    likesCount: PropTypes.number,
    readsCount: PropTypes.number,
    lastPage: PropTypes.bool,
    bookTitleGTM: PropTypes.string,
    titleClasses: PropTypes.string,
    authors: PropTypes.arrayOf(
        PropTypes.shape({
            name: PropTypes.string.isRequired,
            slug: PropTypes.string.isRequired,
        })
    ),
    bookAuthorGTM: PropTypes.string,
    illustrators: PropTypes.arrayOf(
        PropTypes.shape({
            name: PropTypes.string.isRequired,
            slug: PropTypes.string.isRequired,
        })
    ),
    bookIllustratorsGTM: PropTypes.string,
};

export default translate()(BookTile);
