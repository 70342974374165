import en from './en.json';
import id from './id.json';
import my from './my.json';
import vi from './vi.json';
import hi from './hi.json';
import sw from './sw.json';
import mr from './mr.json';
import ne from './ne.json';
import bn from './bn.json';
import si from './si.json';
import km from './km.json';
import lo from './lo.json';
import zh_TW from './zh-TW.json';
import ta from './ta.json';
import fil from './fil.json';
import zh_CN from './zh-CN.json';
import es from './es.json';
import ar from './ar.json';
import lg from './lg.json';
import ur from './ur.json';
import te from './te.json';
import kn from './kn.json';
import nyn from './nyn.json';
import gu from './gu.json';

export default {
    en,
    id,
    my,
    hi,
    vi,
    sw,
    mr,
    ne,
    bn,
    si,
    km,
    lo,
    zh_TW,
    ta,
    fil,
    zh_CN,
    es,
    ar,
    lg,
    ur,
    te,
    kn,
    nyn,
    gu
};
