import React, { useState, useEffect, useRef } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { translate } from 'react-polyglot';
import Button from '../Button';
import Modal from '../Modal';
import Overlay from '../Overlay';
import Stuffer from '../Stuffer';
import TextField from '../TextField';
import { keyCodes } from '../../lib/constants';
import Sizer from '../Sizer';

import './SearchModal.scss';
import { SearchAutoSuggest } from '../../api';

const SearchModal = ({ t, viewport, onClose, onSubmit }) => {
    const [query, setQuery] = useState('');
    const [searchDropDown, setSearchDropDown] = useState(false);
    const [searchResults, setSearchResults] = useState([]);
    const textFieldRef = useRef(null);
    const timeoutRef = useRef(null);

    useEffect(() => {
        if (textFieldRef.current) {
            textFieldRef.current.focus();
        }
        return () => clearTimeout(timeoutRef.current); // Cleanup on unmount
    }, []);

    const handleChange = (e) => {
        const searchValue = e.target.value;
        setQuery(searchValue);
        setSearchDropDown(true);

        if (timeoutRef.current) clearTimeout(timeoutRef.current);

        if (searchValue === '') {
            setSearchDropDown(false);
            setSearchResults([]);
        } else {
            timeoutRef.current = setTimeout(() => {
                SearchAutoSuggest.fetchAutoSuggestResults(searchValue)
                    .then(response => response.json())
                    .then(data => setSearchResults(data.data))
                    .catch(error => console.error("Error fetching search results:", error));
            }, 500);
        }
    };

    const handleSearchResultSelect = (selectedValue) => {
        onSubmit(selectedValue.target?.value || selectedValue);
        setSearchDropDown(false);
    };

    const handleSubmit = () => onSubmit(query);

    const handleKeyPress = (e) => {
        if (e.charCode === keyCodes.return) {
            handleSubmit();
        }
    };

    const baseClassName = 'pb-search-modal';
    const isRightToLeftDirection =
        localStorage.getItem('locale') === 'ar' ||
        localStorage.getItem('locale') === 'ur';

    const classes = {
        [baseClassName]: true,
    };

    return (
        <div className={classNames(classes)}>
            <Modal
                background="transparent"
                onClose={onClose}
                overlayTheme="light"
                search
            >
                <div className={`${baseClassName}__search-box`}>
                    <TextField
                        ref={textFieldRef}
                        name="q"
                        label={t('GlobalSearch.title')}
                        size="l"
                        onChange={handleChange}
                        id="search-modal-input"
                        bordered={false}
                        searchInput
                    />
                    {searchDropDown && (
                        <div
                            className={
                                isRightToLeftDirection
                                    ? `${baseClassName}_title-dropdown-rtl`
                                    : `${baseClassName}_title-dropdown`
                            }
                        >
                            {query.length >= 4 && (
                                <Sizer maxHeight="l" scrollY>
                                    {searchResults.map((searchResultValue, index) => (
                                        <div
                                            key={index}
                                            className={
                                                isRightToLeftDirection
                                                    ? `${baseClassName}_search-results-rtl`
                                                    : `${baseClassName}_search-results`
                                            }
                                            onClick={() => handleSearchResultSelect(searchResultValue)}
                                        >
                                            {searchResultValue}
                                        </div>
                                    ))}
                                </Sizer>
                            )}
                        </div>
                    )}
                </div>
                <div className={query === "" ? "searchbutton-disable" : ""}>
                    <Stuffer horizontalSpacing={viewport.medium ? 'xl' : null}>
                        <Button
                            parentClassName={`${baseClassName}__submit`}
                            label={t('SearchModal.submit-label')}
                            onClick={handleSubmit}
                            variant="nav-link-button"
                            fullWidth
                        />
                    </Stuffer>
                </div>
            </Modal>
            <Overlay theme="light" />
        </div>
    );
};

SearchModal.propTypes = {
    viewport: PropTypes.object.isRequired,
    onChange: PropTypes.func,
    onClose: PropTypes.func,
    onSubmit: PropTypes.func,
};

export default translate()(SearchModal);
