import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { translate } from 'react-polyglot';
import DocumentTitle from 'react-document-title';
import { profileTypes } from '../../lib/constants';
import Avatar from '../Avatar';
import Tab from '../Tab';
import Books from '../Books';
import ReadingLists from '../ReadingLists';
import InfoUnit from '../InfoUnit';
import Link from '../Link';
import Block from '../Block';
import { MediaObject, Media } from '../MediaObject';
import Grid from '../Grid';
import BlankSlate from '../BlankSlate';
import ContentStyler from '../ContentStyler';
import './Profile.scss';
import onEmptyBgImage from '../../assets/watering-can.svg';
import onEmptyBgImageSelf from '../../assets/hand-with-pencil.svg';
import BookCard from '../BookCard';
import Modal from '../Modal';
import VideoPlayer from '../VideoPlayer';
import closeIcon from '../../assets/closeIcon.png';
import FiltersBar from '../FiltersBar';
import LoaderBlock from '../LoaderBlock';
import SvgIcon from '../SvgIcon';

@translate()
class Profile extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isModalVisible: false,
            isVideoPlay: false,
            videoUrl: '',
        };
    }

    openVideoModalHandler = (url) => {
        this.setState({
            isModalVisible: true,
            isVideoPlay: true,
            videoUrl: url,
        });
    };

    closeVideoModalHandler = () => {
        this.setState({
            isModalVisible: false,
            isVideoPlay: false,
        });
    };

    videoPlayerModal = () => {
        return (
            <Modal interviewCard>
                <div className="__close">
                    <Link onClick={this.closeVideoModalHandler}>
                        <img
                            className="__profile-images"
                            src={closeIcon}
                            alt=""
                        />
                    </Link>
                </div>
                <VideoPlayer
                    name={this.props.name}
                    videoSrc={this.state.videoUrl}
                    videoPlay={this.state.isVideoPlay}
                />
            </Modal>
        );
    };

    render() {
        const baseClassName = 'pb-profile';
        const classNames = [baseClassName];
        const {
            t,
            type,
            profile,
            isCurrentUser,
            hitsCount,
            filters,
            appliedFilters,
            applyFilter,
            removeFilter,
            isFetchingPublisherProfile,
        } = this.props;

        console.log('profile type data',profile)

        const { email, website, socialMediaLinks } = profile;

        const {
            facebookUrl,
            twitterUrl,
            youtubeUrl,
        } = socialMediaLinks;

        const myLanguage = localStorage.getItem('locale');

        let filtersComponent = null;

        filtersComponent = (
            <FiltersBar
                noTopBorder
                pullUp
                filters={filters}
                applyFilter={applyFilter}
                removeFilter={removeFilter}
                appliedFilters={appliedFilters}
                isTranslateMobile
                resultsCount={hitsCount}
                languageFilterGTM="themed-reading-list-language-filter"
                alphabetSortGTM="themed-reading-list-alphabetic-sort"
            />
        );

        const NoResults = translate()(({ t }) => (
            <span>
                <p>{t('Books.no-result-warning-1')}</p>
                <p>{t('Books.no-result-warning-2')}</p>
                <ul>
                    <li>{t('Books.no-result-warning-3')}</li>
                    <li>{t('Books.no-result-warning-4')}</li>
                    <li>{t('Books.no-result-warning-6')}</li>
                </ul>
            </span>
        ));

        const emailEls =
            email !== null && email !== '' ? (
                <div className="__profile-email">
                    <InfoUnit noWrap key={email}>
                        <Link href={`mailto:${email}`} dataGTM="profile-email">
                            <SvgIcon name="mail"/>
                        </Link>
                    </InfoUnit>
                </div>
            ) : null;

        //Social-Media Icons with respective Urls
        const faceBookEls =
            facebookUrl !== null && facebookUrl !== '' ? (
                <div className="__profile-facebook">
                    <InfoUnit noWrap key={facebookUrl}>
                        <Link
                            shouldOpenNewPage={true}
                            href={facebookUrl}
                            dataGTM="profile-facebook"
                        >
                            <SvgIcon name="facebook"/>
                        </Link>
                    </InfoUnit>
                </div>
            ) : null;

        const youtubeEls =
            youtubeUrl !== null && youtubeUrl !== '' ? (
                <div className="__profile-youtube">
                    <InfoUnit noWrap key={youtubeUrl}>
                        <Link
                            shouldOpenNewPage={true}
                            href={youtubeUrl}
                            dataGTM="profile-youtube"
                        >
                            <SvgIcon name="facebook"/>
                        </Link>
                    </InfoUnit>
                </div>
            ) : null;

        const twitterEls =
            twitterUrl !== null && twitterUrl !== '' ? (
                <div className="__profile-twitter">
                    <InfoUnit noWrap key={twitterUrl}>
                        <Link
                            shouldOpenNewPage={true}
                            href={twitterUrl}
                            dataGTM="profile-twitter"
                        >
                            <SvgIcon name="twitter"/>
                        </Link>
                    </InfoUnit>
                </div>
            ) : null;

        const websiteEls =
            website !== null && website !== '' ? (
                <div className="__profile-website">
                    <InfoUnit noWrap key={website}>
                        <Link
                            shouldOpenNewPage={true}
                            href={website}
                            dataGTM="profile-website"
                        >
                            <SvgIcon name="website"/>
                        </Link>
                    </InfoUnit>
                </div>
            ) : null;

        // TODO: Clean this up? Looks dirty.
        let tabs, title, url;
        if (isCurrentUser) {
            url = onEmptyBgImageSelf;
            title = t('Profile.empty-content-self');
        } else {
            url = onEmptyBgImage;
            title = t('Profile.empty-content');
        }
        const blankSlateEl = <BlankSlate title={title} url={url} />;
        if (type === profileTypes.USER) {
            tabs = [
                // User books.
                !Boolean(profile.books.meta.hits) && !isCurrentUser ? null : (
                    <Block normalPadding>
                        <ContentStyler>
                            <div className="flex-property">
                                <h3>{t('global.book', 2)}</h3>
                                <h3 className="__normal">
                                    {' '}
                                    ({profile.books.meta.hits})
                                </h3>
                            </div>
                        </ContentStyler>
                        {Boolean(profile.books.meta.hits) ? (
                            <Books
                                myLanguage
                                isProfilePage
                                type="profileBooks"
                                books={profile.books.results}
                                bookImageGTM="profile-book-image"
                                bookTitleGTM="profile-book-title"
                                bookAuthorGTM="profile-book-author"
                                bookIllustratorsGTM="profile-book-illustrators"
                                bookLevelGTM="profile-book-level"
                            />
                        ) : (
                            blankSlateEl
                        )}
                    </Block>
                ),
                // User lists.
                !Boolean(profile.lists.meta.hits) && !isCurrentUser ? null : (
                    <Tab
                        key="user-lists"
                        title={`${t('global.my-bookshelf')} (${
                            profile.lists.meta.hits
                        })`}
                        hasContent={Boolean(profile.lists.meta.hits)}
                    >
                        {Boolean(profile.lists.meta.hits) ? (
                            <div className={`${baseClassName}__lists`}>
                                <ReadingLists lists={profile.lists.results} />
                            </div>
                        ) : (
                            blankSlateEl
                        )}
                    </Tab>
                ),
            ];
        } else if (type === profileTypes.PUBLISHER) {
            tabs = [
                <div>
                    <Block background="transparent">
                        <ContentStyler>
                            <div className="__publisher-book-header">
                                <h3>{t('Book.book-text')}</h3>
                                <h3 className="__books-count">({hitsCount})</h3>
                            </div>
                        </ContentStyler>
                    </Block>
                    <div className="__profile-books-section">
                        {profile.newArrivals.results &&
                        profile.newArrivals.results.length >= 0 ? (
                            <div className="__video-filter-component">
                                {filtersComponent}
                            </div>
                        ) : null}
                        {isFetchingPublisherProfile ? (
                            <LoaderBlock />
                        ) : (
                            <div>
                                <div>
                                    {profile.newArrivals.results.length <= 0 ? (
                                        <div className="no-results">
                                            <NoResults />
                                        </div>
                                    ) : null}
                                </div>

                                {profile.newArrivals.results.length > 0 ? (
                                    <div>
                                        <Grid variant="4up">
                                            {profile.newArrivals.results.map(
                                                (book) => {
                                                    return (
                                                        <div key={book.id}>
                                                            <BookCard
                                                                myLanguage={
                                                                    myLanguage
                                                                }
                                                                type="profileNewArrivals"
                                                                isProfilePage
                                                                key={book.slug}
                                                                book={book}
                                                                id={book.id}
                                                                isFavourite={
                                                                    book.is_favourite
                                                                }
                                                                isFavouriting={
                                                                    book.isFavouriting
                                                                }
                                                                enableFavouriting
                                                                bookLikes={
                                                                    book.likesCount
                                                                }
                                                                bookImageGTM="profile-book-image"
                                                                bookTitleGTM="profile-book-title"
                                                                bookAuthorGTM="profile-book-author"
                                                                bookIllustratorsGTM="profile-book-illustrators"
                                                                bookLevelGTM="profile-book-level"
                                                            />
                                                        </div>
                                                    );
                                                }
                                            )}
                                        </Grid>
                                    </div>
                                ) : null}
                            </div>
                        )}
                    </div>
                </div>,
            ];
        }

        return (
            <div className={classNames.join(' ')}>
                <DocumentTitle
                    title={`${profile.name} - ${t('global.site-title')}`}
                />
                <MediaObject>
                    <Block offlineSection normalPadding>
                        <ContentStyler offline>
                            <div className="__offline-title-header">
                                <Media>
                                    <div
                                        className={`${baseClassName}__avatar-title`}
                                    >
                                        <div
                                            className={`${baseClassName}__avatar`}
                                        >
                                            <Avatar
                                                url={profile.profileImage}
                                                name={profile.name}
                                                website={website}
                                                variant={
                                                    type === profileTypes.USER
                                                        ? 'circular'
                                                        : 'default'
                                                }
                                                size="l"
                                            />
                                        </div>
                                        <div
                                            className={`${baseClassName}__name`}
                                        >
                                            <h3 className="__profile-contact-header">
                                                {' '}
                                                {profile.name}
                                            </h3>
                                            <div className="__profile-contact-details">
                                                <div className="__profile-email-field">
                                                    {emailEls}
                                                </div>
                                                <div className="__profile-facebook-field">
                                                    {faceBookEls}
                                                </div>
                                                <div className="__profile-twitter-field">
                                                    {twitterEls}
                                                </div>
                                                <div className="__profile-youtube-field">
                                                    {youtubeEls}
                                                </div>
                                                <div className="__profile-website-field">
                                                    {websiteEls}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Media>
                            </div>
                        </ContentStyler>
                    </Block>

                    {profile.description ? (
                        <div className={`${baseClassName}__description`}>
                            {profile.description}
                        </div>
                    ) : null}
                </MediaObject>
                <Block noHorizontalPadding>
                    {tabs.some((item) => item != null) ? (
                        <div>{tabs}</div>
                    ) : (
                        blankSlateEl
                    )}
                </Block>
                {this.state.isModalVisible && this.videoPlayerModal()}
            </div>
        );
    }
}
Profile.propTypes = {
    type: PropTypes.string,
    profile: PropTypes.object,
    viewport: PropTypes.object,
};
export default Profile;
