/* eslint-disable jsx-a11y/alt-text */
import React from 'react';
import PropTypes from 'prop-types';
import { translate } from 'react-polyglot';
import SocialLinks from '../SocialLinks';
import Rowifier from '../Rowifier';
import Logo from '../Logo';
import SiteNavLink from '../SiteNavLink';
import { links } from '../../lib/constants';
import RowSeparatorContainer from '../RowSeparatorContainer';
import { getNavLinks } from '../../helpers/MenuItems'
import { FooterLogo } from './FooterLogo';
import classNames from 'classnames';
import { connect } from 'react-redux';
import './SiteFooter.scss';

const SiteFooter = (props) => {
    const baseClassName = 'pb-site-footer';
    const {
        t,
        isLoggedIn,
        offline,
        viewport,
        menuList,
        siteNavLinks,
    } = props;

    const currentYear = new Date().getFullYear();

    const isRightToLeftDirection =
        localStorage.getItem('locale') === 'ar' ||
        localStorage.getItem('locale') === 'ur';

    const userLanguage = localStorage.getItem('locale');

    if (offline) {
        return null;
    }

    const logos = [
        { variant: 'vietnamFooterLogo' },
        { variant: 'logoCentre' },
    ];

    const contactUs = (
        <SiteNavLink
            isExternal
            bold
            mobileFooter={!viewport.large}
            href={links.reportIssue()}
        >
            {t('global.contact-us')}
        </SiteNavLink>
    );

    const socialLinks = (
        <SocialLinks
            links={{
                facebook: 'https://www.facebook.com/RoomtoRead',
                twitter: 'https://www.twitter.com/roomtoread',
                instagram: 'https://www.instagram.com/roomtoread',
                youtube: 'https://www.youtube.com/@Roomtoread',
            }}
            dataGTM="footer-social-link"
        />
    );

    const thirdRow = [
        <div className={`${baseClassName}__row-left`}>
            <p
                className={
                    userLanguage === 'lo'
                        ? `${baseClassName}__third-row-rights-reserved-lo`
                        : `${baseClassName}__third-row-rights-reserved`
                }
            >
                {t('global.rights-reserved', { currentYear })}
            </p>
        </div>,
        contactUs,
        <div
            className={
                isRightToLeftDirection
                    ? `${baseClassName}__row-left-rtl`
                    : `${baseClassName}__row-center`
            }
        >
            {socialLinks}
        </div>,
    ];

    const footerLarger = (
        <Rowifier align="center" separator noPadding>
            <RowSeparatorContainer>
                <FooterLogo style={'__first-row'} />
            </RowSeparatorContainer>
            <RowSeparatorContainer>
                <div className={`${baseClassName}__second-row`}>
                    {siteNavLinks}
                </div>
            </RowSeparatorContainer>
            <div className={`${baseClassName}__third-row`}>{thirdRow}</div>
        </Rowifier>
    );

    const mediumSecondRow =  <FooterLogo style={'__second-row-medium'} />;

    const navLinksFirstColumn = siteNavLinks.slice(0, 5);
    let navLinksSecondColumn = siteNavLinks.slice(5);
    navLinksSecondColumn = [...navLinksSecondColumn, contactUs];

    const mediumFirstRow = (
        <div className={`${baseClassName}__first-row-medium`}>
            <div className={`${baseClassName}__column`}>
                {navLinksFirstColumn}
            </div>
            <div className={`${baseClassName}__column`}>
                {navLinksSecondColumn}
            </div>
        </div>
    );

    const mediumFourthRowClasses = {
        [`${baseClassName}__row-center`]: true,
        [`${baseClassName}__fourth-row-medium`]: true,
    };

    const mediumFourthRow = (
        <div className={classNames(mediumFourthRowClasses)}>
            {socialLinks}
            <p
                className={
                    userLanguage === 'lo'
                        ? `${baseClassName}__third-row-rights-reserved-lo`
                        : `${baseClassName}__third-row-rights-reserved`
                }
            >
                {t('global.rights-reserved', { currentYear })}
            </p>
        </div>
    );

    const footerMedium = (
        <Rowifier align="left" separator noPadding>
            <RowSeparatorContainer>{mediumFirstRow}</RowSeparatorContainer>
            <RowSeparatorContainer>{mediumSecondRow}</RowSeparatorContainer>
            {mediumFourthRow}
        </Rowifier>
    );

    const footerEl = viewport.large ? footerLarger : footerMedium;

    return <div className={baseClassName}>{footerEl}</div>;
}

SiteFooter.propTypes = {
    bgUrl: PropTypes.string,
    shouldShowLanguageSelector: PropTypes.bool.isRequired,
    offline: PropTypes.bool,
};

const mapStateToProps = ({ user }) => ({
    isRightToLeftDirection: user.isRightToLeftDirection,
    languagePreferences: user.languagePreferences,
    readingLevels: user.readingLevels,
    role: user.role,
    menuList: user.menuList,
});

export default translate()(connect(mapStateToProps)(SiteFooter));
