import React from 'react';
import { translate } from 'react-polyglot';
import PropTypes from 'prop-types';
import Link from '../../Link';
import Img from '../../Img';
import SvgIcon from '../../SvgIcon';
import { isRightToLeftDirection } from '../../../util/utils';

const BookImage = ({
    t,
    mobileViewDetails,
    onClick,
    baseClassName,
    bookPath,
    handleClick,
    bookImageGTM,
    synopsis,
    coverImage,
    title,
    imageSrcsetSizes,
    offline,
    landingPageBook,
    myLanguage,
    pagesCount,
    readStory,
    slug,
}) => {
    return (
        <React.Fragment>
            {!mobileViewDetails ? (
                <Link
                    isInternal={onClick ? false : true}
                    parentClassName={`${baseClassName}__link`}
                    href={bookPath}
                    onClick={onClick ? handleClick : null}
                    dataGTM={bookImageGTM}
                >
                    <Img
                        synopsis={synopsis}
                        image={coverImage}
                        alt={title}
                        lazyLoad
                        sizes={imageSrcsetSizes.grid2up6up}
                        offline={offline}
                        dataGTM={bookImageGTM}
                    />

                    {landingPageBook && (
                        <div>
                            <div
                                className={
                                    isRightToLeftDirection
                                        ? `${baseClassName}__border-image-wrapper-arabic`
                                        : `${baseClassName}__border-image-wrapper`
                                }
                            >
                                <SvgIcon
                                    name={'border-image'}
                                    size="border-image"
                                />
                            </div>
                            <div
                                className={
                                    myLanguage === 'lo'
                                        ? `${baseClassName}__page-number-lo`
                                        : isRightToLeftDirection
                                        ? `${baseClassName}__page-number-arabic`
                                        : `${baseClassName}__page-number`
                                }
                            >
                                {pagesCount}
                                <p>{t('Book.page')}</p>
                            </div>
                        </div>
                    )}
                </Link>
            ) : (
                <Link
                    isInternal={onClick ? false : true}
                    parentClassName={`${baseClassName}__link`}
                    onClick={() => readStory(slug)}
                    dataGTM={bookImageGTM}
                >
                    <Img
                        synopsis={synopsis}
                        image={coverImage}
                        alt={title}
                        lazyLoad
                        sizes={imageSrcsetSizes.grid2up6up}
                        offline={offline}
                        dataGTM={bookImageGTM}
                        padding130
                    />

                    {landingPageBook && (
                        <div>
                            <div
                                className={
                                    isRightToLeftDirection
                                        ? `${baseClassName}__border-image-wrapper-arabic`
                                        : `${baseClassName}__border-image-wrapper`
                                }
                            >
                                <SvgIcon
                                    name={'border-image'}
                                    size="border-image"
                                />
                            </div>
                            <div
                                className={
                                    myLanguage === 'lo'
                                        ? `${baseClassName}__page-number-lo`
                                        : isRightToLeftDirection
                                        ? `${baseClassName}__page-number-arabic`
                                        : `${baseClassName}__page-number`
                                }
                            >
                                {pagesCount}
                                <p>{t('Book.page')}</p>
                            </div>
                        </div>
                    )}
                </Link>
            )}
        </React.Fragment>
    );
};

BookImage.propTypes = {
    t: PropTypes.func.isRequired,
    mobileViewDetails: PropTypes.bool,
    onClick: PropTypes.bool,
    baseClassName: PropTypes.string.isRequired,
    bookPath: PropTypes.string.isRequired,
    handleClick: PropTypes.func,
    bookImageGTM: PropTypes.string,
    synopsis: PropTypes.string,
    coverImage: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    imageSrcsetSizes: PropTypes.shape({
        grid2up6up: PropTypes.string.isRequired,
    }).isRequired,
    offline: PropTypes.bool,
    landingPageBook: PropTypes.bool,
    myLanguage: PropTypes.string,
    pagesCount: PropTypes.number,
    readStory: PropTypes.func.isRequired,
    slug: PropTypes.string.isRequired,
};

export default translate()(BookImage);
