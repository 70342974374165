import React from 'react';
import SiteNavLink from '../../SiteNavLink';
import SvgIcon from '../../SvgIcon';

const SmallScreenHeader = ({
    baseClassName,
    menuOpen,
    languageSelectEl,
    onMenuToggle,
}) => {
    return (
        <React.Fragment>
            <div className={`${baseClassName}-sidebar-container`}>
                {menuOpen ? null : languageSelectEl}
                <SiteNavLink onClick={onMenuToggle}>
                    {menuOpen ? (
                        <img
                            alt=""
                            className={`${baseClassName}__close-button`}
                            src={require('../../../assets/close-white.png')}
                        />
                    ) : (
                        <SvgIcon name={'menu_dc'} size="m" />
                    )}
                </SiteNavLink>
            </div>
        </React.Fragment>
    );
};

export default SmallScreenHeader;
