import { getUiConfig } from '../lib/ui';

// TODO: Move this to redux-actions

const viewports = getUiConfig('ui-breakpoints');

const pxToEm = (px, baseFontSize) => {
    let base = 16;

    if (baseFontSize !== undefined) {
        base = baseFontSize;
    }

    return `${parseInt(px, 10) / base}em`;
};

export function getPortMapping() {
    let mapping = {};
    Object.keys(viewports).forEach(k => {
        const query = `(min-width: ${pxToEm(viewports[k])})`;
        const mql = window.matchMedia(query);

        if (mql.matches) {
            mapping[k] = true;
        } else {
            mapping[k] = false;
        }
    });
    return mapping;
}
