import React, { Component } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { translate } from 'react-polyglot';
import { connect } from 'react-redux';
import queryString from 'query-string';
import u from 'updeep';

import LoaderBlock from '../LoaderBlock';
import { sortOptions } from '../../lib/constants';
import {
    fetchAllBooksFiltersWorkflow,
    fetchAllBooksWorkflow,
} from '../../redux/allBooksActions';
import FiltersBar from '../FiltersBar';
import './AddBooks.scss';
import Grid from '../Grid';
import ManageBookCard from '../ManageBookCard';

const mapDispatchToProps = {
    fetchAllBooksFiltersWorkflow,
    fetchAllBooksWorkflow,
};

const mapStateToProps = ({
    allBooks,
    viewport,
    user: { profile },
    allFilters: { readFilters },
}) => ({
    ...allBooks,
    viewport,
    appliedFilters: readFilters,
    userEmail: profile.email,
    name: profile.name,
});

const NoResults = translate()(({ t }) => (
    <span>
        <p>{t('Books.no-result-warning-1')}</p>
        <p>{t('Books.no-result-warning-2')}</p>
        <ul 
            style={ 
                localStorage.getItem('locale') === "ar" || localStorage.getItem('locale') === "ur" 
                ? {paddingLeft:'0',paddingRight:'0'}
                :null
            }
        >
            <li>{t('Books.no-result-warning-3')}</li>
            <li>{t('Books.no-result-warning-4')}</li>
            <li>{t('Books.no-result-warning-5')}</li>
            <li>{t('Books.no-result-warning-6')}</li>
        </ul>
    </span>
));

@translate()
@connect(
    mapStateToProps,
    mapDispatchToProps
)
class AddBooks extends Component {
    constructor(props) {
        super(props);
        const { t } = props;
        // We operate on a copy of the reading list, since it's user editable.
        this.state = {
            // The filters bar allows searching through available
            // filter values. We store that search text here.
            filtersSearchText: {
                category: '',
                publisher: '',
                level: '',
                language: '',
            },
            isAddBookMobile: false,
        };
        this.filtersBarSortOptions = [
            {
                name: t('sort.relevance'),
                queryValue: sortOptions.relevance,
            },
            {
                name: t('sort.new-arrivals-images'),
                queryValue: sortOptions.newArrivals,
            },
            {
                name: t('sort.most-viewed'),
                queryValue: sortOptions.mostViewed,
            },
            {
                name: t('sort.most-liked'),
                queryValue: sortOptions.mostLiked,
            },
        ];

        this.filtersPanelSortOptions = this.filtersBarSortOptions.map(so => ({
            label: so.name,
            value: so.queryValue,
        }));
    }
    updateCategorySearchText = e =>
        this.updateFiltersSearchText('category', e.target.value);
    updatePublisherSearchText = e =>
        this.updateFiltersSearchText('publisher', e.target.value);
    updateLevelSearchText = e =>
        this.updateFiltersSearchText('level', e.target.value);
    updateLanguageSearchText = e =>
        this.updateFiltersSearchText('language', e.target.value);
    getTab = () => {
        const { location } = this.props;
        return queryString.parse(location.search)['tab'];
    };

    updateFiltersSearchText = (key, text) => {
        this.setState(
            u(
                {
                    filtersSearchText: {
                        [key]: text,
                    },
                },
                this.state
            )
        );
    };

    componentDidMount() {
        const {
            fetchAllBooksFiltersWorkflow,
            fetchAllBooksWorkflow,
            appliedFilters,
        } = this.props;

        let processedFilters = appliedFilters;

        // First fetch available filters, then fetch the books.
        fetchAllBooksFiltersWorkflow().then(() =>
            fetchAllBooksWorkflow(processedFilters)
        );
        this.isMobile();
    }

    isMobile = () => {
        if (
            window.screen.width <= 768 
        ) {
            this.setState({
                isAddBookMobile: true,
            });
        } else {
            this.setState({
                isAddBookMobile: false,
            });
        }
    };

    nextPath(path) {
        this.props.history.push(path);
    }

    render() {
        const baseClassName = 'pb-add-books';
        const {
            parentClassName,
            t,
            BooksList,
            newList,
            viewport,
            applyFilter,
            onSortOptionChanged,
            appliedFilters,
            removeFilter,
            filters,
            isFetchingBooks,
            totalBooksCount,
        } = this.props;

        const classes = {
            [baseClassName]: true,
            [parentClassName]: parentClassName,
        };

        const isRightToLeftDirection = localStorage.getItem('locale') === 'ar' || localStorage.getItem('locale') === 'ur';

        let filtersComponent = null;
        filtersComponent = (
            <FiltersBar
                viewport={viewport}
                noTopBorder
                pullUp
                filters={filters}
                applyFilter={applyFilter}
                removeFilter={removeFilter}
                appliedFilters={appliedFilters}
                categorySearchValue={this.state.filtersSearchText.category}
                updateCategorySearchValue={this.updateCategorySearchText}
                publisherSearchValue={this.state.filtersSearchText.publisher}
                updatePublisherSearchValue={this.updatePublisherSearchText}
                levelSearchValue={this.state.filtersSearchText.level}
                updateLevelSearchValue={this.updateLevelSearchText}
                languageSearchValue={this.state.filtersSearchText.language}
                updateLanguageSearchValue={this.updateLanguageSearchText}
                resultsCount={totalBooksCount}
                sortOptions={this.filtersBarSortOptions}
                applySortOption={onSortOptionChanged}
                appliedSortOption={appliedFilters.sort}
                isAddBook
                isAddBookMobile={this.state.isAddBookMobile}
                categoryFilterGTM="add-book-category-filter"
                levelFilterGTM="add-book-level-filter"
                languageFilterGTM="add-book-language-filter"
                favoriteSortGTM="add-book-favorite-sort"
                readSortGTM="add-book-read-sort"
                alphabetSortGTM="add-book-alphabetic-sort"
            />
        );

        // Contains all the id's from newList books
        const newListBooksId = {};
        if (newList && newList.books && newList.books.length > 0) {
            newList.books.forEach(book => {
                newListBooksId[book.id] = true;
            });
        }

        return (
            <div className={classNames(classes)}>
                <div className="__addbooks-section">
                    {BooksList && BooksList.length >= 0 ? (
                        <div className="__filter-section">
                            {filtersComponent}
                        </div>
                    ) : null}
                    {isFetchingBooks || !BooksList ? (
                        <LoaderBlock />
                    ) : (
                            <div>
                                {BooksList.length > 0 ? (
                                    <Grid variant="4up">
                                        {BooksList &&
                                            BooksList.map(bookItem => {
                                                const addBtn = newListBooksId[bookItem.id] || false;

                                                return (
                                                    <div className="__themedBookItem-section">
                                                        <ManageBookCard
                                                            key={bookItem.id}
                                                            book={bookItem}
                                                            isAddBook
                                                            addBookImageGTM="add-books-book-image"
                                                            asdBookTitleGTM="add-books-book-title"
                                                            addBookAuthorGTM="add-books-book-author"
                                                            addBookIllustratorsGTM="add-books-book-illustrator"
                                                            addBookLevelGTM="add-books-book-level"
                                                            removeFromBookListGTM="remove-from-book-list"
                                                            addToBookListGTM="add-to-book-list"
                                                            addBtn={addBtn}
                                                        />
                                                    </div>
                                                );
                                            })}
                                    </Grid>
                                ) : null}
                                <div className={
                                    isRightToLeftDirection
                                    ? "__no-result-section-rtl"
                                    : "__no-result-section"
                                }>
                                    {totalBooksCount ? null : <NoResults />}
                                </div>
                            </div>
                        )}
                </div>
            </div>
        );
    }
}

AddBooks.propTypes = {
    parentClassName: PropTypes.string,
};

export default translate()(AddBooks);