import React,{Component} from 'react';
import translate from 'react-polyglot/lib/translate';
import PublicationForm from '../PublicationForm';
import Block from '../Block';
import './PublicationFormContainer.scss';
@translate()
class PublicationFormContainer extends Component {
    render(){
        return(
            <div className="publication-form-container">
                <Block background="transparent">
                    <PublicationForm history={this.props.history}/>
                </Block>              
            </div>
        );
    }
}

export default PublicationFormContainer;