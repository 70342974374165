import u from 'updeep';
import { handleActions } from 'redux-actions';
import * as actions from './profileActions';
import { profileTypes } from '../lib/constants';

const initialState = {
    isFetchingPublisherProfile: false,
    isFetchingProfile: false,
    isFetchingFilters: false,
    isFetchingMore: false,
    profile: {
        [profileTypes.USER]: null,
        [profileTypes.PUBLISHER]: null,
        hitsCount: 0
    },
};

export default handleActions(
    {
        [actions.fetchPublisherBooksFilters]: (state, action) =>
            u(
                {
                    isFetchingFilters: true,
                },
                state
            ),

        [actions.receivePublisherBooksFilters]: (state, action) =>
            u(
                {
                    isFetchingFilters: false,
                    filters: action.payload.filters,
                },
                state
            ),
        [actions.fetchPublisherProfile]: (state, action) =>
            u(
                {
                    isFetchingPublisherProfile: true,
                    profile: {
                        [action.payload.profileType]: null,
                    },
                },
                state
            ),

        [actions.receivePublisherProfile]: (state, action) =>
            u(
                {
                    isFetchingPublisherProfile: false,
                    profile: {
                        publisher: action.payload.profile,
                        hitsCount: action.payload.profile.newArrivals.meta.hits
                    },
                },
                state
            ),
        [actions.fetchProfile]: (state, action) =>
            u(
                {
                    isFetchingProfile: true,
                    profile: {
                        [action.payload.profileType]: null,
                    },
                },
                state
            ),

        [actions.receiveProfile]: (state, action) =>
            u(
                {
                    isFetchingProfile: false,
                    profile: {
                        [action.payload.profileType]: action.payload.profile,
                    },
                },
                state
            ),

        [actions.fetchMore]: (state, action) =>
            u(
                {
                    isFetchingMore: true,
                },
                state
            ),

        [actions.receiveMore]: (state, action) => {
            const { profileType, collection } = action.payload;
            const data = state.profile[profileType][collection].results.concat(
                action.payload.data[collection].results
            );
            const metadata = action.payload.data[collection].meta;
            return u(
                {
                    isFetchingMore: false,
                    profile: {
                        [profileType]: {
                            [collection]: {
                                meta: metadata,
                                results: data,
                            },
                        },
                    },
                },
                state
            );
        },
        [actions.fetchfavouriteProfilePageData]: (state, action) => {

            switch (action.payload.type) {
                case ('profileBooks'):
                    let index = state.profile.user.books.results.findIndex(
                        book => book.id === action.payload.bookId
                    );
                    if (index >= 0) {
                        let newState = u(
                            {
                                results: u.updateIn([index, 'isFavouriting'], true),
                            },
                            state.profile.user.books
                        );
                        let books = newState;
                        let user = { ...state.profile.user, books }
                        let profile = { ...state.profile, user }
                        let data = { ...state, profile }
                        return data;

                    } else {
                        return state;
                    }

                case ('profileillustratorBooks'):
                    let indexIllustrator = state.profile.user.illustrator_books.results.findIndex(
                        book => book.id === action.payload.bookId
                    );
                    if (indexIllustrator >= 0) {
                        let newState = u(
                            {
                                results: u.updateIn([indexIllustrator, 'isFavouriting'], true),
                            },
                            state.profile.user.illustrator_books
                        );
                        let illustrator_books = newState;
                        let user = { ...state.profile.user, illustrator_books }
                        let profile = { ...state.profile, user }
                        let data = { ...state, profile }
                        return data;

                    } else {
                        return state;
                    }

                case ('profileTranslations'):
                    let indexTranslator = state.profile.user.translations.results.findIndex(
                        book => book.id === action.payload.bookId
                    );
                    if (indexTranslator >= 0) {
                        let newState = u(
                            {
                                results: u.updateIn([indexTranslator, 'isFavouriting'], true),
                            },
                            state.profile.user.translations
                        );
                        let translations = newState;
                        let user = { ...state.profile.user, translations }
                        let profile = { ...state.profile, user }
                        let data = { ...state, profile }
                        return data;

                    } else {
                        return state;
                    }

                case ('profileNewArrivals'):
                    let indexNewArrival = state.profile.publisher.newArrivals.results.findIndex(
                        book => book.id === action.payload.bookId
                    );
                    if (indexNewArrival >= 0) {
                        let newState = u(
                            {
                                results: u.updateIn([indexNewArrival, 'isFavouriting'], true),
                            },
                            state.profile.publisher.newArrivals
                        );
                        let newArrivals = newState;
                        let publisher = { ...state.profile.publisher, newArrivals }
                        let profile = { ...state.profile, publisher }
                        let data = { ...state, profile }
                        return data;

                    } else {
                        return state;
                    }

                default:
                    return null;
            }

        },
        [actions.receivefavouriteProfilePageData]: (state, action) => {

            switch (action.payload.type) {
                case ('profileBooks'):
                    let index = state.profile.user.books.results.findIndex(
                        book => book.id === action.payload.bookId
                    );

                    if (index >= 0) {
                        let favourite = state.profile.user.books.results[index].is_favourite;
                        const newLikes = !favourite
                            ? state.profile.user.books.results[index].likesCount + 1
                            : state.profile.user.books.results[index].likesCount - 1;
                        const likesTemp = u(
                            {
                                results: u.updateIn([index, 'likesCount'], newLikes),
                            },
                            state.profile.user.books
                        );
                        let temp = u(
                            {
                                results: u.updateIn([index, 'isFavouriting'], false),
                            },
                            likesTemp
                        );
                        const newState = u(
                            {
                                results: u.updateIn([index, 'is_favourite'], !favourite),
                            },
                            temp
                        );
                        let books = newState;
                        let user = { ...state.profile.user, books }
                        let profile = { ...state.profile, user }
                        let data = { ...state, profile }
                        return data;
                    } else {
                        return state;
                    }

                case ('profileillustratorBooks'):
                    let indexIllustrator = state.profile.user.illustrator_books.results.findIndex(
                        book => book.id === action.payload.bookId
                    );

                    if (indexIllustrator >= 0) {
                        let favourite = state.profile.user.illustrator_books.results[indexIllustrator].is_favourite;
                        const newLikes = !favourite
                            ? state.profile.user.illustrator_books.results[indexIllustrator].likesCount + 1
                            : state.profile.user.illustrator_books.results[indexIllustrator].likesCount - 1;
                        const likesTemp = u(
                            {
                                results: u.updateIn([indexIllustrator, 'likesCount'], newLikes),
                            },
                            state.profile.user.illustrator_books
                        );
                        let temp = u(
                            {
                                results: u.updateIn([indexIllustrator, 'isFavouriting'], false),
                            },
                            likesTemp
                        );
                        const newState = u(
                            {
                                results: u.updateIn([indexIllustrator, 'is_favourite'], !favourite),
                            },
                            temp
                        );
                        let illustrator_books = newState;
                        let user = { ...state.profile.user, illustrator_books }
                        let profile = { ...state.profile, user }
                        let data = { ...state, profile }
                        return data;

                    } else {
                        return state;
                    }

                case ('profileTranslations'):
                    let indexTranslator = state.profile.user.translations.results.findIndex(
                        book => book.id === action.payload.bookId
                    );

                    if (indexIllustrator >= 0) {
                        let favourite = state.profile.user.translations.results[indexTranslator].is_favourite;
                        const newLikes = !favourite
                            ? state.profile.user.translations.results[indexTranslator].likesCount + 1
                            : state.profile.user.translations.results[indexTranslator].likesCount - 1;
                        const likesTemp = u(
                            {
                                results: u.updateIn([indexTranslator, 'likesCount'], newLikes),
                            },
                            state.profile.user.translations
                        );
                        let temp = u(
                            {
                                results: u.updateIn([indexTranslator, 'isFavouriting'], false),
                            },
                            likesTemp
                        );
                        const newState = u(
                            {
                                results: u.updateIn([indexTranslator, 'is_favourite'], !favourite),
                            },
                            temp
                        );
                        let translations = newState;
                        let user = { ...state.profile.user, translations }
                        let profile = { ...state.profile, user }
                        let data = { ...state, profile }
                        return data;

                    } else {
                        return state;
                    }

                case ('profileNewArrivals'):
                    let indexNewArrival = state.profile.publisher.newArrivals.results.findIndex(
                        book => book.id === action.payload.bookId
                    );

                    if (indexNewArrival >= 0) {
                        let favourite = state.profile.publisher.newArrivals.results[indexNewArrival].is_favourite;
                        const newLikes = !favourite
                            ? state.profile.publisher.newArrivals.results[indexNewArrival].likesCount + 1
                            : state.profile.publisher.newArrivals.results[indexNewArrival].likesCount - 1;
                        const likesTemp = u(
                            {
                                results: u.updateIn([indexNewArrival, 'likesCount'], newLikes),
                            },
                            state.profile.publisher.newArrivals
                        );
                        let temp = u(
                            {
                                results: u.updateIn([indexNewArrival, 'isFavouriting'], false),
                            },
                            likesTemp
                        );
                        const newState = u(
                            {
                                results: u.updateIn([indexNewArrival, 'is_favourite'], !favourite),
                            },
                            temp
                        );
                        let newArrivals = newState;
                        let publisher = { ...state.profile.publisher, newArrivals }
                        let profile = { ...state.profile, publisher }
                        let data = { ...state, profile }
                        return data;

                    } else {
                        return state;
                    }


                default:
                    return null;
            }
        }

    },
    initialState
);
