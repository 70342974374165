import React, { Component } from 'react';
import './BookUpload.scss';
import crop from '../../assets/crop.svg';
import BookUploadHeader from '../BookUploadHeader';
import uploadImg from '../../assets/upload_img.svg';
import reset from '../../assets/reset.svg';
import locales from '../../i18n';
import Modal from 'react-modal';
import CroppedImage from '../CroppedImage/CroppedImage';
import BookUploadFooter from '../BookUploadFooter';
import { API_URL, OfflineStory } from '../../api';
import DataLoader from '../DataLoader/DataLoader';
import withRouter from "../../util/withRouter"

Modal.setAppElement('body');

const customStyles = {
    overlay: {
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        zIndex: 999,
        overflow: 'hidden',
    },
    content: {
        position: 'fixed',
        top: '0%',
        left: '0%',
        right: '0%',
        bottom: '0%',
        border: '1px solid #ccc',
        overflow: 'auto',
        WebkitOverflowScrolling: 'touch',
        borderRadius: '0px',
        outline: 'none',
        padding: '0px',
        zIndex: '999',
        overflowY: 'hidden',
        background: '#808080',
    },
};
class BookUpload extends Component {
    constructor(props) {
        super(props);
        this.state = {
            fileName: '',
            data: '',
            restoreImage: '',
            base64Image: '',
            uploadImage: '',
            progressValue: 0,
            modalIsOpen: false,
            encoding: true,
            firstTime: false,
            uploadStarted: false,
            callApi: false,
            disable: true,
            loader: true,
        };
    }

    componentDidMount() {
        OfflineStory.fetchOfflineStoryDetail(
            this.props.location.pathname.split('/')[2]
        )
            .then((response) => {
                return response.json();
            })
            .then((response) => {
                this.setState((state, props) => ({
                    data: response.data,
                    firstTime: true,
                    loader: false,
                }));
            });
    }

    dataURLtoFile = (dataurl, filename) => {
        var arr = dataurl.split(','),
            mime = arr[0].match(/:(.*?);/)[1],
            bstr = atob(arr[1]),
            n = bstr.length,
            u8arr = new Uint8Array(n);
        while (n--) {
            u8arr[n] = bstr.charCodeAt(n);
        }
        return new File([u8arr], filename, { type: mime });
    };

    closePopup = () => {
        this.setState((state, props) => ({
            modalIsOpen: false,
        }));
    };

    updatedImage = (value) => {
        this.setState((state, props) => ({
            base64Image: value,
        }));
    };

    nextPage = () => {
        this.props.history.push(
            `/preview/${this.props.location.pathname.split('/')[2]}`
        );
    };

    backPage = () => {
        this.props.history.push(
            `/details/${this.props.location.pathname.split('/')[2]}`
        );
    };

    apiToUpdateImg = (file) => {
        this.setState((state, props) => ({
            loader: true,
        }));
        let url = `${API_URL}/offline_stories/${
            this.props.location.pathname.split('/')[2]
        }/modify_cover_thumbnail`;
        let xhr = new XMLHttpRequest();
        let formData = new FormData();
        xhr.open('PUT', url, true);
        xhr.withCredentials = true;
        xhr.setRequestHeader('X-Requested-With', 'XMLHttpRequest');
        formData.append('story[cover_thumbnail]', file);
        xhr.send(formData);
        xhr.onload = () => {
            this.setState((state, props) => ({
                loader: false,
            }));
        };
    };

    croppedImage = () => {
        let thumbnail = document.getElementById('thumbnail_view');
        if (this.state && this.state.base64Image !== '') {
            let img = document.createElement('img');
            img.src = this.state.base64Image;
            thumbnail.innerHTML = '';
            thumbnail.appendChild(img);
        }
        this.closePopup();
        let file = this.dataURLtoFile(this.state.base64Image, `${Date()}.jpg`);
        this.apiToUpdateImg(file);
    };

    render() {
        let progressBar = document.getElementById('progress-bar');
        let dropArea = document.getElementById('drop-area');
        let thumbnail = document.getElementById('thumbnail_view');
        let gallery = document.getElementById('gallery');
        let uploadProgress = [];
        const isRightToLeftDirection =
            localStorage.getItem('locale') === 'ar' ||
            localStorage.getItem('locale') === 'ur';
        const locale = localStorage.getItem('locale') || 'en';
        const messages = locales[locale];
        const baseClassName = 'book-upload_pdf';

        function preventDefaults(e) {
            e.preventDefault();
            e.stopPropagation();
        }

        function handleDrop(e) {
            e.stopImmediatePropagation();
            let dt = e.dataTransfer;
            let files = dt.files;
            if (files[0].type === 'application/pdf') {
                handleFiles(files);
            } else {
                alert('File type is not supported');
            }
        }

        let pdfView = (value, url) => {
            let img = document.createElement('img');
            img.src = url;
            value.innerHTML = '';
            value.appendChild(img);
            this.setState((state, props) => ({
                uploadImage: url,
                encoding: false,
                disable: false,
            }));
        };

        let initializeProgress = (numFiles) => {
            if (progressBar) {
                progressBar.value = 0;
                uploadProgress = [];

                for (let i = numFiles; i > 0; i--) {
                    uploadProgress.push(0);
                }
            }
        };

        let updateProgress = (fileNumber, percent) => {
            uploadProgress[fileNumber] = percent;
            let total =
                uploadProgress.reduce((tot, curr) => tot + curr, 0) /
                uploadProgress.length;
            progressBar.value = total;
            this.setState((state, props) => ({
                progressValue: total,
            }));
        };

        let handleFiles = (files) => {
            if (files && files[0].size < 10915705) {
                files = [...files];
                this.setState((state, props) => ({
                    fileName: files[0].name,
                    uploadStarted: true,
                }));
                progressBar.classList.remove(`${baseClassName}__progress-bar`);
                initializeProgress(files.length);
                files.forEach(uploadFile);
            } else {
                alert('File size should be less than 10mb');
            }
        };

        let selectPdfFile = (e) => {
            handleFiles(e.target.files);
        };

        let selectImgFile = (e) => {
            this.setState((state, props) => ({
                uploadImage: e,
            }));
            let reader = new FileReader();
            reader.onloadend = function () {
                let img = document.createElement('img');
                img.src = reader.result;
                thumbnail.innerHTML = '';
                thumbnail.appendChild(img);
            };
            if (e && e.target && e.target.files[0]) {
                reader.readAsDataURL(e.target.files[0]);
                this.apiToUpdateImg(e.target.files[0]);
            }
        };

        let stopUploading = () => {
            this.setState((state, props) => ({
                fileName: `${messages['Book.drag']}`,
                uploadStarted: false,
                encoding: true,
                disable: true,
                uploadImage: '',
            }));
            uploadProgress = [];
            progressBar.classList.add(`${baseClassName}__progress-bar`);
            gallery.innerHTML = '';
            thumbnail.innerHTML = '';
        };

        let resetImage = () => {
            if (this.state.restoreImage !== '') {
                pdfView(thumbnail, this.state.restoreImage);
                let file = this.dataURLtoFile(
                    this.state.restoreImage,
                    `${Date()}.jpg`
                );
                this.apiToUpdateImg(file);
            }
        };

        let startApiCall = () => {
            let timesRun = 0;
            let dataFetch = setInterval(() => {
                timesRun += 1;
                OfflineStory.fetchOfflineStoryDetail(
                    this.props.location.pathname.split('/')[2]
                )
                    .then((response) => {
                        return response.json();
                    })
                    .then((response) => {
                        const data = response.data
                        // console.log('page', data.pages_created, timesRun);
                        if (data.pages_created) {
                            pdfView(
                                gallery,
                                data.pages && data.pages[0].url
                            );
                            pdfView(
                                thumbnail,
                                'data:image/png;base64,'.concat(
                                    data.cover_thumbnail_image
                                )
                            );
                            this.setState((state, props) => ({
                                restoreImage: 'data:image/png;base64,'.concat(
                                    data.cover_thumbnail_image
                                ),
                            }));
                            clearInterval(dataFetch);
                        }
                    });
                // if (timesRun === 10) {
                //     clearInterval(dataFetch);
                // }
            }, 30000);
        };

        let uploadFile = (file, i, status) => {
            let url = `${API_URL}/offline_stories/${
                this.props.location.pathname.split('/')[2]
            }`;
            let xhr = new XMLHttpRequest();
            let formData = new FormData();
            xhr.open('PUT', url, true);
            xhr.withCredentials = true;
            xhr.setRequestHeader('X-Requested-With', 'XMLHttpRequest');
            xhr.upload.addEventListener('progress', function (e) {
                updateProgress(i, (e.loaded * 100.0) / e.total || 100);
            });
            xhr.addEventListener('readystatechange', function (e) {
                if (xhr.readyState === 4 && xhr.status === 200) {
                    updateProgress(i, 100);
                    startApiCall();
                } else if (xhr.readyState === 4 && xhr.status !== 200) {
                    alert('Error while uploading the file.');
                    stopUploading();
                }
            });
            formData.append('story[pdf_file]', file);
            xhr.send(formData);
        };
        if (
            this.state &&
            this.state.firstTime &&
            this.state.data &&
            this.state.data.cover_thumbnail_image &&
            this.state.data.pages &&
            this.state.data.pages[0] &&
            this.state.data.pages[0].url
        ) {
            this.setState((state, props) => ({
                firstTime: false,
                fileName: this.state.data.backup_pdf_file_name,
                uploadStarted: true,
                restoreImage: 'data:image/png;base64,'.concat(
                    this.state.data.cover_thumbnail_image
                ),
            }));
            progressBar.classList.remove(`${baseClassName}__progress-bar`);
            initializeProgress(1);
            updateProgress(0, 100);
            pdfView(gallery, this.state.data.pages[0].url);
            pdfView(
                thumbnail,
                this.state.data &&
                    'data:image/png;base64,'.concat(
                        this.state.data.cover_thumbnail_image
                    )
            );
        }

        if (dropArea) {
            ['dragenter', 'dragover', 'dragleave', 'drop'].forEach(
                (eventName) => {
                    dropArea.addEventListener(
                        eventName,
                        preventDefaults,
                        false
                    );

                    document.body.addEventListener(
                        eventName,
                        preventDefaults,
                        false
                    );
                }
            );

            dropArea.addEventListener('drop', handleDrop, false);
        }

        return (
            <div>
                <div className={`${baseClassName}__page_view_container`}>
                    <div></div>
                    <div>
                        {' '}
                        <BookUploadHeader
                            currentPage={2}
                            history={this.props.history}
                        />
                        <div
                            className={`${baseClassName}__book_upload_container`}
                        >
                            <div>
                                <span
                                    className={
                                        `${baseClassName}__title ` +
                                        (isRightToLeftDirection
                                            ? `${baseClassName}__mirror`
                                            : '')
                                    }
                                >
                                    {' '}
                                    {messages['Book.upload-pdf-message']}
                                </span>
                                <br />
                                <div id="drop-area">
                                    <div>
                                        <div id="gallery" />
                                    </div>
                                    <div
                                        className={`${baseClassName}__spacing`}
                                    >
                                        <div
                                            className={`${baseClassName}__fileName`}
                                        >
                                            {' '}
                                            {this.state.fileName !== ''
                                                ? this.state.fileName
                                                : `${messages['Book.drag']}`}
                                        </div>
                                        <div style={{ lineHeight: '0rem' }}>
                                            {!this.state.uploadStarted ? (
                                                <span
                                                    className={`${baseClassName}__restrict_message`}
                                                >
                                                    {messages['Book.restrict']}
                                                </span>
                                            ) : (
                                                ''
                                            )}

                                            <progress
                                                id="progress-bar"
                                                className={`${baseClassName}__progress-bar`}
                                                max="100"
                                                value="0"
                                            ></progress>
                                            <span
                                                className={`${baseClassName}__status`}
                                            >
                                                {!this.state.uploadStarted
                                                    ? ' '
                                                    : this.state.progressValue <
                                                      100
                                                    ? messages['Book.uploading']
                                                    : this.state.encoding
                                                    ? messages['Book.encoding']
                                                    : messages['Book.complete']}
                                            </span>
                                        </div>
                                        <div>
                                            {!this.state.uploadStarted ? (
                                                <div>
                                                    <label
                                                        className={`${baseClassName}__button`}
                                                        htmlFor="fileElem"
                                                    >
                                                        <div>
                                                            {' '}
                                                            {
                                                                messages[
                                                                    'Book.select-file'
                                                                ]
                                                            }
                                                        </div>
                                                    </label>
                                                    <input
                                                        type="file"
                                                        accept="application/pdf"
                                                        onChange={(e) =>
                                                            selectPdfFile(e)
                                                        }
                                                        id="fileElem"
                                                    />
                                                </div>
                                            ) : this.state.progressValue <
                                                  100 || this.state.encoding ? (
                                                // <button
                                                //     className={`${baseClassName}__cancel_button`}
                                                //     onClick={() => stopUploading()}
                                                // >
                                                //     {messages['ReadingList.cancel']}
                                                // </button>
                                                ''
                                            ) : (
                                                <button
                                                    className={`${baseClassName}__remove_button`}
                                                    onClick={() =>
                                                        stopUploading()
                                                    }
                                                >
                                                    {
                                                        messages[
                                                            'Book.delete-offline'
                                                        ]
                                                    }
                                                </button>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div>
                                <span
                                    className={
                                        `${baseClassName}__title ` +
                                        (isRightToLeftDirection
                                            ? `${baseClassName}__mirror`
                                            : '')
                                    }
                                >
                                    {' '}
                                    {messages['Book.thumbnail']}
                                </span>
                                <br />
                                <div
                                    className={`${baseClassName}__thumbnail_container`}
                                >
                                    <div id="thumbnail_view"></div>
                                    <div>
                                        <div
                                            className={[
                                                `${baseClassName}__thumbnail_button`,
                                                `${baseClassName}__crop`,
                                                this.state.uploadImage === ''
                                                    ? `${baseClassName}__disable`
                                                    : '',
                                            ].join(' ')}
                                            onClick={() =>
                                                this.setState(
                                                    (state, props) => ({
                                                        modalIsOpen: true,
                                                    })
                                                )
                                            }
                                        >
                                            <img src={crop} alt="alt" />
                                        </div>
                                        <div>
                                            <label htmlFor="imgUpload">
                                                <div
                                                    className={`${baseClassName}__thumbnail_button ${baseClassName}__upload`}
                                                >
                                                    <img
                                                        src={uploadImg}
                                                        alt="alt"
                                                    />
                                                </div>
                                            </label>
                                            <input
                                                type="file"
                                                accept="image/*"
                                                onClick={(e) =>
                                                    (e.target.value = '')
                                                }
                                                onChange={(e) =>
                                                    selectImgFile(e)
                                                }
                                                id="imgUpload"
                                            />
                                        </div>
                                        <div
                                            className={[
                                                `${baseClassName}__thumbnail_button`,
                                                `${baseClassName}__upload`,
                                                this.state.uploadImage === ''
                                                    ? `${baseClassName}__disable`
                                                    : '',
                                            ].join(' ')}
                                            onClick={() => resetImage()}
                                        >
                                            <img src={reset} alt="alt" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <BookUploadFooter
                            disable={this.state.disable}
                            backPage={this.backPage}
                            nextPage={this.nextPage}
                        />
                    </div>
                    <div></div>
                    <Modal
                        isOpen={this.state.modalIsOpen}
                        style={customStyles}
                        onAfterOpen={() => {
                            document.body.style.overflow = 'hidden';
                        }}
                        onAfterClose={() => {
                            document.body.style.overflow = 'auto';
                        }}
                    >
                        <CroppedImage
                            onCropped={this.updatedImage}
                            srcData={thumbnail}
                            handleClose={this.closePopup}
                            handleSave={this.croppedImage}
                        />
                    </Modal>
                </div>
                {this.state.loader && (
                    <div className={`${baseClassName}__book_loader`}>
                        <DataLoader />
                    </div>
                )}
            </div>
        );
    }
}

export default withRouter(BookUpload);
