import React,{Component} from 'react';
import Block from '../Block';
import BookCard from '../BookCard';
import Level from '../Level';
import GovtApproved from './GovtApproved';
import Credits from './Credits';
import BookDetailSummary from '../BookDetailSummary'
import Button from '../Button';
import Dropdown from '../Dropdown';
import List from '../List';
import Link from '../Link';
import classNames from 'classnames';
import LikeAndShareRow from './LikeAndShareRow';


export default class BookTabletView extends Component{
    render(){

        const {
            myLanguage,
            online,
            book,
            slug,
            pagesCount,
            baseClassName,
            title,
            level,
            t,
            storyLevelColour,
            title_logos,
            favouriteStat,
            favouriteStatMainBook,
            readsCount,
            isLoggedIn,
            availableOffline,
            offlineBooksCount,
            MAX_OFFLINE_BOOKS_COUNT,
            isSavingOffline,
            bookStoryDownload,
            onReadClicked,
            readToMeModalOpen,
            roles,
            onAddToOfflineClicked,
            onOpenModal
        } = this.props

        const {
            id,
            isTranslation,
            isRelevelled,
            authors,
            publisher,
            illustrators,
            photographers,
            onProfileLinkClicked,
            description,
            readAlouds,
            downloadLinks,
            offlineTranslators,
            edition_type
        } = book

        const {
            originalStory: {
                name: originalTitle,
                slug: originalSlug,
                authors: originalAuthors,
                isPresent:isOriginalStoryPresent
            },
        } = book;


        const isRightToLeftDirection = localStorage.getItem('locale') === 'ar' || localStorage.getItem('locale') === 'ur';

        const titleClasses = {
            [myLanguage === 'lo'
                ? `${baseClassName}__lo__title`
                : isRightToLeftDirection
                ? `${baseClassName}__title ${baseClassName}__arabic-title`
                : `${baseClassName}__title`]: true,
        };



        return(
            <div className={`${baseClassName}__block-wrapper`}>
                <Block>
                <div className={`${baseClassName}__wrapper`}>
                <div className={isRightToLeftDirection 
                ? `${baseClassName}__cover-wrapper-rtl`
                : `${baseClassName}__cover-wrapper`}>
                    <div className={`${baseClassName}__cover`}>
                        <BookCard
                            myLanguage={myLanguage}
                            online={online}
                            book={book}
                            fontSize="l"
                            readStory={ () =>
                                onReadClicked(slug)
                            }
                            shouldDisplayMenu={false}
                            noSrcSet={!online}
                            bookDetails={
                                true
                            }
                            landingPageBook
                            pagesCount={pagesCount}
                            enableFavouriting
                            />
                        </div>
                        <LikeAndShareRow 
                            t={t}
                            landingPageBook
                            favouriteStatMainBook={favouriteStatMainBook}
                            favouriteStat={favouriteStat}
                            readsCount={readsCount}
                        />
                        {/* Download Button */}
                        {isLoggedIn ? (
                            roles.includes('content_manager') ||
                            roles.includes('sub_admin') ||
                            roles.includes('admin') ? (
                                <div
                                    className={
                                        isRightToLeftDirection
                                            ? `${baseClassName}__book-download-arabic`
                                            : `${baseClassName}__book-download`
                                    }
                                >
                                    <Dropdown
                                        align="left"
                                        toggleEl={
                                            <Link normal>
                                                {t(
                                                    'global.download',
                                                    1
                                                )}
                                            </Link>
                                        }
                                        bookTabletMode
                                    >
                                        <List nowrap tabletView>
                                            {downloadLinks &&
                                                downloadLinks
                                                    .slice(0, 2)
                                                    .map(
                                                        (
                                                            {
                                                                href,
                                                                type,
                                                            },
                                                            index
                                                        ) => {
                                                            return (
                                                                <Link
                                                                    parentClassName={`${baseClassName}__link`}
                                                                    download
                                                                    href={
                                                                        href
                                                                    }
                                                                    dataGTM={
                                                                        bookStoryDownload
                                                                    }
                                                                >
                                                                    <div
                                                                        className="url_name"
                                                                        key={
                                                                            index
                                                                        }
                                                                    >
                                                                        {
                                                                            type
                                                                        }
                                                                    </div>
                                                                </Link>
                                                            );
                                                        }
                                                    )}
                                        </List>
                                    </Dropdown>
                                </div>
                            ) : (
                                <div>
                                    <Button
                                        download
                                        fullWidth
                                        label={t(
                                            'global.download',
                                            1
                                        )}
                                        variant="white-background"
                                        href={downloadLinks[0].href}
                                        id={id}
                                        isRightToLeftDirection={
                                            isRightToLeftDirection
                                        }
                                        dataGTM={bookStoryDownload}
                                    ></Button>
                                </div>
                            )
                        ) : null}
                    </div>
                    <div className={`${baseClassName}__story-book-details`}>
                        <div className={`${baseClassName}__title-row`}>
                            <div className={classNames(titleClasses)}>
                                {title}
                            </div>
                        </div>
                        <hr style={{margin:'8px',marginLeft:'0',marginRight:'0'}}/>
                        <div className={`${baseClassName}__level-logo-wrapper`}>
                            <div className={`${baseClassName}__level-wrapper`}>
                                <Level
                                    title={t(`PublicationForm.level${level}`)}
                                    storyLevelColour={storyLevelColour}
                                />
                            </div>
                            {title_logos.length > 0 && (
                                <GovtApproved 
                                    t={t} 
                                    isRightToLeftDirection={isRightToLeftDirection} 
                                    title_logos={title_logos} 
                                />
                            )}
                        </div>
                        <div className={`${baseClassName}__credits-wrapper`}>
                        <Credits
                            isTranslation={isTranslation}
                            isRelevelled={isRelevelled}
                            authors={authors}
                            publisher={publisher}
                            illustrators={illustrators}
                            photographers={photographers}
                            originalTitle={originalTitle}
                            originalSlug={originalSlug}
                            originalAuthors={originalAuthors}
                            offline={!online}
                            t={t}
                            onProfileLinkClicked={onProfileLinkClicked}
                            landingPageBook={this.props.landingPageBook}
                            translator={offlineTranslators}
                            type={edition_type}
                            isOriginalStoryPresent={isOriginalStoryPresent}
                        />
                        </div>
                        <div className={`${baseClassName}__summary-wrapper`}>
                            <BookDetailSummary isTabletMode description={description}/>
                        </div>
                        <div
                            className={
                                isRightToLeftDirection
                                    ? `${baseClassName}__readStory-wrapper-arabic`
                                    : `${baseClassName}__readStory-wrapper`
                            }
                        >
                            <div style={{width:'100%'}}>
                                <Button
                                    iconLeft="read_white"
                                    buttonBlue
                                    fullWidth
                                    label={t('Book.read')}
                                    variant="primary"
                                    onClick={ () =>
                                        onReadClicked(slug)
                                    }
                                    dataGTM="book-read-story"
                                    isRightToLeftDirection={
                                        isRightToLeftDirection
                                    }
                                />
                            </div>
                        </div>
                        {/* Read to Me Button */}
                        {readAlouds.length > 0 && (
                            <div>
                                <Button
                                    iconLeft="video"
                                    buttonBlue
                                    fullWidth
                                    label={t('Book.readToMe')}
                                    variant="primary"
                                    onClick={() =>
                                        readToMeModalOpen()
                                    }
                                    isRightToLeftDirection={
                                        isRightToLeftDirection
                                    }
                                />
                            </div>
                        )}
                        {/* Save Offline */}
                        {process.env.REACT_APP_FEATURE_OFFLINE ? (
                            <div
                                className={`${baseClassName}__offline-container`}
                            >
                                <Button
                                    saveOffline
                                    t={t}
                                    fullWidth
                                    isLoggedIn={isLoggedIn}
                                    availableOffline={
                                        availableOffline
                                    }
                                    label={t('Book.add-to-offline')}
                                    variant="white-background"
                                    isRightToLeftDirection={
                                        isRightToLeftDirection
                                    }
                                    dataGTM={bookStoryDownload}
                                    onClickAddToOffline={() => {
                                        offlineBooksCount <
                                        MAX_OFFLINE_BOOKS_COUNT
                                            ? onAddToOfflineClicked()
                                            : onOpenModal(
                                                    'noSpaceForMoreOfflineBooks'
                                                );
                                    }}
                                    onClickRemoveFromOffline={() =>
                                        onOpenModal('delete')
                                    }
                                    disabled={
                                        !(
                                            'serviceWorker' in
                                            navigator
                                        )
                                    }
                                    isSavingOffline={
                                        isSavingOffline
                                    }
                                    bookSlug={slug}
                                ></Button>
                            </div>
                        ) : null}
                    </div> 
                </div>
                </Block>
            </div>
        );
    }
}