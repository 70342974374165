import React, { Component } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import './Rowifier.scss';

class Rowifier extends Component {
    static defaultProps = {
        align: 'left',
        noPadding: false,
    };

    render() {
        const baseClassName = 'pb-rowifier';

        const {
            children,
            align,
            separator,
            borderTop,
            borderBottom,
            noPadding,
        } = this.props;

        const classes = {
            [baseClassName]: true,
            [`${baseClassName}--align-${align}`]: align,
            [`${baseClassName}--separator`]: separator,
            [`${baseClassName}--border-top`]: borderTop,
            [`${baseClassName}--border-bottom`]: borderBottom,
        };

        const divClassName = noPadding
            ? `${baseClassName}__row-no-padding`
            : `${baseClassName}__row`;

        return (
            <div className={classNames(classes)}>
                {React.Children.toArray(children).map((child, i) => (
                    <div key={`row.${i}`} className={divClassName}>
                        {child}
                    </div>
                ))}
            </div>
        );
    }
}

Rowifier.propTypes = {
    children: PropTypes.node,
    align: PropTypes.oneOf(['left', 'right', 'center']),
    separator: PropTypes.bool,
    noPadding: PropTypes.bool,
};

export default Rowifier;
