import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { translate } from 'react-polyglot';
import List from '../List';
import Dropdown from '../Dropdown';
import Link from '../Link';


class DownloadRange extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isMobile: false,
            isOther: false,
            isTablet: false
        };
    }

    componentDidMount = () => {
        this.viewPortDetector()
    };

    viewPortDetector = () => {

        if (window.innerWidth < 768) {
            this.setState({
                isMobile: true
            })
        } else if (window.innerWidth >= 768 && window.innerWidth < 1024) {
            this.setState({
                isTablet: true
            })
        } else {
            this.setState({
                isOther: true
            })
        }

    }

    render() {
        const {
            t,
            align,
            videosVideoDownloadGTM,
            dpi_url,
        } = this.props;

        const { isMobile, isOther, isTablet } = this.state;

        return (
            <Dropdown
                align={align}
                toggleEl={
                    <Link normal dataGTM={videosVideoDownloadGTM}>
                        {t('global.download', 1)}
                    </Link>
                }
            >
                <List nowrap>

                    {/*For Mobile View */}

                    {
                        isMobile ? dpi_url &&
                            dpi_url.mobile.map((value, index) => {
                                return <Link
                                    parentClassName={`__link`}
                                    download
                                    onClick={this.downloadModal}
                                    href={value.url}
                                    dataGTM={videosVideoDownloadGTM}
                                >
                                    <div className="url_name" key={index}>
                                        {value.resolution}
                                    </div>
                                </Link>
                            })
                            : null
                    }

                    {/*For Tablet View */}

                    {
                        isTablet ? dpi_url &&
                            dpi_url.tablet.map((value, index) => {
                                return <Link
                                    parentClassName={`__link`}
                                    download
                                    onClick={this.downloadModal}
                                    href={value.url}
                                    dataGTM={videosVideoDownloadGTM}
                                >
                                    <div className="url_name" key={index}>
                                        {value.resolution}
                                    </div>
                                </Link>
                            })
                            : null
                    }

                    {/*For Other (Higher than Tablet) View */}


                    {
                        isOther ? dpi_url &&
                            dpi_url.other.map((value, index) => {
                                return <Link
                                    parentClassName={`__link`}
                                    download
                                    onClick={this.downloadModal}
                                    href={value.url}
                                    dataGTM={videosVideoDownloadGTM}
                                >
                                    <div className="url_name" key={index}>
                                        {value.resolution}
                                    </div>
                                </Link>
                            })
                            : null
                    }
                </List>
            </Dropdown>
        );
    }
}

DownloadRange.propTypes = {
    title: PropTypes.string.isRequired,
    href: PropTypes.string.isRequired,
    align: PropTypes.shape(Dropdown.propTypes.align),
};

export default translate()(DownloadRange);

// const digitIndex = value.resolution.search(/\d/);
// const formattedResolution = value.resolution.substr(0, digitIndex) + ": " + value.resolution.substr(digitIndex);


