import { handleActions } from 'redux-actions';
import u from 'updeep';
import * as actions from './homeActions';
import * as bookActions from './bookActions';

const initialState = {
    bannerImages: [],
    videos: [],
    newArrivals: {
        meta: {},
        results: [],
    },
    mostRead: {
        meta: {},
        results: [],
    },
    bookCategories: [],
    recommendedStories: [],
    readingLists: [],
    profile: {},
    localeLanguage:""
};

export default handleActions(
    {

        [actions.receiveHome]: (state, action) =>
            u(
                {
                    newArrivals: action.payload.data.newArrivals,
                    mostRead: action.payload.data.mostRead,
                    readingLists: action.payload.data.readingLists.results,
                    videos: action.payload.data.videos.results,
                    localeLanguage:action.payload.data.localeLanguage
                },
                state
            ),

        [actions.receiveRecommendations]: (state, action) =>
            u(
                {
                    recommendedStories: action.payload.data,
                },
                state
            ),

        [actions.popupOpened]: (state, action) =>
            u(
                {
                    profile: action.payload,
                },
                state
            ),

        [actions.bookRead]: (state, action) =>
            u(
                {
                    profile: action.payload,
                },
                state
            ),

        [actions.receiveCategories]: (state, action) =>
            u(
                {
                    bookCategories: action.payload.categories,
                },
                state
            ),

        [actions.favouriteVideo]: (state, action) => {
            //Get the associated video and set its isFovouriting state
            //Get the index of the video
            let index = state.videos.findIndex(
                (video) => video.id === action.payload.videoId
            );

            if (index >= 0) {
                return u(
                    {
                        videos: u.updateIn([index, 'isFavouriting'], true),
                    },
                    state
                );
            } else {
                return state;
            }
        },
        [actions.receiveFavouriteVideo]: (state, action) => {
            //Get the associated video and set its isFovouriting state
            //Get the index of the video
            const index = state.videos.findIndex(
                (video) => video.id === action.payload.videoId
            );

            if (index >= 0) {
                let favourite = state.videos[index].is_favourite;
                const newLikes = !favourite
                    ? state.videos[index].likesCount + 1
                    : state.videos[index].likesCount - 1;
                const likesTemp = u(
                    {
                        videos: u.updateIn([index, 'likesCount'], newLikes),
                    },
                    state
                );
                let temp = u(
                    {
                        videos: u.updateIn([index, 'isFavouriting'], false),
                    },
                    likesTemp
                );
                return u(
                    {
                        videos: u.updateIn([index, 'is_favourite'], !favourite),
                    },
                    temp
                );
            } else {
                return state;
            }
        },
        [actions.favouriteBook]: (state, action) => {
            //Get the associated book and set its isFovouriting state
            //Get the index of the book
            let index = state.recommendedStories.findIndex(
                (book) => book.id === action.payload.bookId
            );

            if (index >= 0) {
                return u(
                    {
                        recommendedStories: u.updateIn(
                            [index, 'isFavouriting'],
                            true
                        ),
                    },
                    state
                );
            } else {
                return state;
            }
        },
        [actions.receiveFavouriteBook]: (state, action) => {
            //Get the associated book and set its isFovouriting state
            //Get the index of the book
            const index = state.recommendedStories.findIndex(
                (book) => book.id === action.payload.bookId
            );

            if (index >= 0) {
                let favourite = state.recommendedStories[index].is_favourite;
                const newLikes = !favourite
                    ? state.recommendedStories[index].likesCount + 1
                    : state.recommendedStories[index].likesCount - 1;
                const likesTemp = u(
                    {
                        recommendedStories: u.updateIn(
                            [index, 'likesCount'],
                            newLikes
                        ),
                    },
                    state
                );
                let temp = u(
                    {
                        recommendedStories: u.updateIn(
                            [index, 'isFavouriting'],
                            false
                        ),
                    },
                    likesTemp
                );
                return u(
                    {
                        recommendedStories: u.updateIn(
                            [index, 'is_favourite'],
                            !favourite
                        ),
                    },
                    temp
                );
            } else {
                return state;
            }
        },
    },
    initialState
);
