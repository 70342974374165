import { createAsyncActions, runAsyncWorkflow } from '../lib/reduxHelpers';

import { ReadAlouds } from '../api';

export const [
    fetchReadAlouds,
    receiveReadAlouds,
    fetchReadAloudsError,
] = createAsyncActions(
    'READ_ALOUDS',
    () => { },
    ({ ok, data, metadata }) => ({ ok, readAlouds: data, metadata })
);

export const [
    fetchMoreReadAlouds,
    receiveMoreReadAlouds,
    fetchMoreReadAloudsError,
] = createAsyncActions(
    'MORE_READ_ALOUDS',
    ({ page, perPage }) => ({ page, perPage }),
    ({ data, metadata }) => ({ readAlouds: data, metadata })
);

export const fetchReadAloudsWorkflow = (appliedFilters, page, perPage) => {
    const fetchingMore = page && page > 1;
    const fetchPromise = ReadAlouds.fetch(appliedFilters, page, perPage);

    return runAsyncWorkflow(
        {
            fetch: fetchingMore ? fetchMoreReadAlouds : fetchReadAlouds,
            receive: fetchingMore ? receiveMoreReadAlouds : receiveReadAlouds,
            error: fetchingMore ? fetchMoreReadAloudsError : fetchReadAloudsError,
        },
        fetchPromise
    );
};

export const [
    fetchReadAloudFilters,
    receiveReadAloudFilters,
    fetchReadAloudFiltersError,
] = createAsyncActions(
    'READ_ALOUD_FILTERS',
    () => ({}),
    ({ data }) => ({ filters: data })
);

export const fetchReadAloudFiltersWorkflow = () => {
    const fetchPromise = ReadAlouds.fetchAllFilters();

    return runAsyncWorkflow(
        {
            fetch: fetchReadAloudFilters,
            receive: receiveReadAloudFilters,
            error: fetchReadAloudFiltersError,
        },
        fetchPromise
    );
};

export const [
    videoReadCount,
    receiveVideoReadCount,
    videoReadCountError,
] = createAsyncActions('VIDEO_READ_COUNT');

export const videoReadCountWorkflow = videoId => {
    const videoReadCountPromise = ReadAlouds.videoReadCount(videoId);

    return runAsyncWorkflow(
        {
            fetch: videoReadCount,
            receive: receiveVideoReadCount,
            error: videoReadCountError,
        },
        videoReadCountPromise
    );
};

export const [
    favouriteVideo,
    receiveFavouriteVideo,
    favouriteVideoError,
] = createAsyncActions(
    'FAV_VIDEOS',
    videoId => videoId,
    ({ ok, videoId }) => ({ ok, videoId })
);

export const favouriteReadAloudsWorkflow = videoId => {
    const favVideoPromise = ReadAlouds.toggleFavourite(videoId);

    return runAsyncWorkflow(
        {
            fetch: favouriteVideo,
            receive: receiveFavouriteVideo,
            error: favouriteVideoError,
        },
        favVideoPromise,
        { videoId }
    );
};

export const [
    fetchFavouriteVideos,
    receiveFetchFavouriteVideos,
    fetchFavouriteVideosError,
] = createAsyncActions(
    'FETCH_FAV_VIDEOS',
    () => { },
    ({ ok, data, metadata }) => ({ ok, favVideos: data, metadata })
);

export const fetchFavouriteReadAloudsWorkflow = () => {
    const fetchFavouritePromise = ReadAlouds.fetchFavouriteVideos();

    return runAsyncWorkflow(
        {
            fetch: fetchFavouriteVideos,
            receive: receiveFetchFavouriteVideos,
            error: fetchFavouriteVideosError,
        },
        fetchFavouritePromise
    );
};

export const [
    fetchVideoDetail,
    receivefetchVideoDetail,
    fetchVideoDetailError,
] = createAsyncActions(
    'FETCH_VIDEO_DETAIL',
    () => { },
    ({ ok, data }) => ({ ok, videoDetail: data })
);

export const fetchVideosDetailWorkflow = slug => {
    const fetchVideoDetailPromise = ReadAlouds.fetchVideoDetail(slug);

    return runAsyncWorkflow(
        {
            fetch: fetchVideoDetail,
            receive: receivefetchVideoDetail,
            error: fetchVideoDetailError,
        },
        fetchVideoDetailPromise
    );
};

//favourite handling for similar readalouds
export const [
    favouriteSimilarReadAloud,
    receivefavouriteSimilarReadAloud,
    favouriteSimilarReadAloudError,
] = createAsyncActions(
    'FAVOURITE_SIMILAR_READALOUD',
    ({ videoId }) => ({ videoId }),
    ({ ok, videoId }) => ({ ok, videoId })
);

export const favouriteSimilarReadAloudWorkflow = videoId => {
    const favSimilarVideoPromise = ReadAlouds.toggleFavourite(videoId);

    return runAsyncWorkflow(
        {
            fetch: favouriteSimilarReadAloud,
            receive: receivefavouriteSimilarReadAloud,
            error: favouriteSimilarReadAloudError,
        },
        favSimilarVideoPromise,
        { videoId }
    );
};
