import React, { Component } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { translate } from 'react-polyglot';
import ReadingListCardContainer from '../ReadingListCardContainer';
import Grid from '../Grid';
import {
    deleteReadingListWorkflow,
    fetchMyReadingListWorkflow,
    manageReadingListActionAsync,
} from '../../redux/readingListsActions';

class MyReadingList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            bookSlug: window.location.href.split('/').slice(4),
        };
    }

    componentDidMount() {
        this.props.fetchMyReadingListWorkflow();
    }

    render() {
        const baseClassName = 'pb-my-reading-list';
        const {
            parentClassName,
            myReadingLists,
            myLanguage
        } = this.props;

        const classes = {
            [baseClassName]: true,
            [parentClassName]: parentClassName,
        };

        return (
            <div className={classNames(classes)}>
                <div className="__reading-book-item-container">
                    <Grid variant={window.screen.width <= 767 ? "3up" : "4up"}>
                        {myReadingLists &&
                            myReadingLists.map(myReadingListsItem => {
                                return (
                                    <div key={myReadingListsItem.id}>
                                        <ReadingListCardContainer
                                            isMyReadingList
                                            myLanguage={myLanguage}
                                            key={myReadingListsItem.id}
                                            readingListItem={myReadingListsItem}
                                            readingListsTitle="my-reading-lists-title"
                                        />
                                    </div>
                                );
                            })}
                    </Grid>
                </div>
            </div>
        );
    }
}

MyReadingList.propTypes = {
    parentClassName: PropTypes.string,
    deleteReadingListWorkflow: PropTypes.func.isRequired,
    fetchMyReadingListWorkflow: PropTypes.func.isRequired,
};

const mapStateToProps = ({ viewport, readingLists }) => {
    return {
        viewport,
        myReadingLists: readingLists.myReadingLists.allReadingLists,
    };
};

const mapDispatchToProps = {
    deleteReadingListWorkflow,
    fetchMyReadingListWorkflow,
    manageReadingListActionAsync,
};

export default translate()(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(MyReadingList)
);