import React from 'react';
import CollapsibleSection from '../CollapsibleSection';
import Link from '../Link';
import List from '../List';
import Sizer from '../Sizer';
import { links } from '../../lib/constants';


export default ({ translations, versionCount, languageCount, offline, t }) => {
    let el = null;

    if (!offline && (translations && translations.length)) {
        el = (
            
            <CollapsibleSection
                title={
                    translations && translations.length > 1
                        ?
                        `${t('Book.available-in-version', versionCount)} ${t('Book.available-in-language', 2)}`
                        :
                        `${t('Book.available-in-version', 1)} ${t('Book.available-in-language', 1)}`
                }
                bookLandingPage
            >
                <Sizer maxHeight="l" scrollY>
                    <List noPadding>
                        {translations.map((l, i) => {
                            return (
                                <Link
                                    translationList
                                    isInternal={true}
                                    key={i}
                                    href={links.bookDetails(l.slug)}
                                >
                                    {i + 1}. {l.title} ({l.language} - L
                                    {l.level}) {l.isOriginal ? `- ${t('book.original')}` : null}
                                </Link>
                            );
                        })}
                    </List>
                </Sizer>
            </CollapsibleSection>
        );
    }

    return el;
};
