import u from 'updeep';
import { handleActions } from 'redux-actions';
import * as actions from './bookCommentAction';

const initialState = {
    mainComments: [],
    isMainCommentLoading: false,
    isSubmittedCommentLoading: false,
    isMoreCommentButtonActive: true,
    totalComments: 0,

    replyComments: [],
    isReplyCommentLoading: false,
    isSubmittedReplyLoading: false,
    isMoreReplyButtonActive: false,

    isReportLoading: false,
    isDeleteReportedCommentloading: false,
};

export default handleActions(
    {
        //main comments
        [actions.mainComment]: (state, action) =>
            u(
                {
                    isMainCommentLoading: true,
                },
                state
            ),
        [actions.ReceiveMainComment]: (state, action) => {
            const totalComments = action.payload.meta.total_comments;

            if (
                action.payload.pageNo === 1 &&
                action.payload.data.length < totalComments
            ) {
                return u(
                    {
                        isMainCommentLoading: false,
                        mainComments: [...action.payload.data],
                        totalComments: totalComments,
                    },
                    state
                );
            } else if (
                action.payload.pageNo === 1 &&
                action.payload.data.length === totalComments
            ) {
                return u(
                    {
                        isMainCommentLoading: false,
                        mainComments: [...action.payload.data],
                        isMoreCommentButtonActive: false,
                        totalComments: totalComments,
                    },
                    state
                );
            } else if (
                state.mainComments.length + action.payload.data.length <
                totalComments
            ) {
                return u(
                    {
                        isMainCommentLoading: false,
                        mainComments: [
                            ...state.mainComments,
                            ...action.payload.data,
                        ],
                        totalComments: totalComments,
                    },
                    state
                );
            } else if (
                state.mainComments.length + action.payload.data.length ===
                totalComments
            ) {
                return u(
                    {
                        isMainCommentLoading: false,
                        mainComments: [
                            ...state.mainComments,
                            ...action.payload.data,
                        ],
                        isMoreCommentButtonActive: false,
                        totalComments: totalComments,
                    },
                    state
                );
            } else {
                return u(
                    {
                        isMainCommentLoading: false,
                        mainComments: [],
                        isMoreCommentButtonActive: false,
                    },
                    state
                );
            }
        },
        [actions.mainCommentError]: (state, action) =>
            u(
                {
                    isMainCommentLoading: false,
                },
                state
            ),

        [actions.postMainComment]: (state, action) =>
            u(
                {
                    isSubmittedCommentLoading: true,
                },
                state
            ),
        [actions.ReceivePostMainComment]: (state, action) => {
            return u(
                {
                    isSubmittedCommentLoading: false,
                    mainComments: [action.payload.data, ...state.mainComments],
                },
                state
            );
        },

        [actions.postMainCommentError]: (state, action) =>
            u(
                {
                    isSubmittedCommentLoading: false,
                },
                state
            ),

        //reply comments

        [actions.replyComment]: (state, action) =>
            u(
                {
                    isReplyCommentLoading: true,
                },
                state
            ),
        [actions.ReceiveReplyComment]: (state, action) => {
            const totalComments = action.payload.meta.total_comments;

            if (
                action.payload.pageNo === 1 &&
                action.payload.data.length < totalComments
            ) {
                return u(
                    {
                        isReplyCommentLoading: false,
                        replyComments: [...action.payload.data],
                        isMoreReplyButtonActive: true,
                    },
                    state
                );
            } else if (
                action.payload.pageNo === 1 &&
                action.payload.data.length === totalComments
            ) {
                return u(
                    {
                        isReplyCommentLoading: false,
                        replyComments: [...action.payload.data],
                    },
                    state
                );
            } else if (
                state.replyComments.length + action.payload.data.length <
                totalComments
            ) {
                return u(
                    {
                        isReplyCommentLoading: false,
                        replyComments: [
                            ...state.replyComments,
                            ...action.payload.data,
                        ],
                        isMoreReplyButtonActive: true,
                    },
                    state
                );
            } else if (
                state.replyComments.length + action.payload.data.length ===
                totalComments
            ) {
                return u(
                    {
                        isReplyCommentLoading: false,
                        replyComments: [
                            ...state.replyComments,
                            ...action.payload.data,
                        ],
                        isMoreReplyButtonActive: false,
                    },
                    state
                );
            } else {
                return u(
                    {
                        isReplyCommentLoading: false,
                        replyComments: [],
                        isMoreReplyButtonActive: false,
                    },
                    state
                );
            }
        },
        [actions.replyCommentError]: (state, action) =>
            u(
                {
                    isReplyCommentLoading: false,
                },
                state
            ),

        [actions.postReplyComment]: (state, action) =>
            u(
                {
                    isSubmittedReplyLoading: true,
                },
                state
            ),
        [actions.ReceivePostReplyComment]: (state, action) => {
            const getCommentIndex = state.mainComments.findIndex(
                (data) => data.id === action.payload.commentId
            );

            return u(
                {
                    isSubmittedReplyLoading: false,
                    replyComments: [
                        action.payload.data,
                        ...state.replyComments,
                    ],
                    mainComments: u.updateIn(
                        [getCommentIndex, 'reply_commments'],
                        state.mainComments[getCommentIndex].reply_commments + 1
                    ),
                },
                state
            );
        },

        [actions.postReplyCommentError]: (state, action) =>
            u(
                {
                    isSubmittedReplyLoading: false,
                },
                state
            ),

        //delete reply

        [actions.deleteReplyComment]: (state, action) =>
            u(
                {
                    isSubmittedReplyLoading: true,
                },
                state
            ),

        [actions.ReceiveDeleteReplyComment]: (state, action) => {
            return u(
                {
                    isSubmittedReplyLoading: false,
                    replyComments: [],
                },
                state
            );
        },

        [actions.deleteReplyCommentError]: (state, action) =>
            u(
                {
                    isSubmittedReplyLoading: false,
                },
                state
            ),

        // like comment
        [actions.postLike]: (state, action) =>
            u(
                {
                    isLikeLoading: true,
                },
                state
            ),
        [actions.ReceivePostLike]: (state, action) => {
            const getCommentIndex = state.mainComments.findIndex(
                (data) => data.id === action.payload.commentId
            );
            const getReplyIndex = state.replyComments.findIndex(
                (data) => data.id === action.payload.commentId
            );
            if (action.payload.source === 'mainComment') {
                return u(
                    {
                        mainComments: u.updateIn(
                            [getCommentIndex, 'likes'],
                            action.payload.data
                        ),
                        isLikeLoading: false,
                    },
                    state
                );
            } else if (action.payload.source === 'reply') {
                return u(
                    {
                        replyComments: u.updateIn(
                            [getReplyIndex, 'likes'],
                            action.payload.data
                        ),
                        isLikeLoading: false,
                    },
                    state
                );
            }
        },

        [actions.postLikeError]: (state, action) =>
            u(
                {
                    isLikeLoading: false,
                },
                state
            ),

        // unlike comment
        [actions.deleteLike]: (state, action) =>
            u(
                {
                    isLikeLoading: true,
                },
                state
            ),
        [actions.ReceiveDeleteLike]: (state, action) => {
            const getCommentIndex = state.mainComments.findIndex(
                (data) => data.id === action.payload.commentId
            );
            const getReplyIndex = state.replyComments.findIndex(
                (data) => data.id === action.payload.commentId
            );
            if (action.payload.source === 'mainComment') {
                return u(
                    {
                        mainComments: u.updateIn(
                            [getCommentIndex, 'likes'],
                            action.payload.data
                        ),
                        isLikeLoading: false,
                    },
                    state
                );
            } else if (action.payload.source === 'reply') {
                return u(
                    {
                        replyComments: u.updateIn(
                            [getReplyIndex, 'likes'],
                            action.payload.data
                        ),
                        isLikeLoading: false,
                    },
                    state
                );
            }
        },

        [actions.deleteLikeError]: (state, action) =>
            u(
                {
                    isLikeLoading: false,
                },
                state
            ),

        // report comment
        [actions.fetchReportComment]: (state, action) =>
            u(
                {
                    isReportLoading: true,
                },
                state
            ),
        [actions.ReceiveReportComment]: (state, action) => {
            const getCommentIndex = state.mainComments.findIndex(
                (data) => data.id === action.payload.commentId
            );
            const getReplyIndex = state.replyComments.findIndex(
                (data) => data.id === action.payload.commentId
            );
            if (getCommentIndex >= 0) {
                return u(
                    {
                        mainComments: u.updateIn(
                            [getCommentIndex, 'report_content'],
                            action.payload.reason
                        ),
                        isReportLoading: false,
                    },
                    state
                );
            } else if (getReplyIndex >= 0) {
                return u(
                    {
                        replyComments: u.updateIn(
                            [getReplyIndex, 'report_content'],
                            action.payload.reason
                        ),
                        isReportLoading: false,
                    },
                    state
                );
            }
        },

        [actions.ErrorReportComment]: (state, action) =>
            u(
                {
                    isReportLoading: false,
                },
                state
            ),

        // delete reported comment
        [actions.fetchReportedCommentDelete]: (state, action) =>
            u(
                {
                    isDeleteReportedCommentloading: true,
                },
                state
            ),
        [actions.ReceiveReportedCommentDelete]: (state, action) => {
            const stateComment = state.mainComments;
            const stateReply = state.replyComments;
            const getCommentData = stateComment.filter(
                (data) => data.id !== action.payload.commentId
            );
            const getReplyData = stateReply.filter(
                (data) => data.id !== action.payload.commentId
            );
            if (state.mainComments.length !== getCommentData.length) {
                return u(
                    {
                        mainComments: getCommentData,
                        isDeleteReportedCommentloading: false,
                    },
                    state
                );
            } else if (state.replyComments.length !== getReplyData.length) {
                return u(
                    {
                        replyComments: getReplyData,
                        isDeleteReportedCommentloading: false,
                    },
                    state
                );
            }
        },

        [actions.ErrorReportedCommentDelete]: (state, action) =>
            u(
                {
                    isDeleteReportedCommentloading: false,
                },
                state
            ),

        //empty comments
        [actions.ReceiveEmptyComments]: (state, action) =>
            u(
                {
                    isMoreCommentButtonActive: false,
                },
                initialState
            ),
    },
    initialState
);
