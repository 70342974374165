import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { translate } from 'react-polyglot';
import { connect } from 'react-redux';
import './VideoCard.scss';
import Block from '../Block';
import videoPlaceholder from '../../assets/placeholder.jpg';
import {
    favouriteVideoWorkflow,
    fetchVideosDetailWorkflow,
    favouriteSimilarVideoWorkflow,
} from '../../redux/videoActions';
import { favouriteSimilarReadAloudWorkflow } from '../../redux/readAloudsActions';
import { favouriteVideoWorkflowHome } from '../../redux/homeActions';
import Thumbnail from './components/Thumbnail';
import VideoInfo from './components/VideoInfo';

const mapDispatchToProps = {
    favouriteVideoWorkflow,
    favouriteVideoWorkflowHome,
    fetchVideosDetailWorkflow,
    favouriteSimilarVideoWorkflow,
    favouriteSimilarReadAloudWorkflow,
};

const mapStateToProps = (state, ownProps) => {
    let requiredVideos;
    if (ownProps.isFromHome) {
        requiredVideos = state.home.videos;
    } else {
        requiredVideos = state.videos.videos.length
            ? state.videos.videos
            : state.videos.favouriteVideos;
    }

    const associatedindex = requiredVideos.findIndex(
        (video) => video.id === ownProps.videoID
    );
    let associatedVideo = null;
    if (associatedindex >= 0) {
        associatedVideo = requiredVideos[associatedindex];
    }
    return {
        isFavourite: associatedVideo
            ? associatedVideo.is_favourite
            : ownProps.isFavourite,
        isFavouriting: associatedVideo ? associatedVideo.isFavouriting : null,
    };
};

const VideoCard = ({
    parentClassName,
    videoReads,
    videoTitle,
    videoID,
    isFavourite,
    videoSlug,
    videoThumbnail,
    videoLikes,
    videoTime,
    videoTitleGTM,
    videoThumbnailGTM,
    isSimilarVideo,
    isFavouriting,
    myLanguage,
    sourceData,
    isSimilarReadAloud,
    isLoggedIn,
    favouriteVideoWorkflow,
    favouriteSimilarVideoWorkflow,
    favouriteSimilarReadAloudWorkflow,
}) => {
    const baseClassName = 'pb-video-card';

    const classes = {
        [baseClassName]: true,
        [parentClassName]: parentClassName,
    };

    const videoPath =
        sourceData === 'isSimilarReadAloud'
            ? `readaloudsdetail/${videoSlug}`
            : `videodetail/${videoSlug}`;

    const similarVideoPath = `${videoSlug}`;

    return (
        <div className={classNames(classes)}>
            <Block
                noHorizontalPadding
                noVerticalPadding
                background="transparent"
            >
                <div className="__videocard-section">
                    <Thumbnail
                        isLoggedIn={isLoggedIn}
                        videoThumbnailGTM={videoThumbnailGTM}
                        isSimilarVideo={isSimilarVideo}
                        isSimilarReadAloud={isSimilarReadAloud}
                        similarVideoPath={similarVideoPath}
                        videoPath={videoPath}
                        videoThumbnail={videoThumbnail}
                        videoPlaceholder={videoPlaceholder}
                        videoTime={videoTime}
                    />
                    <VideoInfo
                        baseClassName={baseClassName}
                        videoReads={videoReads}
                        isSimilarVideo={isSimilarVideo}
                        similarVideoPath={similarVideoPath}
                        isLoggedIn={isLoggedIn}
                        videoPath={videoPath}
                        videoTitleGTM={videoTitleGTM}
                        myLanguage={myLanguage}
                        videoTitle={videoTitle}
                    />
                </div>
            </Block>
        </div>
    );
};

VideoCard.propTypes = {
    parentClassName: PropTypes.string,
    videoReads: PropTypes.number.isRequired,
    videoTitle: PropTypes.string.isRequired,
    videoID: PropTypes.string.isRequired,
    isFavourite: PropTypes.bool,
    videoSlug: PropTypes.string.isRequired,
    videoThumbnail: PropTypes.string,
    videoLikes: PropTypes.number,
    videoTime: PropTypes.string,
    videoTitleGTM: PropTypes.string,
    videoThumbnailGTM: PropTypes.string,
    isSimilarVideo: PropTypes.bool,
    isFavouriting: PropTypes.bool,
    myLanguage: PropTypes.string,
    sourceData: PropTypes.string,
    isSimilarReadAloud: PropTypes.bool,
    isLoggedIn: PropTypes.bool.isRequired,
    favouriteVideoWorkflow: PropTypes.func.isRequired,
    favouriteVideoWorkflowHome: PropTypes.func,
    favouriteSimilarVideoWorkflow: PropTypes.func,
    favouriteSimilarReadAloudWorkflow: PropTypes.func,
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(translate()(VideoCard));
