import React, { Component } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import './Level.scss';

class Level extends Component {

    render() {
        const baseClassName = 'pb-level';
        const { 
            parentClassName, 
            level, 
            title, 
            storyLevelColour, 
            isLandingBookPage 
        } = this.props;

        const classes = {
            [baseClassName]: true,
            [`${baseClassName}--book-page`]:isLandingBookPage,
            [`${baseClassName}--level-${level}`]: level,
            [parentClassName]: parentClassName,
        };

        return (
            <p style={{backgroundColor:`${storyLevelColour}`}} className={classNames(classes)}>
                <span className={`${baseClassName}__level`}>{title}</span>
            </p>
        );
    }
}

Level.propTypes = {
    parentClassName: PropTypes.string,
};

export default Level;
