import React, { Component } from 'react';
import { connect } from 'react-redux';
import Profile from '../Profile';
import {
    fetchPublisherBooksFiltersWorkflow,
    fetchPublisherProfileWorkflow,
    fetchProfileWorkflow,
    loadMoreWorkflow,
} from '../../redux/profileActions';
import LoaderBlock from '../LoaderBlock';
import Block from '../Block';
import queryString from 'query-string';
import { sortOptions } from '../../lib/constants';
import { replace } from 'react-router-redux';
import { cloneDeep } from 'lodash';
import { translate } from 'react-polyglot';
import withRouter from "../../util/withRouter"


const defaultFilters = {
    category: [],
    publisher: [],
    role: [],
    style: [],
    language: [],
    sort: sortOptions.relevance,
    tags: [],
    query: '',
};

const mapStateToProps = ({ profile, viewport, user }) => ({
    isFetchingPublisherProfile: profile.isFetchingPublisherProfile,
    isFetchingFilters: profile.isFetchingFilters,
    isFetchingProfile: profile.isFetchingProfile,
    isFetchingMore: profile.isFetchingMore,
    profile: profile.profile,
    filters: profile.filters,
    hitsCount: profile.profile.hitsCount,
    viewport,
    siteUser: user,
});

const mapDispatchToProps = {
    fetchPublisherBooksFiltersWorkflow,
    fetchPublisherProfileWorkflow,
    fetchProfileWorkflow,
    loadMoreWorkflow,
    replace
};

@translate()
@connect(
    mapStateToProps,
    mapDispatchToProps
)
class ProfileContainer extends Component {

    componentDidMount() {
        const {
            profileType,
            fetchPublisherBooksFiltersWorkflow,
            fetchPublisherProfileWorkflow,
            fetchProfileWorkflow
        } = this.props

        if (profileType === "publisher") {
            fetchPublisherBooksFiltersWorkflow().then(response => {
                let filters = this.getAppliedFilters();
                filters.language = response.data.current_language;
                const searchParams = queryString.stringify(filters);
                this.props.navigate({ search: `?${searchParams}` }, { replace: true });
                fetchPublisherProfileWorkflow(filters, this.props.params.slug, profileType);
            });
        } else {
            fetchProfileWorkflow(
                this.props.params.slug,
                profileType
            )
        }

    }

    componentWillReceiveProps(nextProps) {
        if (this.props.params.slug !== nextProps.params.slug) {
            this.props.fetchProfileWorkflow(
                nextProps.params.slug,
                nextProps.profileType
            );
        }
    }

    getAppliedFilters = (props = this.props) => {
        const parsedFilters = queryString.parse(props.location.search);
        Object.entries(parsedFilters).forEach(([k, v]) => {
            if (Array.isArray(defaultFilters[k]) && !Array.isArray(v)) {
                parsedFilters[k] = [v];
            }
        });

        return {
            ...defaultFilters,
            ...parsedFilters,
        };
    };


    applyFilter = (type, value) => {
        const appliedFilters = cloneDeep(this.getAppliedFilters());
        const filterIsArray = Array.isArray(appliedFilters[type]);

        if (filterIsArray) {
            appliedFilters[type].push(value);
        } else {
            appliedFilters[type] = value;
        }

        const searchParams = queryString.stringify(appliedFilters);
        this.props.navigate({ search: `?${searchParams}` }, { replace: true });

        this.props.fetchPublisherProfileWorkflow(appliedFilters, this.props.params.slug);
    };

    removeFilter = (type, value) => {
        const appliedFilters = cloneDeep(this.getAppliedFilters());

        if (!appliedFilters[type]) {
            return;
        }

        if (Array.isArray(appliedFilters[type])) {
            const index = appliedFilters[type].indexOf(value);
            if (index !== -1) {
                appliedFilters[type].splice(index, 1);
            }
        } else {
            delete appliedFilters[type];
        }

        const searchParams = queryString.stringify(appliedFilters);
        this.props.navigate({ search: `?${searchParams}` }, { replace: true });

        this.props.fetchPublisherProfileWorkflow(appliedFilters, this.props.params.slug);
    };


    render() {
        const {
            isFetchingProfile,
            profile,
            profileType,
            viewport,
            loadMoreWorkflow,
            isFetchingMore,
            siteUser,
            filters,
            hitsCount,
            isFetchingPublisherProfile
        } = this.props;

        if (isFetchingProfile || !profile[profileType]) {
            return <LoaderBlock />;
        }

        const appliedFilters = this.getAppliedFilters();


        if (!isFetchingProfile && !profile) {
            return (
                <Block>
                    <h1>Error fetching profile</h1>
                </Block>
            );
        }
        const isCurrentUser =
            siteUser.isLoggedIn &&
            siteUser.profile &&
            siteUser.profile.email === profile[profileType].email;

        return (
            <Profile
                isFetchingPublisherProfile={isFetchingPublisherProfile}
                type={profileType}
                profile={profile[profileType]}
                viewport={viewport}
                loadMore={loadMoreWorkflow}
                isFetchingMore={isFetchingMore}
                isCurrentUser={isCurrentUser}
                appliedFilters={appliedFilters}
                applyFilter={this.applyFilter}
                removeFilter={this.removeFilter}
                filters={filters}
                hitsCount={hitsCount}
            />
        );
    }
}
ProfileContainer.propTypes = {};
export default withRouter(ProfileContainer);
