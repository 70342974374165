import React from 'react';
import PropTypes from 'prop-types';
import Block from '../../Block';
import ContentStyler from '../../ContentStyler';
import Link from '../../Link';
import rightArrow from '../../../assets/right-arrow.svg';
import { sectionClicked } from '../../../lib/constants';
import BooksGrid from './BooksGrid';
import '../Home.scss'

const BookSuggestions = ({
    t,
    books,
    viewport,
    isRightToLeftDirection,
    title,
    subTitle,
    action,
}) => {
    if (!books || books.length === 0) {
        return null;
    }

    return (
        <div>
            <Block background="transparent">
                <ContentStyler>
                    {books.length !== 0 ? (
                        <div className="__homepage-recommended-books">
                            {viewport.large ? (
                                <h1 className="font_home_tab">{title}</h1>
                            ) : (
                                <div className="home">{title}</div>
                            )}
                            {viewport.large ? (
                                <Link
                                    label={subTitle}
                                    href={action}
                                    isInternal
                                    paddingTopHome
                                    dataGTM="see-more-books"
                                >
                                    {subTitle}
                                    <img
                                        className={
                                            isRightToLeftDirection
                                                ? '__left-arrow'
                                                : '__right-arrow'
                                        }
                                        src={rightArrow}
                                        alt=""
                                        data-gtm="see-more-books"
                                    />
                                </Link>
                            ) : null}
                        </div>
                    ) : null}
                </ContentStyler>
            </Block>
            <Block
                noHorizontalPadding
                noVerticalPadding
                background="transparent"
            >
                <BooksGrid
                    horizontal
                    books={books}
                    viewport={viewport}
                    t={t}
                    sectionClicked={sectionClicked.homeRecommendation}
                />
            </Block>
            {viewport.large ? null : (
                <div className="center-align">
                    <Link
                        label={subTitle}
                        href={action}
                        isInternal
                        paddingTopHome
                        dataGTM="see-more-books"
                    >
                        {subTitle}
                        <img
                            className={
                                isRightToLeftDirection
                                    ? '__left-arrow'
                                    : '__right-arrow'
                            }
                            src={rightArrow}
                            alt=""
                            data-gtm="see-more-books"
                        />
                    </Link>
                </div>
            )}
        </div>
    );
};

BookSuggestions.propTypes = {
    t: PropTypes.func.isRequired,
    books: PropTypes.arrayOf(PropTypes.object).isRequired,
    viewport: PropTypes.shape({
        large: PropTypes.bool.isRequired,
    }).isRequired,
    isRightToLeftDirection: PropTypes.bool.isRequired,
    title: PropTypes.string.isRequired,
    subTitle: PropTypes.string.isRequired,
    action: PropTypes.string.isRequired,
};

export default BookSuggestions;
