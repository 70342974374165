import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { TransitionGroup, CSSTransition } from 'react-transition-group';
import onClickOutside from 'react-onclickoutside';
import classNames from 'classnames';
import { connect } from 'react-redux';
import {
    toggleMenuState,
    closeMenu,
    openMenu,
} from '../../redux/menuToggleActions';
import './Dropdown.scss';

const mapStateToProps = state => ({
    isDropdownOpen: state.menuToggle.ismenuOpen,
});

const mapDispatchToProps = {
    toggleMenuState,
    closeMenu,
    openMenu,
};

@connect(
    mapStateToProps,
    mapDispatchToProps
)
@onClickOutside
class Dropdown extends Component {
    static defaultProps = {
        align: 'right',
        minWidth: 'm',
        menu: false,
    };

    constructor(props) {
        super(props);
        this.state = {
            isDropdownOpen: false,
        };

        this.handleClickOutside = this.handleClickOutside.bind(this);
        this.openDropdown = this.openDropdown.bind(this);
        this.closeDropdown = this.closeDropdown.bind(this);
        this.toggleDropdown = this.toggleDropdown.bind(this);
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.disabled) {
            if (this.props.menu) {
                this.props.closeMenu();
            } else {
                this.setState({
                    isDropdownOpen: false,
                });
            }
        }
    }

    toggleDropdown(e) {
        e.preventDefault();

        if (this.props.disabled) {
            return;
        }

        const isDropdownOpen = this.props.menu
            ? this.props.isDropdownOpen
            : this.state.isDropdownOpen;

        if (isDropdownOpen) {
            this.closeDropdown();
        } else {
            this.openDropdown();
        }
    }

    openDropdown() {
        if (this.props.menu) {
            this.props.openMenu();
        } else {
            this.setState({
                isDropdownOpen: true,
            });
        }
        if (this.props.onClose) {
            this.props.onClose();
        }
    }

    closeDropdown() {
        if (this.props.menu) {
            this.props.closeMenu();
        } else {
            this.setState({
                isDropdownOpen: false,
            });
        }
        if (this.props.onClose) {
            this.props.onClose();
        }
    }

    handleClickOutside = e => {
        const isDropdownOpen = this.props.menu
            ? this.props.isDropdownOpen
            : this.state.isDropdownOpen;
        if (isDropdownOpen) {
            this.closeDropdown();
        }
    };

    render() {
        const baseClassName = 'pb-dropdown';
        const baseTransitionDuration = 100;
        const {
            children,
            parentClassName,
            toggleClassName,
            align,
            toggleEl,
            noPadding,
            disabled,
            minWidth,
            translation,
            menu,
            bookFilter,
            readAloudShare,
            bookTabletMode
        } = this.props;
        
        const isRightToLeftDirection = localStorage.getItem("locale") === "ar" || localStorage.getItem('locale') === 'ur';

        let dropdownListEl;
        const childerenClasses = {
            [`${baseClassName}--menu`]: menu,
            [isRightToLeftDirection ? `${baseClassName}__contents-arabic` : `${baseClassName}__contents`]: true,
            [isRightToLeftDirection ? `${baseClassName}__download-rtl` : `${baseClassName}__download`]:bookTabletMode
        };

        const isDropdownOpen = this.props.menu
            ? this.props.isDropdownOpen
            : this.state.isDropdownOpen;

        if (isDropdownOpen) {
            dropdownListEl = (
                <CSSTransition
                    key={`pb-dropdown__content`}
                    classNames={isRightToLeftDirection ? `${baseClassName}__contents-arabic` : `${baseClassName}__contents`}
                    timeout={baseTransitionDuration}
                >
                    <div className={classNames(childerenClasses)}>
                        {children}
                    </div>
                </CSSTransition>
            );
        }

        const classes = {
            [baseClassName]: true,
            [`${baseClassName}--open`]: isDropdownOpen,
            [parentClassName]: parentClassName,
            [`${baseClassName}--align-${align}`]: align,
            [`${baseClassName}--min-width-${minWidth}`]: minWidth,
            [`${baseClassName}--no-padding`]: noPadding,
            [`${baseClassName}--translation`]: translation,
            [`${baseClassName}--book-filter`]: bookFilter,
            [`${baseClassName}--read-aloud`]: readAloudShare,
        };

        const toggleElClassNames = {
            [`${baseClassName}__toggle`]: true,
            [toggleClassName]: toggleClassName,
        };

        return (
            <div className={classNames(classes)}>
                <div
                    className={classNames(toggleElClassNames)}
                    onClick={this.toggleDropdown}
                >
                    {toggleEl && React.cloneElement(toggleEl, { disabled })}
                </div>
                <TransitionGroup>{dropdownListEl}</TransitionGroup>
            </div>
        );
    }
}

Dropdown.propTypes = {
    name: PropTypes.string,
    children: PropTypes.node.isRequired,
    parentClassName: PropTypes.string,
    toggleClassName: PropTypes.string,
    noPadding: PropTypes.bool,
    /* Default minWidth is 'm' */
    minWidth: PropTypes.oneOf(['m', 'l', 'xl', 'xxl', 'auto']),
    /* Default align is 'right' */
    align: PropTypes.oneOf(['right', 'left']),
    disabled: PropTypes.bool,
    menu: PropTypes.bool,
};

export default Dropdown;
