import React, { Component } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import './TitleImage.scss';

class TitleImage extends Component {
    static defaultProps = {
        reverse: false,
        mobileMenu: false,
        avatar: null,
    };

    render() {
        const baseClassName = 'pb-title-image';
        const {
            parentClassName,
            title,
            svgName,
            reverse,
            mobileMenu,
            avatar,
            readingList,
            dataGTM,
            className,
            mobileMenuSearch,
            contentSpecificSearch
        } = this.props;

        const isRightToLeftDirection = localStorage.getItem("locale") === "ar" || localStorage.getItem('locale') === 'ur';

        const classes = {
            [baseClassName]: true,
            [parentClassName]: parentClassName,
            [`${baseClassName}--reading-list`]: readingList,
            [ isRightToLeftDirection ? `${baseClassName}--container-rtl` : `${baseClassName}--container`]: !reverse,
            [`${baseClassName}--reverse`]: reverse,
            [`${baseClassName}--mobile-menu`]: mobileMenu,
            [`${baseClassName}--bold`]: reverse && !avatar,
            [`${baseClassName}--mobile-menu-search`]: mobileMenuSearch,
            [isRightToLeftDirection ? `${baseClassName}--content-specific-search-rtl` : `${baseClassName}--content-specific-search`]: contentSpecificSearch
        };

        let imgElement = avatar ? (
            avatar
        ) : (
                <img
                    className={className}
                    src={svgName}
                    alt=""
                    data-gtm={dataGTM}
                />
            );

        imgElement = !avatar && mobileMenu ? null : imgElement;

        return (
            <div className={classNames(classes)} data-gtm={dataGTM}>
                {title}
                {imgElement}
            </div>
        );
    }
}

TitleImage.propTypes = {
    parentClassName: PropTypes.string,
    title: PropTypes.string,
    svgName: PropTypes.string,
    reverse: PropTypes.bool,
    mobileMenu: PropTypes.bool,
};

export default TitleImage;
