import React, { Component } from 'react';
import classNames from 'classnames';
import { translate } from 'react-polyglot';
import PropTypes from 'prop-types';
import Caret from '../Caret';
import List from '../List';
import Link from '../Link';
import Dropdown from '../Dropdown';
import TitleImage from '../TitleImage';
import FavoriteRR from '../../assets/My_Favorites.svg';
import userProfile from '../../assets/Profile.svg';
import bookshelf from '../../assets/Offline_Library.svg';
import signout from '../../assets/Logout.svg';
import createStory from '../../assets/Book_Upload.svg';
import gearIcon from '../../assets/Dashboards.svg';
import { links } from '../../lib/constants';
import { closeMenu } from '../../redux/menuToggleActions';
import { connect } from 'react-redux';
import './UserInformation.scss';

const mapDispatchToProps = {
    closeMenu,
};

@connect(null, mapDispatchToProps)
@translate()
class UserInformation extends Component {
    static defaultProps = {
        shouldHide: false,
        mobileMenu: false,
    };

    render() {
        const baseClassName = 'pb-user-information';
        const {
            parentClassName,
            title,
            avatar,
            caret,
            logoutHandler,
            shouldHide,
            roles,
            userEmail,
            mobileMenu,
            t,
            variant
        } = this.props;

        const isRightToLeftDirection =
            localStorage.getItem('locale') === 'ar' ||
            localStorage.getItem('locale') === 'ur';

        const classes = {
            [`${baseClassName}--should-hide`]: shouldHide,
            [baseClassName]: true,
            [parentClassName]: parentClassName,
        };

        const menuClasses = {
            [`${baseClassName}--menu-mobile`]: mobileMenu,
            [`${baseClassName}--menu`]: true,
            [parentClassName]: parentClassName,
        };
        let caretEl = caret ? <Caret direction={caret} variant={variant} /> : null;
        const userInfoHeaderDropDown = (
            <div className={classNames(classes)}>
                <div className={`${baseClassName}--child-elements`}>
                    {title}
                </div>
                <div className={`${baseClassName}--child-elements`}>
                    {avatar}
                </div>
                <div className={`${baseClassName}--child-elements`}>
                    {caretEl}
                </div>
            </div>
        );

        const menuCloseHandler = () => {
            this.props.closeMenu();
        };

        const userInfoHeaderExpanded = mobileMenu ? null : (
            <div className={classNames(menuClasses)} onClick={menuCloseHandler}>
                <div className={`${baseClassName}--menu-title`}>{title}</div>
                <div
                    className={
                        isRightToLeftDirection
                            ? `${baseClassName}--menu-avatar-arabic`
                            : `${baseClassName}--menu-avatar`
                    }
                >
                    {avatar}
                </div>
                <div className={`${baseClassName}--menu-caret`}>
                    <Caret direction={caret} variant={'white'} />
                </div>
            </div>
        );

        const contentManagerEls =
            roles.includes('content_manager') ||
            roles.includes('sub_admin') ||
            roles.includes('admin')
                ? [
                      window.screen.width <= 768 
                      ? null :
                        <Link
                            key="upload-pdf"
                            fullWidth
                            href={links.upload()}
                            noPadding
                            dataGTM={userEmail + '-upload-pdf'}
                        >
                            <TitleImage
                                className={`${baseClassName}--menu-icon`}
                                svgName={createStory}
                                title={t('Illustration.upload-pdf')}
                                mobileMenu={mobileMenu}
                                dataGTM={userEmail + '-upload-pdf'}
                            />
                            {mobileMenu ? null : (
                                <div
                                    className={`${baseClassName}--separator`}
                                    data-gtm={userEmail + '-upload-pdf'}
                                />
                            )}
                      </Link>,
                      <Link
                          key="dasboard"
                          fullWidth
                          href={links.dashboard()}
                          noPadding
                          dataGTM={userEmail + '-content-manager-dashboard'}
                      >
                          <TitleImage
                              className={`${baseClassName}--menu-icon`}
                              svgName={gearIcon}
                              title={t('global.content-manager-dashboard', 1)}
                              mobileMenu={mobileMenu}
                              dataGTM={userEmail + '-content-manager-dashboard'}
                          />
                          {mobileMenu ? null : (
                              <div
                                  className={`${baseClassName}--separator`}
                                  data-gtm={
                                      userEmail + '-content-manager-dashboard'
                                  }
                              />
                          )}
                      </Link>,
                      roles.includes('sub_admin') || roles.includes('admin') ? (
                          <Link
                              key="data-dashboard"
                              fullWidth
                              href={links.dataDashboard()}
                              noPadding
                              dataGTM={userEmail + '-data-dashboard'}
                          >
                              <TitleImage
                                  className={`${baseClassName}--menu-icon`}
                                  svgName={gearIcon}
                                  title={t('global.data-dashboard', 1)}
                                  mobileMenu={mobileMenu}
                                  dataGTM={userEmail + '-data-dashboard'}
                              />
                              {mobileMenu ? null : (
                                  <div
                                      className={`${baseClassName}--separator`}
                                      data-gtm={userEmail + '-data-dashboard'}
                                  />
                              )}
                          </Link>
                      ) : null,
                  ]
                : null;

        let subAdminEls = [];
        if (roles.includes('content_manager') && roles.includes('sub_admin')) {
            subAdminEls = [
                <Link
                    key="dasboard"
                    fullWidth
                    href={links.dashboard()}
                    noPadding
                    dataGTM={userEmail + '-content-manager-dashboard'}
                >
                    <TitleImage
                        className={`${baseClassName}--menu-icon`}
                        svgName={gearIcon}
                        title={t('global.content-manager-dashboard', 1)}
                        mobileMenu={mobileMenu}
                        dataGTM={userEmail + '-content-manager-dashboard'}
                    />
                    {mobileMenu ? null : (
                        <div
                            className={`${baseClassName}--separator`}
                            data-gtm={userEmail + '-content-manager-dashboard'}
                        />
                    )}
                </Link>,
            ];
        } else if (roles.includes('sub_admin')) {
            subAdminEls = [
                <Link
                    key="dasboard"
                    fullWidth
                    href={links.dashboard()}
                    noPadding
                    dataGTM={userEmail + '-content-manager-dashboard'}
                >
                    <TitleImage
                        className={`${baseClassName}--menu-icon`}
                        svgName={gearIcon}
                        title={t('global.content-manager-dashboard', 1)}
                        mobileMenu={mobileMenu}
                        dataGTM={userEmail + '-content-manager-dashboard'}
                    />
                    {mobileMenu ? null : (
                        <div
                            className={`${baseClassName}--separator`}
                            data-gtm={userEmail + '-content-manager-dashboard'}
                        />
                    )}
                </Link>,
            ];
        }

        const mobileMenuheader = mobileMenu ? (
            <div>
                <TitleImage
                    title={title}
                    mobileMenu={mobileMenu}
                    avatar={avatar}
                    reverse
                />
                <div
                    className={
                        isRightToLeftDirection
                            ? `${baseClassName}--padding-right`
                            : `${baseClassName}--padding-left`
                    }
                >
                    <div className={`${baseClassName}--separator-menu`} />
                </div>
            </div>
        ) : null;

        const dropDownHeaderEl = mobileMenu
            ? mobileMenuheader
            : userInfoHeaderExpanded;

        const dropDownContent = (
            <List noPadding>
                {dropDownHeaderEl}
                <Link
                    fullWidth
                    href={links.profileDashboard()}
                    noPadding
                    dataGTM={userEmail + '-profile'}
                >
                    <TitleImage
                        className={`${baseClassName}--menu-icon`}
                        svgName={userProfile}
                        title={t('global.profile', 1)}
                        mobileMenu={mobileMenu}
                        dataGTM={userEmail + '-profile'}
                    />
                    {mobileMenu ? null : (
                        <div
                            className={`${baseClassName}--separator`}
                            data-gtm={userEmail}
                        />
                    )}
                </Link>
                {contentManagerEls !== null ? (
                    <div>{contentManagerEls}</div>
                ) : (
                    <div>
                        {subAdminEls}
                    </div>
                )}

                <Link
                    fullWidth
                    href={links.myFavourites()}
                    noPadding
                    dataGTM={userEmail + '-my-favorites'}
                >
                    <TitleImage
                        className={`${baseClassName}--menu-icon`}
                        svgName={FavoriteRR}
                        title={t('global.my-favorites', 1)}
                        mobileMenu={mobileMenu}
                        dataGTM={userEmail + '-my-favorites'}
                    />
                    {mobileMenu ? null : (
                        <div
                            className={`${baseClassName}--separator`}
                            data-gtm={userEmail + '-my-favorites'}
                        />
                    )}
                </Link>
                <Link
                    fullWidth
                    href={links.offlineBooks()}
                    noPadding
                    dataGTM={userEmail + '-my-offline-library'}
                >
                    <TitleImage
                        className={`${baseClassName}--menu-icon`}
                        svgName={bookshelf}
                        title={t('global.my-offline-library', 1)}
                        mobileMenu={mobileMenu}
                        dataGTM={userEmail + '-my-offline-library'}
                    />
                    {mobileMenu ? null : (
                        <div
                            className={`${baseClassName}--separator`}
                            data-gtm={userEmail + '-my-offline-library'}
                        />
                    )}
                </Link>
                <Link
                    fullWidth
                    onClick={logoutHandler}
                    noPadding
                    dataGTM={userEmail + '-sign-out'}
                >
                    <TitleImage
                        className={`${baseClassName}--menu-icon`}
                        svgName={signout}
                        title={t('global.sign-out', 1)}
                        mobileMenu={mobileMenu}
                        dataGTM={userEmail + '-sign-out'}
                    />
                </Link>
                {mobileMenu ? (
                    <div
                        className={
                            isRightToLeftDirection
                                ? `${baseClassName}--padding-right ${baseClassName}--margin-top`
                                : `${baseClassName}--padding-left ${baseClassName}--margin-top`
                        }
                        data-gtm={userEmail + '-sign-out'}
                    >
                        <div
                            className={`${baseClassName}--separator-menu`}
                            data-gtm={userEmail + '-sign-out'}
                        />
                    </div>
                ) : null}
            </List>
        );

        const elements = mobileMenu ? (
            dropDownContent
        ) : (
            <Dropdown align={'right'} menu toggleEl={userInfoHeaderDropDown}>
                {dropDownContent}
            </Dropdown>
        );

        return <div> {elements} </div>;
    }
}

UserInformation.propTypes = {
    parentClassName: PropTypes.string,
    title: PropTypes.string,
    caret: PropTypes.string,
    logoutHandler: PropTypes.func,
    userID: PropTypes.string,
    roles: PropTypes.array,
    mobileMenu: PropTypes.bool,
};

export default UserInformation;
