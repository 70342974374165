import React, { Component } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { translate } from 'react-polyglot';
import { connect } from 'react-redux';
import ReadAloudsDetail from '../ReadAloudsDetail';
import LoaderBlock from '../LoaderBlock';
import NotFoundPage from '../NotFoundPage';
import {
    videoReadCountWorkflow,
    fetchReadAloudsWorkflow,
    fetchVideosDetailWorkflow,
} from '../../redux/readAloudsActions';
import withRouter from "../../util/withRouter"

const mapDispatchToProps = {
    fetchReadAloudsWorkflow,
    fetchVideosDetailWorkflow,
};

const mapStateToProps = ({ readAlouds, viewport, network, user }) => ({
    ...readAlouds,
    videoDetail: readAlouds.videoDetail,
    videoID: readAlouds.videoDetail.id,
    videoReadsCount: readAlouds.videoDetail.readsCount,
    videoLikesCount: readAlouds.videoDetail.likesCount,
    isFavourite: readAlouds.videoDetail.is_favourite,
    videoUrl: readAlouds.videoDetail.materialUrl,
    online: network.online,
    isLoggedIn: user.isLoggedIn,
    viewport,
});

@translate()
@connect(
    mapStateToProps,
    mapDispatchToProps
)
class ReadAloudsDetailContainer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            videoSlug: this.props.params.slug,
        };
    }

    componentDidMount() {
        this.props.fetchReadAloudsWorkflow();
        this.props.fetchVideosDetailWorkflow(this.state.videoSlug);
    }

    componentWillReceiveProps(nextProps) {
        if (this.props.params.slug !== nextProps.params.slug) {
            this.props.fetchVideosDetailWorkflow(nextProps.params.slug);
        }
    }

    render() {
        const baseClassName = 'pb-video-detail-container';
        const {
            videoDetail,
            videoUrl,
            videoID,
            isFetchingVideoDetail,
            parentClassName,
            online,
            isLoggedIn,
            viewport,
            videoReadsCount,
            videoLikesCount,
            isFavourite,
            t,
        } = this.props;

        const myLanguage = localStorage.getItem('locale')

        const classes = {
            [baseClassName]: true,
            [parentClassName]: parentClassName,
        };

        if (isFetchingVideoDetail) {
            return <LoaderBlock />
        }

        if (!isFetchingVideoDetail && videoDetail.id === undefined) {
            return <NotFoundPage />
        }

        return (
            <div className={classNames(classes)}>
                <ReadAloudsDetail
                    myLanguage={myLanguage}
                    t={t}
                    videoId={videoID}
                    readsCount={videoReadsCount}
                    likesCount={videoLikesCount}
                    isFavourite={isFavourite}
                    viewport={viewport}
                    online={online}
                    isLoggedIn={isLoggedIn}
                    readCount={videoReadCountWorkflow}
                    videoDetail={videoDetail}
                    videoUrl={videoUrl}
                    logInMsg={t('global.please-log-in', {
                        action: t('global.like', 1),
                        content_type: t('global.story', 1),
                    })}
                    videosVideoCloseGTM="videos-video-close"
                    videosVideoThumbnailGTM="videos-video-thumbnail"
                    videosVideoWatchGTM="videos-video-watch"
                    videosVideoDownloadGTM="videos-video-download"
                    videosVideoFavoriteGTM="videos-video-favorite"
                    videoDisqusGTM="video-disqus-comments"
                />
            </div>
        );
    }
}

ReadAloudsDetailContainer.propTypes = {
    parentClassName: PropTypes.string,
};

export default withRouter(ReadAloudsDetailContainer);

