import React from 'react';

import Logo from '../Logo';
import { links } from '../../lib/constants';

export const FooterLogo = ({ style }) => {
    const baseClassName = 'pb-site-footer';
    const logos = [
        { variant: 'vietnamFooterLogo' },
        { variant: 'logoCentre' },
    ];
    return (
        <>
            <div className={`${baseClassName}${style}`}>
                <div className={`${baseClassName}__row-center`}>
                    {localStorage.getItem('locale') === 'vi' ? (
                        logos.map((logo, index) => (
                            <Logo
                                isVietnamese
                                variant={logo.variant}
                                href={links.home()}
                            />
                        ))
                    ) : (
                        <Logo
                            isCentreAligned
                            variant={'logoCentre'}
                            href={links.home()}
                        />
                    )}
                </div>
            </div>
        </>
    );
};

