import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { translate } from 'react-polyglot';
import Sizer from '../Sizer';
import List from '../List';
import Link from '../Link';
import SvgIcon from '../SvgIcon';
import './BookRelatedCollection.scss';
import { fetchReadingListBooksWorkflow } from '../../redux/readingListsActions';

const mapDispatchToProps = {
    fetchReadingListBooksWorkflow,
};
const mapStateToProps = () => ({});

@translate()
@connect(mapStateToProps, mapDispatchToProps)
class BookRelatedCollection extends Component {
    // static defaultProps = {}

    fetchBooks = (slug) => {
        this.props.fetchReadingListBooksWorkflow(slug);
    };

    render() {
        const baseClassName = 'pb-book-related-collection';
        const {
            parentClassName,
            title,
            book,
            bookRelatedReadingLists,
            isRightToLeftDirection
        } = this.props;

        const classes = {
            [baseClassName]: true,
            [parentClassName]: parentClassName,
        };

        return (
            <div className={`${baseClassName}`}>
                <div className={isRightToLeftDirection 
                    ? `${baseClassName}__title-rtl`
                    : `${baseClassName}__title`
                    }>
                        {title}
                </div>
                
                    <Sizer maxHeight="ml" scrollY>
                        <List bookRelatedCollection={true}>
                            {book.lists.map((list, i) => {
                                return (
                                    <div
                                        key={i}
                                        className={`${baseClassName}__summary-collection`}
                                        onClick={() =>
                                            this.fetchBooks(list.slug)
                                        }
                                        data-gtm={bookRelatedReadingLists}
                                    >
                                        <Link
                                            bookRelatedCollection={true}
                                            isInternal={true}
                                            key={i}
                                            href={'/readinglists/' + list.slug}
                                            dataGTM={bookRelatedReadingLists}
                                        >
                                            {list.title}
                                        </Link>

                                        <div
                                            className={`${baseClassName}__collection`}
                                        >
                                            <Link
                                                href={
                                                    '/readinglists/' + list.slug
                                                }
                                                dataGTM={
                                                    bookRelatedReadingLists
                                                }
                                            >
                                                <SvgIcon
                                                    name="arrow-right"
                                                    size="m"
                                                    pushRight
                                                    data-gtm={
                                                        bookRelatedReadingLists
                                                    }
                                                />
                                            </Link>
                                        </div>
                                    </div>
                                );
                            })}
                        </List>
                    </Sizer>
                
            </div>
        );
    }
}

BookRelatedCollection.propTypes = {
    parentClassName: PropTypes.string,
};

export default BookRelatedCollection;
