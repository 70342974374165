import { createAsyncActions, runAsyncWorkflow } from '../lib/reduxHelpers';
import { createActions } from 'redux-actions';
import { Home, Videos, Book } from '../api';

export const POPUP_OPENED = 'POPUP_OPENED';
export const BOOK_READ = 'BOOK_READ';
export const CATEGORY_CARD_OPENED = 'CATEGORY_CARD_OPENED';

export const [fetchHome, receiveHome, fetchHomeError] = createAsyncActions(
    'HOME',
    () => {},
    ({ ok, data }) => ({ ok, data })
);

export const fetchHomeWorkflow = () => {
    const fetchPromise = Home.fetch();

    return runAsyncWorkflow(
        {
            fetch: fetchHome,
            receive: receiveHome,
            error: fetchHomeError,
        },
        fetchPromise
    );
};

export const [
    favouriteVideo,
    receiveFavouriteVideo,
    favouriteVideoError,
] = createAsyncActions(
    'FAV_VIDEOS',
    videoId => videoId,
    ({ ok, videoId }) => ({ ok, videoId })
);

export const favouriteVideoWorkflowHome = videoId => {
    const favVideoPromise = Videos.toggleFavourite(videoId);

    return runAsyncWorkflow(
        {
            fetch: favouriteVideo,
            receive: receiveFavouriteVideo,
            error: favouriteVideoError,
        },
        favVideoPromise,
        { videoId }
    );
};

export const [
    favouriteBook,
    receiveFavouriteBook,
    favouriteBookError,
] = createAsyncActions(
    'FAV_BOOK',
    ({ bookId }) => ({ bookId }),
    ({ ok, bookId }) => ({ ok, bookId })
);

export const favouriteBookWorkflowHome = bookId => {
    const favBookPromise = Book.toggleFavourite(bookId);

    return runAsyncWorkflow(
        {
            fetch: favouriteBook,
            receive: receiveFavouriteBook,
            error: favouriteBookError,
        },
        favBookPromise,
        { bookId }
    );
};

export const [
    fetchRecommendations,
    receiveRecommendations,
    fetchRecommendationsError,
] = createAsyncActions(
    'RECOMMENDATIONS',
    () => {},
    ({ ok, data }) => ({ ok, data })
);

export const fetchRecommendationsWorkflow = () => {
    const fetchPromise = Home.fetchRecommendations();

    return runAsyncWorkflow(
        {
            fetch: fetchRecommendations,
            receive: receiveRecommendations,
            error: fetchRecommendationsError,
        },
        fetchPromise
    );
};

export const [
    fetchCategories,
    receiveCategories,
    fetchCategoriesError,
] = createAsyncActions(
    'CATEGORY',
    () => {},
    ({ data }) => ({ categories: data })
);

export const fetchCategoriesWorkflow = () => {
    const fetchPromise = Home.fetchCategories();

    return runAsyncWorkflow(
        {
            fetch: fetchCategories,
            receive: receiveCategories,
            error: fetchCategoriesError,
        },
        fetchPromise
    );
};

export const { popupOpened } = createActions('POPUP_OPENED');
export const { bookRead } = createActions('BOOK_READ');
