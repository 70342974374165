import React, { Component } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { translate } from 'react-polyglot';
import { connect } from 'react-redux';
import queryString from 'query-string';
import u from 'updeep';
import ResourceCard from '../ResourceCard';
import LoaderBlock from '../LoaderBlock';
import Grid from '../Grid';
import { fetchAllReferencesFiltersWorkflow } from '../../redux/referencesActions';

const mapDispatchToProps = {
    fetchAllReferencesFiltersWorkflow,
};

const mapStateToProps = ({
    references,
    viewport,
    user: { profile },
    allFilters: { readFilters },
}) => ({
    ...references,
    viewport,
    appliedFilters: readFilters,
    userEmail: profile.email,
    name: profile.name,
});

@translate()
@connect(
    mapStateToProps,
    mapDispatchToProps
)
class ResourceCardContainer extends Component {
    // static defaultProps = {}

    constructor(props) {
        super(props);
        // We operate on a copy of the reading list, since it's user editable.
        this.state = {
            // The filters bar allows searching through available
            // filter values. We store that search text here.
            filtersSearchText: {
                category: '',
                publisher: '',
                level: '',
                language: '',
            },
            isResourceMobile: false,
        };
    }
    updateCategorySearchText = e =>
        this.updateFiltersSearchText('category', e.target.value);
    updatePublisherSearchText = e =>
        this.updateFiltersSearchText('publisher', e.target.value);
    updateLevelSearchText = e =>
        this.updateFiltersSearchText('level', e.target.value);
    updateLanguageSearchText = e =>
        this.updateFiltersSearchText('language', e.target.value);
    getTab = () => {
        const { location } = this.props;
        return queryString.parse(location.search)['tab'];
    };

    updateFiltersSearchText = (key, text) => {
        this.setState(
            u(
                {
                    filtersSearchText: {
                        [key]: text,
                    },
                },
                this.state
            )
        );
    };

    componentDidMount() {
        this.isMobile();
    }

    isMobile = () => {
        if (
            window.screen.width <= 768 &&
            window.matchMedia('(orientation: portrait)').matches
        ) {
            this.setState({
                isResourceMobile: true,
            });
        } else {
            this.setState({
                isResourceMobile: false,
            });
        }
    };

    render() {
        const baseClassName = 'pb-resource-card-container';
        const {
            parentClassName,
            References,
            filters,
            isFetchingReferences,
            totalReferencesCount,
            myLanguage
        } = this.props;

        const classes = {
            [baseClassName]: true,
            [parentClassName]: parentClassName,
        };

        const isRightToLeftDirection = localStorage.getItem('locale') === 'ar' || localStorage.getItem('locale') === 'ur';


        if (!filters || isFetchingReferences) {
            return <LoaderBlock />;
        }

        const NoResults = translate()(({ t }) => (
            <span>
                <p>{t('Books.no-result-warning-1')}</p>
                <p>{t('Books.no-result-warning-2')}</p>
                <ul
                    style={ 
                        localStorage.getItem('locale') === "ar" || localStorage.getItem('locale') === "ur" 
                        ? {paddingLeft:'0',paddingRight:'0'}
                        :null
                    }
                >
                    <li>{t('Books.no-result-warning-3')}</li>
                    <li>{t('Books.no-result-warning-4')}</li>
                    <li>{t('Books.no-result-warning-5')}</li>
                    <li>{t('Books.no-result-warning-6')}</li>
                </ul>
            </span>
        ));

        return (
            <div className={classNames(classes)}>
                {isFetchingReferences || !References ? (
                    <LoaderBlock />
                ) : (
                        <div>
                            {References.length > 0 ? (
                                <Grid variant="4up">
                                    {References.map(referenceItem => {
                                        return (
                                            <div key={referenceItem.id}>
                                                <ResourceCard
                                                    myLanguage={myLanguage}
                                                    id={referenceItem.id}
                                                    slug={referenceItem.slug}
                                                    title={referenceItem.title}
                                                    contentUrl={
                                                        referenceItem.contentUrl
                                                    }
                                                    likesCount={
                                                        referenceItem.likesCount
                                                    }
                                                    readsCount={
                                                        referenceItem.readsCount
                                                    }
                                                    isFavourite={
                                                        referenceItem.is_favourite
                                                    }
                                                    coverImage={
                                                        referenceItem.thumbUrl
                                                    }
                                                    resourceThumbnailGTM="resource-thumbnail"
                                                    resourceTitleGTM="resource-title"
                                                />
                                            </div>
                                        );
                                    })}
                                </Grid>
                            ) : null}
                            <div className={
                                isRightToLeftDirection
                                ? "__no-result-section-rtl"
                                : "__no-result-section"
                            }>
                                {totalReferencesCount ? null : <NoResults />}
                            </div>
                        </div>
                    )}
            </div>
        );
    }
}

ResourceCardContainer.propTypes = {
    parentClassName: PropTypes.string,
};

export default ResourceCardContainer;
