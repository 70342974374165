import React from 'react';
import { translate } from 'react-polyglot';
import Link from '../Link';
import { links } from '../../lib/constants';
import './DonateContainer.scss';

const DonateContainer = ({t}) => {
    const baseClassName = 'donate';
    return(
    <div className={`${baseClassName}__container`}>
        <div className={`${baseClassName}__text`}>
            {t('Donate.text')}
            &nbsp;
            <Link
                donateLink
                isExternal
                href={links.donate()}
            >
                {t('Donate.link')}
            </Link>
        </div>
    </div>)
}

export default translate()(DonateContainer)