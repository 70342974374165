import { handleActions } from 'redux-actions';
import u from 'updeep';
import * as actions from './videoActions';

const initialState = {
    isFetchingFilters: false,
    filters: null,
    videos: [],
    videoDetail: {},
    isFetchingVideos: false,
    isFetchingMoreVideos: false, // for pagination
    loadedPages: 0,
    totalVideosCount: 0,
    hits: 0,
    favouriteVideos: [],
    isFetchingFavouriteVideos: false,
    totalFavouriteVideosCount: 0,
    isFetchingVideoDetail: false,
};

export default handleActions(
    {
        [actions.fetchAllVideosFilters]: (state, action) =>
            u(
                {
                    isFetchingFilters: true,
                },
                state
            ),

        [actions.receiveAllVideosFilters]: (state, action) =>
            u(
                {
                    isFetchingFilters: false,
                    filters: action.payload.filters,
                },
                state
            ),

        [actions.fetchVideos]: (state, action) =>
            u(
                {
                    isFetchingVideos: true,
                },
                state
            ),

        [actions.receiveVideos]: (state, action) =>
            u(
                {
                    isFetchingVideos: false,
                    totalVideosCount: action.payload.metadata.totalPages,
                    videos: action.payload.videos,
                    loadedPages: 1,
                    hits: action.payload.metadata.hits,
                },
                state
            ),

        [actions.fetchMoreVideos]: (state, action) =>
            u(
                {
                    isFetchingMoreVideos: true,
                },
                state
            ),

        [actions.receiveMoreVideos]: (state, action) =>
            u(
                {
                    isFetchingMoreVideos: false,
                    videos: state.videos.concat(action.payload.videos),
                    loadedPages: state.loadedPages + 1,
                },
                state
            ),

        [actions.receiveVideoReadCount]: (state, action) => u({}, state),

        [actions.favouriteVideo]: (state, action) => {
            //Get the associated video and set its isFovouriting state
            //Get the index of the video
            let index = state.videos.findIndex(
                video => video.id === action.payload.videoId
            );

            const favVideoIndex = state.favouriteVideos.findIndex(
                video => video.id === action.payload.videoId
            );
            if (index >= 0) {
                return u(
                    {
                        videos: u.updateIn([index, 'isFavouriting'], true),
                    },
                    state
                );
            } else if (favVideoIndex >= 0) {
                return u(
                    {
                        favouriteVideos: u.updateIn(
                            [favVideoIndex, 'isFavouriting'],
                            true
                        ),
                    },
                    state
                );
            } else {
                return state;
            }
        },
        [actions.receiveFavouriteVideo]: (state, action) => {
            //Get the associated video and set its isFovouriting state
            //Get the index of the video
            const index = state.videos.findIndex(
                video => video.id === action.payload.videoId
            );

            const favVideoIndex = state.favouriteVideos.findIndex(
                video => video.id === action.payload.videoId
            );

            if (index >= 0) {
                let favourite = state.videos[index].is_favourite;
                const newLikes = !favourite
                    ? state.videos[index].likesCount + 1
                    : state.videos[index].likesCount - 1;
                const likesTemp = u(
                    {
                        videos: u.updateIn([index, 'likesCount'], newLikes),
                    },
                    state
                );
                let temp = u(
                    {
                        videos: u.updateIn([index, 'isFavouriting'], false),
                    },
                    likesTemp
                );
                return u(
                    {
                        videos: u.updateIn([index, 'is_favourite'], !favourite),
                    },
                    temp
                );
            } else if (favVideoIndex >= 0) {
                let favourite =
                    state.favouriteVideos[favVideoIndex].is_favourite;
                const newLikes = !favourite
                    ? state.favouriteVideos[favVideoIndex].likesCount + 1
                    : state.favouriteVideos[favVideoIndex].likesCount - 1;
                const likesTemp = u(
                    {
                        favouriteVideos: u.updateIn(
                            [favVideoIndex, 'likesCount'],
                            newLikes
                        ),
                    },
                    state
                );
                let temp = u(
                    {
                        favouriteVideos: u.updateIn(
                            [favVideoIndex, 'isFavouriting'],
                            false
                        ),
                    },
                    likesTemp
                );
                return u(
                    {
                        favouriteVideos: favourite
                            ? state.favouriteVideos.filter(
                                favVideo =>
                                    favVideo.id !== action.payload.videoId
                            )
                            : state.favouriteVideos,
                        totalFavouriteVideosCount: favourite
                            ? state.totalFavouriteVideosCount - 1
                            : state.totalFavouriteVideosCount,
                    },
                    temp
                );
            } else {
                return state;
            }
        },

        [actions.fetchFavouriteVideos]: (state, action) =>
            u(
                {
                    isFetchingFavouriteVideos: true,
                },
                state
            ),

        [actions.receiveFetchFavouriteVideos]: (state, action) =>
            u(
                {
                    isFetchingFavouriteVideos: false,
                    totalFavouriteVideosCount: action.payload.metadata.hits,
                    favouriteVideos: action.payload.favVideos,
                },
                state
            ),
        [actions.fetchVideoDetail]: (state, action) =>
            u(
                {
                    isFetchingVideoDetail: true,
                },
                state
            ),

        [actions.receivefetchVideoDetail]: (state, action) =>
            u(
                {
                    isFetchingVideoDetail: false,
                    videoDetail: action.payload.videoDetail,
                },
                state
            ),
        [actions.fetchVideoDetailError]: (state, action) =>
            u(
                {
                    isFetchingVideoDetail: false,
                },
                state
            ),
        [actions.favouriteSimilarVideo]: (state, action) => {
            let index = state.videoDetail.similar_videos.findIndex(
                video => video.id === action.payload.videoId
            );

            if (index >= 0) {
                let newState = u(
                    {
                        similar_videos: u.updateIn([index, 'isFavouriting'], true),
                    },
                    state.videoDetail
                );
                let videoDetail = newState;
                // console.log('new state', newState)
                let data = { ...state, videoDetail }
                return data;
            } else {
                return state;
            }
        },

        [actions.receivefavouriteSimilarVideo]: (state, action) => {
            const index = state.videoDetail.similar_videos.findIndex(
                video => video.id === action.payload.videoId
            );

            if (index >= 0) {

                let favourite = state.videoDetail.similar_videos[index].is_favourite;
                const newLikes = !favourite
                    ? state.videoDetail.similar_videos[index].likesCount + 1
                    : state.videoDetail.similar_videos[index].likesCount - 1;
                const likesTemp = u(
                    {
                        similar_videos: u.updateIn([index, 'likesCount'], newLikes),
                    },
                    state.videoDetail
                );
                let temp = u(
                    {
                        similar_videos: u.updateIn([index, 'isFavouriting'], false),
                    },
                    likesTemp
                );
                const newState = u(
                    {
                        similar_videos: u.updateIn([index, 'is_favourite'], !favourite),
                    },
                    temp
                );
                let videoDetail = newState;
                let data = { ...state, videoDetail }
                return data;

            } else {
                return state;
            }
        }
    },
    initialState
);
