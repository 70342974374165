import React, { Component } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { translate } from 'react-polyglot';
import DocumentTitle from 'react-document-title';
import BookCard from '../BookCard';
import Tabs from '../Tabs';
import Tab from '../Tab';
import BooksSearchResultsContainer from './BooksSearchResultsContainer';
import VideoSearchResultsContainer from './VideoSearchResultsContainer';
import ReadinglistsSearchResultsContainer from './ReadinglistsSearchResultsContainer';
import ResourceSearchResultsContainer from './ResourceSearchResultsContainer';
import Block from '../Block';
import { links } from '../../lib/constants';
import './AllBooks.scss';
import ContentStyler from '../ContentStyler';
// This must match the visual order of tabs on screen.
const tabIndexes = {
    books: 0,
    lists: 1,
    videos: 2,
    resources: 3,
};
const translateFiltersBarSortOptionsToFiltersPanelSortOptions = filtersBarSortOptions =>
    filtersBarSortOptions.map(so => ({ label: so.name, value: so.queryValue }));
@translate()
class AllBooks extends Component {
    constructor(props) {
        super(props);
        this.state = {
            activeTabIndex: tabIndexes[props.appliedFilters.tab]
        };
    }
    onTabChange = index => {
        this.setState({ activeTabIndex: index });
        this.props.onTabChange(Object.keys(tabIndexes)[index]);
    };
    render() {
        const baseClassName = 'pb-all-books';
        const {
            t,
            isSearchVariant,
            searchQuery,
            location,
            tags,
            headerTitle,
            applyFilter,
            removeFilter,
            onSortOptionChanged,
            searchPage,
            recommendedBooks,
            trendingBooks
        } = this.props;

        // const { steps } = this.state;
        const isTagSearch = tags && tags.length > 0 && !searchQuery;
        let title;
        if (isSearchVariant && searchQuery && (!tags || tags.length === 0)) {
            title = `"${searchQuery}"`;
        } else if (isSearchVariant && isTagSearch) {
            // We only support a single tag right now. This will become an array join
            // when we support multiple tags.
            // currently, we have tagged search for images and stories
            title = `${t('Item.tagged', { item: t('Stories') })} "${tags}"`;
        } else {
            title = headerTitle ? headerTitle : t('global.all-stories');
        }
        const booksSearchResultsEl = (
            isSearchVariant ? 
            <BooksSearchResultsContainer
                location={location}
                searchQuery={searchQuery}
                applyFilter={applyFilter}
                removeFilter={removeFilter}
                onSortOptionChanged={onSortOptionChanged}
                translateFiltersBarSortOptionsToFiltersPanelSortOptions={
                    translateFiltersBarSortOptionsToFiltersPanelSortOptions
                }
                searchPage={searchPage}
                recommendedBooks={recommendedBooks}
                trendingBooks={trendingBooks}
            /> : 
            <BooksSearchResultsContainer
                location={location}
                searchQuery={searchQuery}
                applyFilter={applyFilter}
                removeFilter={removeFilter}
                onSortOptionChanged={onSortOptionChanged}
                translateFiltersBarSortOptionsToFiltersPanelSortOptions={
                    translateFiltersBarSortOptionsToFiltersPanelSortOptions
                }
                bookResults
                recommendedBooks={recommendedBooks}
                trendingBooks={trendingBooks}
            />
        );
        let searchResultsEl = <Block noMargin>{booksSearchResultsEl}</Block>;
        if (isSearchVariant) {
            const { activeTabIndex } = this.state;
             
                searchResultsEl = (
                    <Block noHorizontalPadding noVerticalPadding>
                        <Tabs
                            onTabChange={this.onTabChange}
                            activeTabIndex={activeTabIndex}
                        >
                            <Tab
                                key="books"
                                title={t('global.book', 2)}
                                shouldUnloadContent={true}
                            >
                                {booksSearchResultsEl}
                            </Tab>
                            <Tab
                                key="lists"
                                title={t('global.list', 2)}
                                shouldUnloadContent={true}
                            >
                                <ReadinglistsSearchResultsContainer
                                    searchQuery={searchQuery}
                                    applyFilter={applyFilter}
                                    removeFilter={removeFilter}
                                    onSortOptionChanged={onSortOptionChanged}
                                    translateFiltersBarSortOptionsToFiltersPanelSortOptions={
                                        translateFiltersBarSortOptionsToFiltersPanelSortOptions
                                    }
                                />
                            </Tab>
                            <Tab
                                key="videos"
                                title={t('global.video', 2)}
                                shouldUnloadContent={true}
                            >
                                <VideoSearchResultsContainer
                                    searchQuery={searchQuery}
                                    applyFilter={applyFilter}
                                    removeFilter={removeFilter}
                                    onSortOptionChanged={onSortOptionChanged}
                                    translateFiltersBarSortOptionsToFiltersPanelSortOptions={
                                        translateFiltersBarSortOptionsToFiltersPanelSortOptions
                                    }
                                />
                            </Tab>
                            <Tab
                                key="resources"
                                title={t('global.resources')}
                                shouldUnloadContent={true}
                            >
                                <ResourceSearchResultsContainer
                                    searchQuery={searchQuery}
                                    applyFilter={applyFilter}
                                    removeFilter={removeFilter}
                                    onSortOptionChanged={onSortOptionChanged}
                                    translateFiltersBarSortOptionsToFiltersPanelSortOptions={
                                        translateFiltersBarSortOptionsToFiltersPanelSortOptions
                                    }
                                />
                            </Tab>
                        </Tabs>
                    </Block>
                );
            
        }
        const breadcrumbPaths = [
            {
                title: t('global.home'),
                href: links.home(),
                isInternal: true,
            },
        ];
        if (isSearchVariant) {
            breadcrumbPaths.push({
                title: t('Search.results-for'),
            });
        }
        const classes = {
            [baseClassName]: true,
        };

        const searchHeaderEl = isSearchVariant ? (
            <ContentStyler>
                <div className="__search-header">
                    <h3>{tags}</h3>
                </div>
            </ContentStyler>
        ) : null;

        return (
            <div className={classNames(classes)}>
                {searchHeaderEl}
                <DocumentTitle title={`${title} - ${t('global.site-title')}`} />
                {searchResultsEl}
            </div>
        );
    }
}
AllBooks.propTypes = {
    books: PropTypes.arrayOf(PropTypes.shape(BookCard.propTypes)),
};
export default AllBooks;
