import React, { Component } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { translate } from 'react-polyglot';

import BookCard from '../BookCard';
import CardShelf from '../CardShelf';

import './BookShelf.scss';

const renderBookEls = (
    t,
    books,
    sectionClicked,
    offline,
    myLanguage,
    lastPage
) => {
    return books.map(book => {
        return (
            <BookCard
                myLanguage={myLanguage}
                key={book.slug}
                id={book.id}
                book={book}
                isSimilarBook
                sectionClicked={sectionClicked}
                offline={offline}
                isFavourite={book.is_favourite}
                isFavouriting={book.isFavouriting}
                bookLikes={book.likesCount}
                hideMenu
                bookImageGTM="related-book-image"
                bookTitleGTM="related-book-title"
                bookAuthorGTM="related-book-author"
                bookIllustratorsGTM="related-book-illustrators"
                bookLevelGTM="related-book-level"
                isRelatedBook
                lastPage={lastPage}
                padding130={true}
            />
        );
    });
};

@translate()
class BookShelf extends Component {
    static defaultProps = {};

    render() {
        const {
            books,
            viewport,
            t,
            sectionClicked,
            offline,
            myLanguage,
            isBookReaderPage = false,
            lastPage
        } = this.props;

        const baseClassName = 'pb-book-shelf';

        const classes = {
            [baseClassName]: true,
        };

        return (
            <div className={classNames(classes)}>
                <CardShelf cellWidth="book-card" viewport={viewport} isBookReaderPage={isBookReaderPage}>
                    {renderBookEls(
                        t,
                        books,
                        sectionClicked,
                        offline,
                        myLanguage,
                        lastPage
                    )}
                </CardShelf>
            </div>
        );
    }
}

BookShelf.propTypes = {
    viewport: PropTypes.object.isRequired,
    books: PropTypes.arrayOf(PropTypes.shape(BookCard.propTypes)).isRequired,
};

export default BookShelf;
