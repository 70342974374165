import React, { Component } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { translate } from 'react-polyglot';
import { connect } from 'react-redux';
import './ResourceDetailContainer.scss';
import ResourceDetail from '../ResourceDetail';
import LoaderBlock from '../LoaderBlock';
import NotFoundPage from '../NotFoundPage';
import { fetchMeWorkflow } from '../../redux/userActions';
import { fetchReferenceDetailWorkflow } from '../../redux/referencesActions';
import { links } from '../../lib/constants';
import withRouter from "../../util/withRouter"

const mapDispatchToProps = {
    fetchReferenceDetailWorkflow,
    fetchMeWorkflow
};

const mapStateToProps = ({ references, viewport, network, user }) => ({
    ...references,
    resourceDetail: references.resourceDetail,
    resourceID: references.resourceDetail.id,
    resourceReadsCount: references.resourceDetail.readsCount,
    resourceLikesCount: references.resourceDetail.likesCount,
    isFavourite: references.resourceDetail.is_favourite,
    resourceUrl: references.resourceDetail.contentUrl,
    online: network.online,
    isLoggedIn: user.isLoggedIn,
    viewport,
});

@translate()
@connect(
    mapStateToProps,
    mapDispatchToProps
)
class ResourceDetailContainer extends Component {
    constructor(props) {
        super(props);
        this.state = { resourceSlug: this.props.params.slug };
    }

    componentDidMount() {
            this.props.fetchReferenceDetailWorkflow(this.state.resourceSlug);
    }

    componentWillReceiveProps(nextProps) {
        if (this.props.params.slug !== nextProps.params.slug) {
            this.props.fetchReferenceDetailWorkflow(
                nextProps.params.slug
            );
        }
    }

    fetchMeAndReferenceDetail = async () => {
        const response = await this.props.fetchMeWorkflow();
        if (response.data.isLoggedIn) {
            this.props.fetchReferenceDetailWorkflow(this.state.resourceSlug);
        } else {
            this.props.history.push(links.fetchTabLogin(`resourcedetail/${this.state.resourceSlug}`));
        }
    }

    render() {
        const baseClassName = 'pb-resource-detail-container';
        const {
            parentClassName,
            t,
            isFetchingResourceDetail,
            resourceDetail,
            resourceID,
            resourceReadsCount,
            resourceLikesCount,
            resourceUrl,
            online,
            viewport,
            isLoggedIn,
            isFavourite,
        } = this.props;

        const classes = {
            [baseClassName]: true,
            [parentClassName]: parentClassName,
        };

        const myLanguage = localStorage.getItem('locale')

        if (isFetchingResourceDetail) {
            return <LoaderBlock />
        }

        if (!isFetchingResourceDetail && resourceDetail.id === undefined) {
            return <NotFoundPage />
        }

        return (
            <div className={classNames(classes)}>
                <div>
                    <ResourceDetail
                        myLanguage={myLanguage}
                        t={t}
                        resourceDetail={resourceDetail}
                        resourceID={resourceID}
                        resourceReadsCount={resourceReadsCount}
                        likesCount={resourceLikesCount}
                        isFavourite={isFavourite}
                        resourceUrl={resourceUrl}
                        online={online}
                        viewport={viewport}
                        isLoggedIn={isLoggedIn}
                        resourcesResourceThumbnailGTM="resources-resource-thumbnail"
                        resourcesResourceDownloadGTM="resources-resource-download"
                        resourcesResourceFavoriteGTM="resources-resource-favorite"
                        resourceDisqusGTM="resource-disqus-comments"
                    />
                </div>
            </div>
        );
    }
}

ResourceDetailContainer.propTypes = {
    parentClassName: PropTypes.string,
};

export default withRouter(ResourceDetailContainer);
