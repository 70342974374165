import React, { Component } from 'react';
import './Book.scss';
import './BookDetails.scss';
import Credits from './Credits';
import Level from '../Level';
import LikeAndShareRow from './LikeAndShareRow';
import { isMobile } from '../../util';
import GovtApproved from './GovtApproved';

class BookDetails extends Component {


    render() {
        const isRightToLeftDirection = localStorage.getItem('locale') === 'ar';
        const {
            level,
            isTranslation,
            isRelevelled,
            authors,
            publisher,
            illustrators,
            photographers,
            originalTitle,
            originalSlug,
            originalAuthors,
            online,
            t,
            onProfileLinkClicked,
            landingPageBook,
            title_logos,
            storyLevelColour,
            translator,
            type,
            isOriginalStoryPresent
        } = this.props;

        return (
            <div className="book-details">
                {/* Level */}
                {!isMobile() && (
                    <div className="level">
                        <div className={title_logos.length > 0 ? 'level-reduced-width' : 'level-custom-width'}>
                            <Level
                                
                                title={t(`PublicationForm.level${level}`)}
                                storyLevelColour={storyLevelColour}
                                isLandingBookPage
                            />
                        </div>
                        {title_logos.length > 0 && (
                            <GovtApproved
                                t={t}
                                isRightToLeftDirection={isRightToLeftDirection}
                                title_logos={title_logos}
                            />
                        )}
                    </div>
                )}

                {/* Credits */}
                <Credits
                    isTranslation={isTranslation}
                    isRelevelled={isRelevelled}
                    authors={authors}
                    publisher={publisher}
                    illustrators={illustrators}
                    photographers={photographers}
                    originalTitle={originalTitle}
                    originalSlug={originalSlug}
                    originalAuthors={originalAuthors}
                    offline={!online}
                    t={t}
                    onProfileLinkClicked={onProfileLinkClicked}
                    landingPageBook={landingPageBook}
                    translator={translator}
                    type={type}
                    isOriginalStoryPresent={isOriginalStoryPresent}
                />

                {!isMobile() && (
                    <div>
                        {/* Like, share */}
                        <LikeAndShareRow {...this.props} />
                    </div>
                )}
            </div>
        );
    }
}

export default BookDetails;
