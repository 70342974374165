import React, { Component } from 'react';
import './PageFlipAnimation.scss';
import RTRBookTitleData from '../RTRBookReaderContainer/components/RTRBookReaderContent/RTRBookTitleData';
import RTRBookReadAgain from '../RTRBookReaderContainer/components/RTRBookReaderContent/RTRBookReadAgain';
import RTRBookClosingPage from '../RTRBookReaderContainer/components/RTRBookReaderContent/RTRBookClosingPage';
import Modal from 'react-modal';
import BookRating from '../BookRating';
import SvgIcon from '../SvgIcon';
Modal.setAppElement('body');

const customStyles = {
    overlay: {
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        zIndex: 999,
        overflow: 'hidden',
        backgroundColor: 'rgba(0, 0, 0, 0.75)',
    },
    content: {
        position: 'fixed',
        top: '20%',
        left: '35%',
        right: '35%',
        bottom: '20%',
        border: '1px solid #ccc',
        overflow: 'auto',
        WebkitOverflowScrolling: 'touch',
        borderRadius: '0px',
        outline: 'none',
        padding: '0px',
        zIndex: '999',
        overflowY: 'hidden',
        background: '#ffffff',
    },
};

class PageFlipAnimation extends Component {
    constructor(props) {
        super(props);
        this.state = {
            currentPageNum: 1,
            isBookOpen: true,
            isBookClosed: {
                isClosed: true,
                isAtBeginning: true,
            },
            flippedPapers: [],
            flippedPages: [],
            totalPages:
                this.props.detailPages.pages.length % 2
                    ? (this.props.detailPages.pages.length + 1) / 2
                    : this.props.detailPages.pages.length / 2,
            browserView:
                navigator.userAgent.indexOf('Safari') !== -1 &&
                navigator.userAgent.indexOf('Chrome') === -1,
            modalIsOpen: false,
        };
    }

    componentDidMount() {
        let lastFlipped = [];
        for (let i = 1; i <= this.props.pageNo; i++) {
            lastFlipped.push(`p${i}`);
        }
        this.setState({
            flippedPapers: lastFlipped,
            flippedPages: lastFlipped,
            currentPageNum: this.props.pageNo + 1,
        });
    }

    openBook = () => {
        this.setState({
            isBookOpen: true,
        });
    };

    closeBook = (isAtBeginning) => {
        this.setState({
            isBookOpen: false,
        });
        if (isAtBeginning) {
            this.setState({
                isBookClosed: {
                    isClosed: true,
                    isAtBeginning: true,
                },
            });
        } else {
            this.setState({
                isBookClosed: {
                    isClosed: true,
                    isAtBeginning: false,
                },
            });
        }
    };

    goNextPage = () => {
        if (this.state.currentPageNum < this.state.totalPages + 1) {
            let newFlippedPapers = [];
            if (this.state.currentPageNum === 1) {
                this.openBook();
                newFlippedPapers = [...this.state.flippedPapers, 'p1'];
            } else if (this.state.currentPageNum === this.state.totalPages) {
                newFlippedPapers = [
                    ...this.state.flippedPapers,
                    `p${this.state.totalPages}`,
                ];
                this.closeBook(false);
            } else {
                newFlippedPapers = [
                    ...this.state.flippedPapers,
                    `p${this.state.currentPageNum}`,
                ];
            }
            this.setState({
                flippedPapers: newFlippedPapers,
                currentPageNum: this.state.currentPageNum + 1,
            });
            setTimeout(() => {
                this.setState({ flippedPages: newFlippedPapers });
            }, 500);
            this.props.nextPage(this.state.currentPageNum);
            if (
                this.state.currentPageNum * 2 ===
                    this.props.detailPages.pages.length - 1 ||
                this.state.currentPageNum * 2 ===
                    this.props.detailPages.pages.length
            ) {
                this.setState({
                    modalIsOpen: true,
                });
            }
        }
    };

    goPrevPage = () => {
        if (this.state.currentPageNum > 1) {
            let newFlippedPapers = [];
            if (this.state.currentPageNum === 2) {
                this.closeBook(true);
                newFlippedPapers = this.state.flippedPapers.filter(
                    (paper) => paper !== 'p1'
                );
            } else if (
                this.state.currentPageNum ===
                this.state.totalPages + 1
            ) {
                this.openBook();
                newFlippedPapers = this.state.flippedPapers.filter(
                    (paper) => paper !== `p${this.state.totalPages}`
                );
            } else {
                newFlippedPapers = this.state.flippedPapers.filter(
                    (paper) => paper !== `p${this.state.currentPageNum - 1}`
                );
            }
            this.setState({
                flippedPapers: newFlippedPapers,
                flippedPages: newFlippedPapers,
                currentPageNum: this.state.currentPageNum - 1,
            });
            this.props.prevPage(this.state.currentPageNum - 2);
        }
    };

    resetAgain = () => {
        this.setState({
            flippedPapers: ['p1'],
            flippedPages: ['p1'],
            currentPageNum: 2,
        });
    };

    closePopup = () => {
        this.setState((state, props) => ({
            modalIsOpen: false,
        }));
    };

    render() {
        const {
            currentPageNum,
            isBookOpen,
            isBookClosed,
            flippedPapers,
            flippedPages,
            totalPages,
        } = this.state;

        const { detailPages, t, book } = this.props;
        const { id, slug } = book;
        const isRightToLeftDirection =
            localStorage.getItem('locale') === 'ar' ||
            localStorage.getItem('locale') === 'ur';
        let updatedSlides = detailPages.pages
            .map((slide, i) => {
                if (i % 2 === 0) {
                    return detailPages.pages.slice(i, i + 2);
                }
                return null;
            })
            .filter(Boolean);

        return (
            <div className="Flip">
                <div
                    id="prev-btn"
                    className={`${isBookOpen ? 'bookOpen' : ''} ${
                        isBookClosed.isClosed ? 'bookClose' : ''
                    }`}
                    onClick={() => this.goPrevPage()}
                >
                    {currentPageNum > 2 && (
                        <SvgIcon 
                            name={isRightToLeftDirection 
                                    ? "reader-view-next-page" 
                                    : "reader-view-previous-page"} 
                            size="reader-view-navigation"
                        />
                    )}
                </div>

                <div
                    id="book"
                    className={`book ${isBookOpen ? 'bookOpen' : ''} ${
                        isBookClosed.isClosed
                            ? isBookClosed.isAtBeginning
                                ? 'bookCloseBeginning'
                                : 'bookClose'
                            : ''
                    } ${
                        book.orientation === 'vertical'
                            ? ' portrait'
                            : ' landScape'
                    }`}
                >
                    {updatedSlides.map((slide, i) => {
                        const pageNum = i + 1;
                        return (
                            <div
                                id={`p${pageNum}`}
                                className={`paper ${
                                    flippedPapers.includes(`p${pageNum}`)
                                        ? 'flipped'
                                        : ''
                                } `}
                                style={{
                                    zIndex: flippedPages.includes(`p${pageNum}`)
                                        ? 0
                                        : totalPages + 1 - pageNum,
                                }}
                                key={i}
                            >
                                <div className="front">
                                    <div
                                        id={`f${pageNum}`}
                                        className="front-content"
                                    >
                                        {slide &&
                                            slide[0] &&
                                            (slide[0].url ? (
                                                <img
                                                    src={slide[0].url}
                                                    alt="book-reader"
                                                    className="fileImage"
                                                />
                                            ) : slide[0].dynamicPage === 3 ? (
                                                <RTRBookReadAgain
                                                    bookData={detailPages}
                                                    t={t}
                                                    book={book}
                                                    online={this.props.online}
                                                    onFavoriteClicked={this.props.onFavoriteClicked.bind(
                                                        this
                                                    )}
                                                    likesCount={
                                                        this.props.likesCount
                                                    }
                                                    isLoggedIn={
                                                        this.props.isLoggedIn
                                                    }
                                                    setBookReaderStatusWorkflow={
                                                        this.props
                                                            .setBookReaderStatusWorkflow
                                                    }
                                                    readAgain={this.props.readAgain.bind(
                                                        this
                                                    )}
                                                    pageNo={this.props.pageNo}
                                                    resetAgain={this.resetAgain}
                                                />
                                            ) : slide[0].dynamicPage === 4 ? (
                                                <RTRBookClosingPage
                                                    bookData={detailPages}
                                                    t={t}
                                                    book={book}
                                                    online={this.props.online}
                                                    viewport={
                                                        this.props.viewport
                                                    }
                                                    isRightToLeftDirection={isRightToLeftDirection}
                                                />
                                            ) : (
                                                ''
                                            ))}
                                    </div>
                                </div>
                                <div
                                    className={`back ${
                                        this.state.browserView
                                            ? 'backSafari'
                                            : ''
                                    }`}
                                >
                                    <div
                                        id={`b${pageNum}`}
                                        className="back-content"
                                    >
                                        {slide &&
                                            slide[1] &&
                                            (slide[1].url ? (
                                                <img
                                                    src={slide[1].url}
                                                    alt=""
                                                    className="fileImage"
                                                />
                                            ) : slide[1].dynamicPage === 3 ? (
                                                <RTRBookReadAgain
                                                    bookData={detailPages}
                                                    t={t}
                                                    book={book}
                                                    online={this.props.online}
                                                    onFavoriteClicked={this.props.onFavoriteClicked.bind(
                                                        this
                                                    )}
                                                    likesCount={
                                                        this.props.likesCount
                                                    }
                                                    isLoggedIn={
                                                        this.props.isLoggedIn
                                                    }
                                                    setBookReaderStatusWorkflow={
                                                        this.props
                                                            .setBookReaderStatusWorkflow
                                                    }
                                                    readAgain={this.props.readAgain.bind(
                                                        this
                                                    )}
                                                    pageNo={this.props.pageNo}
                                                    resetAgain={this.resetAgain}
                                                />
                                            ) : slide[1].dynamicPage === 4 ? (
                                                <RTRBookClosingPage
                                                    bookData={detailPages}
                                                    t={t}
                                                    book={book}
                                                    online={this.props.online}
                                                    viewport={
                                                        this.props.viewport
                                                    }
                                                    isRightToLeftDirection={isRightToLeftDirection}
                                                />
                                            ) : slide[1].dynamicPage === 2 ? (
                                                <RTRBookTitleData
                                                    bookData={detailPages}
                                                    t={t}
                                                    book={book}
                                                    online={this.props.online}
                                                    onFavoriteClicked={this.props.onFavoriteClicked.bind(
                                                        this
                                                    )}
                                                    likesCount={
                                                        this.props.likesCount
                                                    }
                                                    isLoggedIn={
                                                        this.props.isLoggedIn
                                                    }
                                                    onOpenBioModal={
                                                        this.props
                                                            .onOpenBioModal
                                                    }
                                                    onOpenModal={
                                                        this.props.onOpenModal
                                                    }
                                                />
                                            ) : (
                                                ''
                                            ))}
                                    </div>
                                </div>
                            </div>
                        );
                    })}
                </div>

                <div
                    id="next-btn"
                    className={`${isBookOpen ? 'bookOpen' : ''} ${
                        isBookClosed.isClosed ? 'bookClose' : ''
                    }`}
                    onClick={() => this.goNextPage()}
                >
                    {currentPageNum * 2 <= detailPages.pages.length && (
                        <SvgIcon 
                            name={isRightToLeftDirection 
                                    ? "reader-view-previous-page" 
                                    : "reader-view-next-page"} 
                            size="reader-view-navigation"
                        />
                    )}
                </div>

                {   
                    this.props.isLoggedIn &&
                    !this.props.isBookRated &&
                    window.navigator.onLine && 
                        <Modal
                            isOpen={this.state.modalIsOpen}
                            style={customStyles}
                            onAfterOpen={() => {
                                document.body.style.overflow = 'hidden';
                            }}
                            onAfterClose={() => {
                                document.body.style.overflow = 'auto';
                            }}
                        >
                            <BookRating 
                                t={t}
                                handleClose={this.closePopup}
                                bookId={id}
                                bookSlug={slug}
                                history={this.props.history}
                            />
                        </Modal> 
                    
                }
            </div>
        );
    }
}

export default PageFlipAnimation;
