import React, { Component } from 'react';
import ReactPlayer from 'react-player';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import './VideoPlayer.scss';

class VideoPlayer extends Component {

    render() {
        const baseClassName = 'pb-video-player';
        const { parentClassName, videoSrc, videoPlay, name } = this.props;

        const classes = {
            [baseClassName]: true,
            [parentClassName]: parentClassName,
        };

        return (
            <div className={classNames(classes)}>
                <ReactPlayer
                    playing
                    controls="true"
                    title={name}
                    autoPlay={videoPlay}
                    url={videoSrc}
                />
            </div>
        );
    }
}

VideoPlayer.propTypes = {
    parentClassName: PropTypes.string,
};

export default VideoPlayer;
