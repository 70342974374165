import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import Link from '../../Link';

const BookTitle = ({
    onClick,
    baseClassName,
    bookPath,
    bookTitleGTM,
    handleClick,
    titleClasses,
    myLanguage,
    title,
    readingListBookCardTitle,
}) => {
    const renderTitle = () => {
        return (
            <div className={classNames(titleClasses)} data-gtm={bookTitleGTM}>
                <div
                    className={
                        myLanguage === 'lo'
                            ? `${baseClassName}__book-lo-title`
                            : `${baseClassName}__book-title`
                    }
                    data-gtm={bookTitleGTM}
                >
                    {title}
                </div>
            </div>
        );
    };

    return readingListBookCardTitle ? (
        renderTitle()
    ) : (
        <Link
            isInternal={onClick ? false : true}
            parentClassName={`${baseClassName}__link`}
            href={bookPath}
            category={'sdsadad'}
            dataGTM={bookTitleGTM}
            onClick={onClick ? handleClick : null}
        >
            {renderTitle()}
        </Link>
    );
};

BookTitle.propTypes = {
    onClick: PropTypes.func,
    baseClassName: PropTypes.string.isRequired,
    bookPath: PropTypes.string.isRequired,
    bookTitleGTM: PropTypes.string,
    handleClick: PropTypes.func,
    titleClasses: PropTypes.string,
    myLanguage: PropTypes.string,
    title: PropTypes.string.isRequired,
    readingListBookCardTitle: PropTypes.bool,
};

export default BookTitle;
