import React, { Component } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import './DocumentHeader.scss';

class DocumentHeader extends Component {
    render() {
        const baseClassName = 'pb-document-header';
        const {
            parentClassName,
            description,
            keywords,
            imageUrl,
            imgWidth,
            imgHeight,
            title,
        } = this.props;

        const classes = {
            [baseClassName]: true,
            [parentClassName]: parentClassName,
        };

        let pageUrl = window.location.href;
        let prerenderHeader = 'Location:' + pageUrl;

        return (
            <div className={classNames(classes)}>
                <Helmet key={pageUrl}>
                    <meta name="prerender-header" content={prerenderHeader} />

                    {/* <!-- Regular Meta Info  --> */}
                    <title>{title}</title>
                    <meta name="description" content={description} />
                    <meta name="keywords" content={keywords} />
                    <link rel="canonical" href={pageUrl} />

                    {/* <!--  Facebook Meta Info  --> */}
                    <meta property="og:url" content={pageUrl} />
                    <meta property="og:title" content={title} />
                    <meta property="og:type" content="website" />
                    <meta property="og:image" content={imageUrl} />
                    <meta property="og:image:url" content={imageUrl} />
                    <meta property="og:image:secure_url" content={imageUrl} />
                    <meta property="og:image:width" content={imgWidth} />
                    <meta property="og:image:height" content={imgHeight} />
                    <meta property="og:description" content={description} />
                    <meta property="og:image:type" content="image/jpeg" />
                    <meta property="og:image:alt" content={title} />
                    <meta
                        property="fb:app_id"
                        content={process.env.REACT_APP_FACEBOOK_APP_ID}
                    />

                    {/* <!--  Google+ Meta Info --> */}
                    <meta itemprop="name" content={title} />
                    <meta itemprop="description" content={description} />
                    <meta itemprop="image" content={imageUrl} />

                    {/* <!--  Twitter Meta Info  --> */}
                    {/* <meta name="twitter:card" content="summary" />
                    <meta name="twitter:url" content={pageUrl} />
                    <meta name="twitter:title" content={title} />
                    <meta name="twitter:description" content={description} />
                    <meta name="twitter:image" content={imageUrl} /> */}

                    <meta name="twitter:card" content="summary" />
                    <meta property="og:url" content={pageUrl} />
                    <meta property="og:title" content={title} />
                    <meta property="og:description" content={description} />
                    <meta property="og:image" content={imageUrl} />
                </Helmet>
            </div>
        );
    }
}

DocumentHeader.propTypes = {
    parentClassName: PropTypes.string,
};

export default DocumentHeader;
