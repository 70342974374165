export const helpGuideSteps = (t) => ({
    loggedInSteps: [
        {
            target: '.pb-site-nav-link__bookTab',
            content: t('Home.bookTabHelpGuide'),
            placementBeacon: 'left',
            disableBeacon: true,
        },
        {
            target: '.pb-site-nav-link__readingListTab',
            content: t('Home.readingListTabHelpGuide'),
            placementBeacon: 'left',
        },
        {
            target: '.pb-site-nav-link__videosTab',
            content: t('Home.videosTabHelpGuide'),
            placementBeacon: 'left',
        },
        {
            target: '.pb-site-nav-link__resourcesTab',
            content: t('Home.resourcesTabHelpGuide'),
            placementBeacon: 'left',
        },
        {
            target: '.pb-site-nav-link__helpguide',
            content: t('Home.HelpGuide'),
            placementBeacon: 'left',
        },
        {
            target: '.pb-avatar__image',
            content: t('Home.profileTabHelpGuide'),
            placementBeacon: 'left',
        },
    ],
    loggedOutSteps: [
        {
            target: '.pb-site-nav-link__bookTab',
            content: t('Home.bookTabHelpGuide'),
            placementBeacon: 'left',
            disableBeacon: true,
        },
        {
            target: '.pb-site-nav-link__readingListTab',
            content: t('Home.readingListTabHelpGuide'),
            placementBeacon: 'left',
        },
        {
            target: '.pb-site-nav-link__videosTab',
            content: t('Home.videosTabHelpGuide'),
            placementBeacon: 'left',
        },
        {
            target: '.pb-site-nav-link__resourcesTab',
            content: t('Home.resourcesTabHelpGuide'),
            placementBeacon: 'left',
        },
        {
            target: '.pb-site-nav-link__helpguide',
            content: t('Home.HelpGuide'),
            placementBeacon: 'left',
        },
    ],
});
