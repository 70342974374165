import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { translate } from 'react-polyglot';
import Link from '../Link';
import './Logo.scss';
import logo from './RTR.svg';
import logoCentre from './RtR_New_Logo.svg';
import vietnamFooterLogo from './vietnamMoetLogo.svg';

@translate()
class Logo extends Component {
    static defaultProps = {
        isCentreAligned: false,
        variant: 'logo',
    };

    render() {
        const { t, isVietnamese, variant } = this.props;

        let image = { logo, logoCentre, vietnamFooterLogo }[variant];

        const baseClassName = 'pb-logo';
        const classNames = [baseClassName];

        const classNamesDict = {
            logoCentre: 'pb-logo__img--centre-aligned',
            logo: 'pb-logo__img',
            vietnamFooterLogo: 'pb-logo__vietnam-footer-image'
        };

        const imageClassName = classNamesDict[variant];

        if (this.props.parentClassName) {
            classNames.push(this.props.parentClassName);
        }


        return (
            <Link
                parentClassName={classNames.join(' ')}
                {...this.props}
                dataGTM="nav-logo"
            >
                <img
                    className={isVietnamese ? 'vietnamLogo' : imageClassName}
                    src={image}
                    alt={`${t('global.logo-of')} ${t('global.site-title')}`}
                    data-gtm="nav-logo"
                />
            </Link>
        );
    }
}

Logo.propTypes = {
    parentClassName: PropTypes.string,
    t: PropTypes.func,
    isCentreAligned: PropTypes.bool,
    variant: PropTypes.oneOf(['swLogo', 'logoCentre', 'logo']),
};

export default Logo;
