import React, { Component } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { translate } from 'react-polyglot';
import ResourceCard from '../ResourceCard';
import CardShelf from '../CardShelf';

const renderResourcesEls = (
    t,
    resources,
    sectionClicked,
    offline
) => {
    return resources.map(resourcesItem => {
        return (
            <ResourceCard
                id={resourcesItem.id}
                slug={resourcesItem.slug}
                title={resourcesItem.title}
                likesCount={resourcesItem.likesCount}
                readsCount={resourcesItem.readsCount}
                contentUrl={resourcesItem.contentUrl}
                isFavourite={resourcesItem.is_favourite}
                coverImage={resourcesItem.thumbUrl}
                resourceThumbnailGTM="related-resource-thumbnail"
                resourceTitleGTM="related-resource-title"
                isSimilarResource
            />
        );
    });
};

class ResourceShelf extends Component {
    static defaultProps = {};

    @translate()
    render() {
        const baseClassName = 'pb-resource-shelf';
        const {
            resources,
            viewport,
            t,
            sectionClicked,
            offline,
        } = this.props;

        const classes = {
            [baseClassName]: true,
        };

        return (
            <div className={classNames(classes)}>
                <CardShelf cellWidth="book-card" viewport={viewport}>
                    {renderResourcesEls(
                        t,
                        resources,
                        sectionClicked,
                        offline
                    )}
                </CardShelf>
            </div>
        );
    }
}

ResourceShelf.propTypes = {
    parentClassName: PropTypes.string,
};

export default ResourceShelf;
