import React, { Component } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { translate } from 'react-polyglot';
import { connect } from 'react-redux';
import ReadAloudsCard from '../ReadAloudsCard';
import LoaderBlock from '../LoaderBlock';
import './ReadAloudCardContainer.scss';
import Grid from '../Grid';
import { fetchReadAloudFiltersWorkflow, fetchReadAloudsWorkflow, videoReadCountWorkflow } from '../../redux/readAloudsActions';

const mapDispatchToProps = {
    fetchReadAloudFiltersWorkflow,
    fetchReadAloudsWorkflow,
};

const mapStateToProps = ({
    readAlouds,
    viewport,
    allFilters: { readFilters },
}) => ({
    ...readAlouds,
    viewport,
    appliedFilters: readFilters,
});

@translate()
@connect(
    mapStateToProps,
    mapDispatchToProps
)
class ReadAloudCardContainer extends Component {
    constructor(props) {
        super(props);
        // We operate on a copy of the reading list, since it's user editable.
        this.state = {
            // The filters bar allows searching through available
            // filter values. We store that search text here.
            filtersSearchText: {
                category: '',
                publisher: '',
                level: '',
                language: '',
            },
            isVideoMobile: false,
        };
    }

    componentDidMount() {
        this.isMobile();
    }

    isMobile = () => {
        if (
            window.screen.width <= 768 &&
            window.matchMedia('(orientation: portrait)').matches
        ) {
            this.setState({
                isVideoMobile: true,
            });
        } else {
            this.setState({
                isVideoMobile: false,
            });
        }
    };

    render() {
        const baseClassName = 'pb-readAloud-card-container';
        const {
            parentClassName,
            Videos,
            isFetchingReadAlouds,
            totalVideosCount,
            myLanguage
        } = this.props;

        const classes = {
            [baseClassName]: true,
            [parentClassName]: parentClassName,
        };

        const isRightToLeftDirection = localStorage.getItem('locale') === 'ar' || localStorage.getItem('locale') === 'ur';


        const NoResults = translate()(({ t }) => (
            <span>
                <p>{t('Books.no-result-warning-1')}</p>
                <p>{t('Books.no-result-warning-2')}</p>
                <ul 
                    style={ 
                        localStorage.getItem('locale') === "ar" || localStorage.getItem('locale') === "ur" 
                        ? {paddingLeft:'0',paddingRight:'0'}
                        :null
                    } 
                >
                    <li>{t('Books.no-result-warning-3')}</li>
                    <li>{t('Books.no-result-warning-4')}</li>
                    <li>{t('Books.no-result-warning-5')}</li>
                    <li>{t('Books.no-result-warning-6')}</li>
                </ul>
            </span>
        ));

        return (
            <div className={classNames(classes)}>
                {isFetchingReadAlouds || !Videos ? (
                    <LoaderBlock />
                ) : (
                        <div>
                            {Videos.length > 0 ? (
                                <Grid variant="4up">
                                    {Videos.map(videoItem => {
                                        return (
                                            <div key={videoItem.id}>
                                                <ReadAloudsCard
                                                    myLanguage={myLanguage}
                                                    videoID={videoItem.id}
                                                    videoSlug={videoItem.slug}
                                                    videoTitle={videoItem.name}
                                                    videoThumbnail={
                                                        videoItem.thumbUrl
                                                    }
                                                    videoUrl={videoItem.materialUrl}
                                                    videoDownloadUrl={
                                                        videoItem.downloadUrl
                                                    }
                                                    videoLikes={
                                                        videoItem.likesCount
                                                    }
                                                    videoReads={
                                                        videoItem.readsCount
                                                    }
                                                    videoTime={videoItem.duration}
                                                    isFavourite={
                                                        videoItem.is_favourite
                                                    }
                                                    readCount={
                                                        videoReadCountWorkflow
                                                    }
                                                    videoTitleGTM="videos-video-title"
                                                    videoThumbnailGTM="videos-video-thumbnail"
                                                />
                                            </div>
                                        );
                                    })}
                                </Grid>
                            ) : null}
                            <div className={
                                isRightToLeftDirection
                                ? "__no-result-section-rtl"
                                : "__no-result-section"
                            }>
                                {totalVideosCount ? null : <NoResults />}
                            </div>
                        </div>
                    )}
            </div>
        );
    }
}

ReadAloudCardContainer.propTypes = {
    parentClassName: PropTypes.string,
};

export default ReadAloudCardContainer;
