import React, { Component } from 'react';
import { connect } from 'react-redux';
import { translate } from 'react-polyglot';
import u from 'updeep';
import LoaderBlock from '../LoaderBlock';
import Pagination from '../Pagination';
import Block from '../Block';
import ContentStyler from '../ContentStyler';
import Grid from '../Grid';
import BookCardSmall from '../BookCardSmall';
import { fetchReadingListDetailsWorkflow } from '../../redux/readingListsActions';
import { sortOptions } from '../../lib/constants';


// TODO: Move this out or fix it.
const NoResults = translate()(({ t }) => (
    <span className="no-results-search">
        <p>{t('Books.no-result-warning-1')}</p>
        <p>{t('Books.no-result-warning-2')}</p>
        <ul>
            <li>{t('Books.no-result-warning-3')}</li>
            <li>{t('Books.no-result-warning-4')}</li>
            <li>{t('Books.no-result-warning-5')}</li>
            <li>{t('Books.no-result-warning-6')}</li>
        </ul>
    </span>
));

const mapDispatchToProps = {
    fetchReadingListDetailsWorkflow,
};

const mapStateToProps = ({
    readingLists,
    viewport,
    allFilters: { listsFilters },
}) => ({
    readingLists: readingLists.readingLists,
    isFetchingReadingList: readingLists.isFetchingReadingList,
    isFetchingMoreReadingList: readingLists.isFetchingMoreReadingList,
    loadedPages: readingLists.loadedPages,
    totalListsCount: readingLists.totalListsCount,
    hits: readingLists.hits,
    viewport,
    appliedFilters: listsFilters,
});

@translate()
@connect(
    mapStateToProps,
    mapDispatchToProps
)
class ListSearchResultsContainer extends Component {
    constructor(props) {
        super(props);

        const { t } = props;

        this.state = {
            // The filters bar allows searching through available
            // filter values. We store that search text here.
            filtersSearchText: {
                level: '',
                category: '',
                language: '',
            },
        };

        this.filtersBarSortOptions = [
            {
                name: t('sort.relevance'),
                queryValue: sortOptions.relevance,
            },
            {
                name: t('sort.new-arrivals'),
                queryValue: sortOptions.newArrivals,
            },
            {
                name: t('sort.most-viewed'),
                queryValue: sortOptions.mostViewed,
            },
            {
                name: t('sort.most-liked'),
                queryValue: sortOptions.mostLiked,
            },
        ];

        // FiltersBar uses a SelectBox to display sort options, whereas
        // FiltersPanel uses a RadioGroup. Both these components expect
        // different props to display options. We do the transformation
        // here, before passing the props down.
        this.filtersPanelSortOptions = this.props.translateFiltersBarSortOptionsToFiltersPanelSortOptions(
            this.filtersBarSortOptions
        );
    }

    // Updates filter bar search text for a specified filter.
    updateFiltersSearchText = (key, text) => {
        this.setState(
            u(
                {
                    filtersSearchText: {
                        [key]: text,
                    },
                },
                this.state
            )
        );
    };

    // Convenience methods for updating filter bar search text for
    // our current list of filters.
    updateLevelSearchText = e =>
        this.updateFiltersSearchText('level', e.target.value);
    updateCategorySearchText = e =>
        this.updateFiltersSearchText('category', e.target.value);
    updateLanguageSearchText = e =>
        this.updateFiltersSearchText('language', e.target.value);

    onLoadMore = () =>
        this.props.fetchReadingListDetailsWorkflow(
            this.props.appliedFilters,
            this.props.slug,
            this.props.loadedPages + 1
        );

    componentWillReceiveProps(nextProps) {}

    componentDidMount() {
        const {
            appliedFilters,
        } = this.props;

        this.props.fetchReadingListDetailsWorkflow(appliedFilters);
    }

    render() {
        const {
            t,
            readingLists,
            isFetchingReadingList,
            totalListsCount,
            hits,
        } = this.props;

        const isRightToLeftDirection = localStorage.getItem("locale") === "ar" || localStorage.getItem("locale") === "ur"


        // API sends filters in the wrong format for lists.

        const myLanguage = localStorage.getItem('locale');

        // TODO: nested ternary operators are BAD!
        const shouldShowLoadMore =
            this.props.loadedPages &&
            this.props.loadedPages < this.props.totalListsCount;

        const listTitleEl = this.props.searchQuery ? (
            <h3>
                { myLanguage !== 'ne' 
                    ?
                        `${hits} ${t('Search.results-for-count')} \u2018${this.props.searchQuery}\u2019` 
                    :
                        `\u2018${this.props.searchQuery}\u2019 ${t('Search.results-for-count-1')} ${hits} ${t('Search.results-for-count-2')}`
                }
            </h3>
        ) : (
            [
                <h3>{t('global.video', 2)}</h3>,
                <h3 className={isRightToLeftDirection ? "__total-margin-left" : "__normal"}>({hits})</h3>,
            ]
        );

        return (
            <Block background="transparent">
                <ContentStyler>
                    <div className="__search-result-title-list">
                        {listTitleEl}
                    </div>
                </ContentStyler>
                <div className="book-section">
                    {isFetchingReadingList || !readingLists ? (
                        <LoaderBlock />
                    ) : (
                        <div>
                            <div className="__reading-book-item-container">
                                <Grid variant="4up">
                                    {readingLists.map(readingListItem => {
                                        return (
                                            <div key={readingListItem.id}>
                                                <BookCardSmall
                                                    key={readingListItem.id}
                                                    readingListItem={
                                                        readingListItem
                                                    }
                                                    isFromReadingList
                                                    readingListsImage="reading-lists-image"
                                                    readingListsTitle="reading-lists-title"
                                                    readingListsViewBooks="reading-lists-view-books"
                                                />
                                            </div>
                                        );
                                    })}
                                </Grid>
                            </div>
                            {shouldShowLoadMore ? (
                                <Pagination
                                    onClick={this.onLoadMore}
                                    loading={
                                        this.props.isFetchingMoreReadingList
                                    }
                                    dataGTM="thematic-reading-list-view-more"
                                />
                            ) : null}
                            {totalListsCount ? null : <NoResults />}
                        </div>
                    )}
                </div>
            </Block>
        );
    }
}

ListSearchResultsContainer.propTypes = {};

export default ListSearchResultsContainer;
