import React from 'react';
import { translate } from 'react-polyglot';
import Link from '../Link';
import Dropdown from '../Dropdown';
import SvgIcon from '../SvgIcon';
import Loader from '../Loader';
import './RTRBookFavourites.scss';
import { links } from '../../lib/constants';

const RTRBookFavourites = ({
    t,
    isFavourite,
    favouriteToggleHandler,
    isFavouriting,
    isLoggedIn,
    logInMsg,
    parentClassName,
    bookSlug,
    videoSlug,
    bookFavorite,
    likes,
    landingPageBook
}) => {
    let heartEl = isFavourite ? (
        <img
            className="__heart-unfilled"
            src={require('../../assets/heart-filled.svg')}
            alt="Favourite"
        />
    ) : (
        <img
            className={'__heart-unfilled'}
            src={require('../../assets/heart-unfilled.svg')}
            alt=""
        />
    );

    heartEl = isFavouriting ? <Loader /> : heartEl;

    const likesCount = <span className={'__value'}>{likes}</span>

    const favoriteEl = (
        <div className={parentClassName}>
            <Link
                normal
                onClick={isLoggedIn ? favouriteToggleHandler : null}
                dataGTM="book-favorite"
            >
                {heartEl}
                {landingPageBook && <br />}
                {likesCount}
            </Link>
        </div>
    );
    if (isLoggedIn) {
        return favoriteEl;
    } else {
        return (
            <Dropdown toggleEl={favoriteEl}>
                <Link
                    favouriteShare
                    parentClassName={parentClassName}
                    fullWidth
                    legend={logInMsg}
                    href={bookSlug ? links.saveOffLogin(bookSlug) : links.redirectReadAloudSignIn(videoSlug)}
                    dataGTM={bookFavorite}
                >
                    <SvgIcon name="user" /> {t('global.log-in')}
                </Link>
            </Dropdown>
        );
    }
};
export default translate()(RTRBookFavourites);
