import React from 'react';
import PropTypes from 'prop-types';
import Block from '../../Block';
import ContentStyler from '../../ContentStyler';
import Link from '../../Link';
import rightArrow from '../../../assets/right-arrow.svg';
import { sectionClicked } from '../../../lib/constants';
import BooksSmallGrid from './BooksSmallGrid';

const BookSelections = ({
    t,
    readingLists,
    viewport,
    hasUserHistory,
    isRightToLeftDirection,
}) => {
    const readlinglist = `/readinglists`;

    return (
        <div>
            <Block background="transparent">
                <ContentStyler>
                    {readingLists.length !== 0 ? (
                        <div className="__homepage-reading-lists">
                            {viewport.large ? (
                                <h1 className="font_home_tab">
                                    {hasUserHistory
                                        ? t('Home.book-suggestions-title')
                                        : t('global.reading-list', 2)}
                                </h1>
                            ) : (
                                <div className="home">
                                    {hasUserHistory
                                        ? t('Home.book-suggestions-title')
                                        : t('global.reading-list', 2)}
                                </div>
                            )}
                            {viewport.large ? (
                                <Link
                                    label={`${t('global.see-more-books')}`}
                                    href={readlinglist}
                                    isInternal
                                    paddingTopHome
                                    dataGTM="view-thematic-reading-lists"
                                >
                                    {t('global.view-themed-reading-lists')}
                                    <img
                                        className={
                                            isRightToLeftDirection
                                                ? '__left-arrow'
                                                : '__right-arrow'
                                        }
                                        src={rightArrow}
                                        alt=""
                                        data-gtm="view-thematic-reading-lists"
                                    />
                                </Link>
                            ) : null}
                        </div>
                    ) : null}
                </ContentStyler>
            </Block>
            <Block
                noHorizontalPadding
                noVerticalPadding
                background="transparent"
            >
                <BooksSmallGrid
                    t={t}
                    readingLists={readingLists}
                    viewport={viewport}
                    horizontal
                    sectionClicked={sectionClicked.homeRecommendation}
                />
            </Block>
            {readingLists.length !== 0 ? (
                <div>
                    {viewport.large ? null : (
                        <div className="center-align">
                            <Link
                                label={`${t('global.see-more-books')}`}
                                href={readlinglist}
                                isInternal
                                paddingTopHome
                                dataGTM="view-thematic-reading-lists"
                            >
                                {t('global.view-themed-reading-lists')}
                                <img
                                    className={
                                        isRightToLeftDirection
                                            ? '__left-arrow'
                                            : '__right-arrow'
                                    }
                                    src={rightArrow}
                                    alt=""
                                    data-gtm="view-thematic-reading-lists"
                                />
                            </Link>
                        </div>
                    )}
                </div>
            ) : null}
        </div>
    );
};

BookSelections.propTypes = {
    t: PropTypes.func.isRequired,
    readingLists: PropTypes.arrayOf(PropTypes.object).isRequired,
    viewport: PropTypes.shape({
        large: PropTypes.bool.isRequired,
    }).isRequired,
    hasUserHistory: PropTypes.bool.isRequired,
    isRightToLeftDirection: PropTypes.bool.isRequired,
};

export default BookSelections;
