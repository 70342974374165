import React, { Component } from 'react';
import './BookUploadHeader.scss';
import locales from '../../i18n';
import { BookPublicationForm } from '../../api';

class BookUploadHeader extends Component {
    publishBook = (value) => {
        let x =
            'Woohoo! Your story has been published. Please wait for 2-5 minutes until the story shows on the BOOKS page.';
        BookPublicationForm.publish(this.props.bookId).then((response) => {
            if (response.status === 200) {
                value.history.push('/');
                window.location.reload();
                localStorage.setItem('publishMessage', x);
            } else {
                alert('Error while publishing the book');
            }
        });
    };

    saveBook = (value) => {
        let x = document.getElementById('snackbar1');
        x.className = 'show';
        setTimeout(() => {
            value.history.push('/');
            x.className = x.className.replace('show', '');
        }, 7000);
    };

    render() {
        const isRightToLeftDirection =
            localStorage.getItem('locale') === 'ar' ||
            localStorage.getItem('locale') === 'ur';
        const baseClassName = 'book-upload_header';
        const locale = localStorage.getItem('locale') || 'en';
        const messages = locales[locale];

        return (
            <div>
                <div className={`${baseClassName}__next_button`}>
                    <div className={`${baseClassName}__book_tag`}>
                        {' '}
                        {messages['Book.upload']}
                    </div>
                    <div className={`${baseClassName}__gap`}>
                        <button
                            className={
                                `${baseClassName}__save_button ` +
                                (this.props.currentPage === 1
                                    ? `${baseClassName}__disable`
                                    : '')
                            }
                            onClick={() => this.saveBook(this.props)}
                        >
                            {messages['Book.save']}
                        </button>
                        <button
                            className={
                                `${baseClassName}__publish_button ` +
                                (this.props.currentPage === 4
                                    ? ''
                                    : `${baseClassName}__disable`)
                            }
                            onClick={() => this.publishBook(this.props)}
                        >
                            {messages['Book.publish']}
                        </button>
                    </div>
                </div>
                <div className={`${baseClassName}__navigation`}>
                    <div
                        className={
                            this.props.currentPage >= 1
                                ? isRightToLeftDirection
                                    ? `${baseClassName}__step_border_left`
                                    : `${baseClassName}__step_border_right`
                                : `${baseClassName}__initial_step`
                        }
                    >
                        {messages['Book.step-1']}
                    </div>
                    <div
                        className={
                            this.props.currentPage >= 2
                                ? isRightToLeftDirection
                                    ? `${baseClassName}__step_border_left`
                                    : `${baseClassName}__step_border_right`
                                : `${baseClassName}__initial_step`
                        }
                    >
                        {messages['Book.step-2']}
                    </div>
                    <div
                        className={
                            this.props.currentPage >= 3
                                ? isRightToLeftDirection
                                    ? `${baseClassName}__step_border_left`
                                    : `${baseClassName}__step_border_right`
                                : `${baseClassName}__initial_step`
                        }
                    >
                        {messages['Book.step-3']}
                    </div>
                    <div
                        className={
                            `${baseClassName}__initial_step ` +
                            (this.props.currentPage >= 4
                                ? `${baseClassName}__last_step`
                                : '')
                        }
                    >
                        {messages['Book.step-4']}
                    </div>
                </div>
                <div id="snackbar1">
                    Your Story has been saved. You can edit, complete and
                    publish your story from Drafts Tab under the Profile in the
                    Menu.
                </div>
            </div>
        );
    }
}

export default BookUploadHeader;
