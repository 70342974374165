import React, { Component } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import './NotFoundPage.scss';
import { translate } from 'react-polyglot';
import SvgIcon from '../SvgIcon';
import SiteNavLink from '../SiteNavLink';
import Link from '../Link';
import {links} from '../../lib/constants'

@translate()
class NotFoundPage extends Component {
    // static defaultProps = {}
    constructor(props){
        super(props);

        const storedStatus = localStorage.getItem("status");
        const loginStatus = storedStatus !== "undefined" ? JSON.parse(storedStatus) : false;

        this.state = {
            loginStatus:loginStatus
        }
    }
    

    
    render() {
        const baseClassName = 'pb-not-found-page';
        const { t,parentClassName} = this.props;

        const classes = {
            [baseClassName]: true,
            [parentClassName]: parentClassName,
        };



        return (
            <div className={classNames(classes)}>
                <div className={`${baseClassName}__container`}>
                    <div className={`${baseClassName}__not-found-img`}>
                        <SvgIcon
                            name="Error-Page"
                            errorPage
                        />
                    </div>
                    <div className={`${baseClassName}__not-found-text`}>
                       {`${t('ErrorPage.message')}`}
                    </div>
                    <div className={`${baseClassName}__suggestion-text`}>
                        <div className={`${baseClassName}__suggestion-links-title`}>
                            {`${t('ErrorPage.suggestion')}`}{":"}
                        </div>
                        <div className={`${baseClassName}__suggestion-links`}>
                            <SiteNavLink 
                                bold
                                errorPage
                                href={'/'}
                            >
                                {`${t('ErrorPage.homePage')}`}
                            </SiteNavLink>
                            <SiteNavLink 
                                bold
                                errorPage
                                href={'/stories'}
                            >
                                {`${t('Home.book-recommended-books')}`}
                            </SiteNavLink>
                            <SiteNavLink
                                bold
                                errorPage
                                href={
                                    this.state.loginStatus
                                    ? '/resources'
                                    : links.fetchTabLogin('resources')
                                } 
                            >
                                {`${t('global.resources')}`}
                            </SiteNavLink>
                        </div>
                        <div className={`${baseClassName}__contact-us`}>
                            {`${t('ErrorPage.contactUs1')}`}<Link contactUs href={links.reportIssue()}>{`${t('ErrorPage.contactUs')}`}</Link>{`${t('ErrorPage.contactUs2')}`}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

NotFoundPage.propTypes = {
    parentClassName: PropTypes.string,
};

export default translate()(NotFoundPage);
