import React, { Component } from 'react';
import './PublicationForm.scss';
import { translate } from 'react-polyglot';
import InputFieldTitle from '../InputFieldTitle';
import TextField from '../TextField';
import SelectField from '../SelectField';
import { editions, orientations} from '../../lib/constants';
import { BookPublicationForm, OfflineStory } from '../../api';
import BookUploadFooter from '../BookUploadFooter';
import BookUploadHeader from '../BookUploadHeader';
import Picklist from '../Picklist';
import Dropdown from '../Dropdown';
import Authors from '../Authors';
import Illustrators from '../Illustrators';
import Sizer from '../Sizer';
import 'react-select/dist/react-select.css';
import Translators from '../Translators';
import DataLoader from '../DataLoader/DataLoader';
import { links } from '../../lib/constants';

const limitList = list => {
    let result = '';

    if (list.length > 0 && list.length <= 2) {
        result = list.join(', ');
    }

    if (list.length > 2) {
        result = `${list[0]}, ${list[1]} + ${list.length - 2}`;
    }

    return result;
};

@translate()
export default class PublicationForm extends Component {
    constructor(props) {
        super(props);

        const storedStatus = localStorage.getItem("status");
        const loginStatus = storedStatus !== "undefined" ? JSON.parse(storedStatus) : false;

        this.state = {
            english_title: '',
            edition_type: '',
            title: '',
            languages: [{ id: '', name: '', locale: '' }],
            language_id: '',
            orientation: '',
            copy_right_years:[{ name: '', queryValue: '' }],
            copy_right_year: '',
            license_types: [{ id: '', name: '' }],
            license_type_id: '',
            tags_data: '', //individual search term
            synopsis: '',
            publishers: [{ id: '', organization_name: '' }],
            organization_id: '',
            copy_right_holder_id: '',
            donors: [{ id: '', name: '' }],
            donor_id: '',
            credit_line: '',
            copyright_holder: '',
            reading_level: '',
            category_text_value: [],//data to be shown in textfield
            category_id_value:[],//data to send to api
            offline_author_ids: [],
            offline_illustrator_ids: [],
            offline_translator_ids:[],
            titleSearchValue: '',
            stories: [],
            closeDropdown: false,
            closeDropdown1: false,
            isFormValid:false,
            offline_authors:[],
            offline_illustrators:[],
            offline_translators:[],
            isLoadData:false,
            existingTitle:'',
            existingSynopsis:'',
            uploadFlow:false,
            tags_message:'Please ensure each tag is separated by a comma ( , )',
            isLoadSalesForceData:false,
            projectIds:[],
            filteredProjectIds:[],
            projectId:'',
            projectIdDropDown:false,
            bookId:'',
            displayMessages:[],
            isSIDPresent:false,
            loginStatus:loginStatus
        };
    }

    componentDidMount() {
        //new-edition path url check
        if(this.state.loginStatus === false){
            this.props.history.push(links.fetchTabLogin(`details`));
            window.location.reload();
        }
        else {
            if (
                window.location.pathname &&
                window.location.pathname.split('/')[3] !== undefined
            ) {
                OfflineStory.fetchOfflineStoryDetail(
                    window.location.pathname.split('/')[2]
                )
                    .then((response) => {
                        return response.json();
                    })
                    .then((response) => {
                        const data = response.data
                        this.setState({
                            uploadFlow: true,
                            isLoadData: true,
                            existingTitle: data.english_title,
                            english_title: data.english_title,
                            existingSynopsis: data.synopsis,
                            offline_authors: data.authors,
                            offline_illustrators: data.illustrators,
                            offline_translators: data.translators,
                        });
                        this.assignAuthorId(data.authors);
                        this.assignIllustratorId(data.illustrators);
                    });
            } else if (
                window.location.pathname &&
                window.location.pathname.split('/')[2] !== undefined
            ) {
                
                OfflineStory.fetchOfflineStoryDetail(
                    window.location.pathname.split('/')[2]
                )
                    .then((response) => {
                        return response.json();
                    })
                    .then((response) => {
                        const data = response.data
                        this.setState({
                            isLoadData: true,
                            // isFormValid: !this.state.isFormValid,
                            english_title: data.english_title != null ? data.english_title : "",
                            edition_type: data.edition_type != null ? data.edition_type : "",
                            title: data.title,
                            language_id: data.language.id.toString(),
                            orientation: data.orientation != null ? data.orientation : "",
                            copy_right_year: data.copy_right_year.toString(),
                            license_type_id: data.license_type.id.toString(),
                            tags_data: data.tag_list,
                            synopsis: data.synopsis,
                            organization_id: data.publishers.id != null ? data.publishers.id.toString() : "",
                            copy_right_holder_id:
                             data.copy_right_holder.id != null ? data.copy_right_holder.id.toString() : "",
                            donor_id: data.donor != null ? data.donor.id : "",
                            credit_line: data.dedication_language,
                            reading_level: data.level != null ? data.level : "",
                            offline_authors: data.authors,
                            offline_illustrators: data.illustrators,
                            offline_translators: data.translators,
                            projectId:data.salesforceProjectID != null ? data.salesforceProjectID : "",
                            bookId:data.id,
                            isSIDPresent:data.isSIDPresent
                        },
                        function () {
                            this.isFormValid();
                        });
                        this.assignCategoryValue(data.categories);
                        this.assignAuthorId(data.authors);
                        this.assignIllustratorId(data.illustrators);
                        this.assignTranslatorId(data.translators);

                    })
                    .then(() => {
                        BookPublicationForm.categories(this.state.language_id)
                            .then((response) => {
                                return response.json();
                            })
                            .then((response) => {
                                const data = response.categories;
                                this.setState({ categories: data });
                            });
                    });
            } else {
                this.setState({
                    isLoadData: true,
                });
            }

            BookPublicationForm.languages()
                .then((response) => {
                    return response.json();
                })
                .then((response) => {
                    const data = response.languages;
                    this.setState({
                        languages: this.state.languages.concat(data),
                    });
                });
            BookPublicationForm.licence_type()
                .then((response) => {
                    return response.json();
                })
                .then((response) => {
                    const data = response.license_types;
                    this.setState({
                        license_types: this.state.license_types.concat(data),
                    });
                });
            BookPublicationForm.publishers()
                .then((response) => {
                    return response.json();
                })
                .then((response) => {
                    const data = response.publishers;
                    this.setState({
                        publishers: this.state.publishers.concat(data),
                    });
                });
            BookPublicationForm.donors()
                .then((response) => {
                    return response.json();
                })
                .then((response) => {
                    const data = response.donors;
                    this.setState({ donors: this.state.donors.concat(data) });
                });
            BookPublicationForm.copy_right_years()
                .then((response) => {
                    return response.json();
                })
                .then((response) => {
                    const data = response.copy_right_years;
                    this.setState({
                        copy_right_years:
                            this.state.copy_right_years.concat(data),
                    });
                });
            // fetch the project Ids using normal api (remove login and token retrival)
                    BookPublicationForm.salesForceIds()
                    .then((response) => {
                        return response.json()
                    })
                    .then((projectId) =>{
                        
                        this.setState({
                            isLoadData:true,
                            projectIds:projectId.data
                        })
                    })
                .catch((error) => {
                    console.error('Error:', error);
                });
        }
    }

    assignCategoryValue = (value) => {
        value.map((category) => {
            this.setState( prevState => (
                {
                    category_text_value: [...prevState.category_text_value, category.name],
                    category_id_value:[...prevState.category_id_value, category.id]
                }
                )); 
        })
    }

    assignAuthorId = (value) => {
        this.state.offline_author_ids = []
        value.map ((author) => {
            this.setState((prevState) => (
                {
                    offline_author_ids:[...prevState.offline_author_ids, author.id]
                }
            ))
        })
    }
    

    assignIllustratorId = (value) => {
        this.state.offline_illustrator_ids = []
        value.map ((illustrator) => {
            this.setState((prevState) => (
                {
                    offline_illustrator_ids:[...prevState.offline_illustrator_ids, illustrator.id]
                }
            ))
        })
    }

    assignTranslatorId = (value) => {
        this.state.offline_translator_ids = []
        value.map ((translator) => {
            this.setState((prevState) => (
                {
                    offline_translator_ids:[...prevState.offline_translator_ids, translator.id]
                }
            ))
        })
    }



    handleSelect = (field, value) => {
        this.setState({ [field]: value },
            function () {
            this.isFormValid();
        });
        if(field === 'language_id'){
            BookPublicationForm.categories(value)
            .then((response) => {
                return response.json();
            })
        .then((response) => {
            const data = response.categories;
            this.setState({ categories: data }
                ,
                function () {
                    this.isFormValid();
                }
                );
        });
        }
    };

    handleRadio = (level) => {
        this.setState({
            reading_level: level,
        }
        ,
        function () {
            this.isFormValid();
        }
        );
        
    };

    onDescriptionChange = (field, e) => {
        this.setState(
            {
                [field]: e.target && e.target.value ? e.target.value : e,
                closeDropdown: false,
                isLoadData: false,
            },
            function () {
                this.isFormValid();
            }
        );
        BookPublicationForm.prefillData(
            e.target && e.target.value ? e.target.value : e
        )
            .then((response) => {
                return response.json();
            })
            .then((response) => {
                this.setState((prevState) => ({
                    offline_authors: response.data.authors,
                    offline_illustrators: response.data.illustrators,
                    offline_translators: response.data.translators,
                    isLoadData: true,
                }));
                this.assignAuthorId(response.data.authors);
                this.assignIllustratorId(response.data.illustrators);
                this.assignTranslatorId(response.data.translators);
            });
    };

    onTitleChange = (field, e) => {
        this.setState({
            [field]: e.target.value
        }
        ,
        function () {
            this.isFormValid();
        }
        )
    }

    onSummaryChange = (field, e) => {
        this.setState({ [field]: e.target.value }
            ,function () {
            this.isFormValid();
        }
        );
    };

    nextPage = () => {
        this.handleSubmit();
        
    };

    onSearchTitle = (field, e) => {
        const re = /^[a-zA-Z0-9!@#\$%\^\&*\)\(+=._-]+|[\b]+$/g
        if (e.target.value === "" || re.test(e.target.value)) {
            this.setState({ [field]: e.target.value, closeDropdown: true });
        }
      
        BookPublicationForm.searchEnglishTitle(e.target.value)
            .then((response) => {
                return response.json();
            })
            .then((response) => {
                
                this.setState({
                    stories: response.stories,
                    closeDropdown: true,
                });
            });
    };

    
    // Search for ProjectId from SalesForce
    onSearchProjectId = (e) => {
        const { projectIds } = this.state;
        const userInput = e.target.value;
        const filteredIds = projectIds.filter(
            (project) => project.toUpperCase().indexOf(userInput.toUpperCase()) > -1
        );
        this.setState({
            projectId:userInput,
            filteredProjectIds:filteredIds,
            projectIdDropDown:true
        },
        function () {
            this.isFormValid();
        }
        )
        
    }

    // Select ProjectId from SalesForce and login again to fetch token
    // Load selected data
    onSelectProjectId = (e) => {
        this.setState(
            {
                projectId: e.target && e.target.value ? e.target.value : e,
                projectIdDropDown: false,
                isLoadData:false
            },
            function () {
                this.isFormValid();
            }
        );
        BookPublicationForm.salesForceData( e.target && e.target.value ? e.target.value : e)
        .then((response) => {
            return response.json()
        })
        .then((response) =>{
            const projectData = response.data
            this.setState({
                isLoadData:true,
                english_title: projectData.english_title != null ? projectData.english_title : "",
                edition_type: projectData.edition_type != null ? projectData.edition_type : "",
                title: projectData.title,
                language_id: projectData.language != null ? projectData.language.id.toString() : "",
                synopsis: projectData.synopsis,
                reading_level: projectData.level != null ? projectData.level : "",
                offline_authors: projectData.authors,
                offline_illustrators: projectData.illustrators,
                offline_translators: projectData.translators
            });
            this.state.language_id && BookPublicationForm.categories(this.state.language_id)
                .then((response) => {
                    return response.json();
                })
                .then((response) => {
                    const data = response.categories;
                    this.setState({ categories: data });
                });
            this.assignAuthorId(projectData.authors);
            this.assignIllustratorId(projectData.illustrators);
            this.assignTranslatorId(projectData.translators);
        })
        
    }

    onTagsInput = (field,e) => {
        this.setState({
            [field]: e.target.value,
        },
        function () {
                    this.isFormValid();
                });
    };


    handlePicklistChange = (type, value, checked) => {
        const index = this.state[type].indexOf(value);
        const id = this.state.categories.filter ((v) =>
            (v.queryValue === value)
        )

        if (index !== -1) {
            // item found
            this.setState(
                {
                    category_id_value: this.state.category_id_value.filter(style => style !== id[0].id),
                    [type]:this.state[type].filter(style => style !== value)
                }
                ,
                function () {
                    this.isFormValid();
                }
            );
        } else {
            this.setState(
                {
                    category_id_value: this.state.category_id_value.concat([id[0].id]),
                    [type]:this.state[type].concat([value])
                }
                ,
                function () {
                    this.isFormValid();
                }
            );
        }
    };

    onAddAuthorId = (value, status) => {
        if(status === "add"){
            this.setState( prevState => (
                {
                offline_author_ids: [...prevState.offline_author_ids, value]
                }
                )
                ,
                function () {
                    this.isFormValid();
                }
                );
        }
        else{
            this.setState({
                offline_author_ids: this.state.offline_author_ids.filter(
                    c => c !== value
                )
            }
            ,
            function () {
                this.isFormValid();
            }
            );
        }
    }

    onAddIllustratorId =(value, status) => {
        if(status === "add"){
            this.setState( prevState => ({
                offline_illustrator_ids: [...prevState.offline_illustrator_ids, value]
            })
            ,
            function () {
                this.isFormValid();
            }
            );
        }
        else{
            this.setState({
                offline_illustrator_ids: this.state.offline_illustrator_ids.filter(
                    c => c !== value
                )
            }
            ,
            function () {
                this.isFormValid();
            }
            );
        }
    }

    onAddTranslatorId = (value,status) => {
        if(status === "add"){
            this.setState( prevState => ({
                offline_translator_ids: [...prevState.offline_translator_ids, value]
            })
            ,
            function () {
                this.isFormValid();
            }
            );
        }
        else{
            this.setState({
                offline_translator_ids: this.state.offline_translator_ids.filter(
                    c => c !== value
                )
            }
            ,
            function () {
                this.isFormValid();
            }
            );
        }
    }

    isFormValid = () => {
        const {
            english_title,
            edition_type,
            title,
            language_id,
            orientation,
            copy_right_year,
            license_type_id,
            tags_data,
            synopsis,
            organization_id,
            reading_level,
            category_id_value,
            offline_author_ids,
            offline_illustrator_ids,
            offline_translator_ids,
            existingTitle,
            projectId,
            bookId,
        } = this.state;
        this.setState({
            isFormValid:
            (english_title.length > 0 || existingTitle.length > 0) &&
            edition_type.length > 0 &&
            (bookId ? projectId.length >= 0 : projectId.length > 0 )&&
            title != null && title.length > 0 && 
            language_id.length > 0 &&
            orientation.length > 0 &&
            license_type_id.length > 0 &&
            copy_right_year.length > 0 &&
            tags_data.length  > 0 &&
            synopsis != null && synopsis.length > 0 &&
            organization_id.length > 0 &&
            reading_level != null && reading_level && reading_level.length > 0 &&
            category_id_value.length > 0 &&
            offline_author_ids.length > 0 &&
            offline_illustrator_ids.length > 0 &&
            (edition_type === 'adaptation'? offline_translator_ids.length > 0 : true)
        });
    };

    handleSubmit = () => {
        const params = {
            title: this.state.title,
            english_title: this.state.english_title,
            edition_type: this.state.edition_type,
            language_id: this.state.language_id,
            copy_right_year: this.state.copy_right_year,
            license_type_id: this.state.license_type_id,
            tag_list: this.state.tags_data,
            synopsis: this.state.synopsis,
            organization_id: this.state.organization_id,
            copy_right_holder_id: this.state.copy_right_holder_id,
            donor_id: this.state.donor_id,
            credit_line: this.state.credit_line,
            reading_level: this.state.reading_level,
            category_ids: this.state.category_id_value,
            orientation: this.state.orientation,
            offline_author_ids: this.state.offline_author_ids,
            offline_illustrator_ids: this.state.offline_illustrator_ids,
            offline_translator_ids:this.state.offline_translator_ids,
            salesforce_project_id:this.state.projectId
        };

        if(window.location.pathname &&
            window.location.pathname.split('/')[3] !== undefined){
                BookPublicationForm.createStory(params)
                    .then((response) => {
                        return response.json();
                    })
                    .then((response) => {
                        if(response.errors){
                            window.scrollTo(0,30)
                            this.setState({
                                displayMessages:response.errors
                            })
                        }
                        else if (response.data.story.id) {
                            this.props.history.push(
                                `/upload/${response.data.story.id}`
                            );
                        }
                    });
            }
        else if(window.location.pathname &&
            window.location.pathname.split('/')[2] !== undefined){
                BookPublicationForm.updateStory(params,window.location.pathname.split('/')[2])
                    .then((response) => {
                        return response.json();
                    })
                    .then((response) => {
                        if(response.errors){
                            window.scrollTo(0,30)
                            this.setState({
                                displayMessages:response.errors
                            })
                        }
                        else if(response.data.story.id) {
                            this.props.history.push(
                                `/upload/${response.data.story.id}`
                            );
                        }
                    });
            }
        else {
            BookPublicationForm.createStory(params)
                .then((response) => {
                    return response.json();
                })
                .then((response) => {
                    if(response.errors){
                        window.scrollTo(0,30)
                        this.setState({
                            displayMessages:response.errors
                        })
                    }
                    else if (response.data.story.id) {
                        this.props.history.push(
                            `/upload/${response.data.story.id}`
                        );
                    }
                });
            }
        
    };

    render() {
        const isRightToLeftDirection =
            localStorage.getItem('locale') === 'ar' ||
            localStorage.getItem('locale') === 'ur';
        const baseClassName = 'pb-upload-book-details';
        const { t } = this.props;
        const {
            title,
            english_title,
            languages,
            edition_type,
            orientation,
            license_types,
            license_type_id,
            language_id,
            copy_right_year,
            synopsis,
            publishers,
            organization_id,
            copy_right_holder_id,
            donors,
            donor_id,
            credit_line,
            reading_level,
            copy_right_years,
            isLoadData,
            projectId,
            salesForceProjectId,
            displayMessages 
        } = this.state;
        return (
            <div>
                {isLoadData ?
            <div onClick={() => this.setState({ closeDropdown: false, closeDropdown1:false, projectIdDropDown:false })}>
                <BookUploadHeader currentPage={1} />
                <div className={
                    isRightToLeftDirection 
                    ? `${baseClassName}_form-info-rtl`
                    : `${baseClassName}_form-info`
                }>
                    {t('PublicationForm.information')}
                </div>
                <div className={
                    isRightToLeftDirection 
                    ? `${baseClassName}_form-error-info-rtl`
                    : `${baseClassName}_form-error-info`
                }>
                    {
                        displayMessages && displayMessages.map((error)=>{
                            return <div>{error}</div>
                        })
                    }
                </div>
                <form onSubmit={() => this.handleSubmit()}>
                    {   this.state.existingTitle &&
                        this.state.existingTitle.length > 0 
                        ?
                        <div
                            className={
                                isRightToLeftDirection
                                    ? `${baseClassName}_existing-title-rtl`
                                    : `${baseClassName}_existing-title`
                            }
                        >
                            <div style={{fontWeight:'800'}}>
                                {t('PublicationForm.originalStoryTitle')}
                            </div>
                            <div>
                                {this.state.existingTitle}
                            </div>
                        </div>
                        :
                        null
                    }
                    <div
                        className={
                            isRightToLeftDirection
                                ? `${baseClassName}_row-wrapper-rtl`
                                : `${baseClassName}_row-wrapper`
                        }
                    >
                        <div 
                            className={
                                isRightToLeftDirection
                                    ? `${baseClassName}_header-container-rtl`
                                    : `${baseClassName}_header-container`
                            }
                        >
                            <InputFieldTitle
                                isRequired={true}
                                t={t}
                                value={t('PublicationForm.storyTitle')}
                                isRightToLeftDirection={isRightToLeftDirection}
                                isSubHeading={t(
                                    'PublicationForm.storySubHeading'
                                )}
                            />
                            <div className={
                                this.state.existingTitle || this.state.projectId || salesForceProjectId
                                    ? `${baseClassName}_disable-title `
                                    : ''
                            } style={{ position: 'relative' }}>
                                <TextField
                                    id="story-title"
                                    type="text"
                                    placeholder={t('PublicationForm.typeHere')}
                                    autoFocus={false}
                                    custom={true}
                                    bordered={false}
                                    isPublication
                                    onChange={this.onSearchTitle.bind(
                                        this,
                                        'english_title'
                                    )}
                                    value={this.state.existingTitle ? this.state.existingTitle : english_title}
                                    isEnglishOnly
                                />
                                {this.state.closeDropdown && (
                                    <div
                                        className={
                                            isRightToLeftDirection
                                                ? `${baseClassName}_title-dropdown-rtl`
                                                : `${baseClassName}_title-dropdown`
                                        }
                                    >
                                        <Sizer maxHeight="l" scrollY>
                                            {this.state.stories.map(
                                                (story, index) => {
                                                    return (
                                                        <div
                                                            onClick={() =>
                                                                this.onDescriptionChange(
                                                                    'english_title',
                                                                    story
                                                                )
                                                            }
                                                            value={
                                                                english_title
                                                            }
                                                        >
                                                            {story}
                                                        </div>
                                                    );
                                                }
                                            )}
                                        </Sizer>
                                    </div>
                                )}
                            </div>
                        </div>
                        <div className={
                                isRightToLeftDirection
                                    ? `${baseClassName}_header-container-rtl`
                                    : `${baseClassName}_header-container`
                            }>
                            <InputFieldTitle
                                isRequired={true}
                                t={t}
                                value={t('PublicationForm.editionType')}
                                isRightToLeftDirection={isRightToLeftDirection}
                            />
                            <div className={
                                 this.state.projectId || salesForceProjectId
                                    ? `${baseClassName}_disable-title `
                                    : ''
                            } >
                                <SelectField
                                    options={editions}
                                    publicationForm
                                    onChange={this.handleSelect.bind(
                                        this,
                                        'edition_type'
                                    )}
                                    value={edition_type}
                                />
                            </div>
                        </div>
                        <div 
                            className={
                                isRightToLeftDirection
                                    ? `${baseClassName}_header-container-rtl`
                                    : `${baseClassName}_header-container`
                            }
                        >
                            <InputFieldTitle
                                isRequired={this.state.bookId ? false : true}
                                t={t}
                                value={t('PublicationForm.projectId')}
                                isRightToLeftDirection={isRightToLeftDirection}
                                tooltip={true}
                                tooltipData={t(
                                    'PublicationForm.projectId-Message'
                                )}
                            />
                            {/* SalesForce Dropdown */}
                            <div className={
                                ((this.state.isSIDPresent === true)) 
                                    ? `${baseClassName}_disable-title `
                                    : ''
                            } style={{ position: 'relative' }}>
                                <TextField
                                    id="project-id"
                                    type="text"
                                    placeholder={t('PublicationForm.typeHere')}
                                    autoFocus={false}
                                    custom={true}
                                    bordered={false}
                                    isPublication
                                    onChange={this.onSearchProjectId.bind(this)}
                                    value={projectId}
                                />
                                {this.state.projectIdDropDown && (
                                    <div
                                        className={
                                            isRightToLeftDirection
                                                ? `${baseClassName}_title-dropdown-rtl`
                                                : `${baseClassName}_title-dropdown`
                                        }
                                    >
                                        <Sizer maxHeight="l" scrollY>
                                            {this.state.filteredProjectIds.map(
                                                (id, index) => {
                                                    return (
                                                        <div
                                                            onClick={() =>
                                                                this.onSelectProjectId(id)
                                                            }
                                                            value={
                                                                projectId
                                                            }
                                                        >
                                                            {id}
                                                        </div>
                                                    );
                                                }
                                            )}
                                        </Sizer>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                    <div className={`${baseClassName}_row-wrapper`}>
                        <div className={
                                isRightToLeftDirection
                                    ? `${baseClassName}_header-container-rtl`
                                    : `${baseClassName}_header-container`
                            }>
                            <InputFieldTitle
                                isRequired={true}
                                t={t}
                                value={t('PublicationForm.storyTitle')}
                                isRightToLeftDirection={isRightToLeftDirection}
                                isSubHeading={t(
                                    'PublicationForm.translateSubHeading'
                                )}
                            />
                            <div>
                                <TextField
                                    id="translated-story-title"
                                    type="text"
                                    placeholder={t('PublicationForm.typeHere')}
                                    autoFocus={false}
                                    custom={true}
                                    bordered={false}
                                    isPublication
                                    onChange={this.onTitleChange.bind(
                                        this,
                                        'title'
                                    )}
                                    value={title}
                                />
                            </div>
                        </div>
                        <div className={
                                isRightToLeftDirection
                                    ? `${baseClassName}_header-container-rtl`
                                    : `${baseClassName}_header-container`
                            }>
                            <InputFieldTitle
                                isRequired={true}
                                t={t}
                                value={t('PublicationForm.language')}
                                isRightToLeftDirection={isRightToLeftDirection}
                            />
                            {languages.length > 0 && (
                                <div style={{ position: 'relative' }}>
                                    <SelectField
                                        options={languages}
                                        publicationForm
                                        onChange={this.handleSelect.bind(
                                            this,
                                            'language_id'
                                        )}
                                        value={language_id}
                                    />
                                </div>
                            )}
                        </div>
                        <div className={
                                isRightToLeftDirection
                                    ? `${baseClassName}_header-container-rtl`
                                    : `${baseClassName}_header-container`
                            }>
                            <InputFieldTitle
                                isRequired={true}
                                t={t}
                                value={t('PublicationForm.orientation')}
                                isRightToLeftDirection={isRightToLeftDirection}
                            />
                            <SelectField
                                options={orientations}
                                publicationForm
                                onChange={this.handleSelect.bind(
                                    this,
                                    'orientation'
                                )}
                                value={orientation}
                            />
                        </div>
                    </div>
                    <div className={`${baseClassName}_row-wrapper`}>
                        <div className={
                                isRightToLeftDirection
                                    ? `${baseClassName}_header-container-rtl`
                                    : `${baseClassName}_header-container`
                            }>
                            <InputFieldTitle
                                isRequired={true}
                                t={t}
                                value={t('PublicationForm.copyRight')}
                                isRightToLeftDirection={isRightToLeftDirection}
                            />
                            <SelectField
                                options={copy_right_years}
                                publicationForm
                                onChange={this.handleSelect.bind(
                                    this,
                                    'copy_right_year'
                                )}
                                value={copy_right_year}
                            />
                        </div>
                        <div className={
                                isRightToLeftDirection
                                    ? `${baseClassName}_header-container-rtl`
                                    : `${baseClassName}_header-container`
                            }>
                            <InputFieldTitle
                                isRequired={true}
                                t={t}
                                value={t('PublicationForm.licenceType')}
                                isRightToLeftDirection={isRightToLeftDirection}
                            />
                            {license_types.length > 0 && (
                                <SelectField
                                    options={license_types}
                                    publicationForm
                                    onChange={this.handleSelect.bind(
                                        this,
                                        'license_type_id'
                                    )}
                                    value={license_type_id}
                                />
                            )}
                        </div>
                        <div className={
                                isRightToLeftDirection
                                    ? `${baseClassName}_header-container-rtl`
                                    : `${baseClassName}_header-container`
                            }>
                            <InputFieldTitle
                                isRequired={true}
                                t={t}
                                value={t('PublicationForm.tags')}
                                isRightToLeftDirection={isRightToLeftDirection}
                                tooltip={true}
                                tooltipData={t(
                                    'PublicationForm.tooltipMessage'
                                )}
                            />
                            <div style={{ position: 'relative' }}>
                            <TextField
                                id="tags"
                                type="text"
                                placeholder={t('PublicationForm.typeHere')}
                                autoFocus={false}
                                custom={true}
                                bordered={false}
                                isPublication
                                onChange={this.onTagsInput.bind(this,'tags_data')}
                                value={
                                    this.state.tags_data
                                }
                            />
                            <h4 className={isRightToLeftDirection 
                                ? 'input-field-sub-heading-rtl'
                                : 'input-field-sub-heading'}>
                                {this.state.tags_message}
                            </h4>
                            </div>
                        </div>
                    </div>
                    <div className={`${baseClassName}_row-wrapper`}>
                        <div className={`${baseClassName}_summary-container`}>
                            <InputFieldTitle
                                isRequired={true}
                                t={t}
                                value={t('PublicationForm.summary')}
                                isRightToLeftDirection={isRightToLeftDirection}
                                isSubHeading={t(
                                    'PublicationForm.summarySubHeading'
                                )}
                            />
                            {   this.state.existingSynopsis &&
                                this.state.existingSynopsis.length > 0 
                                ?
                                <div
                                    className={
                                        isRightToLeftDirection
                                            ? `${baseClassName}_existing-synopsis-rtl`
                                            : `${baseClassName}_existing-synopsis`
                                    }
                                >
                                    <span style={{fontWeight:'800'}}>
                                        {t('PublicationForm.originalStorySynopsis')}&nbsp;
                                    </span>
                                    <span>
                                        {this.state.existingSynopsis}
                                    </span>
                                </div>
                                :
                                null
                            }
                            <div>
                                <TextField
                                    id="upload-details-input"
                                    placeholder={t('PublicationForm.typeHere')}
                                    size="m"
                                    theme="dark"
                                    onChange={this.onSummaryChange.bind(
                                        this,
                                        'synopsis'
                                    )}
                                    value={synopsis}
                                    custom={true}
                                    bordered={false}
                                    type="multiline"
                                    rows="4"
                                    isPublication
                                />
                            </div>
                        </div>
                    </div>
                    <div className={`${baseClassName}_row-wrapper`}>
                        <div className={
                                isRightToLeftDirection
                                    ? `${baseClassName}_header-container-rtl`
                                    : `${baseClassName}_header-container`
                            }>
                            <InputFieldTitle
                                isRequired={true}
                                t={t}
                                value={t('PublicationForm.publisher')}
                                isRightToLeftDirection={isRightToLeftDirection}
                            />
                            {publishers.length > 0 && (
                                <SelectField
                                    options={publishers}
                                    publicationForm
                                    onChange={this.handleSelect.bind(
                                        this,
                                        'organization_id'
                                    )}
                                    value={organization_id}
                                />
                            )}
                        </div>
                        <div className={
                                isRightToLeftDirection
                                    ? `${baseClassName}_header-container-rtl`
                                    : `${baseClassName}_header-container`
                            }>
                            <InputFieldTitle
                                isRequired={false}
                                t={t}
                                value={t('PublicationForm.copyRightHolder')}
                                isRightToLeftDirection={isRightToLeftDirection}
                            />
                            <SelectField
                                options={publishers}
                                publicationForm
                                onChange={this.handleSelect.bind(
                                    this,
                                    'copy_right_holder_id'
                                )}
                                value={copy_right_holder_id}
                            />
                        </div>
                        <div className={
                                isRightToLeftDirection
                                    ? `${baseClassName}_header-container-rtl`
                                    : `${baseClassName}_header-container`
                            }>
                            <InputFieldTitle
                                isRequired={false}
                                t={t}
                                value={t('PublicationForm.donor')}
                                isRightToLeftDirection={isRightToLeftDirection}
                            />
                            {donors.length > 0 && (
                                <SelectField
                                    options={donors}
                                    publicationForm
                                    onChange={this.handleSelect.bind(
                                        this,
                                        'donor_id'
                                    )}
                                    value={donor_id}
                                />
                            )}
                        </div>
                    </div>
                    <div className={`${baseClassName}_row-wrapper`}>
                        <div className={`${baseClassName}_summary-container`}>
                            <InputFieldTitle
                                isRequired={false}
                                t={t}
                                value={t('PublicationForm.dedication')}
                                isRightToLeftDirection={isRightToLeftDirection}
                            />
                            <TextField
                                id="upload-details-input"
                                placeholder={t('PublicationForm.typeHere')}
                                size="m"
                                theme="dark"
                                onChange={this.onSummaryChange.bind(
                                    this,
                                    'credit_line'
                                )}
                                value={credit_line}
                                custom={true}
                                bordered={false}
                                type="multiline"
                                rows="4"
                                isPublication
                            />
                        </div>
                    </div>
                    <div style={{marginBottom:'20px'}}>
                        <div>
                            <InputFieldTitle
                                isRequired={true}
                                t={t}
                                value={t('PublicationForm.readingLevel')}
                                isRightToLeftDirection={isRightToLeftDirection}
                            />
                            <div className={`${baseClassName}_radio-buttons`}>
                                <label className="radioinput-label">
                                    <input
                                        className={
                                            isRightToLeftDirection 
                                            ? "level-radioinput-rtl"
                                            : "level-radioinput"}
                                        id="level1"
                                        value={t('PublicationForm.level1')}
                                        name="level1"
                                        type="radio"
                                        checked={reading_level === '1'}
                                        onChange={() => this.handleRadio('1')}
                                    />
                                    <span className={
                                        isRightToLeftDirection 
                                        ? "levelradiotext-rtl"
                                        : "levelradiotext"}>
                                        <b>{t('PublicationForm.level1')}</b>
                                        {':'} {t('PublicationForm.level1Text')}
                                    </span>
                                </label>
                                <label className="radioinput-label">
                                    <input
                                        className={
                                            isRightToLeftDirection 
                                            ? "level-radioinput-rtl"
                                            : "level-radioinput"}
                                        id="level2"
                                        value={t('PublicationForm.level2')}
                                        name="level2"
                                        type="radio"
                                        checked={reading_level === '2'}
                                        onChange={() => this.handleRadio('2')}
                                    />
                                    <span className={
                                        isRightToLeftDirection 
                                        ? "levelradiotext-rtl"
                                        : "levelradiotext"}>
                                        <b>{t('PublicationForm.level2')}</b>
                                        {':'} {t('PublicationForm.level2Text')}
                                    </span>
                                </label>
                                <label className="radioinput-label">
                                    <input
                                        className={
                                            isRightToLeftDirection 
                                            ? "level-radioinput-rtl"
                                            : "level-radioinput"}
                                        id="level3"
                                        value={t('PublicationForm.level3')}
                                        name="level3"
                                        type="radio"
                                        onChange={() => this.handleRadio('3')}
                                        checked={reading_level === '3'}
                                    />
                                    <span className={
                                        isRightToLeftDirection 
                                        ? "levelradiotext-rtl"
                                        : "levelradiotext"}>
                                        <b>{t('PublicationForm.level3')}</b>
                                        {':'} {t('PublicationForm.level3Text')}
                                    </span>
                                </label>
                                <label className="radioinput-label">
                                    <input
                                        className={
                                            isRightToLeftDirection 
                                            ? "level-radioinput-rtl"
                                            : "level-radioinput"}
                                        id="level4"
                                        value={t('PublicationForm.level4')}
                                        name="level4"
                                        type="radio"
                                        onChange={() => this.handleRadio('4')}
                                        checked={reading_level === '4'}
                                    />
                                    <span className={
                                        isRightToLeftDirection 
                                        ? "levelradiotext-rtl"
                                        : "levelradiotext"}>
                                        <b>{t('PublicationForm.level4')}</b>
                                        {':'} {t('PublicationForm.level4Text')}
                                    </span>
                                </label>
                                <label className="radioinput-label">
                                    <input
                                        className={
                                            isRightToLeftDirection 
                                            ? "level-radioinput-rtl"
                                            : "level-radioinput"}
                                        id="level5"
                                        value={t('PublicationForm.level5')}
                                        name="level5"
                                        type="radio"
                                        onChange={() => this.handleRadio('5')}
                                        checked={reading_level === '5'}
                                    />
                                    <span className={
                                        isRightToLeftDirection 
                                        ? "levelradiotext-rtl"
                                        : "levelradiotext"}>
                                        <b>{t('PublicationForm.level5')}</b>
                                        {':'} {t('PublicationForm.level5Text')}
                                    </span>
                                </label>
                                <label className="radioinput-label">
                                    <input
                                        className={
                                            isRightToLeftDirection 
                                            ? "level-radioinput-rtl"
                                            : "level-radioinput"}
                                        id="level6"
                                        value={t('PublicationForm.level6')}
                                        name="level6"
                                        type="radio"
                                        onChange={() => this.handleRadio('6')}
                                        checked={reading_level === '6'}
                                    />
                                    <span className={
                                        isRightToLeftDirection 
                                        ? "levelradiotext-rtl"
                                        : "levelradiotext"}>
                                        <b>{t('PublicationForm.level6')}</b>
                                        {':'} {t('PublicationForm.level6Text')}
                                    </span>
                                </label>
                            </div>
                        </div>
                    </div>
                    {language_id.length > 0 &&
                    <div className={`${baseClassName}_row-wrapper`}>
                        <div className={
                                isRightToLeftDirection
                                    ? `${baseClassName}_header-container-rtl`
                                    : `${baseClassName}_header-container`
                            }>
                            <InputFieldTitle
                                isRequired={true}
                                t={t}
                                value={t('PublicationForm.categories')}
                                isRightToLeftDirection={isRightToLeftDirection}
                            />
                            <Dropdown
                            align="left"
                            toggleEl={
                                <TextField
                                    id="publication-form-categories"
                                    value={limitList(this.state.category_text_value)}
                                    isPublication
                                    autoFocus={false}
                                    bordered={false}
                                    custom={true}
                                    type="text"
                                />
                            }
                            minWidth="auto"
                        >
                            <Picklist
                                id="publication-form-category"
                                searchValue={this.state.categorySearchValue}
                                onSearchChange={this.updateCategorySearchValue}
                                options={this.state.categories}
                                onChange={this.handlePicklistChange.bind(
                                    this,
                                    'category_text_value'
                                )}
                                checkedValues={this.state.category_text_value}
                            />
                        </Dropdown>       
                        </div>
                    </div>}
                    <div style={{margin:'30px 0'}}>
                        <Authors
                            t={t}
                            title={t('PublicationForm.author')}
                            subHeading={t('PublicationForm.authorSubHeading')}
                            isRightToLeftDirection={isRightToLeftDirection}
                            btnLabel={t('PublicationForm.addAuthor')}
                            offline_author_ids={this.onAddAuthorId}
                            offline_author_data={this.state.offline_authors}
                            projectId={this.state.projectId || salesForceProjectId}
                        />
                    </div>
                    <div style={{margin:'30px 0'}}>
                        <Illustrators
                            t={t}
                            title={t('PublicationForm.illustrator')}
                            subHeading={t('PublicationForm.illustratorSubHeading')}
                            isRightToLeftDirection={isRightToLeftDirection}
                            btnLabel={t('PublicationForm.addIllustrator')}
                            offline_illustrator_ids={this.onAddIllustratorId}
                            offline_illustrator_data={this.state.offline_illustrators}
                            projectId={this.state.projectId || salesForceProjectId}
                        />
                    </div>
                    {
                        edition_type === 'adaptation' ?
                        <div style={{margin:'30px 0'}}>
                            <Translators
                                t={t}
                                title={t('PublicationForm.translator')}
                                subHeading={t('PublicationForm.translatorSubHeading')}
                                isRightToLeftDirection={isRightToLeftDirection}
                                btnLabel={t('PublicationForm.addTranslator')}
                                offline_translator_ids={this.onAddTranslatorId}
                                offline_translator_data={this.state.offline_translators}
                                projectId={this.state.projectId || salesForceProjectId}
                            />
                        </div>
                        : null
                    }
                </form>
                <BookUploadFooter 
                    disable={!this.state.isFormValid} 
                    nextPage={this.nextPage} 
                />
            </div>
            : <div className={`${baseClassName}__book_loader`}>
                <DataLoader />
            </div>
            }
            </div>
            
        );
    }
}

PublicationForm.propTypes = {};
