import React, { Component } from 'react';
import './BookUploadFooter.scss';
import locales from '../../i18n';

class BookUploadFooter extends Component {
    constructor(props) {
        super(props);
        // console.log('props', this.props);
    }

    render() {
        const baseClassName = 'book-upload_footer';
        const locale = localStorage.getItem('locale') || 'en';
        const messages = locales[locale];
        return (
            <div className={
                this.props.backPage === undefined 
                ? `${baseClassName}__button_wrapper_publication-form`
                : `${baseClassName}__button_wrapper`}>
                {this.props.backPage !== undefined ? (
                    <button
                        className={`${baseClassName}__save_button`}
                        onClick={this.props.backPage}
                    >
                        {messages['global.previous']}
                    </button>
                ) : (
                    ''
                )}
                {this.props.nextPage !== undefined ? (
                    <button
                        className={
                            `${baseClassName}__publish_button ` +
                            (this.props.disable
                                ? `${baseClassName}__disable_button`
                                : '')
                        }
                        onClick={this.props.nextPage}
                    >
                        {messages['global.Next']}
                    </button>
                ) : (
                    ''
                )}
            </div>
        );
    }
}

export default BookUploadFooter;
