import { createAction } from 'redux-actions';

export const helpStatusAction = createAction(
    `HELP_GUIDE_ACTION`,
    payload => payload
);

export const helpStatusActionAsync = helpStatus => {
    return dispatch => {
        dispatch(helpStatusAction(helpStatus));
    };
};
