import queryString from 'query-string';

import { pagination } from './lib/constants';
import { getPortMapping } from './lib/viewportHelper';
let mediaQuery = getPortMapping();

export const API_URL =
    process.env.REACT_APP_API_URL || 'https://dev.literacycloud.org/api/v1';
// process.env.REACT_APP_API_URL || 'https://itg.literacycloud.org/api/v1';
// process.env.REACT_APP_API_URL || 'https://literacycloud.org/api/v1';
// process.env.REACT_APP_API_URL || 'https://dev.pbees.party/api/v1';
//  process.env.REACT_APP_API_URL || 'https://staging.literacycloud.org/api/v1'

const getWithCredentials = (url) => fetch(url, { credentials: 'include' });
const postWithCredentials = (url, body, contentType = 'application/json') =>
    fetch(url, {
        method: 'POST',
        body,
        credentials: 'include',
        headers: {
            'Content-Type': contentType,
        },
    });
const putWithCredentials = (url, body, contentType = 'application/json') =>
    fetch(url, {
        method: 'PUT',
        body,
        credentials: 'include',
        headers: {
            'Content-Type': contentType,
        },
    });

// for 'multipart/form-data' 'content-type' will be undefined so
// that the browser auto detects data field boundaries.

const postMultiPartWithCredentials = (url, body) =>
    fetch(url, {
        // mod: 'no-cors', // enable for posting to localhost:xxxx
        method: 'POST',
        body,
        credentials: 'include',
    });

// Converts filters from a form supplied by our filter bar/filter panel
// into a form expected by the backend.
// Why? Because like idiots we hardcoded a bunch of these keys in the
// filters component, which is really complex. Changing one thing
// breaks a million other things, so it's easiest to do the translation
// here. If you're reading this in the future, pliss fix the filters
// bar and filters panel.
const sanitizeFilters = (appliedFilters) => {
    const query = {};

    if (!appliedFilters) {
        return query;
    }

    const optionalFilters = {
        category: 'categories',
        role: 'roles',
        publisher: 'publishers',
        level: 'levels',
        language: 'languages',
        query: 'query',
        tags: 'tags',
        sort: 'sort',
        style: 'styles',
        // This query string is passed to the URL, when a user clicks on
        // 'More Stories' link from Image details page.
        illustration_slug: 'illustration_slug',
        bulk_download: 'bulk_download',
        read_alouds:'read_alouds'
    };

    Object.keys(optionalFilters).forEach((filterKey) => {
        if (appliedFilters[filterKey] && appliedFilters[filterKey].length > 0) {
            query[optionalFilters[filterKey]] = appliedFilters[filterKey];
        }
    });

    return query;
};

const sanitizeFiltersForReadAlouds = (appliedFilters) => {
    const query = {};

    if (!appliedFilters) {
        return query;
    }

    const optionalFilters = {
        category: 'categories',
        role: 'roles',
        language: 'languages',
        query: 'query',
        sort: 'sort',
        style: 'styles',
        // This query string is passed to the URL, when a user clicks on
        // 'More Stories' link from Image details page.
        illustration_slug: 'illustration_slug',
        bulk_download: 'bulk_download',
    };

    Object.keys(optionalFilters).forEach((filterKey) => {
        if (appliedFilters[filterKey] && appliedFilters[filterKey].length > 0) {
            query[optionalFilters[filterKey]] = appliedFilters[filterKey];
        }
    });

    return query;
};

const sanitizeFiltersForReadingList = (appliedFilters) => {
    const query = {};

    if (!appliedFilters) {
        return query;
    }

    const optionalFilters = {
        category: 'categories',
        role: 'roles',
        publisher: 'publishers',
        level: 'levels',
        language: 'rl_language',
        query: 'query',
        tags: 'tags',
        sort: 'sort',
        style: 'styles',
        // This query string is passed to the URL, when a user clicks on
        // 'More Stories' link from Image details page.
        illustration_slug: 'illustration_slug',
        bulk_download: 'bulk_download',
    };

    Object.keys(optionalFilters).forEach((filterKey) => {
        if (appliedFilters[filterKey] && appliedFilters[filterKey].length > 0) {
            query[optionalFilters[filterKey]] = appliedFilters[filterKey];
        }
    });

    return query;
};


export class User {
    static fetchProfile(slug, perPage) {
        return getWithCredentials(
            `${API_URL}/users/${slug}?perPage=${perPage}`
        );
    }

    static fetchMore(profileName, slug, collection, page, perPage) {
        if (collection === 'illustrations') {
            return getWithCredentials(
                `${API_URL}/illustrations-search?search[illustrator_slugs]=${slug}&page=${page}&per_page=${perPage}`
            );
        }
    }

    static fetchMe() {
        return getWithCredentials(`${API_URL}/me`);
    }

    static logout() {
        //Using /users/sign_out for old sign-out (so that we can display flash messages), else use /api/v1/users/sign_out
        return fetch(
            `${
                process.env.REACT_APP_FEATURE_AUTH ? API_URL : ''
            }/users/sign-out`,
            {
                method: 'DELETE',
                credentials: 'include',
            }
        );
    }

    static changeLanguage(newLanguage) {
        const dataChange =
            newLanguage === 'zh_TW'
                ? 'zh-TW'
                : newLanguage === 'zh_CN'
                ? 'zh-CN'
                : newLanguage;

        return fetch(`${API_URL}/set-locale/${dataChange}`, {
            method: 'POST',
            credentials: 'include',
        });
    }

    static toggleFavourite(bookId) {
        return fetch(`${API_URL}/stories/${bookId}/toggle-favourite`, {
            method: 'PUT',
            credentials: 'include',
        });
    }
    //show or hide header menues basded on book/video/resource availability
    static fetchHeaderAndFooterNavLinks(language) {
        const dataChange =
            language === 'zh_TW'
                ? 'zh-TW'
                : language === 'zh_CN'
                ? 'zh-CN'
                : language;
        return getWithCredentials(`${API_URL}/menu_headings/${dataChange}`);
    }
}

export class Publisher {
    static fetchProfile(slug, perPage = 24, page = 1) {
        return getWithCredentials(
            `${API_URL}/publishers/${slug}?${queryString.stringify({
                perPage: perPage,
                page: page,
            })}`
        );
    }

    static fetchAllFilters() {
        return getWithCredentials(`${API_URL}/lists/filters`);
    }

    static fetchPublisherProfile(appliedFilters, slug, perPage = 24, page = 1) {
        const sanitizedFilters = sanitizeFilters(appliedFilters);

        const query = Object.assign(
            {
                page,
                perPage: perPage,
            },
            sanitizedFilters
        );

        const qs = queryString.stringify(query, { arrayFormat: 'bracket' });

        return getWithCredentials(`${API_URL}/publishers/${slug}?${qs}`);
    }

    static fetchMore(profileName, slug, collection, page, perPage) {
            return getWithCredentials(
                `${API_URL}/books-search?${queryString.stringify({
                    'publishers[]': profileName,
                    page: page,
                    per_page: perPage,
                    sort: 'New Arrivals',
                })}`
            );
        }
    static toggleFavourite(bookId) {
        return fetch(`${API_URL}/stories/${bookId}/toggle-favourite`, {
            method: 'PUT',
            credentials: 'include',
        });
    }
}

export class ReadingList {
    static fetchDetails(
        appliedFilters,
        slug,
        page = 1,
        perPage = pagination.mobileReadingLists
    ) {
        if (mediaQuery.mobileToTablet) {
            perPage = pagination.readingLists;
        }
        const sanitizedFilters = sanitizeFiltersForReadingList(appliedFilters);

        const query = Object.assign(
            {
                page,
                per_page: perPage,
            },
            sanitizedFilters
        );

        const qs = queryString.stringify(query, { arrayFormat: 'bracket' });
        return getWithCredentials(`${API_URL}/lists-search?${qs}`);
    }

    static fetchThematic(
        appliedFilters,
        slug,
        page = 1,
        perPage = pagination.mobileReadingLists
    ) {
        if (mediaQuery.mobileToTablet) {
            perPage = pagination.readingLists;
        }
        const sanitizedFilters = sanitizeFiltersForReadingList(appliedFilters);

        const query = Object.assign(
            {
                page,
                per_page: perPage,
            },
            sanitizedFilters
        );

        const qs = queryString.stringify(query, { arrayFormat: 'bracket' });

        return getWithCredentials(
            `${API_URL}/lists-search?${qs}&list_card=true`
        );
    }

    static update(readingList) {
        return fetch(`${API_URL}/lists/${readingList.slug}`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
            },
            credentials: 'include',
            body: JSON.stringify({
                title: readingList.title,
                description: readingList.description,
                books: readingList.books.map((item) => item.slug),
            }),
        });
    }

    // Update Reading list title or description
    static updateDetails(readingList = {}) {
        const { id, title, description } = readingList;

        return fetch(`${API_URL}/lists/${id}/update`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
            },
            credentials: 'include',
            body: JSON.stringify({
                list: {
                    title,
                    description,
                },
            }),
        });
    }

    static postLike(slug) {
        return postWithCredentials(`${API_URL}/lists/${slug}/like`, '');
    }

    static create(title, description, books = []) {
        let booksSlugs = books.map((item) => {
            return item.slug;
        });
        return postWithCredentials(
            `${API_URL}/lists`,
            JSON.stringify({
                title,
                description,
                books: booksSlugs,
            })
        );
    }

    static deleteList(list) {
        return fetch(`${API_URL}/lists/${list.id}`, {
            method: 'DELETE',
            credentials: 'include',
        });
    }

    static fetchAllFilters() {
        return getWithCredentials(`${API_URL}/lists/filters`);
    }

    static search(appliedFilters, page = 1, perPage = pagination.allLists) {
        const sanitizedFilters = sanitizeFilters(appliedFilters);

        const query = Object.assign(
            {
                page,
                per_page: perPage,
            },
            sanitizedFilters
        );

        const qs = queryString.stringify(query, { arrayFormat: 'bracket' });
        return getWithCredentials(`${API_URL}/lists-search?${qs}`);
    }

    static fetchReadingListsBooks(slug) {
        return getWithCredentials(`${API_URL}/lists/${slug}`);
    }

    static fetchMyReadingLists(
        appliedFilters,
        slug,
        page = 1,
        perPage = pagination.mobileReadingLists
    ) {
        if (mediaQuery.mobileToTablet) {
            perPage = pagination.myReadingLists;
        }

        const sanitizedFilters = sanitizeFilters(appliedFilters);

        const query = Object.assign(
            {
                page,
                per_page: perPage,
            },
            sanitizedFilters
        );

        const qs = queryString.stringify(query, { arrayFormat: 'bracket' });

        return getWithCredentials(
            `${API_URL}/my-reading-lists-search?${qs}&list_card=true`
        );
    }

    static toggleFavourite(bookId) {
        return fetch(`${API_URL}/stories/${bookId}/toggle-favourite`, {
            method: 'PUT',
            credentials: 'include',
        });
    }
}

export class Book {
    static fetch(slug) {
        return getWithCredentials(`${API_URL}/stories/${slug}`);
    }

    static fetchAllFilters() {
        return getWithCredentials(`${API_URL}/books/filters`);
    }

    static fetchAll(
        appliedFilters,
        page = 1,
        perPage = pagination.mobileBooks
    ) {
        const sanitizedFilters = sanitizeFilters(appliedFilters);
        if (mediaQuery.mobileToTablet) {
            perPage = pagination.allBooks;
        }

        const query = Object.assign(
            {
                page,
                per_page: perPage,
            },
            sanitizedFilters
        );

        const qs = queryString.stringify(query, { arrayFormat: 'bracket' });
        return getWithCredentials(`${API_URL}/books-search?${qs}`);
    }

    //Recommended Books Api

    static fetchAllRecommended(
        appliedFilters,
        page = 1,
        perPage = pagination.mobileBooks
    ) {
        const sanitizedFilters = sanitizeFilters(appliedFilters);
        if (mediaQuery.mobileToTablet) {
            perPage = pagination.allBooks;
        }

        const query = Object.assign(
            {
                page,
                per_page: perPage,
            },
            sanitizedFilters
        );

        const qs = queryString.stringify(query, { arrayFormat: 'bracket' });
        return getWithCredentials(`${API_URL}/books/recommended?${qs}`);
    }

    //All Trending Books Api
    static fetchAllTrending(
        appliedFilters,
        page = 1,
        perPage = pagination.mobileBooks
    ) {
        const sanitizedFilters = sanitizeFilters(appliedFilters);
        if (mediaQuery.mobileToTablet) {
            perPage = pagination.allBooks;
        }

        const query = Object.assign(
            {
                page,
                per_page: perPage,
            },
            sanitizedFilters
        );

        const qs = queryString.stringify(query, { arrayFormat: 'bracket' });
        return getWithCredentials(`${API_URL}/books/trending?${qs}`);
    }

    static toggleFavourite(bookId) {
        return fetch(`${API_URL}/stories/${bookId}/toggle-favourite`, {
            method: 'PUT',
            credentials: 'include',
        });
    }

    static fetchFavouriteBooks() {
        return getWithCredentials(`${API_URL}/books/favourites`);
    }
    static rtrGetBookData(bookId) {
        return getWithCredentials(
            `${API_URL}/offline_stories/${bookId}?type=read_story`
        );
    }
    static rtrsetBookReaderStatus(slug, current_page, type = '') {
        const payload = {
            current_page: current_page,
            type: type,
        };

        return postWithCredentials(
            `${API_URL}/stories/${slug}/read_details`,
            JSON.stringify(payload)
        );
    }

    static rtrgetBookReaderStatusWorkflow(slug) {
        return getWithCredentials(`${API_URL}/stories/${slug}/read_details`);
    }
}

export class Home {
    static fetch() {
        return getWithCredentials(`${API_URL}/home`);
    }

    static fetchCategories() {
        return getWithCredentials(`${API_URL}/home/categories`);
    }
}

export class HomePage {
    static fetchRecommendedBooks() {
        const currentLanguage = localStorage.getItem('language');
        return getWithCredentials(
            `${API_URL}/books/recommended?languages[]=${currentLanguage}&sort=New%20Arrivals`
        );
    }

    static fetchPopularBooks() {
        const currentLanguage = localStorage.getItem('language');
        return getWithCredentials(
            `${API_URL}/books-search?languages[]=${currentLanguage}&sort=Popular%20Books`
        );
    }

    static fetchTrendingBooks() {
        const currentLanguage = localStorage.getItem('language');
        return getWithCredentials(
            `${API_URL}/books/trending?languages[]=${currentLanguage}`
        );
    }

    static fetchNewArrivals() {
        const currentLanguage = localStorage.getItem('language');
        return getWithCredentials(
            `${API_URL}/books-search?languages[]=${currentLanguage}&sort=New%20Arrivals`
        );
    }
}

export class Banner{
    static fetchBannerImage(section) {
        return fetch(`${API_URL}/home/banners?display_section=${section}`);
    }
}

export class Offline {
    static save(slug) {
        return fetch(`/offline-books/${slug}`, {
            method: 'POST',
        });
    }
    static fetchStoryOfflineCount(bookId) {
        return fetch(`${API_URL}/stories/${bookId}/offline_download_count`, {
            method: 'POST',
            credentials: 'include',
        });
    }
}


//ReadAlouds related

export class ReadAlouds {
    static fetch(appliedFilters, page = 1, perPage = pagination.mobileVideos) {
        if (mediaQuery.mobileToTablet) {
            perPage = pagination.videos;
        }

        const sanitizedFilters = sanitizeFiltersForReadAlouds(appliedFilters);

        const query = Object.assign(
            {
                page,
                per_page: perPage,
            },
            sanitizedFilters
        );

        const qs = queryString.stringify(query, { arrayFormat: 'bracket' });
        return getWithCredentials(
            `${API_URL}/videos-search?categories[]=Read%20Alouds%20%28for%20kids%29&${qs}`
        );
    }

    static fetchAllFilters() {
        return getWithCredentials(`${API_URL}/videos/filters`);
    }

    static toggleFavourite(videoId) {
        return fetch(`${API_URL}/videos/${videoId}/toggle-favourite`, {
            method: 'PUT',
            credentials: 'include',
        });
    }
    static videoReadCount(videoId) {
        return fetch(`${API_URL}/videos/${videoId}/read`, {
            method: 'PUT',
            credentials: 'include',
        });
    }

    static fetchFavouriteVideos() {
        return getWithCredentials(`${API_URL}/videos/favourites`);
    }

    static fetchVideoDetail(slug) {
        return getWithCredentials(`${API_URL}/videos/${slug}`);
    }
}

//Video related
export class Videos {
    static fetch(appliedFilters, page = 1, perPage = pagination.mobileVideos) {
        if (mediaQuery.mobileToTablet) {
            perPage = pagination.videos;
        }
        const sanitizedFilters = sanitizeFilters(appliedFilters);

        const query = Object.assign(
            {
                page,
                per_page: perPage,
            },
            sanitizedFilters
        );

        const qs = queryString.stringify(query, { arrayFormat: 'bracket' });
        return getWithCredentials(`${API_URL}/videos-search?${qs}`);
    }

    static toggleFavourite(videoId) {
        return fetch(`${API_URL}/videos/${videoId}/toggle-favourite`, {
            method: 'PUT',
            credentials: 'include',
        });
    }
    static videoReadCount(videoId) {
        return fetch(`${API_URL}/videos/${videoId}/read`, {
            method: 'PUT',
            credentials: 'include',
        });
    }

    static fetchFavouriteVideos() {
        return getWithCredentials(`${API_URL}/videos/favourites`);
    }

    static fetchAllFilters() {
        return getWithCredentials(`${API_URL}/videos/filters`);
    }

    static fetchVideoDetail(slug) {
        return getWithCredentials(`${API_URL}/videos/${slug}`);
    }
}

//References related
export class References {
    static fetch(
        appliedFilters,
        page = 1,
        perPage = pagination.mobileReferences
    ) {
        if (mediaQuery.mobileToTablet) {
            perPage = pagination.refereces;
        }
        const sanitizedFilters = sanitizeFilters(appliedFilters);

        const query = Object.assign(
            {
                page,
                per_page: perPage,
            },
            sanitizedFilters
        );

        const qs = queryString.stringify(query, { arrayFormat: 'bracket' });
        return getWithCredentials(`${API_URL}/references-search?${qs}`);
    }
    static toggleFavourite(referenceId) {
        return fetch(`${API_URL}/resources/${referenceId}/toggle-favourite`, {
            method: 'PUT',
            credentials: 'include',
        });
    }

    static handleDownloadCount(referenceId) {
        return fetch(`${API_URL}/resources/${referenceId}/read`, {
            method: 'PUT',
            credentials: 'include',
        });
    }

    static fetchFavouriteResources() {
        return getWithCredentials(`${API_URL}/resources/favourites`);
    }

    static fetchAllFilters() {
        return getWithCredentials(`${API_URL}/resources/filters`);
    }

    static fetchReferenceDetail(slug) {
        return getWithCredentials(`${API_URL}/references/${slug}`);
    }
}

//Comment related

export class CommentsAndReply {
    static fetchMainComments(bookId, pageNo, commentSource) {
        const source =
            commentSource === 'book'
                ? 'story_id'
                : commentSource === 'video' || commentSource === 'readAloud'
                ? 'video_id'
                : commentSource === 'resource'
                ? 'resource_id'
                : '';
        return getWithCredentials(
            `${API_URL}/comments?${source}=${bookId}&page=${pageNo}`
        );
    }

    static postMainComment(bookId, NewComment, commentSource) {
        const source =
            commentSource === 'book'
                ? 'story_id'
                : commentSource === 'video' || commentSource === 'readAloud'
                ? 'video_id'
                : commentSource === 'resource'
                ? 'resource_id'
                : '';
        return postWithCredentials(
            `${API_URL}/comments?${source}=${bookId}`,

            JSON.stringify({
                comment: {
                    content: NewComment,
                },
            })
        );
    }

    static fetchReplyComments(commentId, pageNo) {
        return getWithCredentials(
            `${API_URL}/story_comments/${commentId}/child_comments?page=${pageNo}`
        );
    }

    static postReplyComment(commentId, NewReply) {
        return postWithCredentials(
            `${API_URL}/story_comments/${commentId}/reply`,

            JSON.stringify({
                comment: {
                    content: NewReply,
                },
            })
        );
    }

    static postLikes(commentId) {
        return postWithCredentials(
            `${API_URL}/story_comments/${commentId}/like`
        );
    }

    static deleteLike(commentId) {
        return fetch(`${API_URL}/story_comments/${commentId}/unlike`, {
            method: 'DELETE',
            credentials: 'include',
        });
    }

    static reportComment(commentId, reason) {
        return fetch(`${API_URL}/comments/${commentId}/report`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
            },
            credentials: 'include',
            body: JSON.stringify({
                report_content: reason,
            }),
        });
    }

    static deleteComment(commentId) {
        return fetch(`${API_URL}/comments/${commentId}`, {
            method: 'DELETE',
            credentials: 'include',
        });
    }
}

export class OfflineStory {
    static fetchOfflineStoryDetail(id) {
        return getWithCredentials(`${API_URL}/offline_stories/${id}`);
    }
}
//Publication Form

export class BookPublicationForm {
    static searchEnglishTitle(searchValue) {
        return getWithCredentials(
            `${API_URL}/story-titles?search=${searchValue}`
        );
    }
    static languages() {
        return getWithCredentials(`${API_URL}/languages`);
    }
    static licence_type() {
        return getWithCredentials(`${API_URL}/license-types`);
    }
    static book_tags(searchValue) {
        return getWithCredentials(`${API_URL}/get-tag?search=${searchValue}`);
    }
    static publishers() {
        return getWithCredentials(`${API_URL}/publishers`);
    }
    static donors() {
        return getWithCredentials(`${API_URL}/donors`);
    }
    static categories(id) {
        return getWithCredentials(
            `${API_URL}/languages/${id}/story-categories`
        );
    }
    static contributor(searchValue) {
        return getWithCredentials(
            `${API_URL}/get-user-by-email?search=${searchValue}`
        );
    }
    static copy_right_years() {
        return getWithCredentials(`${API_URL}/copy_right_years`);
    }
    static addUser(data) {
        return postWithCredentials(
            `${API_URL}/add-user`,
            JSON.stringify({
                user: data,
            })
        );
    }
    static createStory(formData) {
        return postWithCredentials(
            `${API_URL}/stories`,
            JSON.stringify({
                story: formData,
            })
        );
    }
    static updateStory(formData, id) {
        return putWithCredentials(
            `${API_URL}/stories/${id}`,
            JSON.stringify({
                story: formData,
            })
        );
    }
    static publish(id) {
        return fetch(`${API_URL}/offline_stories/${id}/publish`, {
            method: 'PATCH',
            credentials: 'include',
        });
    }
    static prefillData(value) {
        return getWithCredentials(
            `${API_URL}/offline_stories/fetch-users?english_title=${value}`
        );
    }
    static salesForceIds() {
        return fetch(`${API_URL}/salesforces`, {
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json',
            },
        });
    }
    static salesForceData(id) {
        return fetch(`${API_URL}/salesforces/${id}`, {
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json',
            },
        });
    }
}

export class PopUpRating {
    static fetchAllRatings() {
        return getWithCredentials(`${API_URL}/ratings/all`);
    }
    static postRating(data) {
        return postWithCredentials(
            `${API_URL}/ratings`,
            JSON.stringify({
                entity_rating: data,
            })
        );
    }
}

export class SearchAutoSuggest {
    static fetchAutoSuggestResults(searchValue) {
        return getWithCredentials(`${API_URL}/search/autocomplete?query=${searchValue}`)
    }
}
