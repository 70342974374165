import React, { Component } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { translate } from 'react-polyglot';
import { connect } from 'react-redux';
import queryString from 'query-string';
import { replace } from 'react-router-redux';
import DocumentTitle from 'react-document-title';
import u from 'updeep';
import { isEqual } from 'lodash';
import './AddBooksContainer.scss';
import ContentStyler from '../ContentStyler';
import Block from '../Block';
import Pagination from '../Pagination';
import AddBooks from '../AddBooks';
import { fetchAllBooksWorkflow } from '../../redux/allBooksActions';
import {
    createMyReadingListWorkflow,
    fetchMyReadingListWorkflow,
    fetchManageReadingListWorkflow,
} from '../../redux/readingListsActions';
import { applyFilter, resetFilters } from '../../redux/allFiltersActions';
import { sortOptions } from '../../lib/constants';
import SearchModal from '../SearchModal';
import TitleImage from '../TitleImage';
import { API_URL } from '../../api';
import Modal from '../Modal';
import withRouter from "../../util/withRouter"

// Tabs list in search page
const tabFilters = {
    books: 'readFilters',
    lists: 'listsFilters',
    images: 'imagesFilters',
    people: 'peopleFilters',
};

const defaultFilterValues = {
    category: [],
    publisher: [],
    level: [],
    language: [],
    sort: sortOptions.newArrivals,
    tags: [],
    query: '',
    style: [],
};

class AddBooksContainer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            searchQueryValue: '',
            isSearchModalOpen: false,
            languages: {
                'en': 'English',
                'id': 'Bahasa Indonesia',
                'my': 'Burmese',
                'hi': 'Hindi',
                'vi': 'Tiếng Việt',
                'sw': 'KiSwahili',
                'mr': 'Marathi',
                'ne': 'Nepali',
                'bn': 'Bengali',
                'si': 'Sinhala',
                'km': 'Khmer',
                'lo': 'Lao',
                'zh_TW': 'Chinese (Traditional)',
                'ta': 'Tamil',
                'fil': 'Filipino',
                'zh_CN': 'Chinese (Simplified)',
                'es': 'Spanish',
                'ar': 'Arabic',
                'gu': 'Gujarati'
            },
            disable: false,
            showModal: false,
            errorMessage: ""
        };
    }

    componentDidMount() {
        this.props.fetchAllBooksWorkflow();
    }

    componentWillMount() {
        const {
            location,
            allFilters,
            applyFilter,
        } = this.props;
        // Find tab value from URL, set other filters to queryAppliedFilters
        const { tab, item, ...queryAppliedFilters } = queryString.parse(
            location.search
        );

        // to update Filter type to redux state, from tab map it to filterType
        const filterType =
            tabFilters[tab] || tabFilters[item] || this.props.filterType;
        const appliedFilters = allFilters[filterType];
        const updatedFilters = { ...appliedFilters, ...queryAppliedFilters };

        Object.entries(updatedFilters).forEach(([k, v]) => {
            if (Array.isArray(defaultFilterValues[k]) && !Array.isArray(v)) {
                updatedFilters[k] = [v];
            }
        });

        // if isStoryCategory field set and category present in filters category, get category image
        // with category title. If not by default set query present in url
            applyFilter({ filterType: filterType, filter: updatedFilters });
    }

    componentWillReceiveProps(nextProps) {
        if (!isEqual(this.props.appliedFilters, nextProps.appliedFilters)) {
            this.props.fetchAllBooksWorkflow(nextProps.appliedFilters);
            // Reset all checked books on changing /applying new filters
        }
    }

    updateFiltersSearchText = (key, text) => {
        this.setState(
            u(
                {
                    filtersSearchText: {
                        [key]: text,
                    },
                },
                this.state
            )
        );
    };

    getTab = () => {
        const { location } = this.props;
        return queryString.parse(location.search)['tab'];
    };

    getItem = () => {
        const { location } = this.props;
        return queryString.parse(location.search)['item'];
    };

    applyFilter = (type, value) => {
        const { appliedFilters, replace, filterType, applyFilter } = this.props;
        const filterIsArray = Array.isArray(appliedFilters[type]);
        if (filterIsArray) {
            value = appliedFilters[type].concat(value);
        }
        let updatedField = { [type]: value };
        const newFilters = queryString.stringify({
            ...appliedFilters,
            ...updatedField,
            tab: this.getTab(),
        });

        this.props.navigate({ search: `?${newFilters}` }, { replace: true });
        applyFilter({ filter: updatedField, filterType: filterType });
    };

    removeFilter = (type, value) => {
        const { appliedFilters, replace, filterType, applyFilter } = this.props;
        if (!appliedFilters[type]) {
            return;
        }
        if (Array.isArray(appliedFilters[type])) {
            const index = appliedFilters[type].indexOf(value);
            value = [...appliedFilters[type]];
            if (index !== -1) {
                value.splice(index, 1);
            }
        } else {
            value = '';
        }
        let updatedField = { [type]: value };

        const searchParams = queryString.stringify({
            ...appliedFilters,
            ...updatedField,
            tab: this.getTab(),
        });
        this.props.navigate({ search: `?${searchParams}` }, { replace: true });
        applyFilter({ filter: updatedField, filterType: filterType });
    };
    componentWillUnmount() {
        this.props.resetFilters();
    }

    onSortOptionChanged = option => this.applyFilter('sort', option);

    onTabChange = newTab => {
        const {
            appliedFilters: { query, tags },
            applyFilter,
            replace,
        } = this.props;
        const filterType = tabFilters[newTab];
        const filters = { query: query, tags: tags };
        applyFilter({ filterType: filterType, filter: filters });
        // push filters and tab to URL

        const searchParams = queryString.stringify({
            ...this.props.allFilters[filterType],
            ...filters,
            tab: newTab,
        });
        this.props.navigate({ search: `?${searchParams}` }, { replace: true });

   
    };

    onLoadMore = () =>
        this.props.fetchAllBooksWorkflow(
            this.props.appliedFilters,
            this.props.loadedPages + 1
        );

    createReadingList = () => {
        const { newList } = this.props;
        let title = newList.title;
        let description = newList.description;
        let books = newList.books;
        let booksSlugs = books.map((item) => {
            return item.slug;
        });
        const data = {
            "title": title,
            "description": description,
            "books": booksSlugs
        }
        let currentThis = this;
        fetch(`${API_URL}/lists`, {
            method: 'POST',
            body: JSON.stringify(data),
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json',
            },
        }).then(response => response.json())
            .then(response => {
                if (response.meta.success) {
                    setTimeout(function () {
                        currentThis.nextPath("/myreadinglists")
                    }
                        , 3000);
                }
                else {
                    this.setState({ showModal: true, errorMessage: response.meta.errors.join(", ") })
                }
            });

    };

    manageReadingList = () => {
        console.log("this", this);
        let currentThis = this;
        let readingList = this.props.newList;
        console.log("readingList", readingList)
        let data = {
            title: readingList.title,
            description: readingList.description,
            books: readingList.books.map((item) => item.slug),
        }
        fetch(`${API_URL}/lists/${readingList.slug}`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
            },
            credentials: 'include',
            body: JSON.stringify(data),
        }).then(response => response.json())
            .then(response => {
                if (response.meta.success) {
                    setTimeout(function () {
                        currentThis.nextPath("/myreadinglists")
                    }
                        , 3000);
                }
                else {
                    this.setState({ showModal: true, errorMessage: response.meta.errors.join(", ") })
                }
            }
            );
    };

    nextPath = (path) => {
        this.props.history.push(path);
    }

    getAppliedFilters = (props = this.props) => {
        const parsedFilters = queryString.parse(window.location.search);
        let currentLanguage = localStorage.getItem('locale')

        Object.entries(parsedFilters).forEach(([k, v]) => {
            if (Array.isArray(defaultFilterValues[k]) && !Array.isArray(v)) {
                parsedFilters[k] = [v];
            }
        });

        let translatedLanguage = currentLanguage && this.state.languages[currentLanguage]
        if (!translatedLanguage) {
            translatedLanguage = 'English'
        } else {
            translatedLanguage = this.state.languages[currentLanguage]
        }

        //WARN: Need to look into a side effect bug here
        //that is suspiciously changes the defaultFilters.role array even though
        //there is not direct mutation of this object. So each time, we ensure
        //role of defaultFilters is reset to empty array.

        defaultFilterValues.language = [translatedLanguage];

        return {
            ...defaultFilterValues,
            ...parsedFilters,
        };
    };

    onOpenSearchModal = () => this.setState({ isSearchModalOpen: true });
    onCloseSearchModal = () => this.setState({ isSearchModalOpen: false });

    onBookSearch = searchQueryValue => {
        let appliedFilters = this.getAppliedFilters();
        this.setState({ isSearchModalOpen: false, searchQueryValue }, () => {
            appliedFilters.query = searchQueryValue
            const searchParams = queryString.stringify(appliedFilters);
            this.props.navigate({ search: `?${searchParams}` }, { replace: true });
            this.props.fetchAllBooksWorkflow(appliedFilters)
        });
    };

    render() {
        const baseClassName = 'pb-add-books-container';
        const {
            parentClassName,
            Bookslist,
            appliedFilters,
            newList,
            t,
        } = this.props;

        const shouldShowLoadMore =
            this.props.loadedPages &&
            this.props.loadedPages < this.props.totalBooksPage;

        const classes = {
            [baseClassName]: true,
            [parentClassName]: parentClassName,
        };

        const isRightToLeftDirection = localStorage.getItem('locale') === 'ar' || localStorage.getItem('locale') === 'ur';

        // Fetch tab from URL. to initialize default tab
        const tab = this.getTab();

        return (
            <div className={classNames(classes)}>
                <DocumentTitle
                    title={`${t('Readinglist.add-books')} - ${t(
                        'global.site-title'
                    )}`}
                />
                <Block background="transparent">
                    {this.state.showModal &&
                        <Modal>
                            <div className='error-modal-div' >
                                <span className='error-message'>{this.state.errorMessage}</span>
                                <span
                                    className="__done-button close-button"
                                    onClick={() => {
                                        this.setState({ showModal: false })
                                    }}
                                    style={{ position: "absolute", bottom: "10px", right: "10px" }}
                                >
                                    {t('global.close')}
                                </span>
                            </div>
                        </Modal>
                    }
                    <ContentStyler>
                        <div className="__myReadingList-header">
                            <h3>{t('Readinglist.add-books')}</h3>
                            <div
                                className={`book-section--menu-search`}
                                onClick={this.onOpenSearchModal}
                            >
                                <TitleImage
                                    className={isRightToLeftDirection ? "__profile-images-arabic" : "__profile-images"}
                                    svgName={require('../../assets/search_tl.svg')}
                                    title={t('books.search')}
                                    contentSpecificSearch
                                />
                            </div>
                            {newList && newList.books.length <= 0 ? (
                                <div className={`${baseClassName}__disabled-button`}>
                                    <span
                                        className="__done-button-disabled"

                                    >
                                        {t('ReadingList.done')}
                                    </span>
                                </div>
                            ) : (
                                <div className="__done-button-section">
                                    {this.state.disable ?

                                        <span
                                            className="__done-button-disabled"

                                        >
                                            <img src="/public/loader.svg" alt="loader" className="svgLoaderRL" style={{ margin: "0px 5px" }} />
                                            {t('ReadingList.done')}
                                        </span>
                                        :
                                        <span
                                            className="__done-button"
                                            onClick={() => {
                                                if (this.props.managingList) {
                                                    this.manageReadingList();
                                                    this.setState({ disable: true });
                                                } else {
                                                    this.createReadingList();
                                                    this.setState({ disable: true });
                                                }
                                            }}
                                        >
                                            {t('ReadingList.done')}
                                        </span>
                                    }
                                    {/* <Button
                                        disable={this.state.disable}
                                        buttonBlue
                                        label={t('ReadingList.done')}
                                        variant="primary"
                                        dataGTM="done"
                                    /> */}
                                </div>
                            )}
                        </div>
                    </ContentStyler>
                </Block>

                <AddBooks
                    BooksList={Bookslist}
                    applyFilter={this.applyFilter}
                    removeFilter={this.removeFilter}
                    onSortOptionChanged={this.onSortOptionChanged}
                    onTabChange={this.onTabChange}
                    tags={appliedFilters.tags}
                    appliedFilters={{ ...appliedFilters, tab: tab }}
                    isAddBook
                    newList={newList}
                />
                {
                    shouldShowLoadMore ? (
                        <Pagination
                            onClick={this.onLoadMore}
                            loading={this.props.isFetchingMoreBooks}
                            dataGTM="addbooks-view-more"
                        />
                    ) : null
                }
                {
                    this.state.isSearchModalOpen ? (
                        <SearchModal
                            viewport={this.props.viewport}
                            onClose={this.onCloseSearchModal}
                            onSubmit={this.onBookSearch}
                        />
                    ) : null
                }
            </div >
        );
    }
}

AddBooksContainer.propTypes = {
    parentClassName: PropTypes.string,
    fetchMyReadingListWorkflow: PropTypes.func.isRequired,
};

const mapStateToProps = ({ allBooks, viewport, allFilters, readingLists }) => {
    return {
        Bookslist: allBooks.books,
        isFetchingMoreBooks: allBooks.isFetchingMoreBooks,
        totalBooksPage: allBooks.totalBooksPage,
        loadedPages: allBooks.loadedPages,
        appliedFilters: allFilters[allFilters.filterType],
        filterType: allFilters.filterType,
        newList: readingLists.newList,
        // BookAddedToList: readingLists.newList,
        managingList: readingLists.managingList,
        allFilters,
        viewport,
        totalBooksCount: allBooks.totalBooksCount
    };
};

const mapDispatchToProps = {
    fetchAllBooksWorkflow,
    createMyReadingListWorkflow,
    fetchMyReadingListWorkflow,
    fetchManageReadingListWorkflow,
    replace,
    applyFilter,
    resetFilters,
};

export default withRouter(translate()(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(AddBooksContainer)
));
