import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { translate } from 'react-polyglot';
import DocumentTitle from 'react-document-title';
import queryString from 'query-string';
import { replace } from 'react-router-redux';
import u from 'updeep';
import { cloneDeep } from 'lodash';
import Joyride from 'react-joyride';
import SearchModal from '../SearchModal';
import TitleImage from '../TitleImage';
import ResourceCardContainer from '../ResourceCardContainer';
import Block from '../Block';
import {
    fetchReferencesWorkflow,
    fetchAllReferencesFiltersWorkflow,
} from '../../redux/referencesActions';
import './ResourceContainer.scss';
import Pagination from '../Pagination';
import { applyFilter, resetFilters } from '../../redux/allFiltersActions';
import { sortOptions } from '../../lib/constants';
import LoaderBlock from '../LoaderBlock';
import FiltersBar from '../FiltersBar';
import { links } from '../../lib/constants';
import HeroCarousel from '../HeroCarousel';
import { Banner } from '../../api';
import withRouter from "../../util/withRouter"

const mapDispatchToProps = {
    fetchAllReferencesFiltersWorkflow,
    fetchReferencesWorkflow,
    replace,
    applyFilter,
    resetFilters,
};

const mapStateToProps = ({
    user,
    references,
    viewport,
    user: { profile },
    allFilters: { readFilters },
    help,
}) => ({
    ...references,
    viewport,
    appliedFilters: readFilters,
    userEmail: profile.email,
    filters: references.filters,
    name: profile.name,
    helpStatus: help.helpStatus,
    isLoggedIn:user.isLoggedIn
});

const defaultFilters = {
    category: [],
    role: [],
    language: [],
    sort: sortOptions.relevance,
};

@translate()
@connect(
    mapStateToProps,
    mapDispatchToProps
)
@translate()
class ResourceContainer extends Component {
    constructor(props) {
        super(props);
        const { t } = props;
        this.state = {
            // The filters bar allows searching through available
            // filter values. We store that search text here.
            filtersSearchText: {
                category: '',
                role: '',
            },
            isResourceMobile: false,
            isSearchModalOpen: false,
            queryValue: '',
            bannerData:[]
        };
        this.filtersBarSortOptions = [
            {
                name: t('sort.relevance'),
                queryValue: sortOptions.relevance,
            },
            {
                name: t('sort.new-arrivals-images'),
                queryValue: sortOptions.newArrivals,
            },
            {
                name: t('sort.most-viewed'),
                queryValue: sortOptions.mostViewed,
            },
            {
                name: t('sort.most-liked'),
                queryValue: sortOptions.mostLiked,
            },
        ];

        this.filtersPanelSortOptions = this.filtersBarSortOptions.map(so => ({
            label: so.name,
            value: so.queryValue,
        }));
    }

    componentDidMount() {
        this.isMobile();
            this.fetchReferences();
        // Banner.fetchBannerImage("resources")
        //     .then((response)=>{
        //         return response.json();
        //     })
        //     .then((response)=>{
        //         this.setState({
        //             bannerData:response.data.bannerImages
        //         })
        //     })
    }

    componentDidUpdate(nextProps) {
        if (this.props.location.search !== nextProps.location.search) {
            if (this.props.location.search === '') {
                this.isMobile();
                this.fetchReferences();
            }
        }
    }

    fetchReferences = () => {
        const {
            fetchAllReferencesFiltersWorkflow,
            fetchReferencesWorkflow,
            location
        } = this.props;
        const { language } = queryString.parse(
            location.search
        );
        fetchAllReferencesFiltersWorkflow().then((response) => {
            let filters = this.getAppliedFilters();
            filters.role = [];
            filters.role = filters.role.concat(response.data.current_role);
            filters.language = language || response.data.current_language;

            const searchParams = queryString.stringify(filters);
            this.props.navigate({ search: `?${searchParams}` }, { replace: true });

            fetchReferencesWorkflow(filters);
        });
    }

    fetchMeAndReferences = async () => {
        const response = await this.props.fetchMeWorkflow();
        if (response.data.isLoggedIn) {
            this.fetchReferences();   
        } else {
            this.props.history.push(links.fetchTabLogin('resources'))
        }
    }

    updateFiltersSearchText = (key, text) => {
        this.setState(
            u(
                {
                    filtersSearchText: {
                        [key]: text,
                    },
                },
                this.state
            )
        );
    };

    updateStyleSearchText = e =>
        this.updateFiltersSearchText('style', e.target.value);
    updatePublisherSearchText = e =>
        this.updateFiltersSearchText('publisher', e.target.value);
    updateCategorySearchText = e =>
        this.updateFiltersSearchText('category', e.target.value);
    updateCategorySearchText = e =>
        this.updateFiltersSearchText('role', e.target.value);

    getAppliedFilters = (props = this.props) => {
        const parsedFilters = queryString.parse(window.location.search);
        Object.entries(parsedFilters).forEach(([k, v]) => {
            if (Array.isArray(defaultFilters[k]) && !Array.isArray(v)) {
                parsedFilters[k] = [v];
            }
        });

        //WARN: Need to look into a side effect bug here
        //that is suspiciously changes the defaultFilters.role array even though
        //there is not direct mutation of this object. So each time, we ensure
        //role of defaultFilters is reset to empty array.
        defaultFilters.role = [];
        return {
            ...defaultFilters,
            ...parsedFilters,
        };
    };

    applyFilter = (type, value) => {
        const appliedFilters = cloneDeep(this.getAppliedFilters());
        const filterIsArray = Array.isArray(appliedFilters[type]);

        if (filterIsArray) {
            appliedFilters[type].push(value);
        } else {
            appliedFilters[type] = value;
        }

        const searchParams = queryString.stringify(appliedFilters);
        this.props.navigate({ search: `?${searchParams}` }, { replace: true });

        this.props.fetchReferencesWorkflow(appliedFilters);
    };

    removeFilter = (type, value) => {
        const appliedFilters = cloneDeep(this.getAppliedFilters());

        if (!appliedFilters[type]) {
            return;
        }

        if (Array.isArray(appliedFilters[type])) {
            const index = appliedFilters[type].indexOf(value);
            if (index !== -1) {
                appliedFilters[type].splice(index, 1);
            }
        } else {
            delete appliedFilters[type];
        }

        const searchParams = queryString.stringify(appliedFilters);
        this.props.navigate({ search: `?${searchParams}` }, { replace: true });

        this.props.fetchReferencesWorkflow(appliedFilters);
    };

    onSortOptionChanged = option => this.applyFilter('sort', option);

    onLoadMore = () =>
        this.props.fetchReferencesWorkflow(
            this.getAppliedFilters(),
            this.props.loadedPages + 1
        );

    isMobile = () => {
        if (
            window.screen.width <= 768
            //  &&
            // window.matchMedia('(orientation: portrait)').matches
        ) {
            this.setState({
                isResourceMobile: true,
            });
        } else {
            this.setState({
                isResourceMobile: false,
            });
        }
    };

    //Resource Content-Specific Search Function
    onResourceSearch = event => {
        let appliedFilters = this.getAppliedFilters();
        this.setState({ queryValue: event, isSearchModalOpen: false }, () => {
            appliedFilters.query = this.state.queryValue
            appliedFilters.sort = 'Relevance';

            const searchParams = queryString.stringify(appliedFilters);
            this.props.navigate({ search: `?${searchParams}` }, { replace: true });
            
            this.props.fetchReferencesWorkflow(appliedFilters);
        })
    }

    onOpenSearchModal = () => this.setState({ isSearchModalOpen: true });
    onCloseSearchModal = () => this.setState({ isSearchModalOpen: false });

    render() {
        const {
            t,
            references,
            resourceHits,
            filters,
            totalReferencesCount,
            viewport
        } = this.props;

        const isRightToLeftDirection = localStorage.getItem("locale") === "ar" || localStorage.getItem('locale') === 'ur';

        const { isResourceMobile, isSearchModalOpen } = this.state;

        const myLanguage = localStorage.getItem('locale');

        const shouldShowLoadMore =
            this.props.loadedPages &&
            this.props.loadedPages < totalReferencesCount;

        const { filtersSearchText } = this.state;

        const appliedFilters = this.getAppliedFilters();

        if (!filters) {
            return <LoaderBlock />;
        }

        let filtersComponent = null;

        filtersComponent = (
            <FiltersBar
                noTopBorder
                pullUp
                filters={filters}
                applyFilter={this.applyFilter}
                removeFilter={this.removeFilter}
                appliedFilters={appliedFilters}
                styleSearchValue={filtersSearchText.style}
                updateStyleSearchValue={this.updateStyleSearchText}
                categorySearchValue={filtersSearchText.category}
                updateCategorySearchValue={this.updateCategorySearchText}
                publisherSearchValue={filtersSearchText.publisher}
                updatePublisherSearchValue={this.updatePublisherSearchText}
                resultsCount={totalReferencesCount}
                sortOptions={this.filtersBarSortOptions}
                applySortOption={this.onSortOptionChanged}
                appliedSortOption={appliedFilters.sort}
                isResources
                isResourceMobile={isResourceMobile}
                categoryFilterGTM="resource-category-filter"
                roleFilterGTM="resource-role-filter"
                languageFilterGTM="resource-language-filter"
                alphabetSortGTM="resource-alphabetic-sort"
                hideLevelSelector
            />
        );

        let steps = [
            {
                target: '.__bookFilter',
                content: t('Resources.filterHelpGuide'),
                placementBeacon: 'left',
                disableBeacon: true,
            },
            {
                target: '.pb-filters-bar__actions--secondary',
                content: t('Resources.sortHelpGuide'),
                placementBeacon: 'left',
            },
            {
                target: '.pb-link--normal',
                content: t('Resources.favoriteHelpGuide'),
                placementBeacon: 'left',
            },
        ];

        let mobileSteps = [
            myLanguage === "ar" || myLanguage === "ur" ? 
            {
                target: '.pb-filters-bar__filter-option-rtl',
                content: t('Resources.filterHelpGuide'),
                placementBeacon: 'left',
                disableBeacon: true,
            }
            :
            {
                target: '.pb-filters-bar__filter-option',
                content: t('Resources.filterHelpGuide'),
                placementBeacon: 'left',
                disableBeacon: true,
            },
            {
                target: '.pb-filters-bar__sort-option',
                content: t('Resources.sortHelpGuide'),
                placementBeacon: 'left',
            },
            {
                target: '.pb-link--normal',
                content: t('Resources.favoriteHelpGuide'),
                placementBeacon: 'left',
            },
        ];

        return (
            <div className="__resource-container">
                <Joyride
                    enabled={true}
                    steps={isResourceMobile ? mobileSteps : steps}
                    continuous
                    showProgress
                    run={this.props.helpStatus}
                    scrollToFirstStep
                    scrollOffset={250}
                    disableScrolling={true}
                    locale={{
                        next: t('global.Next'),
                        back: t('global.Back'),
                        last: t('global.Last'),
                    }}
                    styles={{
                        options: {
                            arrowColor: '#e3ffeb',
                            backgroundColor: '#e3ffeb',
                            textColor: '#004a14',
                            overlayColor: 'rgba(0, 0, 0, 0.8)',
                            primaryColor: '#007AA4',
                            spotlightShadow: '0 0 15px rgba(0, 0, 0, 0.8)',
                            zIndex: 1000,
                        },
                    }}
                />
                <HeroCarousel banners={this.state.bannerData} padding130={false} />  
                <DocumentTitle
                    title={`${t('global.resources')} - ${t(
                        'global.site-title'
                    )}`}
                />
                <Block verticalMargin={true} background="transparent">
                    <div className="resources-header">
                        <div className="__resources-title">
                            <h3>{t('global.resources')}</h3>
                            <h3
                                className={
                                    isRightToLeftDirection
                                        ? '__total-margin-left'
                                        : '__normal'
                                }
                            >
                                ({resourceHits})
                            </h3>
                        </div>
                        <div
                            className={'__resources-search'}
                            onClick={this.onOpenSearchModal}
                        >
                            <TitleImage
                                className={isRightToLeftDirection ? "__profile-images-arabic" : "__profile-images"}
                                svgName={require('../../assets/search_tl.svg')}
                                title={t('resources.search')}
                                contentSpecificSearch
                            />
                        </div>
                    </div>
                </Block>
                {references && references.length >= 0 ? (
                    <div className="__video-filter-component">
                        {filtersComponent}
                    </div>
                ) : null}
                <div className="__resource-card-section">
                    <ResourceCardContainer
                        myLanguage={myLanguage}
                        References={references}
                        applyFilter={this.applyFilter}
                        removeFilter={this.removeFilter}
                        onSortOptionChanged={this.onSortOptionChanged}
                        tags={appliedFilters.tags}
                        appliedFilters={{ ...appliedFilters }}
                    />
                    {shouldShowLoadMore ? (
                        <Pagination
                            onClick={this.onLoadMore}
                            loading={this.props.isFetchingMoreReferences}
                            dataGTM="resources-view-more"
                        />
                    ) : null}
                </div>
                {isSearchModalOpen ? (
                    <SearchModal
                        viewport={viewport}
                        onClose={this.onCloseSearchModal}
                        onSubmit={this.onResourceSearch}
                    />
                ) : null}
            </div>
        );
    }
}
ResourceContainer.propTypes = {
    parentClassName: PropTypes.string,
};
export default withRouter(ResourceContainer);
