import React from 'react';
import { translate } from 'react-polyglot';
import PropTypes from 'prop-types';
import Img from '../../Img';
import { isRightToLeftDirection } from '../../../util/utils';
import SvgIcon from '../../SvgIcon';

const ListCardImage = ({
    t,
    synopsis,
    coverImage,
    title,
    imageSrcsetSizes,
    offline,
    bookImageGTM,
    landingPageBook,
    baseClassName,
    myLanguage,
    pagesCount,
}) => {
    return (
        <div>
            <Img
                synopsis={synopsis}
                image={coverImage}
                alt={title}
                lazyLoad
                sizes={imageSrcsetSizes.grid2up6up}
                offline={offline}
                dataGTM={bookImageGTM}
                padding130
            />
            {landingPageBook && (
                <div>
                    <div
                        className={
                            isRightToLeftDirection
                                ? `${baseClassName}__border-image-wrapper-arabic`
                                : `${baseClassName}__border-image-wrapper`
                        }
                    >
                        <SvgIcon name={'border-image'} size="border-image" />
                    </div>
                    <div
                        className={
                            myLanguage === 'lo'
                                ? `${baseClassName}__page-number-lo`
                                : isRightToLeftDirection
                                ? `${baseClassName}__page-number-arabic`
                                : `${baseClassName}__page-number`
                        }
                    >
                        {pagesCount}
                        <p>{t('Book.page')}</p>
                    </div>
                </div>
            )}
        </div>
    );
};

ListCardImage.propTypes = {
  t: PropTypes.func.isRequired,
  synopsis: PropTypes.string,
  coverImage: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  imageSrcsetSizes: PropTypes.shape({
      grid2up6up: PropTypes.string,
  }).isRequired,
  offline: PropTypes.bool,
  bookImageGTM: PropTypes.string,
  landingPageBook: PropTypes.bool,
  baseClassName: PropTypes.string.isRequired,
  myLanguage: PropTypes.string,
  pagesCount: PropTypes.number,
};

export default translate()(ListCardImage);
