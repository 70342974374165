import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import queryString from 'query-string';
import cloneDeep from 'lodash/cloneDeep';
import { translate } from 'react-polyglot';
import DocumentTitle from 'react-document-title';
import Joyride from 'react-joyride';
import { replace } from 'react-router-redux';
import TitleImage from '../TitleImage';
import SearchModal from '../SearchModal';
import Button from '../Button';
import Block from '../Block';
import {
    fetchReadingListDetailsWorkflow,
    fetchMyReadingListWorkflow,
    createNewReadingListActionAsync,
} from '../../redux/readingListsActions';
import ReadingListInnerMenuTabs from '../ReadingListInnerMenuTabs';
import ThemedReadingListContainer from '../ThemedReadingListContainer';
import MyReadingListContainer from '../MyReadingListContainer';
import { sortOptions } from '../../lib/constants';
import Link from "../Link";
import HeroCarousel from '../HeroCarousel';
import {Banner} from '../../api';
import withRouter from "../../util/withRouter"

import './ReadingListContainer.scss';



const defaultFilters = {
    category: [],
    publisher: [],
    role: [],
    style: [],
    language: [],
    sort: sortOptions.position,
    tags: [],
    query: '',
};

class ReadingListContainer extends Component {
    constructor(props) {
        super(props);

        const storedStatus = localStorage.getItem("status");
        const loginStatus = storedStatus !== "undefined" ? JSON.parse(storedStatus) : false;

        this.state = {
            readingList: cloneDeep(props.readingList),
            isEditing: false,
            didEditReadingList: false,
            isConfirmDeleteModalVisible: false,
            bookIndexToDelete: -1,
            isListOpenedGaFired: false,
            isReadingListMobile: false,
            isSearchModalOpen: false,
            loginStatus: loginStatus ,
            bannerData:[]
        };
    }

    componentDidMount() {
        const myReadingListsLoadedPages = this.props.myReadingListsLoadedPages || 0;

        this.isMobile();
        this.state.loginStatus === true &&
            this.props.fetchMyReadingListWorkflow(
                this.props.appliedFilters,
                this.props.slug,
                myReadingListsLoadedPages + 1
            );
        // Banner.fetchBannerImage("reading_lists")
        //     .then((response)=>{
        //         return response.json();
        //     })
        //     .then((response)=>{
        //         this.setState({
        //             bannerData:response.data.bannerImages
        //         })
        //     })
    }



    loadMoreMyReadingList = () => {
        this.props.fetchMyReadingListWorkflow(
            this.props.appliedFilters,
            this.props.slug,
            this.props.myReadingListsLoadedPages + 1
        );
    };

    nextPath(path) {
        this.props.navigate(path);
    }

    createReadingListButton = () => {
        return (
            <div className="__reading-list-button">
                <Button
                    buttonBlue
                    fullWidth
                    label={this.props.t(
                        'Readinglist.create-new-reading-list'
                    )}
                    iconLeftOffline="plus"
                    variant="primaryRL"
                    onClick={() =>
                        this.nextPath(
                            '/myreadinglists/createreadinglist'
                        )
                    }
                    dataGTM="create-new-reading-list"
                />
            </div>
        );
    };

    isMobile = () => {
        if (
            window.screen.width <= 768 &&
            window.matchMedia('(orientation: portrait)').matches
        ) {
            this.setState({
                isReadingListMobile: true,
            });
        } else {
            this.setState({
                isReadingListMobile: false,
            });
        }
    };

    getAppliedFilters = (props = this.props) => {
        const parsedFilters = queryString.parse(props.location.search);
        Object.entries(parsedFilters).forEach(([k, v]) => {
            if (Array.isArray(defaultFilters[k]) && !Array.isArray(v)) {
                parsedFilters[k] = [v];
            }
        });

        return {
            ...defaultFilters,
            ...parsedFilters,
        };
    }

    //Reading-List Content-Specific Search Function
    onReadingListSearch = event => {
        let appliedFilters = this.getAppliedFilters();
        this.setState({ queryValue: event, isSearchModalOpen: false }, () => {
            appliedFilters.query = this.state.queryValue;

            const searchParams = queryString.stringify(appliedFilters);
            this.props.navigate({ search: `?${searchParams}` }, { replace: true });

            this.props.fetchReadingListDetailsWorkflow(appliedFilters);
        });

    }

    onOpenSearchModal = () => this.setState({ isSearchModalOpen: true });
    onCloseSearchModal = () => this.setState({ isSearchModalOpen: false });

    render() {
        const {
            t,
            themedReadingLists,
            myReadingLists,
            loadedPages,
            myReadingListsLoadedPages,
            totalListsCount,
            totalMyReadingListsCount,
            isFetchingMoreReadingList,
            isFetchingMoreMyReadingList,
            filters,
            sortOptions,
            viewport,
            isLoggedIn,
            isFetchingReadingList
        } = this.props;

        const { isReadingListMobile, isSearchModalOpen } = this.state;

        const myLanguage = localStorage.getItem('locale');

        const isRightToLeftDirection = localStorage.getItem('locale') === 'ar' || localStorage.getItem('locale') === 'ur';

        // const showMyReadingListsLoadMore =
        //     myReadingListsLoadedPages &&
        //     myReadingListsLoadedPages < totalMyReadingListsCount;

        // const baseClassName = 'pb-readinglist-container';
        const themedReadingList =
        window.location.pathname=== '/readinglists';
        let steps = [
            {
                target: isLoggedIn ? '.subMenuTabs' : '.Tabs',
                content: this.props.t('ReadingList.subMenuHelpGuide'),
                placementBeacon: 'left',
                disableBeacon: true,
            },
            {
                target: '.__bookFilter',
                content: this.props.t('ReadingList.filterHelpGuide'),
                placementBeacon: 'left',
                disableBeacon: true,
            },
            {
                target: '.pb-filters-bar__actions--secondary',
                content: this.props.t('ReadingList.sortHelpGuide'),
                placementBeacon: 'left',
            },
        ];

        let mobileSteps = [
            {
                target: isLoggedIn ? '.subMenuTabs' : '.Tabs',
                content: this.props.t('ReadingList.subMenuHelpGuide'),
                placementBeacon: 'left',
                disableBeacon: true,
            },
            {
                target: '.pb-filters-bar__filter-option',
                content: this.props.t('ReadingList.filterHelpGuide'),
                placementBeacon: 'left',
            },
            {
                target: '.pb-filters-bar__sort-option',
                content: this.props.t('ReadingList.sortHelpGuide'),
                placementBeacon: 'left',
            },
        ];

        return (
            <div className="__reading-list-container">
                <Joyride
                    enabled={true}
                    steps={isReadingListMobile ? mobileSteps : steps}
                    continuous
                    showProgress
                    run={this.props.helpStatus}
                    scrollToFirstStep
                    scrollOffset={250}
                    disableScrolling={false}
                    locale={{
                        next: t('global.Next'),
                        back: t('global.Back'),
                        last: t('global.Last'),
                    }}
                    styles={{
                        options: {
                            arrowColor: '#e3ffeb',
                            backgroundColor: '#e3ffeb',
                            textColor: '#004a14',
                            overlayColor: 'rgba(0, 0, 0, 0.8)',
                            primaryColor: '#007AA4',
                            spotlightShadow: '0 0 15px rgba(0, 0, 0, 0.8)',
                            zIndex: 1000,
                        },
                    }}
                />
                <DocumentTitle
                    title={`${t('global.reading-list', 2)} - ${t(
                        'global.site-title'
                    )}`}
                />
                <Block offlineSection>
                <HeroCarousel banners={this.state.bannerData} padding130={false} />  
                    <div className="reading-list-container">
                        <div className="__reading-list-header">
                            <h3>{t('global.reading-list', 2)}</h3>
                        </div>
                        <div
                            className={'__reading-list-search'}
                            onClick={this.onOpenSearchModal}
                        >
                            <TitleImage
                                className={isRightToLeftDirection ? "__profile-images-arabic" : "__profile-images"}
                                svgName={require('../../assets/search_tl.svg')}
                                title={t('lists.search')}
                                contentSpecificSearch
                            />
                        </div>
                    </div>
                </Block>
                <div className="__readinglist-navbar-section">
                    <div className="__readinglist-navbar">
                        <ReadingListInnerMenuTabs isLoggedIn={isLoggedIn} />
                    </div>
                    {!themedReadingList ? (
                        <div>{this.createReadingListButton()}</div>
                    ) : null}
                </div>
                {themedReadingList ? (
                    <div className="__reading-list-container-section">
                        <ThemedReadingListContainer
                            myLanguage={myLanguage}
                            themedReadingLists={themedReadingLists}
                            loadedPages={loadedPages}
                            totalListsCount={totalListsCount}
                            isFetchingMoreReadingList={
                                isFetchingMoreReadingList
                            }
                            isFetchingReadingList={isFetchingReadingList}
                            viewport={viewport}
                            location={this.props.location}
                            filters={filters}
                            sortOptions={sortOptions}
                            t={t}
                        />
                    </div>
                ) : (
                    <div className="__my-readinglist-container-section">
                        {myReadingLists && myReadingLists.length > 0 ? (
                            <MyReadingListContainer
                                myLanguage={myLanguage}
                                myReadingLists={myReadingLists}
                                myReadingListsLoadedPages={
                                    myReadingListsLoadedPages
                                }
                                totalMyReadingListsCount={
                                    totalMyReadingListsCount
                                }
                                isFetchingMoreMyReadingList={
                                    isFetchingMoreMyReadingList
                                }
                                loadedPages={loadedPages}
                                location={this.props.location}
                                filters={filters}
                                sortOptions={sortOptions}
                                t={t}
                                isMyReadingList
                            />
                        ) : (
                            <div className="__no-reading-list">
                                <span>
                                    {t('Readinglist.Readinglist-not-available')}
                                    ,{' '}
                                </span>
                                <Link
                                    href="/myreadinglists/createreadinglist"
                                    theme="blue-text"
                                >
                                    {t('Readinglist.create-new-reading-list')}
                                </Link>
                            </div>
                        )}
                    </div>
                )}
                {isSearchModalOpen ? (
                    <SearchModal
                        viewport={viewport}
                        onClose={this.onCloseSearchModal}
                        onSubmit={this.onReadingListSearch}
                    />
                ) : null}
            </div>
        );
    }
}

ReadingListContainer.propTypes = {
    themedReadingLists: PropTypes.object,
    isFetchingReadingList: PropTypes.bool.isRequired,
    isLoggedIn: PropTypes.bool.isRequired,
    profile: PropTypes.shape({
        id: PropTypes.number,
    }).isRequired,
    fetchReadingListDetailsWorkflow: PropTypes.func.isRequired,
    fetchMyReadingListWorkflow: PropTypes.func.isRequired,
    postReadingListLikeWorkflow: PropTypes.func.isRequired,
    updateReadingListWorkflow: PropTypes.func.isRequired,
    viewport: PropTypes.object.isRequired,
};
const mapStateToProps = ({
    readingLists,
    viewport,
    myReadingLists,
    user,
    help,
}) => {
    return {
        themedReadingLists: readingLists.readingLists,
        myReadingLists: readingLists.myReadingLists.allReadingLists,
        isFetchingReadingList: readingLists.isFetchingReadingList,
        isFetchingMoreReadingList: readingLists.isFetchingMoreReadingList,
        isFetchingMoreMyReadingList: readingLists.isFetchingMoreMyReadingList,
        loadedPages: readingLists.loadedPages,
        myReadingListsLoadedPages: readingLists.myReadingListsLoadedPages,
        totalListsCount: readingLists.totalListsCount,
        totalMyReadingListsCount: readingLists.totalMyReadingListsCount,
        filters: readingLists.filters,
        sortOptions: readingLists.sortOptions,
        helpStatus: help.helpStatus,
        // isUpdatingReadingList: readingList.isUpdatingReadingList,
        isLoggedIn: user.isLoggedIn,
        // profile: user.profile,
        viewport,
        // isSavingOffline: offlineBooks.isSavingOffline,
        // offlineBooks,
    };
};

const mapDispatchToProps = {
    replace,
    fetchReadingListDetailsWorkflow,
    fetchMyReadingListWorkflow,
    createNewReadingListActionAsync,
};

export default withRouter(translate()(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(ReadingListContainer)
));
