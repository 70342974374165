import React, { Component } from 'react';
import right from '../../assets/Right_option.svg';
import left from '../../assets/Left_option.svg';
import './BookPreview.scss';
import '../Book/Book.scss';
import locales from '../../i18n';
import DataLoader from '../DataLoader/DataLoader';
import { OfflineStory } from '../../api';
import DynamicBookDetails from '../DynamicBookDetails';
import BookUploadHeader from '../BookUploadHeader';
import BookUploadFooter from '../BookUploadFooter';

class BookPreview extends Component {
    constructor(props) {
        super(props);
        this.state = {
            startIndex: 1,
            bookLength: [],
            loader: true,
        };
    }

    componentDidMount() {
        OfflineStory.fetchOfflineStoryDetail(
            this.props.location.pathname.split('/')[2]
        )
            .then((response) => {
                return response.json();
            })
            .then((response) => {
                const data = response.data;
                let listOfImages = [...data.pages];
                this.setState((state, props) => ({
                    bookLength: [data, ...listOfImages],
                    loader: false,
                }));
            });
    }

    nextPage = () => {
        this.props.history.push(
            `/publication/${this.props.location.pathname.split('/')[2]}`
        );
    };

    backPage = () => {
        this.props.history.push(
            `/upload/${this.props.location.pathname.split('/')[2]}`
        );
    };

    render() {
        const isRightToLeftDirection =
            localStorage.getItem('locale') === 'ar' ||
            localStorage.getItem('locale') === 'ur';
        const locale = localStorage.getItem('locale') || 'en';

        const messages = locales[locale];
        const baseClassName = 'book-preview';

        return (
            <div>
                {this.state.loader ? (
                    <DataLoader />
                ) : (
                    <div className={`${baseClassName}__page_view_container`}>
                        <div className={`${baseClassName}__arrow_position`}>
                            <img
                                onClick={() => {
                                    if (this.state.startIndex > 1) {
                                        this.setState((state, props) => ({
                                            startIndex: state.startIndex - 2,
                                        }));
                                    }
                                }}
                                className={
                                    (isRightToLeftDirection
                                        ? `${baseClassName}__right_arrow `
                                        : `${baseClassName}__left_arrow `) +
                                    (this.state.startIndex > 1
                                        ? ''
                                        : `${baseClassName}__disable_button`)
                                }
                                src={isRightToLeftDirection ? right : left}
                                alt="left"
                            />
                        </div>
                        <div>
                            <BookUploadHeader
                                currentPage={3}
                                history={this.props.history}
                            />
                            <div
                                className={`${baseClassName}__carousal_position`}
                            >
                                <div>
                                    <span
                                        className={
                                            `${baseClassName}__title ` +
                                            (isRightToLeftDirection
                                                ? `${baseClassName}__mirror`
                                                : '')
                                        }
                                    >
                                        {' '}
                                        {messages['Book.check']}
                                    </span>
                                </div>
                                <div
                                    className={`${baseClassName}__preview_wrapper`}
                                >
                                    {this.state.bookLength
                                        .slice(
                                            this.state.startIndex - 1,
                                            this.state.startIndex + 1
                                        )
                                        .map((e) => (
                                            <div
                                                className={`${baseClassName}__verticalhorizontal`}
                                            >
                                                {e.url !== undefined ? (
                                                    <img
                                                        src={e.url}
                                                        alt="preview_img"
                                                        className={`${baseClassName}__center_image`}
                                                    />
                                                ) : (
                                                    <DynamicBookDetails
                                                        title={e.title}
                                                        level={e.reading_level}
                                                        synopsis={e.synopsis}
                                                        messages={messages}
                                                        publisher={e.publishers}
                                                        levelColour={
                                                            e.story_level_colour
                                                        }
                                                        authors={e.authors}
                                                        illustrators={
                                                            e.illustrators
                                                        }
                                                        translators={
                                                            e.translators
                                                        }
                                                        dynamicWrapperClass={`${baseClassName}__dynamic_wrapper`}
                                                    />
                                                )}
                                            </div>
                                        ))}
                                </div>
                            </div>
                            <BookUploadFooter
                                backPage={this.backPage}
                                nextPage={this.nextPage}
                            />
                        </div>
                        <div className={`${baseClassName}__arrow_position`}>
                            <img
                                onClick={() => {
                                    if (
                                        this.state.startIndex <
                                        this.state.bookLength.length - 1
                                    ) {
                                        this.setState((state, props) => ({
                                            startIndex: state.startIndex + 2,
                                        }));
                                    }
                                }}
                                className={
                                    (isRightToLeftDirection
                                        ? `${baseClassName}__left_arrow `
                                        : `${baseClassName}__right_arrow `) +
                                    (this.state.startIndex <
                                    this.state.bookLength.length - 1
                                        ? ''
                                        : `${baseClassName}__disable_button`)
                                }
                                src={isRightToLeftDirection ? left : right}
                                alt="right"
                            />
                        </div>
                    </div>
                )}{' '}
            </div>
        );
    }
}

export default BookPreview;
