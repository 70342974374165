import React, { Component } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import SvgIcon from '../SvgIcon';
import Loader from '../Loader';
import Link from '../Link';
import Dropdown from '../Dropdown';
import List from '../List';
import './Button.scss';
import { links } from '../../lib/constants';

// eslint-disable-next-line no-script-url
const noopStr = 'javascript:;';

class Button extends Component {
    static defaultProps = {
        variant: 'default',
        size: 'm',
    };

    constructor(props) {
        super(props);
        this.handleClick = this.handleClick.bind(this);
    }

    handleClick(e) {
        const { disabled, loading, onClick, download, href, id } = this.props;
        e.preventDefault();
        if (disabled) {
            e.stopPropagation();
        } else {
            if (!loading && onClick) {
                this.props.onClick(e);
            } else if (!loading && download) {
                var element = document.createElement('a');
                var file = new Blob([href], { type: 'pdf/*' });
                element.href = URL.createObjectURL(file);
                element.download = `book-${id}.pdf`;
                element.click();
            }
        }
    }

    render() {
        const baseClassName = 'pb-button';

        const {
            label,
            parentClassName,
            iconLeft,
            iconRight,
            fullWidth,
            disabled,
            matchInputFields,
            loading,
            variant,
            inGroup,
            mergeTop,
            mergeBottom,
            mergeSides,
            size,
            buttonBlue,
            readStory,
            iconLeftOffline,
            applyButton,
            dataGTM,
            viewVideos,
            download,
            href,
            onClickAddToOffline,
            onClickRemoveFromOffline,
            availableOffline,
            saveOffline,
            isLoggedIn,
            homePage,
            cardLevelOffline,
            isSavingOffline,
            t,
            bookSlug,
            btnDisabled,
            storyReader,
            buttonWatchBlue
        } = this.props;

        const isRightToLeftDirection = localStorage.getItem('locale') === 'ar' || localStorage.getItem('locale') === 'ur';

        let leftIconEl;
        if (loading) {
            leftIconEl = (
                <Loader
                    size="m"
                    parentClassName={
                        isRightToLeftDirection
                            ? `${baseClassName}__icon-right`
                            : `${baseClassName}__icon-left`
                    }
                />
            );
        } else if (iconLeft) {
            leftIconEl = (
                <SvgIcon
                    name={iconLeft}
                    size="ml"
                    parentClassName={
                        isRightToLeftDirection
                            ? `${baseClassName}__icon-right`
                            : `${baseClassName}__icon-left`
                    }
                    dataGTM={dataGTM}
                />
            );
        } else if (iconLeftOffline) {
            leftIconEl = (
                <SvgIcon
                    name={iconLeftOffline}
                    size="sm"
                    parentClassName={
                        isRightToLeftDirection
                            ? `${baseClassName}__icon-left-offline`
                            : `${baseClassName}__icon-right-offline`
                    }
                    dataGTM={dataGTM}
                />
            );
        }

        let rightIconEl;
        if (iconRight) {
            rightIconEl = (
                <SvgIcon
                    name={iconRight}
                    size="m"
                    parentClassName={`${baseClassName}__icon-right`}
                    dataGTM={dataGTM}
                />
            );
        }

        const classes = {
            [baseClassName]: true,
            [parentClassName]: parentClassName,
            [`${baseClassName}--${variant}`]: variant,
            [`${baseClassName}--size-${size}`]: size,
            [`${baseClassName}--disabled`]: disabled || loading,
            [`${baseClassName}--loading`]: loading,
            [`${baseClassName}--match-input-fields`]: matchInputFields,
            [`${baseClassName}--full-width`]: fullWidth,
            [`${baseClassName}--in-group-${inGroup}`]: inGroup,
            [`${baseClassName}--merge-top`]: mergeTop,
            [`${baseClassName}--merge-bottom`]: mergeBottom,
            [`${baseClassName}--merge-sides`]: mergeSides,
            [`${baseClassName}--button-blue`]: buttonBlue,
            [`${baseClassName}--read-story`]: readStory,
            [`${baseClassName}--apply-button`]: applyButton,
            [`${baseClassName}--view-videos`]: viewVideos,
            [`${baseClassName}--button-disabled`]:btnDisabled,
            [`${baseClassName}--story-reader`]:storyReader,
            [`${baseClassName}--button-watch-blue`]:buttonWatchBlue
        };
        // console.log('classes', classNames(classes));

        if (saveOffline) {
            if (disabled) {
                return (
                    <Dropdown
                        toggleEl={
                            <a
                                className={classNames(classes)}
                                href={noopStr}
                                saveOffline={saveOffline ? saveOffline : null}
                                cardLevelOffline={
                                    cardLevelOffline ? cardLevelOffline : null
                                }
                                dataGTM="book-save-offline"
                            >
                                {/* <SvgIcon name="offline" size="m" pushRight /> */}
                                <div className="save-offline-text">
                                    {t('Book.add-to-offline')}
                                </div>
                            </a>
                        }
                        minWidth="m"
                        align="left"
                    >
                        <List nowrap>
                            {process.env.REACT_APP_FEATURE_AUTH ? (
                                <a
                                    className={classNames(classes)}
                                    href={noopStr}
                                    dataGTM="book-log-in"
                                >
                                    {t(
                                        'Book.please-log-in-add-story-to-offline'
                                    )}
                                    <SvgIcon name="user" /> {t('global.log-in')}
                                </a>
                            ) : (
                                <a
                                    className={classNames(classes)}
                                    href={noopStr}
                                    onClick={
                                        bookSlug === 'false'
                                            ? links.login()
                                            : links.saveOffLogin(bookSlug)
                                    }
                                    dataGTM="book-log-in"
                                >
                                    {t(
                                        'Book.please-log-in-add-story-to-offline'
                                    )}
                                    <SvgIcon name="user" /> {t('global.log-in')}
                                </a>
                            )}
                        </List>
                    </Dropdown>
                );
            } else {
                if (isLoggedIn) {
                    return (
                        <div>
                            {homePage ? (
                                <Link
                                    saveOffline={
                                        saveOffline ? saveOffline : null
                                    }
                                    cardLevelOffline={
                                        cardLevelOffline
                                            ? cardLevelOffline
                                            : null
                                    }
                                    onClick={
                                        availableOffline
                                            ? onClickRemoveFromOffline
                                            : onClickAddToOffline
                                    }
                                    theme={availableOffline ? 'danger' : null}
                                    dataGTM="book-save-offline"
                                >
                                    {isSavingOffline ? null : null}{' '}
                                    {availableOffline ? (
                                        <div className={classNames(classes)}>
                                            {t('Book.saved')}
                                        </div>
                                    ) : (
                                        <div className={classNames(classes)}>
                                            {t('Book.add-to-offline')}
                                        </div>
                                    )}
                                </Link>
                            ) : (
                                <Link
                                    className={classNames(classes)}
                                    href={noopStr}
                                    saveOffline={
                                        saveOffline ? saveOffline : null
                                    }
                                    cardLevelOffline={
                                        cardLevelOffline
                                            ? cardLevelOffline
                                            : null
                                    }
                                    onClick={
                                        availableOffline
                                            ? onClickRemoveFromOffline
                                            : onClickAddToOffline
                                    }
                                    theme={availableOffline ? 'danger' : null}
                                    dataGTM="book-save-offline"
                                >
                                    {isSavingOffline ? <Loader /> : null}{' '}
                                    {availableOffline ? (
                                        <div className={classNames(classes)}>
                                            {t('Book.remove-from-offline')}
                                        </div>
                                    ) : (
                                        <div className={classNames(classes)}>
                                            {t('Book.add-to-offline')}
                                        </div>
                                    )}
                                </Link>
                            )}
                        </div>
                    );
                } else {
                    if (availableOffline) {
                        return (
                            <List inline bookRelatedCollection>
                                <Link
                                    onClick={onClickRemoveFromOffline}
                                    saveOffline={
                                        saveOffline ? saveOffline : null
                                    }
                                    cardLevelOffline={
                                        cardLevelOffline
                                            ? cardLevelOffline
                                            : null
                                    }
                                    dataGTM="book-remove-offline"
                                >
                                    {/* <SvgIcon name="bin" />{' '} */}
                                    {homePage ? (
                                        <div className={classNames(classes)}>
                                            {t('Book.saved')}
                                        </div>
                                    ) : (
                                        <div className={classNames(classes)}>
                                            {t('Book.remove-from-offline')}
                                        </div>
                                    )}
                                </Link>
                            </List>
                        );
                    } else {
                        return (
                            <Dropdown
                                toggleEl={
                                    <Link
                                        saveOffline={
                                            saveOffline ? saveOffline : null
                                        }
                                        cardLevelOffline={
                                            cardLevelOffline
                                                ? cardLevelOffline
                                                : null
                                        }
                                        dataGTM="book-save-offline"
                                    >
                                        {/* <SvgIcon name="offline" size="m" pushRight /> */}
                                        <div className={classNames(classes)}>
                                            {t('Book.add-to-offline')}
                                        </div>
                                    </Link>
                                }
                                minWidth="m"
                                align="left"
                            >
                                <List nowrap>
                                    {process.env.REACT_APP_FEATURE_AUTH ? (
                                        <Link
                                            fullWidth
                                            legend={t(
                                                'Book.please-log-in-add-story-to-offline'
                                            )}
                                            dataGTM="book-log-in"
                                        >
                                            <SvgIcon name="user" />{' '}
                                            {t('global.log-in')}
                                        </Link>
                                    ) : (
                                        <Link
                                            fullWidth
                                            legend={t(
                                                'Book.please-log-in-add-story-to-offline'
                                            )}
                                            href={
                                                bookSlug === 'false'
                                                    ? links.login()
                                                    : links.saveOffLogin(
                                                        bookSlug
                                                    )
                                            }
                                            dataGTM="book-log-in"
                                        >
                                            <SvgIcon name="user" />{' '}
                                            {t('global.log-in')}
                                        </Link>
                                    )}
                                </List>
                            </Dropdown>
                        );
                    }
                }
            }
        } else
            return (
                <a
                    className={classNames(classes)}
                    href={download ? href : noopStr}
                    onClick={this.handleClick}
                    data-gtm={dataGTM}
                >
                    {leftIconEl}
                    {label}
                    {rightIconEl}
                </a>
            );
    }
}

Button.propTypes = {
    label: PropTypes.string.isRequired,
    onClick: PropTypes.func,
    parentClassName: PropTypes.string,
    iconLeft: PropTypes.string,
    iconRight: PropTypes.string,
    fullWidth: PropTypes.bool,
    matchInputFields: PropTypes.bool,
    loading: PropTypes.bool,
    disabled: PropTypes.bool,
    inGroup: PropTypes.oneOf(['first', 'last', 'middle']),
    mergeTop: PropTypes.bool,
    mergeBottom: PropTypes.bool,
    mergeSides: PropTypes.bool,
    /* Default variant is default */
    variant: PropTypes.oneOf([
        'default',
        'primary',
        'info',
        'danger',
        'warning',
        'success',
        'facebook',
        'google',
        'nav-link-button',
        'view-more',
        'enter',
        'primaryRL',
        'submit'
    ]),
    /* Default size is m */
    size: PropTypes.oneOf(['m', 's', 'l']),
};

export default Button;
