import React, { Component } from 'react';
import './BookRating.scss';
import close from '../../assets/cancel.svg';
import TextField from '../TextField';
import Link from '../Link';
import {PopUpRating} from '../../api'


class BookRating extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isEdit: true,
            value: 0,
            reviewDesc: '',
            happyEmojiClicked:true,
            neutralEmojiClicked:true,
            sadEmojiClicked:true,
            dataId:'',
            rating_id:'',
        };
    }

    componentDidMount(){
        PopUpRating.fetchAllRatings()
        .then((response) => {
            return response.json();
        })
        .then((response) => {
            const data = response.data
            this.setState({
                dataId:data
            })
        })
    }
    
    
    onDescriptionChange = (e) => this.setState({ reviewDesc: e.target.value });

    happyEmojiHandler = () => {
        this.setState({
            happyEmojiClicked:!this.state.happyEmojiClicked,
            neutralEmojiClicked:true,
            sadEmojiClicked:true,
            rating_id:this.state.happyEmojiClicked ? this.state.dataId[0].id : ''
        })
    }

    neutralEmojiHandler = () => {
        this.setState({
            neutralEmojiClicked:!this.state.neutralEmojiClicked,
            happyEmojiClicked:true,
            sadEmojiClicked:true,
            rating_id:this.state.neutralEmojiClicked ? this.state.dataId[1].id : ''
        })
    }

    sadEmojiHandler = () => {
        this.setState({
            sadEmojiClicked:!this.state.sadEmojiClicked,
            neutralEmojiClicked:true,
            happyEmojiClicked:true,
            rating_id:this.state.sadEmojiClicked ? this.state.dataId[2].id : ''
        })
    }

    onSubmit = () => {
        const {bookId,bookSlug} = this.props
        console.log('current props',this.props)
        const params = {
            entity_ratable_id:bookId,
            entity_ratable_type : "Story",
            rating_id : this.state.rating_id,
            comment : this.state.reviewDesc
        }
        PopUpRating.postRating(params)
        .then((response) =>{
            if(response.status === 200){
                console.log('current props inside post',this.props)
                this.props.history.push(`/stories/${bookSlug}`)
            }
        })
        
    };

    render() {

        const {t} = this.props

        let happyEmoji = this.state.happyEmojiClicked ? (
            <img
                className="emoji_rating"
                src={require('../../assets/Happy_outline.svg')}
                alt="happy_outline"
            />
        ) : (
                <img
                    className="emoji_rating"
                    src={require('../../assets/Happy_filled.svg')}
                    alt="happy_filled"
                />
            );

        let neutralEmoji = this.state.neutralEmojiClicked ? (
            <img
                className="emoji_rating"
                src={require('../../assets/Okay_outline.svg')}
                alt="okay_outline"
            />
        ) : (
            <img
                    className="emoji_rating"
                    src={require('../../assets/Okay_filled.svg')}
                    alt="okay_filled"
                />
        )

        let sadEmoji = this.state.sadEmojiClicked ? (
            <img
                className="emoji_rating"
                src={require('../../assets/Bad_outline.svg')}
                alt="sad_outline"
            />
        ) : (
            <img
                    className="emoji_rating"
                    src={require('../../assets/Bad_filled.svg')}
                    alt="sad_filled"
                />
        )

        return (
            <div className="modal_wrapper">
                <div onClick={this.props.handleClose}>
                    <img className="close-img" src={close} alt="" />
                </div>
                <div className="main_body">
                    <div className="title_question">
                        {' '}
                        {t('Rating.bookTitle')}
                    </div>
                    <div style={{display:'flex'}} className="emoji_style">
                        <Link 
                            normal
                            onClick={() => this.happyEmojiHandler()}
                        >
                            {happyEmoji}
                        </Link>
                        <Link 
                            normal
                            onClick={() => this.neutralEmojiHandler()}
                        >
                            {neutralEmoji}
                        </Link>
                        <Link 
                            normal
                            onClick={() => this.sadEmojiHandler()}
                        >
                            {sadEmoji}
                        </Link>
                    </div>
                    <div className="title_message">
                        {' '}
                        {t('Rating.feedback')}
                    </div>
                    <div className="create_message_box">
                        <TextField
                            id="search-modal-input"
                            value={this.state.reviewDesc}
                            size="m"
                            theme="dark"
                            onChange={this.onDescriptionChange}
                            bordered={true}
                            type="multiline"
                            rows="1"
                            maxLength="100"
                        />
                        <span className="description_length">
                            {this.state.reviewDesc.length}/100
                        </span>
                    </div>
                    <div>
                        { <button
                            // disabled={this.state.rating_id === ""}
                            className={
                                this.state.rating_id === "" 
                                    ? "disableSubmitReview" 
                                    : "submitReview"
                                }
                            onClick={this.onSubmit}
                        >
                            {t('book.submit')}
                        </button>}
                    </div>
                </div>
            </div>
        );
    }
}

export default BookRating;
