import u from 'updeep';
import { handleActions } from 'redux-actions';
import * as actions from './bookActions';
const initialState = {
    isFetchingBook: false,
    // TODO: save books by slug to avoid race conditions.
    // By extension, everything else gets saved by slug as well.
    book: null,
    //favourites related
    isFetchingFavouriteBooks: false,
    favouriteBooks: [],
    totalFavouriteBooksCount: 0,
    isBookDataFetched: false
};

export default handleActions(
    {
        [actions.fetchBook]: (state, action) =>
            u(
                {
                    isFetchingBook: true,
                },
                state
            ),
        [actions.receiveBook]: (state, action) =>
            u(
                {
                    isFetchingBook: false,
                    book: action.payload.book,
                },
                state
            ),
        [actions.fetchBookError]: (state, action) =>
            u(
                {
                    isFetchingBook: false,
                },
                state
            ),
        [actions.favouriteBook]: (state, action) => {
            const favBookIndex = state.favouriteBooks.findIndex(
                book => book.id === action.payload.bookId
            );
            if (favBookIndex >= 0) {
                return u(
                    {
                        favouriteBooks: u.updateIn(
                            [favBookIndex, 'isFavouriting'],
                            true
                        ),
                    },
                    state
                );
            } else {
                return u(
                    {
                        book: {
                            isFavouriting: true,
                        },
                    },
                    state
                );
            }
        },
        [actions.receiveFavouriteBook]: (state, action) => {
            const favBookIndex = state.favouriteBooks.findIndex(
                book => book.id === action.payload.bookId
            );
            if (favBookIndex >= 0) {
                let favourite = state.favouriteBooks[favBookIndex].is_favourite;
                let temp = u(
                    {
                        favouriteBooks: u.updateIn(
                            [favBookIndex, 'isFavouriting'],
                            false
                        ),
                    },
                    state
                );
                return u(
                    {
                        favouriteBooks: favourite
                            ? state.favouriteBooks.filter(
                                book => book.id !== action.payload.bookId
                            )
                            : state.favouriteBooks,
                        totalFavouriteBooksCount: favourite
                            ? state.totalFavouriteBooksCount - 1
                            : state.totalFavouriteBooksCount,
                    },
                    temp
                );
            } else {
                return u(
                    {
                        book: {
                            isFavouriting: false,
                            isFavourite: !state.book.isFavourite,
                            likesCount: !state.book.isFavourite
                                ? state.book.likesCount + 1
                                : state.book.likesCount - 1,
                        },
                    },
                    state
                );
            }
        },
        [actions.fetchFavouriteBooks]: (state, action) =>
            u(
                {
                    isFetchingFavouriteBooks: true,
                },
                state
            ),
        [actions.rtrGetBookData]: (state, action) =>
            u(
                {
                    isBookDataFetched: true,
                },
                state
            ),
        [actions.receiveFetchFavouriteBooks]: (state, action) =>
            u(
                {
                    isFetchingFavouriteBooks: false,
                    totalFavouriteBooksCount: action.payload.metadata.hits,
                    totalFavouriteVideosCount: action.payload.counts.videos,
                    totalFavouriteResourcesCount:
                        action.payload.counts.resources,
                    favouriteBooks: action.payload.favBooks,

                },
                state
            ),
        //bug where fetch happens but receive is not updated in state.book but only in state - need to check
        [actions.favouriteSimilarBooks]: (state, action) => {
            let index = state.book.similarBooks.findIndex(
                book => book.id === action.payload.bookId
            );

            if (index >= 0) {
                let newState = u(
                    {
                        similarBooks: u.updateIn([index, 'isFavouriting'], true),
                    },
                    state.book
                );
                let book = newState;
                let data = { ...state, book }
                return data;
            } else {
                return state;
            }
        },

        [actions.receivefavouriteSimilarBooks]: (state, action) => {
            const index = state.book.similarBooks.findIndex(
                book => book.id === action.payload.bookId
            );

            if (index >= 0) {

                let favourite = state.book.similarBooks[index].is_favourite;
                const newLikes = !favourite
                    ? state.book.similarBooks[index].likesCount + 1
                    : state.book.similarBooks[index].likesCount - 1;
                const likesTemp = u(
                    {
                        similarBooks: u.updateIn([index, 'likesCount'], newLikes),
                    },
                    state.book
                );
                let temp = u(
                    {
                        similarBooks: u.updateIn([index, 'isFavouriting'], false),
                    },
                    likesTemp
                );
                const newState = u(
                    {
                        similarBooks: u.updateIn([index, 'is_favourite'], !favourite),
                    },
                    temp
                );
                let book = newState;
                let data = { ...state, book }
                return data;

            } else {
                return state;
            }
        }
    },
    initialState
);
