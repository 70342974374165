import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import Img from '../Img';
import useIsMobile from '../../hooks/useIsMobile';
import './HeroCarousel.scss';

const HeroCarousel = ({
    banners,
    autoplay = false,
    autoplayInterval = 5000,
}) => {
    const isMobile = useIsMobile();

    const baseClassName = 'pb-hero-carousel';
    const enabled = banners && banners.length > 0;

    const classes = classNames(baseClassName, {
        [`${baseClassName}--disabled`]: !enabled,
    });

    const renderBanner = () => {
        return (
            <Img
                image={
                    isMobile
                        ? banners[0] && banners[0].mobileBannerUrl
                        : banners[0] && banners[0].imageUrls
                }
                data-GTM="hero-carousel"
                padding130
            />
        );
    };

    return banners.length === 1 ? (
        <div className={classes}>
            <div className={`${baseClassName}__container`}>
                {renderBanner()}
            </div>
        </div>
    ) : (
        ''
    );
};

HeroCarousel.propTypes = {
    banners: PropTypes.arrayOf(
        PropTypes.shape({
            mobileBannerUrl: PropTypes.string,
            imageUrls: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
        })
    ),
    autoplay: PropTypes.bool,
    autoplayInterval: PropTypes.number,
};

export default HeroCarousel;
