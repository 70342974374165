import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Grid from '../Grid';
import BookCard from '../BookCard';

class Books extends Component {
    static defaultProps = {
        books: [],
    };

    isProfilePageValue = false;

    isProfilePageCheckHandler = () => {

        if (this.props.isProfilePage) {
            this.isProfilePageValue = true;
        } else {
            this.isProfilePageValue = false;
        }
    }

    render() {
        const {
            type,
            books,
            bookImageGTM,
            bookTitleGTM,
            bookAuthorGTM,
            bookIllustratorsGTM,
            bookLevelGTM,
            myLanguage
        } = this.props;


        return (
            <Grid variant="4up">
                {books.map(book => {
                    return (
                        <BookCard
                            myLanguage={myLanguage}
                            type={type}
                            key={book.slug}
                            book={book}
                            id={book.id}
                            isFavourite={
                                book.is_favourite
                            }
                            isFavouriting={book.isFavouriting}
                            bookLikes={book.likesCount}
                            bookImageGTM={bookImageGTM}
                            bookTitleGTM={bookTitleGTM}
                            bookAuthorGTM={bookAuthorGTM}
                            bookIllustratorsGTM={bookIllustratorsGTM}
                            bookLevelGTM={bookLevelGTM}
                            isProfilePage={this.isProfilePageCheckHandler}
                        />
                    );
                })}
            </Grid>
        );
    }
}

Books.propTypes = {
    // TODO: Add shape of each object
    books: PropTypes.array,
};

export default Books;
