import { createAsyncActions, runAsyncWorkflow } from '../lib/reduxHelpers';

import { Videos } from '../api';

export const [
    fetchVideos,
    receiveVideos,
    fetchVideosError,
] = createAsyncActions(
    'VIDEOS',
    () => { },
    ({ ok, data, metadata }) => ({ ok, videos: data, metadata })
);

export const [
    fetchMoreVideos,
    receiveMoreVideos,
    fetchMoreVideosError,
] = createAsyncActions(
    'MORE_VIDEOS',
    ({ page, perPage }) => ({ page, perPage }),
    ({ data, metadata }) => ({ videos: data, metadata })
);

export const fetchVideosWorkflow = (appliedFilters, page, perPage) => {
    const fetchingMore = page && page > 1;
    const fetchPromise = Videos.fetch(appliedFilters, page, perPage);

    return runAsyncWorkflow(
        {
            fetch: fetchingMore ? fetchMoreVideos : fetchVideos,
            receive: fetchingMore ? receiveMoreVideos : receiveVideos,
            error: fetchingMore ? fetchMoreVideosError : fetchVideosError,
        },
        fetchPromise
    );
};

export const [
    fetchAllVideosFilters,
    receiveAllVideosFilters,
    fetchAllVideosFiltersError,
] = createAsyncActions(
    'ALL_VIDEOS_FILTERS',
    () => ({}),
    ({ data }) => ({ filters: data })
);

export const fetchAllVideosFiltersWorkflow = () => {
    const fetchPromise = Videos.fetchAllFilters();

    return runAsyncWorkflow(
        {
            fetch: fetchAllVideosFilters,
            receive: receiveAllVideosFilters,
            error: fetchAllVideosFiltersError,
        },
        fetchPromise
    );
};

export const [
    videoReadCount,
    receiveVideoReadCount,
    videoReadCountError,
] = createAsyncActions('VIDEO_READ_COUNT');

export const videoReadCountWorkflow = videoId => {
    const videoReadCountPromise = Videos.videoReadCount(videoId);

    return runAsyncWorkflow(
        {
            fetch: videoReadCount,
            receive: receiveVideoReadCount,
            error: videoReadCountError,
        },
        videoReadCountPromise
    );
};

export const [
    favouriteVideo,
    receiveFavouriteVideo,
    favouriteVideoError,
] = createAsyncActions(
    'FAV_VIDEOS',
    videoId => videoId,
    ({ ok, videoId }) => ({ ok, videoId })
);

export const favouriteVideoWorkflow = videoId => {
    const favVideoPromise = Videos.toggleFavourite(videoId);

    return runAsyncWorkflow(
        {
            fetch: favouriteVideo,
            receive: receiveFavouriteVideo,
            error: favouriteVideoError,
        },
        favVideoPromise,
        { videoId }
    );
};

export const [
    fetchFavouriteVideos,
    receiveFetchFavouriteVideos,
    fetchFavouriteVideosError,
] = createAsyncActions(
    'FETCH_FAV_VIDEOS',
    () => { },
    ({ ok, data, metadata }) => ({ ok, favVideos: data, metadata })
);

export const fetchFavouriteVideosWorkflow = () => {
    const fetchFavouritePromise = Videos.fetchFavouriteVideos();

    return runAsyncWorkflow(
        {
            fetch: fetchFavouriteVideos,
            receive: receiveFetchFavouriteVideos,
            error: fetchFavouriteVideosError,
        },
        fetchFavouritePromise
    );
};

export const [
    fetchVideoDetail,
    receivefetchVideoDetail,
    fetchVideoDetailError,
] = createAsyncActions(
    'FETCH_VIDEO_DETAIL',
    () => { },
    ({ ok, data }) => ({ ok, videoDetail: data })
);

export const fetchVideosDetailWorkflow = slug => {
    const fetchVideoDetailPromise = Videos.fetchVideoDetail(slug);

    return runAsyncWorkflow(
        {
            fetch: fetchVideoDetail,
            receive: receivefetchVideoDetail,
            error: fetchVideoDetailError,
        },
        fetchVideoDetailPromise
    );
};

//favourite handling for similar videos
export const [
    favouriteSimilarVideo,
    receivefavouriteSimilarVideo,
    favouriteSimilarVideoError,
] = createAsyncActions(
    'FAVOURITE_SIMILAR_VIDEO',
    ({ videoId }) => ({ videoId }),
    ({ ok, videoId }) => ({ ok, videoId })
);

export const favouriteSimilarVideoWorkflow = videoId => {
    const favSimilarVideoPromise = Videos.toggleFavourite(videoId);

    return runAsyncWorkflow(
        {
            fetch: favouriteSimilarVideo,
            receive: receivefavouriteSimilarVideo,
            error: favouriteSimilarVideoError,
        },
        favSimilarVideoPromise,
        { videoId }
    );
};
