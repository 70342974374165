import u from 'updeep';
import { handleActions } from 'redux-actions';
import * as actions from './allBooksActions';

const initialState = {
    isFetchingFilters: false,
    filters: null,
    isFetchingBooks: false,
    isFetchingMoreBooks: false, // for pagination
    loadedPages: 0,
    books: null,
    totalBooksCount: 0,
    headerImage: '',
    searchString:null
};

export default handleActions(
    {
        [actions.fetchAllBooksFilters]: (state, action) =>
            u(
                {
                    isFetchingFilters: true,
                },
                state
            ),

        [actions.receiveAllBooksFilters]: (state, action) =>
            u(
                {
                    isFetchingFilters: false,
                    filters: action.payload.filters,
                },
                state
            ),

        [actions.fetchAllBooks]: (state, action) =>
            u(
                {
                    isFetchingBooks: true,
                },
                state
            ),

        [actions.fetchAllRecommendedBooks]: (state, action) =>
            u(
                {
                    isFetchingBooks: true,
                },
                state
            ),

        [actions.fetchAllTrendingBooks]: (state, action) =>
            u(
                {
                    isFetchingBooks: true,
                },
                state
            ),

        [actions.receiveAllBooks]: (state, action) =>
            u(
                {
                    isFetchingBooks: false,
                    books: action.payload.books,
                    totalBooksCount: action.payload.metadata.hits,
                    searchString:action.payload.metadata.query,
                    totalBooksPage: action.payload.metadata.totalPages,
                    loadedPages: 1,
                },
                state
            ),

        [actions.receiveAllRecommendedBooks]: (state, action) =>
            u(
                {
                    isFetchingBooks: false,
                    books: action.payload.books,
                    totalBooksCount: action.payload.metadata.hits,
                    searchString:action.payload.metadata.query,
                    totalBooksPage: action.payload.metadata.totalPages,
                    loadedPages: 1,
                },
                state
            ),
        
        [actions.receiveAllTrendingBooks]: (state, action) =>
            u(
                {
                    isFetchingBooks: false,
                    books: action.payload.books,
                    totalBooksCount: action.payload.metadata.hits,
                    searchString:action.payload.metadata.query,
                    totalBooksPage: action.payload.metadata.totalPages,
                    loadedPages: 1,
                },
                state
            ),

        [actions.fetchMoreAllBooks]: (state, action) =>
            u(
                {
                    isFetchingMoreBooks: true,
                },
                state
            ),

        [actions.fetchMoreAllRecommendedBooks]: (state, action) =>
            u(
                {
                    isFetchingMoreBooks: true,
                },
                state
            ),
        
        [actions.fetchMoreAllTrendingBooks]: (state, action) =>
            u(
                {
                    isFetchingMoreBooks: true,
                },
                state
            ),

        [actions.receiveMoreAllBooks]: (state, action) =>
            u(
                {
                    isFetchingMoreBooks: false,
                    books: state.books.concat(action.payload.books),
                    loadedPages: state.loadedPages + 1,
                },
                state
            ),
        
        [actions.receiveMoreAllRecommendedBooks]: (state, action) =>
            u(
                {
                    isFetchingMoreBooks: false,
                    books: state.books.concat(action.payload.books),
                    loadedPages: state.loadedPages + 1,
                },
                state
            ),

        [actions.receiveMoreAllTrendingBooks]: (state, action) =>
            u(
                {
                    isFetchingMoreBooks: false,
                    books: state.books.concat(action.payload.books),
                    loadedPages: state.loadedPages + 1,
                },
                state
            ),

        [actions.favouriteAllBooks]: (state, action) => {
            let index = state.books.findIndex(
                book => book.id === action.payload.bookId
            );

            if (index >= 0) {
                return u(
                    {
                        books: u.updateIn([index, 'isFavouriting'], true),
                    },
                    state
                );
            } else {
                return state;
            }
        },
        [actions.receiveFavouriteAllBooks]: (state, action) => {
            //Get the associated video and set its isFovouriting state
            //Get the index of the video
            const index = state.books.findIndex(
                book => book.id === action.payload.bookId
            );

            if (index >= 0) {
                let favourite = state.books[index].is_favourite;
                const newLikes = !favourite
                    ? state.books[index].likesCount + 1
                    : state.books[index].likesCount - 1;
                const likesTemp = u(
                    {
                        books: u.updateIn([index, 'likesCount'], newLikes),
                    },
                    state
                );
                let temp = u(
                    {
                        books: u.updateIn([index, 'isFavouriting'], false),
                    },
                    likesTemp
                );
                return u(
                    {
                        books: u.updateIn([index, 'is_favourite'], !favourite),
                    },
                    temp
                );
            } else {
                return state;
            }
        },
    },
    initialState
);
