import u from 'updeep';
import { cloneDeep } from 'lodash';
import { handleActions } from 'redux-actions';
import * as actions from './readingListsActions';

const initialState = {
    isFetchingFilters: false,
    filters: null,
    readingLists: [],
    readingListsBookDetail: {},
    myReadingLists: {},
    allReadingLists: [],
    manageReadingList: [],
    isFetchingReadingList: false,
    isFetchingMyReadingList: false,
    isFetchingMoreReadingList: false,
    isFetchingMoreMyReadingList: false,
    isFetchingManageReadingList: false,
    isCreatingReadingList: false,
    isDeleteReadingList: false,
    isLikingReadingList: false,
    isEditingReadingList: false,
    isUpdatingReadingList: false,
    isUpdatingReadingListDetails: false, // Update title/description of a Reading list
    isFetchingMoreLists: false, // for pagination
    loadedPages: 0,
    lists: null,
    totalListsCount: 0,
};

export default handleActions(
    {
        [actions.fetchReadingListsFilters]: (state, action) =>
            u(
                {
                    isFetchingFilters: true,
                },
                state
            ),

        [actions.receiveReadingListsFilters]: (state, action) =>
            u(
                {
                    isFetchingFilters: false,
                    filters: action.payload.filters,
                },
                state
            ),
        [actions.fetchReadingListDetails]: (state, action) =>
            u(
                {
                    isFetchingReadingList: true,
                },
                state
            ),

        [actions.receiveReadingListDetails]: (state, action) =>
            u(
                {
                    isFetchingReadingList: false,
                    readingLists: action.payload.readingList,
                    totalListsCount: action.payload.metadata.totalPages,
                    loadedPages: 1,
                    hits: action.payload.metadata.hits,
                },
                state
            ),
        [actions.fetchMoreReadingListDetails]: (state, action) =>
            u(
                {
                    isFetchingMoreReadingList: true,
                },
                state
            ),

        [actions.receiveMoreReadingListDetails]: (state, action) =>
            u(
                {
                    isFetchingMoreReadingList: false,
                    readingLists: state.readingLists.concat(
                        action.payload.readingList
                    ),
                    loadedPages: state.loadedPages + 1,
                },
                state
            ),

        [actions.fetchMyReadingListDetails]: (state, action) =>
            u(
                {
                    isFetchingMyReadingList: true,
                },
                state
            ),

        [actions.receiveMyReadingListDetails]: (state, action) =>
            u(
                {
                    isFetchingMyReadingList: false,
                    myReadingLists: {
                        allReadingLists: action.payload.myReadingList,
                    },
                    totalMyReadingListsCount:
                        action.payload.metadata.totalPages,
                    loadedPages: 1,
                    myReadingListsHits: action.payload.metadata.hits,
                },
                state
            ),
        [actions.fetchMoreMyReadingListDetails]: (state, action) =>
            u(
                {
                    isFetchingMoreMyReadingList: true,
                },
                state
            ),

        [actions.receiveMoreMyReadingListDetails]: (state, action) => {
            let UpdatedMyReadingList = state.myReadingLists.allReadingLists.concat(
                action.payload.myReadingList
            );
            let newState = u(
                {
                    isFetchingMoreMyReadingList: false,

                    myReadingLists: {
                        allReadingLists: UpdatedMyReadingList,
                    },
                    loadedPages: state.loadedPages + 1,
                },
                state
            );
            return newState;
        },

        [actions.postReadingListLike]: (state, action) =>
            u(
                {
                    isLikingReadingList: true,
                },
                state
            ),

        [actions.receiveReadingListLike]: (state, action) =>
            u(
                {
                    isLikingReadingList: false,
                    readingList: action.payload.readingList,
                },
                state
            ),

        [actions.updateReadingList]: (state, action) =>
            u(
                {
                    isUpdatingReadingList: true,
                },
                state
            ),

        [actions.receiveUpdateReadingList]: (state, action) =>
            u(
                {
                    isUpdatingReadingList: false,
                    readingList: action.payload.readingList,
                },
                state
            ),

        [actions.updateReadingListDetails]: (state, action) =>
            u({ isUpdatingReadingListDetails: true }, state),
        [actions.receiveUpdateReadingListDetails]: (state, action) =>
            u(
                {
                    isUpdatingReadingListDetails: false,
                },
                state
            ),
        [actions.updateReadingListDetailsError]: (state, action) =>
            u(
                {
                    isUpdatingReadingListDetails: false,
                },
                state
            ),
        [actions.fetchReadingListBooks]: (state, action) =>
            u(
                {
                    isReadingListBooks: true,
                },
                state
            ),

        [actions.receiveReadingListBooks]: (state, action) =>
            u(
                {
                    isReadingListBooks: false,
                    readingListsBookDetail: action.payload.readingListBooks,
                },
                state
            ),

        [actions.createReadingList]: (state, action) =>
            u(
                {
                    isCreatingReadingList: true,
                },
                state
            ),

        [actions.receiveCreateReadingList]: (state, action) =>
            u(
                {
                    isCreatingReadingList: true,
                },
                state
            ),

        [actions.createMyReadingList]: (state, action) =>
            u(
                {
                    isCreatingReadingList: true,
                },
                state
            ),

        [actions.receiveMyCreateReadingList]: (state, action) => {
            let newState = u(
                {
                    isCreatingReadingList: false,
                    myReadingLists: {
                        allReadingLists: [action.payload.myReadingLists].concat(
                            state.myReadingLists.allReadingLists
                        ),
                        newList: u.omitted,
                    },
                },
                state
            );
            return newState;
        },

        [actions.createMyReadingListError]: (state, action) =>
            u(
                {
                    isCreatingReadingList: false,
                },
                state
            ),

        [actions.deleteReadingList]: (state, action) =>
            u(
                {
                    isDeleteReadingList: true,
                },
                state
            ),

        [actions.receiveDeleteReadingList]: (state, action) => {
            let newState = state;
            let newReadingList = newState.myReadingLists.allReadingLists.filter(
                (item) => item.id !== action.payload.id
            );

            newState = u(
                {
                    isDeleteReadingList: false,
                    myReadingLists: {
                        allReadingLists: newReadingList,
                    },
                },
                state
            );
            return newState;
        },

        [actions.createReadingListError]: (state, action) =>
            u(
                {
                    isDeleteReadingList: false,
                },
                state
            ),

        [actions.fetchManageReadingList]: (state, action) =>
            u(
                {
                    isFetchingManageReadingList: true,
                },
                state
            ),
        [actions.receiveManageReadingList]: (state, action) => {
            //We need to place the newList that is modified
            //in allReadingLists of myReadingLists.
            //Get the index of the newList in allReadingLists
            let index = state.myReadingLists.allReadingLists.findIndex(
                (item) => item.slug === state.newList.slug
            );
            let newAllList = [...state.myReadingLists.allReadingLists];
            newAllList[index] = state.newList;

            let newState = u(
                {
                    isFetchingManageReadingList: false,
                    myReadingLists: {
                        allReadingLists: newAllList,
                    },
                    managingList: false,
                    newList: u.omitted,
                },
                state
            );
            return newState;
        },

        [actions.manageReadingListAction]: (state, action) => {
            let newState = u(
                {
                    newList: cloneDeep(action.payload.list),
                    managingList: true,
                },
                state
            );
            return newState;
        },

        [actions.createNewReadingListAction]: (state, action) => {
            let newState = u(
                {
                    newList: {
                        title: action.payload.name,
                        description: action.payload.desc,
                        books: [],
                    },
                },
                state
            );

            return newState;
        },

        [actions.addBookToReadingListAction]: (state, action) => {
            let newState = u.updateIn(
                'newList.books',
                state.newList.books.concat(action.payload.book),
                state
            );

            newState = u.updateIn(
                'newList.count',
                newState.newList.books.length,
                newState
            );
            return newState;
        },

        [actions.removeBookFromReadingListAction]: (state, action) => {
            let newState = u.updateIn(
                'newList.books',
                state.newList.books.filter(
                    (item) => item.slug !== action.payload.book.slug
                ),
                state
            );

            newState = u.updateIn(
                'newList.count',
                newState.newList.books.length,
                newState
            );
            return newState;
        },

        [actions.favouriteThemedBooks]: (state, action) => {
            const favBookIndex = state.readingListsBookDetail.books.findIndex(
                (book) => book.id === action.payload.bookId
            );
            // console.log('state reading list', state);
            if (favBookIndex >= 0) {
                // console.log('if entered');
                let temp = u(
                    {
                        books: u.updateIn(
                            [favBookIndex, 'isFavouriting'],
                            true
                        ),
                    },
                    state.readingListsBookDetail
                );
                let readingListsBookDetail = temp;
                return { ...state, readingListsBookDetail };
            }
        },

        [actions.receiveFavouriteThemedBooks]: (state, action) => {
            const index = state.readingListsBookDetail.books.findIndex(
                (book) => book.id === action.payload.bookId
            );

            if (index >= 0) {
                let favourite =
                    state.readingListsBookDetail.books[index].is_favourite;
                const newLikes = !favourite
                    ? state.readingListsBookDetail.books[index].likesCount + 1
                    : state.readingListsBookDetail.books[index].likesCount - 1;
                const likesTemp = u(
                    {
                        books: u.updateIn([index, 'likesCount'], newLikes),
                    },
                    state.readingListsBookDetail
                );
                let temp = u(
                    {
                        books: u.updateIn([index, 'isFavouriting'], false),
                    },
                    likesTemp
                );
                const newState = u(
                    {
                        books: u.updateIn([index, 'is_favourite'], !favourite),
                    },
                    temp
                );
                let readingListsBookDetail = newState;
                return { ...state, readingListsBookDetail };
            } else {
                return state;
            }
        },
    },
    initialState
);
