import React from 'react';
import PropTypes from 'prop-types';
import VideoCard from '../../VideoCard';
import Grid from '../../Grid';

const VideoGrid = ({ videos, t, videoReadCountWorkflow, isLoggedIn }) => {
    const myLanguage = localStorage.getItem('locale');

    if (!videos || videos.length === 0) {
        return null;
    }

    const videosEls = videos.slice(0, 4).map((videoItem) => {
        return (
            <div>
                <VideoCard
                    myLanguage={myLanguage}
                    videoID={videoItem.id}
                    videoSlug={videoItem.slug}
                    videoTitle={videoItem.name}
                    videoThumbnail={videoItem.thumbUrl}
                    videoUrl={videoItem.materialUrl}
                    videoDownloadUrl={videoItem.downloadUrl}
                    videoLikes={videoItem.likesCount}
                    videoReads={videoItem.readsCount}
                    videoTime={videoItem.duration}
                    isFavourite={videoItem.is_favourite}
                    readCount={videoReadCountWorkflow}
                    videosTitleGTM="recommened-video-title"
                    videosThumbnailGTM="recommened-video-thumbnail"
                    isFromHome
                    isLoggedIn={isLoggedIn}
                    youtubeIdentifier={videoItem.youtube_video_identifier}
                />
            </div>
        );
    });
    return <Grid variant="4up">{videosEls}</Grid>;
};

VideoGrid.propTypes = {
    videos: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.string.isRequired,
            slug: PropTypes.string.isRequired,
            name: PropTypes.string.isRequired,
            thumbUrl: PropTypes.string.isRequired,
            materialUrl: PropTypes.string.isRequired,
            downloadUrl: PropTypes.string,
            likesCount: PropTypes.number.isRequired,
            readsCount: PropTypes.number.isRequired,
            duration: PropTypes.string.isRequired,
            is_favourite: PropTypes.bool.isRequired,
            youtube_video_identifier: PropTypes.string,
        })
    ).isRequired,
    t: PropTypes.func.isRequired,
    videoReadCountWorkflow: PropTypes.func.isRequired,
    isLoggedIn: PropTypes.bool.isRequired,
};

export default VideoGrid;
