import React, { Component } from 'react';
import { connect } from 'react-redux';
import { translate } from 'react-polyglot';
import u from 'updeep';
import LoaderBlock from '../LoaderBlock';
import Pagination from '../Pagination';
import BookCard from '../BookCard';
import Grid from '../Grid';
import {
    fetchVideosWorkflow,
    videoReadCountWorkflow,
} from '../../redux/videoActions';
import { fetchMeWorkflow } from '../../redux/userActions';
import { recordGaEvents } from '../../redux/googleAnalyticsActions';
import { addSlimNotification } from '../../redux/slimNotificationActions';
import ContentStyler from '../ContentStyler';
import Block from '../Block';
import VideoCard from '../VideoCard';
import {
    sortOptions
} from '../../lib/constants';

import './BooksSearchResultsContainer.scss';

const VideoGridEl = ({ videos, t, isLoggedIn }) => {
    const videoEls = videos.map(videoItem => {
        return (
            <div key={videoItem.id}>
                <VideoCard
                    videoID={videoItem.id}
                    videoSlug={videoItem.slug}
                    videoTitle={videoItem.name}
                    videoThumbnail={videoItem.thumbUrl}
                    videoUrl={videoItem.materialUrl}
                    videoDownloadUrl={videoItem.downloadUrl}
                    videoLikes={videoItem.likesCount}
                    videoReads={videoItem.readsCount}
                    videoTime={videoItem.duration}
                    isFavourite={videoItem.is_favourite}
                    readCount={videoReadCountWorkflow}
                    videoTitleGTM="search-video-title"
                    videoThumbnailGTM="search-video-thumbnail"
                    isLoggedIn={videoItem.is_read_aloud ? true : isLoggedIn}
                    youtubeIdentifier={videoItem.youtube_video_identifier}
                />
            </div>
        );
    });
    // RB changes
    return <Grid variant="4up">{videoEls}</Grid>;
};

const NoResults = translate()(({ t }) => (
    <span>
        <p>{t('Books.no-result-warning-1')}</p>
        <p>{t('Books.no-result-warning-2')}</p>
        <ul>
            <li>{t('Books.no-result-warning-3')}</li>
            <li>{t('Books.no-result-warning-4')}</li>
            <li>{t('Books.no-result-warning-5')}</li>
            <li>{t('Books.no-result-warning-6')}</li>
        </ul>
    </span>
));

const mapDispatchToProps = {
    fetchVideosWorkflow,
    recordGaEvents,
    addSlimNotification,
    fetchMeWorkflow
};

const mapStateToProps = ({
    videos,
    viewport,
    allFilters: { readFilters },
    user
}) => ({
    videos: videos.videos,
    isFetchingVideos: videos.isFetchingVideos,
    isFetchingMoreVideos: videos.isFetchingMoreVideos,
    loadedPages: videos.loadedPages,
    totalVideosCount: videos.totalVideosCount,
    videoHits: videos.hits,
    viewport,
    appliedFilters: readFilters,
    isLoggedIn:user.isLoggedIn
});

@translate()
@connect(
    mapStateToProps,
    mapDispatchToProps
)
class BooksSearchResultsContainer extends Component {
    constructor(props) {
        super(props);

        const { t } = props;

        this.state = {
            // The filters bar allows searching through available
            // filter values. We store that search text here.
            filtersSearchText: {
                category: '',
                publisher: '',
                level: '',
                language: '',
            },
        };

        this.filtersBarSortOptions = [
            {
                name: t('sort.most-liked'),
                queryValue: sortOptions.mostLiked,
            },
            {
                name: t('sort.most-read'),
                queryValue: sortOptions.mostRead,
            },
        ];

        // FiltersBar uses a SelectBox to display sort options, whereas
        // FiltersPanel uses a RadioGroup. Both these components expect
        // different props to display options. We do the transformation
        // here, before passing the props down.
        this.filtersPanelSortOptions = this.props.translateFiltersBarSortOptionsToFiltersPanelSortOptions(
            this.filtersBarSortOptions
        );
    }

    // Updates filter bar search text for a specified filter.
    updateFiltersSearchText = (key, text) => {
        this.setState(
            u(
                {
                    filtersSearchText: {
                        [key]: text,
                    },
                },
                this.state
            )
        );
    };

    // Convenience methods for updating filter bar search text for
    // our current list of filters.
    updateCategorySearchText = e =>
        this.updateFiltersSearchText('category', e.target.value);
    updatePublisherSearchText = e =>
        this.updateFiltersSearchText('publisher', e.target.value);
    updateLevelSearchText = e =>
        this.updateFiltersSearchText('level', e.target.value);
    updateLanguageSearchText = e =>
        this.updateFiltersSearchText('language', e.target.value);

    onLoadMore = () =>
        this.props.fetchVideosWorkflow(
            this.props.appliedFilters,
            this.props.loadedPages + 1
        );

    renderBookEls() {
        const { books } = this.props;

        return books.map(book => {
            return (
                <BookCard
                    key={book.slug}
                    id={book.id}
                    book={book}
                    faded={book.storyDownloaded}
                    bookImageGTM="books-book-image"
                    bookTitleGTM="books-book-title"
                    bookAuthorGTM="books-book-author"
                    bookIllustratorsGTM="books-book-Illustrators"
                    bookLevelGTM="books-book-Level"
                />
            );
        });
    }

    componentDidMount() {
        const { appliedFilters } = this.props;
        // First fetch available filters, then fetch the books.
        this.props.fetchVideosWorkflow(appliedFilters);
    }

    render() {
        const {
            t,
            isFetchingVideos,
            videos,
            totalVideosCount,
            videoHits,
            isLoggedIn
        } = this.props;


        const myLanguage = localStorage.getItem('locale');

        const shouldShowLoadMore =
            this.props.loadedPages &&
            this.props.loadedPages < this.props.totalVideosCount;

        const resourceTitleEl = this.props.searchQuery ? (
            <h3>
                { myLanguage !== 'ne' 
                    ?
                        `${videoHits} ${t('Search.results-for-count')} \u2018${this.props.searchQuery}\u2019` 
                    :
                        `\u2018${this.props.searchQuery}\u2019 ${t('Search.results-for-count-1')} ${videoHits} ${t('Search.results-for-count-2')}`
                }
            </h3>
        ) : (
                [
                    <h3>{t('global.video', 2)}</h3>,
                    <h3 className="__normal">({videoHits})</h3>,
                ]
            );

        return (
            <Block background="transparent">
                <ContentStyler>
                    <div className="__search-result-title">
                        {resourceTitleEl}
                    </div>
                </ContentStyler>
                <div className="book-section">
                    {isFetchingVideos || !videos ? (
                        <LoaderBlock />
                    ) : (
                            <div>
                                {videos.length > 0 ? (
                                    <div>
                                        <VideoGridEl videos={videos} t={t} isLoggedIn={isLoggedIn} />
                                        {shouldShowLoadMore ? (
                                            <Pagination
                                                onClick={this.onLoadMore}
                                                loading={
                                                    this.props.isFetchingMoreVideos
                                                }
                                                dataGTM="videos-view-more"
                                            />
                                        ) : null}
                                    </div>
                                ) : null}
                                {totalVideosCount ? null : <NoResults />}
                            </div>
                        )}
                </div>
            </Block>
        );
    }
}

BooksSearchResultsContainer.propTypes = {};

export default BooksSearchResultsContainer;
