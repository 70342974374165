import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import BookCard from '../BookCard';
import './BookCardGroup.scss';

const BookCardGroup = ({
    books = [],
    bookResults = [],
    parentClassName,
    isReadingList,
}) => {
    const baseClassName = 'pb-book-card-group';

    const displayedBooks = books.slice(0, 4).reverse();
    const displayedBookResults = bookResults.slice(0, 3).reverse();

    const isEmpty = !books.length && !bookResults.length;

    const classes = classNames({
        [baseClassName]: true,
        [parentClassName]: parentClassName,
        [`${baseClassName}--empty`]: isEmpty,
    });

    return (
        <div className={classes}>
            {isReadingList
                ? displayedBookResults.map((bookResult) => (
                    <div className={`${baseClassName}__book`} key={bookResult.slug}>
                        <BookCard 
                            book={bookResult} 
                            isReadingList 
                            shouldDisplayMenu={false} 
                        />
                    </div>
                ))
                : displayedBooks.map((book) => (
                    <div className={`${baseClassName}__book`} key={book.slug}>
                        <BookCard book={book} shouldDisplayMenu={false} />
                    </div>
                ))
            }
        </div>
    );
};

BookCardGroup.propTypes = {
    books: PropTypes.arrayOf(PropTypes.shape(BookCard.propTypes)).isRequired,
    bookResults: PropTypes.array,
    parentClassName: PropTypes.string,
    isReadingList: PropTypes.bool,
};

export default BookCardGroup;
