import React, { Component } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { translate } from 'react-polyglot';
import MyReadingList from '../MyReadingList';
import Pagination from '../Pagination';
import { fetchMyReadingListWorkflow } from '../../redux/readingListsActions';

class MyReadingListContainer extends Component {

    onLoadMore = () =>
        this.props.fetchMyReadingListWorkflow(
            this.props.appliedFilters,
            this.props.slug,
            this.props.loadedPages + 1
        );

    render() {
        const baseClassName = 'pb-my-reading-list-container';
        const {
            parentClassName,
            myReadingLists,
            isMyReadingList,
            loadedPages,
            totalMyReadingListsCount,
            isFetchingMoreMyReadingList,
            myLanguage
        } = this.props;

        const shouldShowLoadMore =
            loadedPages && loadedPages < totalMyReadingListsCount;

        const classes = {
            [baseClassName]: true,
            [parentClassName]: parentClassName,
        };

        return (
            <div className={classNames(classes)}>
                <MyReadingList
                    myLanguage={myLanguage}
                    myReadingLists={myReadingLists}
                    isMyReadingList={isMyReadingList}
                />
                {shouldShowLoadMore ? (
                    <Pagination
                        onClick={this.onLoadMore}
                        loading={isFetchingMoreMyReadingList}
                        dataGTM="thematic-reading-list-view-more"
                    />
                ) : null}
            </div>
        );
    }
}

MyReadingListContainer.propTypes = {
    parentClassName: PropTypes.string,
};

const mapStateToProps = ({ viewport, readingLists }) => {
    return {
        // viewport,
        // myReadingLists: readingLists.myReadingLists.allReadingLists,
    };
};

const mapDispatchToProps = {
    fetchMyReadingListWorkflow,
};

export default translate()(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(MyReadingListContainer)
);
