import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { translate } from 'react-polyglot';
import List from '../List';
import Dropdown from '../Dropdown';
import Link from '../Link';
import SvgIcon from '../SvgIcon';
import { isMobile } from '../../util';
import {
    makeTwitterShareUrl,
    makeFacebookShareUrl,
    makeLineShareUrl,
    makePinterestShareUrl,
    makeEmailShareUrl,
    makeWhatsappShareUrl,
} from '../../lib/social';

class ShareMenu extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isMobile: false,
        };
    }

    componentDidMount = () => {
        this.isMobileCheck();
    };

    isMobileCheck = () => {
        if (
            navigator.userAgent.match(/Android/i) ||
            navigator.userAgent.match(/webOS/i) ||
            navigator.userAgent.match(/iPhone/i) ||
            navigator.userAgent.match(/iPad/i) ||
            navigator.userAgent.match(/iPod/i) ||
            navigator.userAgent.match(/BlackBerry/i) ||
            navigator.userAgent.match(/Windows Phone/i) ||
            window.screen.width <= 768
        ) {
            this.setState({
                isMobile: true,
            });
        } else {
            this.setState({
                isMobile: false,
            });
        }
    };

    render() {
        const {
            t,
            title,
            thumbUrl,
            contentUrl,
            href,
            align,
            onClick,
            videoShare,
            isIllustration,
            readingList,
            landingPageBook,
        } = this.props;

        const encodedUrl = encodeURIComponent(href);

        return (
            <Dropdown
                align={align}
                toggleEl={
                    !isMobile() ? (
                        <Link
                            favouriteShare={true}
                            normal
                            dataGTM={
                                isIllustration
                                    ? 'illustration-share'
                                    : 'book-share'
                            }
                        >
                            {!videoShare && !landingPageBook && (
                                <SvgIcon 
                                    name={"Share"} 
                                    size="reader-view-share"
                                />
                            )}
                            {landingPageBook && (
                                <div>
                                    <SvgIcon
                                        name="Share"
                                        size="mi"
                                        shareIcon />
                                </div>
                            )}
                            {t('global.share', 1)}
                        </Link>
                    ) : (
                        <Link
                            favouriteShare={true}
                            normal
                            dataGTM={
                                isIllustration
                                    ? 'illustration-share'
                                    : 'book-share'
                            }
                        >
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <SvgIcon 
                                    name={"Share"} 
                                    size="reader-view-share"
                                />
                                <span>{t('global.share', 1)}</span>
                            </div>
                        </Link>
                    )
                }
            >
                <List nowrap socialShareIcons>
                    <Link
                        fullWidthShare
                        href={makeTwitterShareUrl(href, title)}
                        shouldOpenNewPage={true}
                        onClick={onClick}
                        dataGTM={
                            isIllustration
                                ? 'illustration-twitter-share'
                                : 'book-twitter-share'
                        }
                    >
                        <SvgIcon
                            name="twitter"
                            size="m"
                            pushRight
                            dataGTM={
                                isIllustration
                                    ? 'illustration-twitter-share'
                                    : 'book-twitter-share'
                            }
                        />{' '}
                        {t('global.twitter')}
                    </Link>
                    <Link
                        fullWidthShare
                        href={makeFacebookShareUrl(href)}
                        shouldOpenNewPage={true}
                        onClick={onClick}
                        dataGTM={
                            isIllustration
                                ? 'illustration-facebook-share'
                                : 'book-facebook-share'
                        }
                    >
                        <SvgIcon
                            name="facebook"
                            size="m"
                            pushRight
                            dataGTM={
                                isIllustration
                                    ? 'illustration-facebook-share'
                                    : 'book-facebook-share'
                            }
                        />{' '}
                        {t('global.facebook')}
                    </Link>
                    <Link
                        fullWidthShare
                        href={makeEmailShareUrl(href, title)}
                        shouldOpenNewPage={true}
                        onClick={onClick}
                        dataGTM={
                            isIllustration
                                ? 'illustration-email-share'
                                : 'book-email-share'
                        }
                    >
                        <SvgIcon
                            name="mail"
                            size="m"
                            pushRight
                            dataGTM={
                                isIllustration
                                    ? 'illustration-email-share'
                                    : 'book-email-share'
                            }
                        />{' '}
                        {t('global.mailTo')}
                    </Link>
                    {!readingList ? (
                        <Link
                            fullWidthShare
                            href={makePinterestShareUrl(
                                thumbUrl,
                                contentUrl,
                                title
                            )}
                            shouldOpenNewPage={true}
                            onClick={onClick}
                            dataGTM={
                                isIllustration
                                    ? 'illustration-pinterest-share'
                                    : 'book-pinterest-share'
                            }
                        >
                            <SvgIcon
                                name="pinterest"
                                size="m"
                                pushRight
                                dataGTM={
                                    isIllustration
                                        ? 'illustration-pinterest-share'
                                        : 'book-pinterest-share'
                                }
                            />{' '}
                            {t('global.pinterest')}
                        </Link>
                    ) : null}
                    {this.state.isMobile ? (
                        <Link
                            fullWidthShare
                            href={makeLineShareUrl(href, title)}
                            shouldOpenNewPage={true}
                            onClick={onClick}
                            dataGTM={
                                isIllustration
                                    ? 'illustration-line-share'
                                    : 'book-line-share'
                            }
                        >
                            <SvgIcon name="line" size="m" pushRight />{' '}
                            {t('global.line')}
                        </Link>
                    ) : null}
                    {this.state.isMobile ? (
                        <Link
                            fullWidthShare
                            href={makeWhatsappShareUrl(encodedUrl)}
                            shouldOpenNewPage={true}
                            onClick={onClick}
                            dataGTM={
                                isIllustration
                                    ? 'illustration-whats-app-share'
                                    : 'book-whats-app-share'
                            }
                        >
                            <SvgIcon
                                name="whatsapp"
                                size="m"
                                pushRight
                                dataGTM={
                                    isIllustration
                                        ? 'illustration-whats-app-share'
                                        : 'book-whats-app-share'
                                }
                            />{' '}
                            {t('global.whatsapp')}
                        </Link>
                    ) : null}
                </List>
            </Dropdown>
        );
    }
}

ShareMenu.propTypes = {
    title: PropTypes.string,
    href: PropTypes.string.isRequired,
    align: PropTypes.shape(Dropdown.propTypes.align),
};

export default translate()(ShareMenu);
