import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import Link from '../Link';
import Caret from '../Caret';
import './SiteNavLink.scss';

const SiteNavLink = ({
    isInternal,
    isExternal,
    variant = 'default',
    active = false,
    respondWidth,
    disabled,
    respondFontSize,
    truncateLongText,
    parentClassName,
    caret,
    href,
    onClick,
    children,
    title,
    bold = false,
    dark = false,
    search = false,
    align = null,
    noPadding = false,
    buttonTitle,
    light = false,
    mobileFooter,
    logInButton,
    booksTab,
    readingListTab,
    videosTab,
    resourcesTab,
    helpguide,
    dataGTM,
    moreVideos,
    errorPage
}) => {
    const baseClassName = 'pb-site-nav-link';
    const myLanguage = localStorage.getItem('locale');

    const classes = {
        [baseClassName]: true,
        [`${baseClassName}--no-padding`]: noPadding,
        [`${baseClassName}--align-${align}`]: align,
        [parentClassName]: parentClassName,
        [`${baseClassName}--${variant}`]: variant,
        [`${baseClassName}--active`]: active,
        [`${baseClassName}--respond-width`]: respondWidth,
        [`${baseClassName}--respond-font-size`]: respondFontSize,
        [`${baseClassName}--truncate-long-text`]: truncateLongText,
        [`${baseClassName}--caret`]: caret,
        [`${baseClassName}__bold`]: bold,
        [`${baseClassName}__dark`]: dark,
        [`${baseClassName}__light`]: light,
        [`${baseClassName}__redirect-to-videos`]: moreVideos
    };

    const spanClass = {
        [`${baseClassName}__search`]: search,
        [myLanguage === 'lo' ? `${baseClassName}__lo-title` : `${baseClassName}__title`]: !search,
        [`${baseClassName}__bookTab`]: booksTab,
        [`${baseClassName}__readingListTab`]: readingListTab,
        [`${baseClassName}__videosTab`]: videosTab,
        [`${baseClassName}__resourcesTab`]: resourcesTab,
        [`${baseClassName}__helpguide`]: helpguide,
        [`${baseClassName}__title--mobile-footer`]: mobileFooter,
        [`${baseClassName}__suggestion-text`]: errorPage
    };

    const caretEl = caret && (
        <Caret
            direction={caret}
            parentClassName={`${baseClassName}__caret`}
        />
    );

    const spanEl = buttonTitle || children;

    const linkEl = (
        <Link
            isInternal={isInternal}
            isExternal={isExternal}
            parentClassName={classNames(classes)}
            disabled={disabled}
            onClick={onClick}
            title={title}
            href={href}
            dataGTM={dataGTM}
            logInButton={logInButton || null}
        >
            <span className={classNames(spanClass)} data-gtm={dataGTM}>
                {spanEl}
            </span>
            {caretEl}
        </Link>
    );

    const containerClass = buttonTitle
        ? `${baseClassName}--button`
        : `${baseClassName}--container`;

    return <div className={containerClass}>{linkEl}</div>;
};

SiteNavLink.propTypes = {
    children: PropTypes.node,
    parentClassName: PropTypes.string,
    href: PropTypes.string,
    active: PropTypes.bool,
    truncateLongText: PropTypes.bool,
    disabled: PropTypes.bool,
    respondWidth: PropTypes.bool,
    respondFontSize: PropTypes.bool,
    isInternal: PropTypes.bool,
    onClick: PropTypes.func,
    variant: PropTypes.oneOf(['default', 'bordered']),
    caret: PropTypes.oneOf(['down', 'top', 'left', 'right']),
    title: PropTypes.string,
    bold: PropTypes.bool,
    dark: PropTypes.bool,
    search: PropTypes.bool,
    align: PropTypes.string,
    noPadding: PropTypes.bool,
    buttonTitle: PropTypes.string,
    light: PropTypes.bool,
    mobileFooter: PropTypes.bool,
    logInButton: PropTypes.bool,
    booksTab: PropTypes.bool,
    readingListTab: PropTypes.bool,
    videosTab: PropTypes.bool,
    resourcesTab: PropTypes.bool,
    helpguide: PropTypes.bool,
    dataGTM: PropTypes.string,
    moreVideos: PropTypes.bool,
    errorPage: PropTypes.bool
};

export default SiteNavLink;
