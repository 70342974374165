import React, { Component } from 'react';
import { translate } from 'react-polyglot';
import { connect } from 'react-redux';
import VideoDetail from '../VideoDetail';
import LoaderBlock from '../LoaderBlock';
import NotFoundPage from '../NotFoundPage';
import { links } from '../../lib/constants';
import {
    videoReadCountWorkflow,
    fetchVideosDetailWorkflow,
} from '../../redux/videoActions';
import { fetchMeWorkflow } from '../../redux/userActions';
import withRouter from "../../util/withRouter"

const mapDispatchToProps = {
    fetchVideosDetailWorkflow,
    fetchMeWorkflow
};

const mapStateToProps = ({ videos, viewport, network, user }) => ({
    ...videos,
    videoDetail: videos.videoDetail,
    videoID: videos.videoDetail.id,
    videoReadsCount: videos.videoDetail.readsCount,
    videoLikesCount: videos.videoDetail.likesCount,
    isFavourite: videos.videoDetail.is_favourite,
    videoUrl: videos.videoDetail.materialUrl,
    online: network.online,
    isLoggedIn: user.isLoggedIn,
    viewport,
});

@translate()
@connect(
    mapStateToProps,
    mapDispatchToProps
)
class VideoDetailContainer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            videoSlug: this.props.params.slug,
        };
    }

    componentDidMount() {
            this.props.fetchVideosDetailWorkflow(this.state.videoSlug);
    }

    componentWillReceiveProps(nextProps) {
        if (this.props.params.slug !== nextProps.params.slug) {
            this.props.fetchVideosDetailWorkflow(nextProps.params.slug);
        }
    }

    fetchMeAndVideoDetail = async () => {
        const response = await this.props.fetchMeWorkflow();
        if (response.data.isLoggedIn) {
            this.props.fetchVideosDetailWorkflow(this.state.videoSlug);
        } else {
            this.props.history.push(links.fetchTabLogin(`videodetail/${this.state.videoSlug}`));
        }
    }

    render() {
        const {
            videoDetail,
            videoUrl,
            videoID,
            isFetchingVideoDetail,
            online,
            isLoggedIn,
            viewport,
            videoReadsCount,
            videoLikesCount,
            isFavourite,
            t,
        } = this.props;

        const myLanguage = localStorage.getItem('locale')

        if (isFetchingVideoDetail) {
            return <LoaderBlock />
        }

        if (!isFetchingVideoDetail && videoDetail.id === undefined) {
            return <NotFoundPage />
        }

        return (
            <div>
                <VideoDetail
                    myLanguage={myLanguage}
                    t={t}
                    videoId={videoID}
                    readsCount={videoReadsCount}
                    likesCount={videoLikesCount}
                    isFavourite={isFavourite}
                    viewport={viewport}
                    online={online}
                    isLoggedIn={isLoggedIn}
                    readCount={videoReadCountWorkflow}
                    videoDetail={videoDetail}
                    videoUrl={videoUrl}
                    videosVideoCloseGTM="videos-video-close"
                    videosVideoThumbnailGTM="videos-video-thumbnail"
                    videosVideoWatchGTM="videos-video-watch"
                    videosVideoDownloadGTM="videos-video-download"
                    videosVideoFavoriteGTM="videos-video-favorite"
                    videoDisqusGTM="video-disqus-comments"
                />
            </div>
        );
    }
}


export default withRouter(VideoDetailContainer);

