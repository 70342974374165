import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { translate } from 'react-polyglot';
import queryString from 'query-string';
import { replace } from 'react-router-redux';
import u from 'updeep';
import { cloneDeep } from 'lodash';
import DocumentTitle from 'react-document-title';
import Joyride from 'react-joyride';
import Block from '../Block';
import './VideoContainer.scss';
import VideoCardContainer from '../VideoCardContainer';
import Pagination from '../Pagination';
import {
    fetchVideosWorkflow,
    fetchAllVideosFiltersWorkflow,
} from '../../redux/videoActions';
import { applyFilter, resetFilters } from '../../redux/allFiltersActions';
import SearchModal from '../SearchModal';
import TitleImage from '../TitleImage';
import { sortOptions } from '../../lib/constants';
import LoaderBlock from '../LoaderBlock';
import FiltersBar from '../FiltersBar';
import { links } from '../../lib/constants';
import { fetchMeWorkflow } from '../../redux/userActions';
import HeroCarousel from '../HeroCarousel';
import { Banner } from '../../api';
import withRouter from "../../util/withRouter"

// Tabs list in search page
const defaultFilters = {
    category: [],
    role: [],
    language: [],
    sort: sortOptions.position,
};

const mapStateToProps = ({ user, videos, viewport, help }) => ({
    ...videos,
    isFetchingVideos: videos.isFetchingVideos,
    isFetchingMoreVideos: videos.isFetchingMoreVideos,
    loadedPages: videos.loadedPages,
    totalVideosCount: videos.totalVideosCount,
    filters: videos.filters,
    videoHits: videos.hits,
    viewport,
    helpStatus: help.helpStatus,
    isLoggedIn: user.isLoggedIn
});

const mapDispatchToProps = {
    fetchAllVideosFiltersWorkflow,
    fetchVideosWorkflow,
    replace,
    applyFilter,
    resetFilters,
    fetchMeWorkflow
};

@connect(
    mapStateToProps,
    mapDispatchToProps
)
@translate()
class VideoContainer extends Component {
    constructor(props) {
        super(props);
        const { t } = props;

        this.state = {
            filtersSearchText: {
                category: '',
                role: '',
            },
            isVideoMobile: false,
            isSearchmodalOpen: false,
            queryValue: '',
            bannerData:[]
        };

        this.filtersBarSortOptions = [
            {
                name: t('sort.relevance'),
                queryValue: sortOptions.relevance,
            },
            {
                name: t('sort.new-arrivals-images'),
                queryValue: sortOptions.newArrivals,
            },
            {
                name: t('sort.most-viewed'),
                queryValue: sortOptions.mostViewed,
            },
            {
                name: t('sort.most-liked'),
                queryValue: sortOptions.mostLiked,
            },
        ];

        this.filtersPanelSortOptions = this.filtersBarSortOptions.map(so => ({
            label: so.name,
            value: so.queryValue,
        }));
    }

    componentDidMount() {
        this.isMobile();
        // window.addEventListener('resize', this.isMobile)
        // if (this.props.isLoggedIn) {
            this.fetchVideos();
        // } else {
            // this.fetchMeAndVideos();
        // }
        // Banner.fetchBannerImage("videos")
        //     .then((response)=>{
        //         return response.json();
        //     })
        //     .then((response)=>{
        //         this.setState({
        //             bannerData:response.data.bannerImages
        //         })
        //     })
    }

    componentDidUpdate(nextProps) {
        if (this.props.location.search !== nextProps.location.search) {
            if (this.props.location.search === '') {
                this.isMobile();
                this.fetchVideos();
            }
        }
    }

    fetchVideos = ()=> {
        const {
            fetchAllVideosFiltersWorkflow,
            fetchVideosWorkflow,
            location
        } = this.props;
        const { language } = queryString.parse(
            location.search
        );      
        fetchAllVideosFiltersWorkflow().then(response => {
        let filters = this.getAppliedFilters();
        filters.role = [];
        filters.role = filters.role.concat(response.data.current_role);
        filters.language = language || response.data.current_language;

        const searchParams = queryString.stringify(filters);
        this.props.navigate({ search: `?${searchParams}` }, { replace: true });

        fetchVideosWorkflow(filters);
        })
    } 

    fetchMeAndVideos = async () => {
       const response = await this.props.fetchMeWorkflow();
        if (response.data.isLoggedIn) {
            this.fetchVideos();   
        } else {
            this.props.history.push(links.fetchTabLogin('videos'));
        }
    }


    updateFiltersSearchText = (key, text) => {
        this.setState(
            u(
                {
                    filtersSearchText: {
                        [key]: text,
                    },
                },
                this.state
            )
        );
    };

    updateStyleSearchText = e =>
        this.updateFiltersSearchText('style', e.target.value);
    updatePublisherSearchText = e =>
        this.updateFiltersSearchText('publisher', e.target.value);
    updateCategorySearchText = e =>
        this.updateFiltersSearchText('category', e.target.value);
    updateCategorySearchText = e =>
        this.updateFiltersSearchText('role', e.target.value);

    getAppliedFilters = (props = this.props) => {
        const parsedFilters = queryString.parse(props.location.search);
        Object.entries(parsedFilters).forEach(([k, v]) => {
            if (Array.isArray(defaultFilters[k]) && !Array.isArray(v)) {
                parsedFilters[k] = [v];
            }
        });

        //WARN: Need to look into a side effect bug here
        //that is suspiciously changes the defaultFilters.role array even though
        //there is not direct mutation of this object. So each time, we ensure
        //role of defaultFilters is reset to empty array.
        defaultFilters.role = [];
        return {
            ...defaultFilters,
            ...parsedFilters,
        };
    };

    applyFilter = (type, value) => {
        const appliedFilters = cloneDeep(this.getAppliedFilters());
        const filterIsArray = Array.isArray(appliedFilters[type]);

        if (filterIsArray) {
            appliedFilters[type].push(value);
        } else {
            appliedFilters[type] = value;
        }

        const searchParams = queryString.stringify(appliedFilters);
        this.props.navigate({ search: `?${searchParams}` }, { replace: true });

        this.props.fetchVideosWorkflow(appliedFilters);
    };

    removeFilter = (type, value) => {
        const appliedFilters = cloneDeep(this.getAppliedFilters());

        if (!appliedFilters[type]) {
            return;
        }

        if (Array.isArray(appliedFilters[type])) {
            const index = appliedFilters[type].indexOf(value);
            if (index !== -1) {
                appliedFilters[type].splice(index, 1);
            }
        } else {
            delete appliedFilters[type];
        }

        const searchParams = queryString.stringify(appliedFilters);
        this.props.navigate({ search: `?${searchParams}` }, { replace: true });

        this.props.fetchVideosWorkflow(appliedFilters);
    };

    onSortOptionChanged = option => this.applyFilter('sort', option);

    onLoadMore = () =>
        this.props.fetchVideosWorkflow(
            this.getAppliedFilters(),
            this.props.loadedPages + 1
        );

    isMobile = () => {
        if (
            window.screen.width <= 768 
            // window.matchMedia('(orientation: portrait)').matches
        ) {
            this.setState({
                isVideoMobile: true,
            });
        } else {
            this.setState({
                isVideoMobile: false,
            });
        }
    };

    //Content-Specific Search Function for Vidoes
    onVideoSearch = event => {
        let appliedFilters = this.getAppliedFilters();
        this.setState({ queryValue: event, isSearchModalOpen: false }, () => {
            appliedFilters.query = this.state.queryValue
            appliedFilters.sort = 'Relevance';

            const searchParams = queryString.stringify(appliedFilters);
            this.props.navigate({ search: `?${searchParams}` }, { replace: true });

            this.props.fetchVideosWorkflow(appliedFilters);
        })
    }

    onOpenSearchModal = () => this.setState({ isSearchModalOpen: true });
    onCloseSearchModal = () => this.setState({ isSearchModalOpen: false });


    render() {
        const {
            t,
            videos,
            viewport,
            videoHits,
            filters,
            totalVideosCount,
            isFetchingMoreVideos,
            isLoggedIn
        } = this.props;

        const { isVideoMobile, filtersSearchText, isSearchModalOpen } = this.state;

        const myLanguage = localStorage.getItem('locale');

        const shouldShowLoadMore =
            this.props.loadedPages &&
            this.props.loadedPages < this.props.totalVideosCount;


        let appliedFilters = this.getAppliedFilters();

        const isRightToLeftDirection = localStorage.getItem('locale') === 'ar' || localStorage.getItem('locale') === 'ur';

        if (!filters) {
            return <LoaderBlock />;
        }

        let filtersComponent = null;

        filtersComponent = (
            <FiltersBar
                noTopBorder
                pullUp
                filters={filters}
                applyFilter={this.applyFilter}
                removeFilter={this.removeFilter}
                appliedFilters={appliedFilters}
                styleSearchValue={filtersSearchText.style}
                updateStyleSearchValue={this.updateStyleSearchText}
                categorySearchValue={filtersSearchText.category}
                updateCategorySearchValue={this.updateCategorySearchText}
                publisherSearchValue={filtersSearchText.publisher}
                updatePublisherSearchValue={this.updatePublisherSearchText}
                resultsCount={totalVideosCount}
                sortOptions={this.filtersBarSortOptions}
                applySortOption={this.onSortOptionChanged}
                appliedSortOption={appliedFilters.sort}
                isVideos
                isVideoMobile={isVideoMobile}
                categoryFilterGTM="video-category-filter"
                roleFilterGTM="video-role-filter"
                languageFilterGTM="video-language-filter"
                favoriteSortGTM="video-favorite-sort"
                viewSortGTM="video-view-sort"
                alphabetSortGTM="video-alphabetic-sort"
                hideLevelSelector
            />
        );

        let steps = [
            {
                target: '.__bookFilter',
                content: t('Video.filterHelpGuide'),
                placementBeacon: 'left',
                disableBeacon: true,
            },
            {
                target: '.pb-filters-bar__actions--secondary',
                content: t('Video.sortHelpGuide'),
                placementBeacon: 'left',
            },
            {
                target: '.pb-link--normal',
                content: t('Video.favoriteHelpGuide'),
                placementBeacon: 'left',
            },
        ];

        let mobileSteps = [
            myLanguage === "ar" || myLanguage === "ur" ? 
            {
                target: '.pb-filters-bar__filter-option-rtl',
                content: t('Video.filterHelpGuide'),
                placementBeacon: 'left',
                disableBeacon: true,
            }
            :
            {
                target: '.pb-filters-bar__filter-option',
                content: t('Video.filterHelpGuide'),
                placementBeacon: 'left',
                disableBeacon: true,
            },
            {
                target: '.pb-filters-bar__sort-option',
                content: t('Video.sortHelpGuide'),
                placementBeacon: 'left',
            },
            {
                target: '.pb-link--normal',
                content: t('Video.favoriteHelpGuide'),
                placementBeacon: 'left',
            },
        ];

        return (
            <div className="__video-container">
                <Joyride
                    enabled={true}
                    steps={isVideoMobile ? mobileSteps : steps}
                    continuous
                    showProgress
                    run={this.props.helpStatus}
                    scrollToFirstStep
                    scrollOffset={250}
                    disableScrolling={true}
                    locale={{
                        next: t('global.Next'),
                        back: t('global.Back'),
                        last: t('global.Last'),
                    }}
                    styles={{
                        options: {
                            arrowColor: '#e3ffeb',
                            backgroundColor: '#e3ffeb',
                            textColor: '#004a14',
                            overlayColor: 'rgba(0, 0, 0, 0.8)',
                            primaryColor: '#007AA4',
                            spotlightShadow: '0 0 15px rgba(0, 0, 0, 0.8)',
                            zIndex: 1000,
                        },
                    }}
                />
                <HeroCarousel  banners={this.state.bannerData} padding130={false} />  
                <DocumentTitle
                    title={`${t('global.video', 2)} - ${t(
                        'global.site-title'
                    )}`}
                />
                <Block verticalMargin={true} background="transparent">
                    <div className="video-resource-header">
                        <div className="__video-header">
                            <h3>{t('global.video', 2)}</h3>
                            <h3 className="__video-count">({videoHits})</h3>
                        </div>
                        <div
                            className={'__videos-search'}
                            onClick={this.onOpenSearchModal}
                        >
                            <TitleImage
                                className={isRightToLeftDirection ? "__profile-images-arabic" : "__profile-images"}
                                svgName={require('../../assets/search_tl.svg')}
                                title={t('videos.search')}
                                contentSpecificSearch
                            />
                        </div>
                    </div>
                </Block>
                {videos && videos.length >= 0 ? (
                    <div className="__video-filter-component">
                        {filtersComponent}
                    </div>
                ) : null}
                <div className="__video-card-section">
                    <VideoCardContainer
                        isLoggedIn={isLoggedIn}
                        myLanguage={myLanguage}
                        Videos={videos}
                        applyFilter={this.applyFilter}
                        removeFilter={this.removeFilter}
                        onSortOptionChanged={this.onSortOptionChanged}
                        tags={appliedFilters.tags}
                        appliedFilters={{ ...appliedFilters }}
                    />
                    {shouldShowLoadMore ? (
                        <Pagination
                            onClick={this.onLoadMore}
                            loading={isFetchingMoreVideos}
                            dataGTM="videos-view-more"
                        />
                    ) : null}
                </div>
                {isSearchModalOpen ? (
                    <SearchModal
                        viewport={viewport}
                        onClose={this.onCloseSearchModal}
                        onSubmit={this.onVideoSearch}
                    />
                ) : null}
            </div>
        );
    }
}

VideoContainer.propTypes = {
    parentClassName: PropTypes.string,
};

export default withRouter(VideoContainer);
