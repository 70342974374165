import React, { useEffect } from 'react';
import { translate } from 'react-polyglot';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Book from '../Book';
import LoaderBlock from '../LoaderBlock';
import { videoReadCountWorkflow } from '../../redux/readAloudsActions';
import { fetchBookWorkflow } from '../../redux/bookActions';
import { recordGaEvents } from '../../redux/googleAnalyticsActions';
import {
    saveOfflineWorkflow,
    unsaveOffline,
    isAvailableOffline,
} from '../../redux/offlineBooksActions';
import {
    addSlimNotification,
    removeSlimNotification,
} from '../../redux/slimNotificationActions';
import './BookContainer.scss';
import NotFoundPage from '../NotFoundPage';
import { useParams } from 'react-router-dom';

const mapStateToProps = ({
    book,
    viewport,
    user,
    readingLists,
    offlineBooks,
    network,
}) => ({
    isFetchingBook: book.isFetchingBook,
    book: book.book,
    isLoggedIn: user.isLoggedIn,
    roles: user.profile.roles,
    viewport,
    offlineBooks,
    isSavingOffline: offlineBooks.isSavingOffline,
    online: network.online,
    userEmail: user.profile.email,
    name: user.profile.name,
});

const mapDispatchToProps = {
    fetchBookWorkflow,
    saveOfflineWorkflow,
    unsaveOffline,
    addSlimNotification,
    recordGaEvents,
    removeSlimNotification,
};

const BookContainer = ({
    isFetchingBook,
    book,
    isLoggedIn,
    viewport,
    assets,
    saveOfflineWorkflow,
    roles,
    offlineBooks,
    unsaveOffline,
    online,
    isSavingOffline,
    addSlimNotification,
    userEmail,
    name,
    recordGaEvents,
    removeSlimNotification,
    fetchBookWorkflow,
}) => {
    const { slug } = useParams();

    useEffect(() => {
        fetchBookWorkflow(slug);
    }, [slug, fetchBookWorkflow]);

    if (isFetchingBook) {
        return <LoaderBlock />;
    }

    if (!isFetchingBook && !book) {
        return <NotFoundPage />;
    }

    return (
        <Book
            book={book}
            isLoggedIn={isLoggedIn}
            viewport={viewport}
            assets={assets}
            availableOffline={isAvailableOffline(book.id, offlineBooks)}
            offlineBooksCount={offlineBooks.books.length}
            onClickAddToOffline={saveOfflineWorkflow.bind(this, book)}
            onClickRemoveFromOffline={unsaveOffline.bind(this, book.id)}
            online={online}
            roles={roles}
            isSavingOffline={isSavingOffline}
            addSlimNotification={addSlimNotification}
            userEmail={userEmail}
            name={name}
            recordGaEvents={recordGaEvents}
            removeSlimNotification={removeSlimNotification}
            bookStoryDownload="book-story-download"
            readToMeAction={videoReadCountWorkflow}
        />
    );
};

BookContainer.propTypes = {
    fetchBookWorkflow: PropTypes.func.isRequired,
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(translate()(BookContainer));
