import React, { Component } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import withRouter from '../../util/withRouter';
import { translate } from 'react-polyglot';

import './ReadingListInnerMenuTabs.scss';

@translate()
class ReadingListInnerMenuTabs extends Component {
    // static defaultProps = {}
    loggedinTabs = [
        {
            name: this.props.t('global.themed-reading-lists'),
            url: '/readinglists',
        },
        {
            name: this.props.t('global.my-reading-lists'),
            url: '/myreadinglists',
        },
    ];

    state = {
        selectedTab: this.loggedinTabs[0],
    };

    onClick = tab => {
        this.setState({ selectedTab: tab }, () => {
            this.props.navigate(tab.url);
        });
    };

    componentDidMount() {
        let selectedTab =
            this.props.location.pathname === '/readinglists'
                ? this.loggedinTabs[0]
                : this.loggedinTabs[1];
        this.setState({ selectedTab: selectedTab });
    }

    componentWillReceiveProps(props) {
        let selectedTab =
            props.location.pathname === '/readinglists'
                ? this.loggedinTabs[0]
                : this.loggedinTabs[1];
        this.setState({ selectedTab: selectedTab });
    }

    render() {
        const baseClassName = 'pb-reading-list-inner-menu-tabs';
        const { parentClassName, isLoggedIn } = this.props;

        const classes = {
            [baseClassName]: true,
            [parentClassName]: parentClassName,
        };

        return (
            <div className={classNames(classes)}>
                {isLoggedIn ? (
                    <div className="subMenuTabs">
                        {this.loggedinTabs.map((tab, index) => {
                            return (
                                <div className={'NavigationItem'} key={index}>
                                    <div
                                        className={
                                            this.state.selectedTab === tab
                                                ? 'active'
                                                : 'inactive'
                                        }
                                        onClick={() => this.onClick(tab)}
                                    >
                                        {(tab.name)}
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                ) : (
                        <div className="Tabs">
                            <div className={'NavigationItem'}>
                                <div className="active">
                                    {this.props.t('global.themed-reading-lists')}
                                </div>
                            </div>
                        </div>
                    )}
            </div>
        );
    }
}

ReadingListInnerMenuTabs.propTypes = {
    parentClassName: PropTypes.string,
};

export default withRouter(ReadingListInnerMenuTabs);
