import React from 'react';
import './IframeContainer.scss';

const IframeContainer = ({title, videoId}) => {
    return (
        <div className="__iframe-video-container">
            <iframe
                title={title}
                src={`https://www.youtube.com/embed/${videoId}`}
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
            />
        </div>
    );
}


export default IframeContainer;
