import React, { Component } from 'react';
import { sortOptions } from '../../lib/constants'
import { connect } from 'react-redux';
import { translate } from 'react-polyglot';
import queryString from 'query-string';
import { replace } from 'react-router-redux';
import u from 'updeep';
import Joyride from 'react-joyride';
import DocumentTitle from 'react-document-title';
import { cloneDeep } from 'lodash';
import { fetchReadAloudFiltersWorkflow, fetchReadAloudsWorkflow } from '../../redux/readAloudsActions';
import { applyFilter, resetFilters } from '../../redux/allFiltersActions';
import LoaderBlock from '../LoaderBlock';
import FiltersBar from '../FiltersBar';
import Block from '../Block';
import TitleImage from '../TitleImage';
import Pagination from '../Pagination';
import SearchModal from '../SearchModal';
import ReadAloudCardContainer from '../ReadAloudCardContainer';
import withRouter from "../../util/withRouter";



// Tabs list in search page
const defaultFilters = {
    category: [],
    role: [],
    language: [],
    sort: sortOptions.relevance,
};

const mapStateToProps = ({ readAlouds, viewport, help }) => ({
    ...readAlouds,
    isFetchingReadAlouds: readAlouds.isFetchingReadAlouds,
    isFetchingMoreReadAlouds: readAlouds.isFetchingMoreReadAlouds,
    loadedPages: readAlouds.loadedPages,
    totalVideosCount: readAlouds.totalVideosCount,
    filters: readAlouds.filters,
    videoHits: readAlouds.hits,
    viewport,
    helpStatus: help.helpStatus,
});

const mapDispatchToProps = {
    fetchReadAloudFiltersWorkflow,
    fetchReadAloudsWorkflow,
    replace,
    applyFilter,
    resetFilters
}

@connect(
    mapStateToProps,
    mapDispatchToProps
)

@translate()
class ReadAloudsContainer extends Component {
    constructor(props) {
        super(props);
        const { t } = props;
        this.state = {
            filtersSearchText: {
                category: '',
                role: '',
            },
            isVideoMobile: false,
            isSearchmodalOpen: false,
            queryValue: ''
        }

        this.filtersBarSortOptions = [
            {
                name: t('sort.relevance'),
                queryValue: sortOptions.relevance,
            },
            {
                name: t('sort.new-arrivals-images'),
                queryValue: sortOptions.newArrivals,
            },
            {
                name: t('sort.most-viewed'),
                queryValue: sortOptions.mostViewed,
            },
            {
                name: t('sort.most-liked'),
                queryValue: sortOptions.mostLiked,
            },
        ];

        this.filtersPanelSortOptions = this.filtersBarSortOptions.map(so => ({
            label: so.name,
            value: so.queryValue,
        }));

    }

    componentDidMount() {
        const {
            fetchReadAloudFiltersWorkflow,
            fetchReadAloudsWorkflow
        } = this.props;
        this.isMobile();
        window.addEventListener('resize', this.isMobile)
        fetchReadAloudFiltersWorkflow().then(response => {
            let filters = this.getAppliedFilters();
            filters.role = [];
            filters.role = filters.role.concat(response.data.current_role);
            filters.language = response.data.current_language;
            // filters.category = "Read Alouds (for kids)"

            const searchParams = queryString.stringify(filters);
            this.props.navigate({ search: `?${searchParams}` }, { replace: true });
            fetchReadAloudsWorkflow(filters);
        });
    }

    componentDidUpdate(nextProps) {
        if (this.props.location.search !== nextProps.location.search) {
            if (this.props.location.search === '?sort=Relevance') {
                this.isMobile();
                this.props.fetchReadAloudFiltersWorkflow().then(response => {
                    let filters = this.getAppliedFilters();
                    filters.role = [];
                    filters.role = filters.role.concat(response.data.current_role);
                    filters.language = response.data.current_language;
                    // filters.category = "Read Alouds (for kids)"

                    const searchParams = queryString.stringify(filters);
                    this.props.navigate({ search: `?${searchParams}` }, { replace: true });

                    this.props.fetchReadAloudsWorkflow(filters);
                });
            }
        }
    }


    updateFiltersSearchText = (key, text) => {
        this.setState(
            u(
                {
                    filtersSearchText: {
                        [key]: text,
                    },
                },
                this.state
            )
        );
    };

    updateStyleSearchText = e =>
        this.updateFiltersSearchText('style', e.target.value);
    updatePublisherSearchText = e =>
        this.updateFiltersSearchText('publisher', e.target.value);
    updateCategorySearchText = e =>
        this.updateFiltersSearchText('category', e.target.value);
    updateCategorySearchText = e =>
        this.updateFiltersSearchText('role', e.target.value);

    getAppliedFilters = (props = this.props) => {
        const parsedFilters = queryString.parse(props.location.search);
        Object.entries(parsedFilters).forEach(([k, v]) => {
            if (Array.isArray(defaultFilters[k]) && !Array.isArray(v)) {
                parsedFilters[k] = [v];
            }
        });

        //WARN: Need to look into a side effect bug here
        //that is suspiciously changes the defaultFilters.role array even though
        //there is not direct mutation of this object. So each time, we ensure
        //role of defaultFilters is reset to empty array.
        defaultFilters.role = [];
        return {
            ...defaultFilters,
            ...parsedFilters,
        };
    };


    applyFilter = (type, value) => {
        const appliedFilters = cloneDeep(this.getAppliedFilters());
        const filterIsArray = Array.isArray(appliedFilters[type]);

        if (filterIsArray) {
            appliedFilters[type].push(value);
        } else {
            appliedFilters[type] = value;
        }

        const searchParams = queryString.stringify(appliedFilters);
        this.props.navigate({ search: `?${searchParams}` }, { replace: true });

        this.props.fetchReadAloudsWorkflow(appliedFilters);
    };

    removeFilter = (type, value) => {
        const appliedFilters = cloneDeep(this.getAppliedFilters());

        if (!appliedFilters[type]) {
            return;
        }

        if (Array.isArray(appliedFilters[type])) {
            const index = appliedFilters[type].indexOf(value);
            if (index !== -1) {
                appliedFilters[type].splice(index, 1);
            }
        } else {
            delete appliedFilters[type];
        }

        const searchParams = queryString.stringify(appliedFilters);
        this.props.navigate({ search: `?${searchParams}` }, { replace: true });

        this.props.fetchReadAloudsWorkflow(appliedFilters);
    };

    onSortOptionChanged = option => this.applyFilter('sort', option);

    onLoadMore = () =>
        this.props.fetchReadAloudsWorkflow(
            this.getAppliedFilters(),
            this.props.loadedPages + 1
        );

    isMobile = () => {
        if (
            window.screen.width <= 768 
            // window.matchMedia('(orientation: portrait)').matches
        ) {
            this.setState({
                isVideoMobile: true,
            });
        } else {
            this.setState({
                isVideoMobile: false,
            });
        }
    };

    //Content-Specific Search Function for Vidoes
    onVideoSearch = event => {
        let appliedFilters = this.getAppliedFilters();
        this.setState({ queryValue: event, isSearchModalOpen: false }, () => {
            appliedFilters.query = this.state.queryValue
            appliedFilters.sort = 'Relevance';

            const searchParams = queryString.stringify(appliedFilters);
            this.props.navigate({ search: `?${searchParams}` }, { replace: true });

            this.props.fetchReadAloudsWorkflow(appliedFilters);
        })
    }

    onOpenSearchModal = () => this.setState({ isSearchModalOpen: true });
    onCloseSearchModal = () => this.setState({ isSearchModalOpen: false });



    render() {
        const {
            t,
            readAlouds,
            viewport,
            videoHits,
            filters,
            totalVideosCount,
            isFetchingMoreVideos,
        } = this.props;

        const { isVideoMobile, filtersSearchText, isSearchModalOpen } = this.state;

        const myLanguage = localStorage.getItem('locale');

        const isRightToLeftDirection = localStorage.getItem('locale') === 'ar' || localStorage.getItem('locale') === 'ur';

        const shouldShowLoadMore =
            this.props.loadedPages &&
            this.props.loadedPages < this.props.totalVideosCount;


        let appliedFilters = this.getAppliedFilters();

        if (!filters) {
            return <LoaderBlock />;
        }

        let filtersComponent = null;

        filtersComponent = (
            <FiltersBar
                readAloud
                noTopBorder
                pullUp
                filters={filters}
                applyFilter={this.applyFilter}
                removeFilter={this.removeFilter}
                appliedFilters={appliedFilters}
                styleSearchValue={filtersSearchText.style}
                updateStyleSearchValue={this.updateStyleSearchText}
                resultsCount={totalVideosCount}
                sortOptions={this.filtersBarSortOptions}
                applySortOption={this.onSortOptionChanged}
                appliedSortOption={appliedFilters.sort}
                isVideos
                isVideoMobile={isVideoMobile}
                viewSortGTM="video-view-sort"
                alphabetSortGTM="video-alphabetic-sort"
                hideLevelSelector
                hideLanguageSelector
            />
        );

        let steps = [
            {
                target: '.__bookFilter',
                content: t('Video.filterHelpGuide'),
                placementBeacon: 'left',
                disableBeacon: true,
            },
            {
                target: '.pb-filters-bar__actions--secondary',
                content: t('Video.sortHelpGuide'),
                placementBeacon: 'left',
            },
            {
                target: '.pb-link--normal',
                content: t('Video.favoriteHelpGuide'),
                placementBeacon: 'left',
            },
        ];

        let mobileSteps = [
            myLanguage === "ar" || myLanguage === "ur" ? 
            {
                target: '.pb-filters-bar__filter-option-rtl',
                content: t('Video.filterHelpGuide'),
                placementBeacon: 'left',
                disableBeacon: true,
            }
            :
            {
                target: '.pb-filters-bar__filter-option',
                content: t('Video.filterHelpGuide'),
                placementBeacon: 'left',
                disableBeacon: true,
            },
            {
                target: '.pb-filters-bar__sort-option',
                content: t('Video.sortHelpGuide'),
                placementBeacon: 'left',
            },
            {
                target: '.pb-link--normal',
                content: t('Video.favoriteHelpGuide'),
                placementBeacon: 'left',
            },
        ];

        return (
            <div className="__video-container">
                <Joyride
                    enabled={true}
                    steps={isVideoMobile ? mobileSteps : steps}
                    continuous
                    showProgress
                    run={this.props.helpStatus}
                    scrollToFirstStep
                    scrollOffset={250}
                    disableScrolling={true}
                    locale={{
                        next: t('global.Next'),
                        back: t('global.Back'),
                        last: t('global.Last'),
                    }}
                    styles={{
                        options: {
                            arrowColor: '#e3ffeb',
                            backgroundColor: '#e3ffeb',
                            textColor: '#004a14',
                            overlayColor: 'rgba(0, 0, 0, 0.8)',
                            primaryColor: '#007AA4',
                            spotlightShadow: '0 0 15px rgba(0, 0, 0, 0.8)',
                            zIndex: 1000,
                        },
                    }}
                />
                <DocumentTitle
                    title={`${t('global.readAlouds')} - ${t(
                        'global.site-title'
                    )}`}
                />
                <Block background="transparent">
                    <div className="video-resource-header">
                        <div className="__video-header">
                            <h3>{t('global.readAlouds')}</h3>
                            <h3 className="__video-count">({videoHits})</h3>
                        </div>
                        <div
                            className={'__videos-search'}
                            onClick={this.onOpenSearchModal}
                        >
                            <TitleImage
                                className={isRightToLeftDirection ? "__profile-images-arabic" : "__profile-images"}
                                svgName={require('../../assets/search_tl.svg')}
                                title={t('videos.search')}
                                contentSpecificSearch
                            />
                        </div>
                    </div>
                </Block>
                {/* {readAlouds && readAlouds.length >= 0 ? ( */}
                    <div className="__video-filter-component">
                        {filtersComponent}
                    </div>
                {/* ) : null} */}
                <div className="__video-card-section">
                    <ReadAloudCardContainer
                        myLanguage={myLanguage}
                        Videos={readAlouds}
                        applyFilter={this.applyFilter}
                        removeFilter={this.removeFilter}
                        onSortOptionChanged={this.onSortOptionChanged}
                        appliedFilters={{ ...appliedFilters }}
                    />
                    {shouldShowLoadMore ? (
                        <Pagination
                            onClick={this.onLoadMore}
                            loading={isFetchingMoreVideos}
                            dataGTM="videos-view-more"
                        />
                    ) : null}
                </div>
                {isSearchModalOpen ? (
                    <SearchModal
                        viewport={viewport}
                        onClose={this.onCloseSearchModal}
                        onSubmit={this.onVideoSearch}
                    />
                ) : null}
            </div>
        );
    }
}

export default withRouter(ReadAloudsContainer)