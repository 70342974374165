import React, { Component } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { translate } from 'react-polyglot';
import { connect } from 'react-redux';
import Favourites from '../Favourites';
import './ResourceCard.scss';
import Block from '../Block';
import Link from '../Link';
import { favouriteReferenceWorkflow } from '../../redux/referencesActions';

const mapDispatchToProps = {
    favouriteReferenceWorkflow,
};

const mapStateToProps = (state, ownProps) => {
    const requiredResources = state.references.references.length
        ? state.references.references
        : state.references.favouriteResources;
    const associatedindex = requiredResources.findIndex(
        reference => reference.id === ownProps.id
    );
    let associatedReference = null;
    if (associatedindex >= 0) {
        associatedReference = requiredResources[associatedindex];
    }
    return {
        isFavourite: associatedReference
            ? associatedReference.is_favourite
            : ownProps.isFavourite,
        isFavouriting: associatedReference
            ? associatedReference.isFavouriting
            : null
    };
};
@translate()
@connect(
    mapStateToProps,
    mapDispatchToProps
)
class ResourceCard extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isModalVisible: false,
        };
    }

    favoriteToggleHandler = id => {
        const { favouriteReferenceWorkflow } = this.props;
        favouriteReferenceWorkflow(id);
    };

    render() {
        const baseClassName = 'pb-resource-card';
        const {
            id,
            isFavourite,
            parentClassName,
            slug,
            t,
            title,
            likesCount,
            coverImage,
            resourceThumbnailGTM,
            resourceTitleGTM,
            isSimilarResource,
            myLanguage
        } = this.props;

        const isRightToLeftDirection = localStorage.getItem("locale") === "ar" || localStorage.getItem('locale') === 'ur';

        const classes = {
            [baseClassName]: true,
            [parentClassName]: parentClassName,
        };

        const resourcePath = `/resourcedetail/${slug}`;

        const similarResourcePath = `${slug}`;

        let favouriteStat = (
            <Favourites
                isFavourite={isFavourite}
                favouriteToggleHandler={() =>
                    this.favoriteToggleHandler(id)
                }
                isLoggedIn={true}
                bookSlug={slug}
                likes={likesCount}
            />
        )

        return (
            <div className={classNames(classes)}>
                <Block
                    noHorizontalPadding
                    noVerticalPadding
                    background="transparent"
                >
                    <div className="__resourceCard-section">
                        <Link
                            isInternal
                            noPadding
                            shouldOpenNewPage
                            translation
                            href={
                                isSimilarResource
                                    ? similarResourcePath
                                    : resourcePath
                            }
                            dataGTM={resourceThumbnailGTM}
                        >
                            <div
                                className={`${baseClassName}__resource-image`}
                                data-gtm={resourceThumbnailGTM}
                            >
                                <img
                                    src={coverImage}
                                    alt="Resource Thumbnail Img"
                                    data-gtm={resourceThumbnailGTM}
                                />
                            </div>
                        </Link>
                        <div className="__View-like-count">
                            <div className={isRightToLeftDirection ? `${baseClassName}__like-count-arabic` : `${baseClassName}__like-count`}>
                                {favouriteStat}
                            </div>
                        </div>
                        <Link
                            isInternal
                            noPadding
                            shouldOpenNewPage
                            translation
                            href={
                                isSimilarResource
                                    ? similarResourcePath
                                    : resourcePath
                            }
                            dataGTM={resourceTitleGTM}
                        >
                            <div
                                className={myLanguage === 'lo' ? `${baseClassName}_lo-title` : isRightToLeftDirection ? `${baseClassName}_title ${baseClassName}__align-right` : `${baseClassName}_title`}
                                data-gtm={resourceTitleGTM}
                            >
                                {title}
                            </div>
                        </Link>
                    </div>
                </Block>
            </div>
        );
    }
}
ResourceCard.propTypes = {
    parentClassName: PropTypes.string,
};
export default ResourceCard;
