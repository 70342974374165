import React from 'react';
import { translate } from 'react-polyglot';
import PropTypes from 'prop-types';
import Link from '../../Link';
import Img from '../../Img';
import { isRightToLeftDirection } from '../../../util/utils';
import SvgIcon from '../../SvgIcon';

const BookDetailsCard = ({
    t,
    baseClassName,
    bookPath,
    onClick,
    readStory,
    slug,
    coverImage,
    title,
    imageSrcsetSizes,
    offline,
    myLanguage,
    pagesCount
}) => {
    return (
        <div>
            <div className={`${baseClassName}__wrapper-details`}>
                <div
                    className={`${baseClassName}__image-wrapper`}
                    href={bookPath}
                >
                    <Link
                        isInternal={onClick ? false : true}
                        parentClassName={`${baseClassName}__link`}
                        onClick={() => readStory(slug)}
                        dataGTM="books-book-image"
                    >
                        <div
                            className={`${baseClassName}__image`}
                            data-gtm="books-book-image"
                        >
                            <Img
                                bookDetails
                                image={coverImage}
                                alt={title}
                                lazyLoad
                                sizes={imageSrcsetSizes.grid2up6up}
                                offline={offline}
                                data-gtm="books-book-image"
                            />
                        </div>
                    </Link>
                </div>
            </div>
            <div
                className={
                    isRightToLeftDirection
                        ? `${baseClassName}__border-image-wrapper-arabic`
                        : `${baseClassName}__border-image-wrapper`
                }
            >
                <SvgIcon name={'border-image'} size="border-image" />
            </div>
            <div
                className={
                    myLanguage === 'lo'
                        ? `${baseClassName}__page-number-lo`
                        : isRightToLeftDirection
                        ? `${baseClassName}__page-number-arabic`
                        : `${baseClassName}__page-number`
                }
            >
                {pagesCount}
                <p>{t('Book.page')}</p>
            </div>
        </div>
    );
};

BookDetailsCard.propTypes = {
  t: PropTypes.func.isRequired,
  baseClassName: PropTypes.string.isRequired,
  bookPath: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  readStory: PropTypes.func.isRequired,
  slug: PropTypes.string.isRequired,
  coverImage: PropTypes.object.isRequired,
  title: PropTypes.string.isRequired,
  imageSrcsetSizes: PropTypes.shape({
      grid2up6up: PropTypes.string
  }).isRequired,
  offline: PropTypes.bool,
  myLanguage: PropTypes.string,
  pagesCount: PropTypes.number.isRequired
};

export default translate()(BookDetailsCard);
