import { createActions } from 'redux-actions';

import { createAsyncActions, runAsyncWorkflow } from '../lib/reduxHelpers';
import { Offline } from '../api';

export const { unsaveOffline } = createActions({
    UNSAVE_OFFLINE: bookId => ({ bookId }),
});

export const { removeOffline } = createActions({
    REMOVE_OFFLINE: () => ({}),
});

export const [
    saveOffline,
    receiveSaveOffline,
    saveOfflineError,
] = createAsyncActions('SAVE_OFFLINE', () => { }, ({ ok, book }) => ({ ok, book }));

export const saveOfflineWorkflow = book => {
    const fetchPromise = Offline.save(book.slug).then((resp) => {
        if (resp.ok) {
            Offline.fetchStoryOfflineCount(book.id)
        }
        return resp;
    });

    return runAsyncWorkflow(
        {
            fetch: saveOffline,
            receive: receiveSaveOffline,
            error: saveOfflineError,
        },
        fetchPromise,
        { book }
    );
};

export const isAvailableOffline = (bookId, offlineBooks) =>
    offlineBooks.books.filter(offlineBook => offlineBook.id === bookId).length;
