import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Link from '../Link';
import './CollapsibleSection.scss';

class CollapsibleSection extends Component {
    constructor(props) {
        super(props);
        this.toggleOpen = this.toggleOpen.bind(this);

        this.state = {
            open: false,
        };
    }

    toggleOpen() {
        if (!this.props.disableCollapse) {
            this.setState({
                open: !this.state.open,
            });
        }
    }

    render() {
        const baseClassName = 'pb-collapsible-section';

        const {
            children,
            expandOnMediumViewport,
            expandOnLargeViewport,
            theme,
            title,
            disableCollapse,
            upperCaseTitle,
            parentClassName,
            relatedBookList,
        } = this.props;

        let titleEl;

        const titleClasses = {
            [`${baseClassName}__title`]: true,
            [`${baseClassName}__title ${baseClassName}__align-text-right`]:
                localStorage.getItem('locale') === 'ar' || localStorage.getItem('locale') === 'ur',
            [`${baseClassName}__RelatedBookList`]: relatedBookList,
        };

        if (title) {
            titleEl = (
                <Link onClick={this.toggleOpen} readOtherLanguage={true}>
                    <div className={classNames(titleClasses)}>
                        {title}
                        <div
                            className={
                                this.state.open
                                    ? `${baseClassName}__arrow ArrowUp`
                                    : `${baseClassName}__arrow ArrowDown`
                            }
                        ></div>
                    </div>
                </Link>
            );
        }

        const classes = {
            [baseClassName]: true,
            [parentClassName]: parentClassName,
            [`${baseClassName}--open`]: this.state.open,
            [`${baseClassName}--${theme}`]: theme,
            [`${baseClassName}--upper-case-title`]: upperCaseTitle,
            [`${baseClassName}--disable-collapse`]: disableCollapse,
            [`${baseClassName}--expand-on-medium-viewport`]: expandOnMediumViewport,
            [`${baseClassName}--expand-on-large-viewport`]: expandOnLargeViewport,
        };

        return (
            <div className={classNames(classes)}>
                {titleEl}
                <div className={`${baseClassName}__content`}>{children}</div>
            </div>
        );
    }
}

CollapsibleSection.propTypes = {
    title: PropTypes.string,
    children: PropTypes.node,
    theme: PropTypes.string,
    parentClassName: PropTypes.string,
    expandOnLargeViewport: PropTypes.bool,
    disableCollapse: PropTypes.bool,
};

export default CollapsibleSection;
