import { createAsyncActions, runAsyncWorkflow } from '../lib/reduxHelpers';
import { createAction } from 'redux-actions';

import { ReadingList } from '../api';

export const [
    fetchReadingListDetails,
    receiveReadingListDetails,
    fetchReadingListDetailsError,
] = createAsyncActions(
    'THEMED_READING_LIST',
    slug => ({ slug }),
    ({ ok, data, metadata }) => ({ ok, readingList: data, metadata })
);

export const [
    fetchMoreReadingListDetails,
    receiveMoreReadingListDetails,
    fetchMoreReadingListDetailsError,
] = createAsyncActions(
    'MORE_THEMED_READING_LIST',
    ({ page, perPage }) => ({ page, perPage }),
    ({ data, metadata }) => ({ readingList: data, metadata })
);

export const fetchReadingListDetailsWorkflow = (
    appliedFilters,
    slug,
    page,
    perPage
) => {
    const fetchingMore = page && page > 1;
    const fetchPromise = ReadingList.fetchThematic(appliedFilters, slug, page);

    return runAsyncWorkflow(
        {
            fetch: fetchingMore
                ? fetchMoreReadingListDetails
                : fetchReadingListDetails,
            receive: fetchingMore
                ? receiveMoreReadingListDetails
                : receiveReadingListDetails,
            error: fetchingMore
                ? fetchMoreReadingListDetailsError
                : fetchReadingListDetailsError,
        },
        fetchPromise
    );
};

export const [
    fetchManageReadingList,
    receiveManageReadingList,
    fetchManageReadingListError,
] = createAsyncActions(
    'MANAGE_READING_LIST',
    slug => ({ slug }),
    ({ data }) => ({ manageReadingList: data })
);

export const fetchManageReadingListWorkflow = list => {
    const fetchPromise = ReadingList.update(list);

    return runAsyncWorkflow(
        {
            fetch: fetchManageReadingList,
            receive: receiveManageReadingList,
            error: fetchManageReadingListError,
        },
        fetchPromise
    );
};

export const [
    fetchReadingListBooks,
    receiveReadingListBooks,
    fetchReadingListBooksError,
] = createAsyncActions(
    'FETCH_READING_LIST_BOOKS',
    slug => ({ slug }),
    ({ ok, data }) => ({ ok, readingListBooks: data })
);

export const fetchReadingListBooksWorkflow = slug => {
    const fetchPromise = ReadingList.fetchReadingListsBooks(slug);

    return runAsyncWorkflow(
        {
            fetch: fetchReadingListBooks,
            receive: receiveReadingListBooks,
            error: fetchReadingListBooksError,
        },
        fetchPromise
    );
};

export const [
    fetchMyReadingListDetails,
    receiveMyReadingListDetails,
    fetchMyReadingListDetailsError,
] = createAsyncActions(
    'MY_READING_LIST',
    slug => ({ slug }),
    ({ ok, data, metadata }) => ({ ok, myReadingList: data, metadata })
);

export const [
    fetchMoreMyReadingListDetails,
    receiveMoreMyReadingListDetails,
    fetchMoreMyReadingListDetailsError,
] = createAsyncActions(
    'MORE_MY_READING_LIST',
    ({ page, perPage }) => ({ page, perPage }),
    ({ data, metadata }) => ({ myReadingList: data, metadata })
);

export const fetchMyReadingListWorkflow = (
    appliedFilters,
    slug,
    page,
    perPage
) => {
    const fetchingMore = page && page > 1;
    const fetchPromise = ReadingList.fetchMyReadingLists(
        appliedFilters,
        slug,
        page
    );

    return runAsyncWorkflow(
        {
            fetch: fetchingMore
                ? fetchMoreMyReadingListDetails
                : fetchMyReadingListDetails,
            receive: fetchingMore
                ? receiveMoreMyReadingListDetails
                : receiveMyReadingListDetails,
            error: fetchingMore
                ? fetchMoreMyReadingListDetailsError
                : fetchMyReadingListDetailsError,
        },
        fetchPromise
    );
};

export const [
    postReadingListLike,
    receiveReadingListLike,
    postReadingListLikeError,
] = createAsyncActions(
    'READING_LIST_LIKE',
    slug => ({ slug }),
    ({ ok, data }) => ({ readingList: data }),
    'POST'
);

export const postReadingListLikeWorkflow = slug => {
    const postPromise = ReadingList.postLike(slug);

    return runAsyncWorkflow(
        {
            fetch: postReadingListLike,
            receive: receiveReadingListLike,
            error: postReadingListLikeError,
        },
        postPromise,
        { slug }
    );
};

export const [
    updateReadingList,
    receiveUpdateReadingList,
    updateReadingListError,
] = createAsyncActions(
    'UPDATE_READING_LIST',
    readingList => ({ readingList }),
    ({ ok, data }) => ({ readingList: data }),
    'PUT'
);

export const updateReadingListWorkflow = readingList => {
    const updateReadingListPromise = ReadingList.update(readingList);

    return runAsyncWorkflow(
        {
            fetch: updateReadingList,
            receive: receiveUpdateReadingList,
            error: updateReadingListError,
        },
        updateReadingListPromise,
        { readingList }
    );
};

// Update Reading list details(title/description) actions
export const [
    updateReadingListDetails,
    receiveUpdateReadingListDetails,
    updateReadingListDetailsError,
] = createAsyncActions(
    'UPDATE_READING_LIST_DETAILS',
    () => {},                                          // Fetch payload creator
    ({ ok, data }) => ({ readingList: data.list }),    // Recieve payload creator
    'PUT'
);

// Update Reading list details(title/description)
export const updateReadingListDetailsWorkflow = readingList => {
    const updateReadingListPromise = ReadingList.updateDetails(readingList);

    return runAsyncWorkflow(
        {
            fetch: updateReadingListDetails,
            receive: receiveUpdateReadingListDetails,
            error: updateReadingListDetailsError,
        },
        updateReadingListPromise,
        { readingList }
    );
};

export const [
    createReadingList,
    receiveCreateReadingList,
    createReadingListError,
] = createAsyncActions(
    'CREATE_READING_LIST',
    (title, description, books) => ({ title, description, books }),
    ({ data }) => ({ readingList: data })
);

export const createReadingListWorkflow = (name, description, books) => {
    const createReadingListPromise = ReadingList.create( 
        name,
        description,
        books
    );

    return runAsyncWorkflow(
        {
            fetch: createReadingList,
            receive: receiveCreateReadingList,
            error: createReadingListError,
        },
        createReadingListPromise
    );
};

export const [
    createMyReadingList,
    receiveMyCreateReadingList,
    createMyReadingListError,
] = createAsyncActions(
    'CREATE_MY_READING_LIST',
    (title, description, books) => ({ title, description, books }),
    ({ data }) => ({ myReadingLists: data })
);

export const createMyReadingListWorkflow = (name, description, books) => {
    const createMyReadingListPromise = ReadingList.create(
        name,
        description,
        books
    );

    return runAsyncWorkflow(
        {
            fetch: createMyReadingList,
            receive: receiveMyCreateReadingList,
            error: createMyReadingListError,
        },
        createMyReadingListPromise
    );
};

export const [
    deleteReadingList,
    receiveDeleteReadingList,
    deleteReadingListError,
] = createAsyncActions(
    'DELETE_READING_LIST',
    list => list,
    ({ id, slug, message }) => ({ id, slug, message })
);

export const deleteReadingListWorkflow = list => {
    const deleteReadingListPromise = ReadingList.deleteList(list);

    return runAsyncWorkflow(
        {
            fetch: deleteReadingList,
            receive: receiveDeleteReadingList,
            error: deleteReadingListError,
        },
        deleteReadingListPromise
    );
};

export const [
    fetchReadingListsFilters,
    receiveReadingListsFilters,
    fetchReadingListsFiltersError,
] = createAsyncActions(
    'READING_LISTS_FILTERS',
    () => ({}),
    ({ data }) => ({ filters: data })
);

export const fetchReadingListsFiltersWorkflow = () => {
    const fetchPromise = ReadingList.fetchAllFilters();

    return runAsyncWorkflow(
        {
            fetch: fetchReadingListsFilters,
            receive: receiveReadingListsFilters,
            error: fetchReadingListsFiltersError,
        },
        fetchPromise
    );
};

export const manageReadingListAction = createAction(
    `MANAGE_READING_LIST`,
    payload => payload
);

export const manageReadingListActionAsync = list => {
    return dispatch => {
        dispatch(manageReadingListAction({ list }));
    };
};

export const createNewReadingListAction = createAction(
    `CREATE_NEW_READING_LIST`,
    payload => payload
);

export const createNewReadingListActionAsync = (name, desc) => {
    return dispatch => {
        dispatch(createNewReadingListAction({ name, desc }));
    };
};

export const addBookToReadingListAction = createAction(
    `ADD_BOOK_MY_READING_LIST`,
    payload => payload
);

export const addBookToReadingListActionAsync = (book, listId) => {
    return dispatch => {
        dispatch(addBookToReadingListAction({ book, listId }));
    };
};

export const removeBookFromReadingListAction = createAction(
    `REMOVE_BOOK_MY_READING_LIST`,
    payload => payload
);

export const removeBookFromReadingListActionAsync = (book, listId) => {
    return dispatch => {
        dispatch(removeBookFromReadingListAction({ book, listId }));
    };
};

export const [
    favouriteThemedBooks,
    receiveFavouriteThemedBooks,
    favouriteThemedBooksError,
] = createAsyncActions(
    'FAV_THEMED_BOOKS',
    ({ bookId }) => ({ bookId }),
    ({ ok, bookId }) => ({ ok, bookId })
); 

export const favouriteThemedBooksWorkflow = bookId => {
    const favBookPromise = ReadingList.toggleFavourite(bookId);

    return runAsyncWorkflow(
        {
            fetch: favouriteThemedBooks,
            receive: receiveFavouriteThemedBooks,
            error: favouriteThemedBooksError,
        },
        favBookPromise,
        { bookId }
    );
};
