export const makeTwitterShareUrl = (url, text) =>
    `https://twitter.com/share?url=${url}&text=${text}`;
export const makeFacebookShareUrl = url =>
    `http://www.facebook.com/sharer.php?u=${url}`;
export const makeLineShareUrl = (url, text) =>
    `https://lineit.line.me/share/ui?url=${url}&text=${text}`;
export const makePinterestShareUrl = (media, url, title) =>
    `http://pinterest.com/pin/create/bookmarklet/?media=${media}&url=${url}&description=${title}`
export const makeEmailShareUrl = (url, text) =>
    `mailto:?subject=${text}&body=${url}`;
export const makeWhatsappShareUrl = url => `https://api.whatsapp.com/send?text=${url}`;
export const makeTelegramShareUrl = (url, text) =>
    `https://t.me/share/url?url=${url}&text=${text}`;


// export const makeWhatsappShareUrl = url => `whatsapp://send?text=${url}`;
// `https://t.me/share/url?url=${url}&text=${text}&to=${to}`;
