import { handleActions } from 'redux-actions';
import u from 'updeep';
import * as actions from './helpActions';

const initialState = {
    helpStatus: false,
};

export default handleActions(
    {
        [actions.helpStatusAction]: (state, action) =>
            u(
                {
                    helpStatus: action.payload,
                },
                state
            ),
    },
    initialState
);
