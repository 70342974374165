import React, { Component } from 'react';
import PostComment from './PostComment';
import { translate } from 'react-polyglot';
import { connect } from 'react-redux';
import Pagination from '../../components/Pagination/Pagination';
import Loader from '../Loader';
import Button from '../Button';
import DeleteCommentModal from './deleteCommentModal';
import './comments.scss';
import {
    getMainComments,
    postMainComments,
    removeComments,
    postReplyComments,
    getReplyComments,
    postLikeCall,
    deleteLikeCall,
    deleteReplyComments,
    reportComment,
    deleteReportedComment,
} from '../../redux/bookCommentAction';

import LikeIcon from '../../assets/like.svg';
import CommentIcon from '../../assets/comment.svg';
import ReportIcon from '../../assets/flag.svg';
import DummyUserImage from '../../assets/user.svg';
import SvgIcon from '../SvgIcon';

const baseClassName = 'pb-comments';
let commentedAgo = '';
let commentSource = '';
const mapDispatchToProps = {
    getMainComments,
    postMainComments,
    postReplyComments,
    getReplyComments,
    removeComments,
    postLikeCall,
    deleteLikeCall,
    deleteReplyComments,
    reportComment,
    deleteReportedComment,
};

@translate()
@connect(mapStateToProps, mapDispatchToProps)
class Comments extends Component {
    constructor(props) {
        super(props);

        this.state = {
            pageNo: 1,
            ReplyPageNo: 1,
            showReply: '',
            showLikedUsers: '',
            isLike: true,
            activeCommentId: 0,
            showDeleteModal: false,
            reportedCommentId: '',
        };
    }

    componentDidMount() {
        commentSource = this.props.source;
        this.emptyComments();
    }

    fetchMainComments = () => {
        const id = this.props.id;
        const pageNo = this.state.pageNo;
        const { getMainComments } = this.props;

        getMainComments(id, pageNo, commentSource);
        this.setState({ pageNo: pageNo + 1 });
    };

    emptyComments = async () => {
        const id = this.props.id;
        const { removeComments } = this.props;

        await removeComments(id, commentSource);
        this.fetchMainComments();
    };

    postComment = (newComment) => {
        const id = this.props.id;
        const { postMainComments } = this.props;

        postMainComments(id, newComment, commentSource);
    };

    getReplyComments = (commentId) => {
        const { showReply } = this.state;
        const {
            getReplyComments,
            deleteReplyComments,
        } = this.props;

        if (showReply !== '') {
            deleteReplyComments(commentId);
            this.setState({ showReply: '', activeCommentId: commentId });
        } else {
            getReplyComments(commentId, 1);
            this.setState({
                showReply: commentId,
                ReplyPageNo: 2,
                activeCommentId: commentId,
            });
        }
    };

    getMoreReplyComments = (commentId) => {
        const { ReplyPageNo, showReply } = this.state;
        const { getReplyComments } = this.props;
        this.setState({ activeCommentId: commentId });
        getReplyComments(commentId, ReplyPageNo);
        this.setState({ showReply: commentId, ReplyPageNo: ReplyPageNo + 1 });
    };

    postReply = (commentid, newReply) => {
        const { postReplyComments } = this.props;
        postReplyComments(commentid, newReply);
    };

    postOrDeleteLike = (commentId, likedUsersList, userId, source) => {
        const {
            postLikeCall,
            deleteLikeCall
        } = this.props;
        const isLikedAlready = likedUsersList.filter(
            (list) => list.user_id === userId
        );

        this.setState({ activeCommentId: commentId });

        isLikedAlready.length === 0
            ? postLikeCall(commentId, source)
            : deleteLikeCall(commentId, source);
    };

    async likeMouseEnter(commentId) {
        await this.setState({ showLikedUsers: commentId });
        document.querySelector(`.${baseClassName}__likedUsersContainer`) &&
        document.querySelector(`.${baseClassName}__likedUsersContainer`) !==
            null
            ? (document.querySelector(
                  `.${baseClassName}__likedUsersContainer`
              ).style.marginTop = `-${
                  document.querySelector('.pb-comments__likedUsersContainer')
                      .scrollHeight
              }px`)
            : '';
    }

    likeMouseOut = () => {
        this.setState({ showLikedUsers: '' });
    };

    commentedTimeHandler = (commentedAt) => {
        let date1 = Date.now();
        let date2 = new Date(commentedAt).getTime();
        const t = this.props.t;

        const secondsDiff = (d1, d2) => {
            let millisecondDiff = d2 - d1;
            let secDiff = Math.floor((d2 - d1) / 1000);
            return secDiff;
        };

        const minutesDiff = (d1, d2) => {
            let seconds = secondsDiff(d1, d2);
            let minutesDiff = Math.floor(seconds / 60);
            return minutesDiff;
        };

        const hoursDiff = (d1, d2) => {
            let minutes = minutesDiff(d1, d2);
            let hoursDiff = Math.floor(minutes / 60);
            return hoursDiff;
        };

        const daysDiff = (d1, d2) => {
            let hours = hoursDiff(d1, d2);
            let daysDiff = Math.floor(hours / 24);
            return daysDiff;
        };

        const weeksDiff = (d1, d2) => {
            let days = daysDiff(d1, d2);
            let weeksDiff = Math.floor(days / 7);
            return weeksDiff;
        };

        const yearsDiff = (d1, d2) => {
            let date1 = new Date(d1);
            let date2 = new Date(d2);
            let yearsDiff = date2.getFullYear() - date1.getFullYear();
            return yearsDiff;
        };

        const monthsDiff = (d1, d2) => {
            let date1 = new Date(d1);
            let date2 = new Date(d2);
            let years = yearsDiff(d1, d2);
            let monthsDiff = years * 12 + (date2.getMonth() - date1.getMonth());
            return monthsDiff;
        };

        if (Math.abs(secondsDiff(date1, date2)) < 60) {
            const isValueOne = Math.abs(secondsDiff(date1, date2)) === 1;
            isValueOne
                ? (commentedAgo = t('comments.second'))
                : (commentedAgo = t('comments.seconds'));
            return Math.abs(secondsDiff(date1, date2));
        } else if (Math.abs(minutesDiff(date1, date2)) < 60) {
            const isValueOne = Math.abs(minutesDiff(date1, date2)) === 1;
            isValueOne
                ? (commentedAgo = t('comments.minute'))
                : (commentedAgo = t('comments.minutes'));
            return Math.abs(minutesDiff(date1, date2));
        } else if (Math.abs(hoursDiff(date1, date2)) < 24) {
            const isValueOne = Math.abs(hoursDiff(date1, date2)) === 1;
            isValueOne
                ? (commentedAgo = t('comments.hour'))
                : (commentedAgo = t('comments.hours'));
            return Math.abs(hoursDiff(date1, date2));
        } else if (Math.abs(daysDiff(date1, date2)) < 30) {
            const isValueOne = Math.abs(daysDiff(date1, date2)) === 1;
            isValueOne
                ? (commentedAgo = t('comments.day'))
                : (commentedAgo = t('comments.days'));
            return Math.abs(daysDiff(date1, date2));
        } else if (Math.abs(monthsDiff(date1, date2)) < 12) {
            const isValueOne = Math.abs(monthsDiff(date1, date2)) === 1;
            isValueOne
                ? (commentedAgo = t('comments.month'))
                : (commentedAgo = t('comments.months'));
            return Math.abs(monthsDiff(date1, date2));
        } else if (Math.abs(yearsDiff(date1, date2)) > 0) {
            const isValueOne = Math.abs(yearsDiff(date1, date2)) === 1;
            isValueOne
                ? (commentedAgo = t('comments.year'))
                : (commentedAgo = t('comments.years'));
            return Math.abs(yearsDiff(date1, date2));
        }
    };

    submitReportRequest = (reason) => {
        this.props.reportComment(this.state.reportedCommentId, reason);
        this.setState({ reportedCommentId: '' });
    };

    deleteComment = (commentId) => {
        this.props.deleteReportedComment(commentId);
    };

    render() {
        const {
            t,
            id,
            mainComments,
            replyComments,
            isMoreCommentButtonActive,
            profileImage,
            isMoreReplyButtonActive,
            isMainCommentLoading,
            isReplyCommentLoading,
            totalComments,
            isLikeLoading,
            roles,
            viewport,
            isReportLoading,
            isDeleteReportedCommentloading,
            isLoggedIn
        } = this.props;

        const {
            showReply,
            showLikedUsers,
            activeCommentId,
            showDeleteModal,
            reportedCommentId,
        } = this.state;

        const isRightToLeftDirection = localStorage.getItem('locale') === "ar" || localStorage.getItem('locale') === "ur"

        const isUserAdmin = () => {
            let getResult =
                roles.includes('content_manager') ||
                roles.includes('sub_admin') ||
                roles.includes('admin');

            return getResult;
        };
        return (
            <div>
                <div 
                    className={isRightToLeftDirection 
                        ? "__comments-rtl"
                        : "__comments"} 
                    data-gtm="book-disqus-comments"
                >
                    {t('Book.comments') + ' (' + mainComments.length + ')'}
                </div>
                {
                    isLoggedIn 
                    ?
                    <PostComment
                        id={id}
                        url={profileImage}
                        postComment={this.postComment}
                        t={t}
                        isLoggedIn={isLoggedIn}
                    />
                    :
                    <p className={
                        isRightToLeftDirection 
                        ? `${baseClassName}__login-message-rtl`
                        : `${baseClassName}__login-message`
                    }>
                        {t('comments.loginNotification')}
                    </p>
                }
                {isMainCommentLoading ? (
                    <Loader />
                ) : (
                    mainComments.map((data, index) => (
                        <div
                            key={index}
                            className={`${baseClassName}__individual-comments-section`}
                        >
                            <div
                                className={
                                    isRightToLeftDirection
                                    ? `${baseClassName}__commentedby-image-container-rtl`
                                    : `${baseClassName}__commentedby-image-container`
                                }
                            >
                                <SvgIcon
                                    name={data.user_image ? data.user_image : "user-image"}
                                    size="user-image"
                                />
                            </div>
                            <div
                                className={`${baseClassName}__name-and-comment-section`}
                            >
                                <h2 className={
                                    isRightToLeftDirection
                                    ? `${baseClassName}__username-rtl`
                                    : `${baseClassName}__username`
                                }>
                                    {data.user_name}
                                </h2>
                                <p className={
                                    isRightToLeftDirection
                                    ? `${baseClassName}__comment-rtl`
                                    : `${baseClassName}__comment`
                                }>
                                    {data.content}
                                </p>
                                <p className={
                                    isRightToLeftDirection
                                    ? `${baseClassName}__comment-time-rtl`
                                    : `${baseClassName}__comment-time`
                                }>
                                    {this.commentedTimeHandler(data.created_at)}{' '}
                                    {commentedAgo} {t('comments.ago')}
                                </p>
                            </div>
                            {showLikedUsers === data.id &&
                                data.likes.length > 0 && (
                                    <div
                                        onMouseOut={() => this.likeMouseOut()}
                                        className={`${baseClassName}__likedUsersContainer`}
                                    >
                                        {data.likes.map((users) => (
                                            <div>
                                                <div
                                                    className={`${baseClassName}__likedUserImage-container`}
                                                >
                                                    <img
                                                        src={
                                                            users.image
                                                                ? users.image
                                                                : DummyUserImage
                                                        }
                                                        className={`${baseClassName}__likedUserImage`}
                                                    />
                                                </div>
                                                <span
                                                    className={`${baseClassName}__likedUserName`}
                                                >
                                                    {users.name}
                                                </span>
                                            </div>
                                        ))}
                                    </div>
                                )}
                            <div
                                className={
                                    isRightToLeftDirection
                                        ? `${baseClassName}__likeAndReplyBar-rtl`
                                        : `${baseClassName}__likeAndReplyBar`
                                }
                            >
                                { isLoggedIn ? <div
                                    onMouseEnter={() =>
                                        this.likeMouseEnter(data.id)
                                    }
                                    onMouseOut={() => this.likeMouseOut()}
                                    className={`${baseClassName}__likeReplyContainer`}
                                >
                                    {isLikeLoading &&
                                    activeCommentId === data.id ? (
                                        <Loader />
                                    ) : (
                                        <img
                                            src={LikeIcon}
                                            className={`${baseClassName}_likeButton`}
                                            onMouseEnter={() =>
                                                this.likeMouseEnter(data.id)
                                            }
                                            onMouseOut={() =>
                                                this.likeMouseOut()
                                            }
                                            onClick={() =>
                                                this.postOrDeleteLike(
                                                    data.id,
                                                    data.likes,
                                                    data.user_id,
                                                    'mainComment'
                                                )
                                            }
                                        />
                                    )}
                                    <div
                                        onMouseEnter={() =>
                                            this.likeMouseEnter(data.id)
                                        }
                                        onMouseOut={() => this.likeMouseOut()}
                                        onClick={() =>
                                            this.postOrDeleteLike(
                                                data.id,
                                                data.likes,
                                                data.user_id,
                                                'mainComment'
                                            )
                                        }
                                    >
                                        {data.likes.length}
                                    </div>
                                </div> : null}
                                <div
                                    className={`${baseClassName}__comments-reply-container`}
                                >
                                    {isReplyCommentLoading &&
                                    activeCommentId === data.id ? (
                                        <Loader />
                                    ) : (
                                        <img
                                            src={CommentIcon}
                                            className={`${baseClassName}_likeButton`}
                                            onClick={() =>
                                                this.getReplyComments(data.id)
                                            }
                                        />
                                    )}
                                    <div>
                                        {t('comments.reply')} (
                                        {replyComments.length > 0 &&
                                        replyComments[0].parent_id === data.id
                                            ? replyComments.length
                                            : data.reply_commments}
                                        )
                                    </div>
                                </div>
                                {isReportLoading &&
                                activeCommentId === data.id ? (
                                    <Loader />
                                ) : (
                                    isLoggedIn ? <div
                                        className={`${baseClassName}__comments-report-container`}
                                    >
                                        <img
                                            src={ReportIcon}
                                            className={`${baseClassName}_likeButton`}
                                            onClick={() =>
                                                this.setState({
                                                    showDeleteModal: true,
                                                    reportedCommentId: data.id,
                                                    activeCommentId: data.id,
                                                })
                                            }
                                        />
                                        <div>{t('comments.report')}</div>
                                    </div>: null
                                )}
                                {isUserAdmin() && data.report_content && (
                                    <Button
                                        label={t('comments.delete')}
                                        variant="report"
                                        onClick={() =>
                                            this.deleteComment(data.id)
                                        }
                                    />
                                )}
                            </div>
                            <div
                                className={
                                    isRightToLeftDirection
                                    ? `${baseClassName}__individual-reply-section-rtl`
                                    : `${baseClassName}__individual-reply-section`
                                }
                            >
                                {showReply === data.id && (
                                    isLoggedIn ? <PostComment
                                        id={data.id}
                                        url={profileImage}
                                        postReply={this.postReply}
                                        t={t}
                                        isLoggedIn={isLoggedIn}
                                    /> : null
                                )}
                                {isReplyCommentLoading ? (
                                    <Loader />
                                ) : (
                                    showReply === data.id &&
                                    replyComments
                                        .filter(
                                            (replies) =>
                                                replies.parent_id === data.id
                                        )
                                        .map((reply, index) => (
                                            <div>
                                                <SvgIcon 
                                                    replyArrow
                                                    name={isRightToLeftDirection ? "Reply-blue-rtl" : "Reply-blue-ltr"} 
                                                    size="si"
                                                    />
                                                <div
                                                    key={index}
                                                    className={`${baseClassName}__reply-container`}
                                                >
                                                    
                                                    <div
                                                        className={
                                                            isRightToLeftDirection
                                                            ? `${baseClassName}__repliedby-image-container-rtl`
                                                            : `${baseClassName}__repliedby-image-container`
                                                        }
                                                    >
                                                        <SvgIcon
                                                            name={reply.user_image ? reply.user_image : "user-image"}
                                                            size="user-image"
                                                        />
                                                    </div>
                                                    <div
                                                        className={`${baseClassName}__name-and-reply-section`}
                                                    >
                                                        <h2
                                                            className={
                                                                isRightToLeftDirection
                                                                ? `${baseClassName}__username-reply-rtl`
                                                                : `${baseClassName}__username-reply`
                                                            }
                                                        >
                                                            {reply.user_name}
                                                        </h2>
                                                        <p
                                                            className={
                                                                isRightToLeftDirection
                                                                ? `${baseClassName}__reply-rtl`
                                                                : `${baseClassName}__reply`
                                                            }
                                                        >
                                                            {reply.content}
                                                        </p>
                                                        <p
                                                            className={
                                                                isRightToLeftDirection
                                                                ? `${baseClassName}__reply-time-rtl`
                                                                : `${baseClassName}__reply-time`
                                                            }
                                                        >
                                                            {this.commentedTimeHandler(
                                                                reply.created_at
                                                            )}{' '}
                                                            {commentedAgo} ago
                                                        </p>
                                                    </div>
                                                    {showLikedUsers === reply.id &&
                                                        reply.likes.length > 0 && (
                                                            <div
                                                                onMouseOut={() =>
                                                                    this.likeMouseOut()
                                                                }
                                                                className={`${baseClassName}__likedUsersContainer`}
                                                            >
                                                                {reply.likes.map(
                                                                    (
                                                                        likeedUsers
                                                                    ) => (
                                                                        <div>
                                                                            <div
                                                                                className={`${baseClassName}__likedUserImage-container`}
                                                                            >
                                                                                <img
                                                                                    src={
                                                                                        likeedUsers.image
                                                                                            ? likeedUsers.image
                                                                                            : DummyUserImage
                                                                                    }
                                                                                    className={`${baseClassName}__likedUserImage`}
                                                                                />
                                                                            </div>
                                                                            <span
                                                                                className={`${baseClassName}__likedUserName`}
                                                                            >
                                                                                {
                                                                                    likeedUsers.name
                                                                                }
                                                                            </span>
                                                                        </div>
                                                                    )
                                                                )}
                                                            </div>
                                                        )}
                                                    {isLoggedIn ? <div
                                                        className={
                                                            isRightToLeftDirection
                                                            ? `${baseClassName}__repliedby-like-container-rtl`
                                                            : `${baseClassName}__repliedby-like-container`
                                                        }
                                                    >
                                                        <div
                                                            onMouseEnter={() =>
                                                                this.likeMouseEnter(
                                                                    reply.id
                                                                )
                                                            }
                                                            onMouseOut={() =>
                                                                this.likeMouseOut()
                                                            }
                                                            className={`${baseClassName}__likeReplyContainer`}
                                                        >
                                                            {isLikeLoading &&
                                                            activeCommentId ===
                                                                reply.id ? (
                                                                <Loader />
                                                            ) : (
                                                                <img
                                                                    src={LikeIcon}
                                                                    className={`${baseClassName}_likeButton`}
                                                                    onMouseEnter={() =>
                                                                        this.likeMouseEnter(
                                                                            reply.id
                                                                        )
                                                                    }
                                                                    onMouseOut={() =>
                                                                        this.likeMouseOut()
                                                                    }
                                                                    onClick={() =>
                                                                        this.postOrDeleteLike(
                                                                            reply.id,
                                                                            reply.likes,
                                                                            reply.user_id,
                                                                            'reply'
                                                                        )
                                                                    }
                                                                />
                                                            )}
                                                            <div
                                                                onMouseEnter={() =>
                                                                    this.likeMouseEnter(
                                                                        reply.id
                                                                    )
                                                                }
                                                                onMouseOut={() =>
                                                                    this.likeMouseOut()
                                                                }
                                                                onClick={() =>
                                                                    this.postOrDeleteLike(
                                                                        reply.id,
                                                                        reply.likes,
                                                                        reply.user_id,
                                                                        'reply'
                                                                    )
                                                                }
                                                            >
                                                                {reply.likes.length}
                                                            </div>
                                                        </div>
                                                        <div
                                                            className={`${baseClassName}__comments-report-container`}
                                                        >
                                                            <img
                                                                src={ReportIcon}
                                                                className={`${baseClassName}_likeButton`}
                                                                onClick={() =>
                                                                    this.setState({
                                                                        showDeleteModal: true,
                                                                        reportedCommentId:
                                                                            reply.id,
                                                                    })
                                                                }
                                                            />
                                                            <div>
                                                                {t(
                                                                    'comments.report'
                                                                )}
                                                            </div>
                                                        </div>
                                                        {isUserAdmin() &&
                                                            reply.report_content && (
                                                                <Button
                                                                    label={t(
                                                                        'comments.delete'
                                                                    )}
                                                                    variant="report"
                                                                    onClick={() =>
                                                                        this.deleteComment(
                                                                            reply.id
                                                                        )
                                                                    }
                                                                />
                                                            )}
                                                    </div> : null}
                                                </div>
                                            </div>
                                        ))
                                )}
                            </div>
                            {isMoreReplyButtonActive &&
                                replyComments.filter(
                                    (replies) => replies.parent_id === data.id
                                ).length > 0 && (
                                    <div>
                                        <Pagination
                                            label="Show More Replies"
                                            onClick={() =>
                                                this.getMoreReplyComments(
                                                    data.id
                                                )
                                            }
                                        />
                                    </div>
                                )}
                        </div>
                    ))
                )}

                {isMoreCommentButtonActive && (
                    <div>
                        <Pagination
                            label="Show More Comments"
                            onClick={() => this.fetchMainComments()}
                        />
                    </div>
                )}
                {this.state.showDeleteModal ? (
                    <DeleteCommentModal
                        onConfirm={(reason) => this.submitReportRequest(reason)}
                        onClose={() =>
                            this.setState({ showDeleteModal: false })
                        }
                        viewport={viewport}
                        isDeleteReportedCommentloading={
                            isDeleteReportedCommentloading
                        }
                    />
                ) : null}
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        mainComments: state.bookComments.mainComments,
        replyComments: state.bookComments.replyComments,
        isMoreCommentButtonActive: state.bookComments.isMoreCommentButtonActive,
        isMoreReplyButtonActive: state.bookComments.isMoreReplyButtonActive,
        profileImage: state.user.profile.profileImage,
        isMainCommentLoading: state.bookComments.isMainCommentLoading,
        isReplyCommentLoading: state.bookComments.isReplyCommentLoading,
        totalComments: state.bookComments.totalComments,
        isLikeLoading: state.bookComments.isLikeLoading,
        isReplyCommentLoading: state.bookComments.isReplyCommentLoading,
        isReportLoading: state.bookComments.isReportLoading,
        isDeleteReportedCommentloading:
            state.bookComments.isDeleteReportedCommentloading,
    };
};

export default translate()(connect(mapStateToProps)(Comments));
