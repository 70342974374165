import React, { Component } from 'react';
import './RTRBookReaderContent.scss';
import Link from '../../../Link';
import SvgIcon from '../../../SvgIcon';
import BookTags from '../../../Book/BookTags';

class RTRBookClosingPage extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }


    render() {
        const { isRightToLeftDirection, t, bookData, online } = this.props;
        return (
            <div
                className="bookReaderPageWrapper"
                style={{ height: '80vh', width: '99%', marginLeft: '1%' }}
            >
                <div className="RightBookPage closingPage">
                    <div className="closingPageHeader">
                        <div className="RTRLogo" style={{margin:'0.5rem auto'}}>
                            {' '}
                            
                            <SvgIcon 
                                name={"rtr-logo"} 
                                size="rtr-logo"
                            />
                        </div>
                        {/* <div className="closingDescription">
                            {this.props.t('Book.closingHeaderDescription')}
                        </div> */}
                    </div>
                    <div className="closingPageContent">
                        {/* {this.props.book && this.props.book.similarBooks && (
                            <SimilarBooks
                                myLanguage={myLanguage}
                                offline={!this.props.online}
                                similarBooks={this.props.book.similarBooks}
                                viewport={this.props.viewport}
                                t={this.props.t}
                                isBookReaderPage={true}
                                lastPage
                            />
                        )} */}
                        <BookTags
                            tags={this.props.book.tags}
                            online={online}
                            t={t}
                            type={'storyReaderFinalPage'}
                        />
                        <div className="closingPageContentData">
                            <div className={isRightToLeftDirection ? "closingPageContentDataTitle-Rtl" : "closingPageContentDataTitle"}>
                                {t('book.storyFinalPageContentTitle')}
                            </div>
                            <div className={isRightToLeftDirection ? "closingPageContentDataPara1-Rtl" : "closingPageContentDataPara1"}>
                                {t('book.storyFinalPageContentPara1')}
                            </div>
                            <div className={isRightToLeftDirection ? "closingPageContentDataPara2-Rtl" : "closingPageContentDataPara2"}>
                                {t('book.storyFinalPageContentPara2')}
                            </div>
                        </div>
                    </div>
                    <div className="closingPageFooter" style={{paddingBottom:'1.5rem'}}>
                        <p className="disclaimerLink">
                            <span>
                                {t('Book.contentFooterDescriptionDisclaimer')}
                            </span>
                            <Link
                                className="LicenceDiscriptionLink"
                                href={'https://literacycloud.org/terms-and-conditions'}
                            >
                                {' '}
                                {'https://literacycloud.org/terms-and-conditions'}
                            </Link>
                        </p>
                        <p className="bookLicenseContainer">
                        {
                            this.props.bookData.license_type.image_url && 
                            <img
                                className="bookLicense"
                                src={ bookData.license_type.image_url }
                                alt="license"
                            />
                        }
                        </p>
                        <p className="licenseDescription">
                            { bookData.license_type.message}
                        </p>
                    </div>
                </div>
            </div>
        );
    }
}

export default RTRBookClosingPage;
