import React, { Component } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { translate } from 'react-polyglot';
import { connect } from 'react-redux';
import DocumentTitle from 'react-document-title';
import './ManageReadingList.scss';
import Link from '../Link';
import Block from '../Block';
import Grid from '../Grid';
import ManageBookCard from '../ManageBookCard';
import ContentStyler from '../ContentStyler';
import { fetchManageReadingListWorkflow } from '../../redux/readingListsActions';
import { API_URL } from '../../api';
import withProps from "../../util/withRouter"
class ManageReadingList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            bookSlug: window.location.href.split('/').slice(4),
            disable: false
        };
    }

    nextPath(path) {
        this.props.history.push(path);
    }

    manageReadingList = () => {
        let currentThis = this;
        let readingList = this.props.newList;
        console.log("readingList", readingList)
        fetch(`${API_URL}/lists/${readingList.slug}`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
            },
            credentials: 'include',
            body: JSON.stringify({
                title: readingList.title,
                description: readingList.description,
                books: readingList.books.map((item) => item.slug),
            }),
        }).then(response => response.json())
            .then(response => {
                if (response.ok && response.data) {
                    setTimeout(function () {
                        currentThis.nextPath("/myreadinglists")
                    }
                        , 2500);
                }
                else {
                    alert("failed");
                }
            }
            );
    };

    render() {
        const baseClassName = 'pb-manage-reading-list';
        const {
            parentClassName,
            t,
            manageReadingList,
            BookAddedToList,
            manageReadingListBookCount,
        } = this.props;

        const classes = {
            [baseClassName]: true,
            [parentClassName]: parentClassName,
        };

        return (
            <div className={classNames(classes)}>
                <div className="__manage-readinglist-section">
                    <DocumentTitle
                        title={`${t('Readinglist.manage-reading-list')} - ${t(
                            'global.site-title'
                        )}`}
                    />
                    <div className="__themedReadingLists-section">
                        <div className="__themedReadingListItem-container">
                            <div className="__themed-readinglist-section-header">
                                {manageReadingList && (
                                    <Block background="transparent">
                                        <ContentStyler manageReadingList>
                                            <div className="__header-section">
                                                <div>
                                                    <div className="__title">
                                                        <h3>
                                                            {
                                                                manageReadingList.title
                                                            }
                                                        </h3>
                                                        <h3 className="__readinglist-count">
                                                            (
                                                            {
                                                                manageReadingListBookCount
                                                            }
                                                            )
                                                        </h3>
                                                    </div>
                                                    <div className="__manage-description-text">
                                                        {
                                                            manageReadingList.description
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="__header-button-section">
                                                {BookAddedToList.length <= 0 ? (
                                                    <div className="__disabled-button">
                                                        <span
                                                            className="__done-button-disabled"

                                                        >
                                                            {t('ReadingList.done')}
                                                        </span>
                                                    </div>
                                                ) : (
                                                    <div className="__done-button-section">
                                                        {
                                                            this.state.disable ?
                                                                <span
                                                                    className="__done-button-disabled"
                                                                    data-gtm="done"
                                                                >
                                                                    <img src="/public/loader.svg" alt="loader" className="svgLoaderRL" style={{ margin: "0px 5px" }} />
                                                                    {t(
                                                                        'ReadingList.done'
                                                                    )}
                                                                </span>
                                                                :
                                                                <span
                                                                    className="__done-button"
                                                                    onClick={() => {
                                                                        this.manageReadingList();
                                                                        this.setState({ disable: true });
                                                                    }}
                                                                    data-gtm="done"
                                                                >
                                                                    {t(
                                                                        'ReadingList.done'
                                                                    )}
                                                                </span>
                                                        }

                                                    </div>
                                                )}
                                                {
                                                    this.state.disable ?
                                                        <div
                                                            className="__manage-button-disabled"
                                                            data-gtm="add-books"
                                                        >
                                                            {this.props.t(
                                                                'Readinglist.add-books'
                                                            )}
                                                        </div>
                                                        :
                                                        <Link
                                                            href={
                                                                '/myreadinglists/addbooks'
                                                            }
                                                            isInternal
                                                            dataGTM="add-books"
                                                        >
                                                            <div
                                                                className="__manage-button"
                                                                data-gtm="add-books"
                                                            >
                                                                {this.props.t(
                                                                    'Readinglist.add-books'
                                                                )}
                                                            </div>
                                                        </Link>
                                                }
                                            </div>
                                        </ContentStyler>
                                    </Block>
                                )}
                                <div>
                                    <Grid variant="4up">
                                        {manageReadingList.books &&
                                            manageReadingList.books.map(
                                                bookItem => {
                                                    return (
                                                        <div className="__themedBookItem-section">
                                                            <ManageBookCard
                                                                key={
                                                                    bookItem.id
                                                                }
                                                                book={bookItem}
                                                                manageReadingListId={
                                                                    manageReadingList.id
                                                                }
                                                                isManageReadingList
                                                                addBookImageGTM="manage-book-image"
                                                                asdBookTitleGTM="manage-book-title"
                                                                addBookAuthorGTM="manage-book-author"
                                                                addBookIllustratorsGTM="manage-book-illustrator"
                                                                addBookLevelGTM="manage-book-level"
                                                                removeFromBookListGTM="remove-from-book-list"
                                                                addToBookListGTM="add-to-book-list"
                                                            />
                                                        </div>
                                                    );
                                                }
                                            )}
                                    </Grid>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* )} */}
                </div>
            </div >
        );
    }
}

ManageReadingList.propTypes = {
    parentClassName: PropTypes.string,
    fetchManageReadingListWorkflow: PropTypes.func.isRequired,
};

const mapStateToProps = ({ readingLists }) => {
    return {
        manageReadingList: readingLists.newList,
        manageReadingListBookCount: readingLists.newList.books.length,
        newList: readingLists.newList,
        BookAddedToList: readingLists.newList.books,
    };
};

const mapDispatchToProps = {
    fetchManageReadingListWorkflow,
};

export default withProps(
    translate()(connect(mapStateToProps, mapDispatchToProps)(ManageReadingList))
);
