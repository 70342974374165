import React from 'react';
import PropTypes from 'prop-types';
import LazyLoad from 'react-lazyload';
import classNames from 'classnames';
import {
    getLargestImage,
    getSmallestImage,
    generateSrcSet,
} from '../../lib/images';

import './Img.scss';

const Img = ({
    image,
    parentClassName,
    alt,
    sizes,
    lazyLoad,
    useNativeAspectRatio,
    onLoad,
    debug,
    noSrcSet,
    bookDetails,
    carousel,
    dataGTM,
    padding130,
    bannerImage,
}) => {
    const log = (...params) => {
        if (debug) {
            console.log('<Img>', ...params);
        }
    };

    const aspectRatio = (width, height) => width / height;
    const isLandscape = (width, height) => width > height;

    const getSrcSetSizes = (aspectRatio) => {
        if (!sizes) return;

        const srcset = Object.keys(sizes).map((s) => {
            let unit = 'vw';
            let width = sizes[s];
            if (sizes[s].value && sizes[s].unit) {
                width = sizes[s].value;
                unit = sizes[s].unit;
            }
            if (aspectRatio) {
                width = Math.ceil(width * aspectRatio);
            }
            const viewportSize = s !== 'default' ? `${s} ` : '';
            return `${viewportSize}${width}${unit}`;
        });

        return srcset.join(', ');
    };

    const cropCoordsWithRespectToCrop = (
        cropCoords,
        aspectRatio,
        imageWidth,
        imageHeight
    ) => {
        const destCropCoords = { x: 0, y: 0 };
        const isLandscapeImage = isLandscape(imageWidth, imageHeight);
        const pseudoDestHeight = isLandscapeImage ? imageHeight : imageWidth / aspectRatio;
        const pseudoDestWidth = isLandscapeImage ? pseudoDestHeight * aspectRatio : imageWidth;

        if (cropCoords.x && cropCoords.x > 0) {
            destCropCoords.x = (imageWidth * cropCoords.x) / pseudoDestWidth;
        }
        if (cropCoords.y && cropCoords.y > 0) {
            destCropCoords.y = (imageHeight * cropCoords.y) / pseudoDestHeight;
        }
        return destCropCoords;
    };

    if (!image.sizes || image.sizes.length === 0) return null;

    const srcSet = image.thumbnailSizes === "null" ? generateSrcSet(image.sizes) : generateSrcSet(image.thumbnailSizes);
    log(srcSet);

    let srcUrl, fallbackImage;
    if (noSrcSet) {
        fallbackImage = getLargestImage(image.sizes);
    } else if (bannerImage) {
        fallbackImage = image.sizes[3];
    } else {
        fallbackImage = getSmallestImage(image.sizes);
    }

    let isImgLandscape = false;
    let imageAspectRatio = 1;
    if (fallbackImage) {
        srcUrl = fallbackImage.url;
        isImgLandscape = isLandscape(fallbackImage.width, fallbackImage.height);
        imageAspectRatio = aspectRatio(fallbackImage.width, fallbackImage.height);
    }
    log('srcUrl', srcUrl);
    log('isImgLandscape', isImgLandscape);
    log('imageAspectRatio', imageAspectRatio);

    let destAspectRatio = useNativeAspectRatio ? imageAspectRatio : image.aspectRatio || 1;
    log('destAspectRatio', destAspectRatio);

    const { x: xAdjust = 0, y: yAdjust = 0 } = image.cropCoords
        ? cropCoordsWithRespectToCrop(image.cropCoords, destAspectRatio, fallbackImage.width, fallbackImage.height)
        : {};

    const paddingTop = (1 / destAspectRatio) * 100;

    const imagesStyles = { top: yAdjust ? `-${yAdjust * 100}%` : 0 };
    const imgSizing = useNativeAspectRatio ? getSrcSetSizes() : isImgLandscape && imageAspectRatio >= destAspectRatio
        ? getSrcSetSizes(imageAspectRatio)
        : getSrcSetSizes();

    imagesStyles[isImgLandscape && imageAspectRatio >= destAspectRatio ? 'height' : 'width'] = '100%';

    const imageClasses = {
        'pb-img__img': true,
        'pb-img__carousel': carousel,
    };

    const imageEl = (
        <img
            className={classNames(imageClasses)}
            src={srcUrl}
            srcSet={noSrcSet ? null : srcSet}
            sizes={noSrcSet ? null : imgSizing}
            style={imagesStyles}
            alt={alt}
            onLoad={onLoad}
            data-gtm={dataGTM}
        />
    );

    return (
        <div className={`${parentClassName || ''} pb-img`} data-gtm={dataGTM}>
            <div
                className={padding130 ? "pb-img__wrapper" : "pb-img__wrapper padding-130"}
                style={{ paddingTop: `${paddingTop}%` }}
                data-gtm={dataGTM}
            >
                {!noSrcSet && lazyLoad ? (
                    <LazyLoad offset={250} once>
                        {imageEl}
                    </LazyLoad>
                ) : (
                    imageEl
                )}
            </div>
        </div>
    );
};

Img.propTypes = {
    image: PropTypes.object.isRequired,
    parentClassName: PropTypes.string,
    alt: PropTypes.string,
    sizes: PropTypes.object,
    lazyLoad: PropTypes.bool,
    useNativeAspectRatio: PropTypes.bool,
    onLoad: PropTypes.func,
    debug: PropTypes.bool,
    noSrcSet: PropTypes.bool,
    carousel: PropTypes.bool,
};

export default Img;
