import { createAsyncActions, runAsyncWorkflow } from '../lib/reduxHelpers';

import { Book } from '../api';

export const [
    fetchAllBooksFilters,
    receiveAllBooksFilters,
    fetchAllBooksFiltersError,
] = createAsyncActions(
    'ALL_BOOKS_FILTERS',
    () => ({}),
    ({ data }) => ({ filters: data })
);

export const fetchAllBooksFiltersWorkflow = () => {
    const fetchPromise = Book.fetchAllFilters();

    return runAsyncWorkflow(
        {
            fetch: fetchAllBooksFilters,
            receive: receiveAllBooksFilters,
            error: fetchAllBooksFiltersError,
        },
        fetchPromise
    );
};

export const [
    fetchAllBooks,
    receiveAllBooks,
    fetchAllBooksError,
] = createAsyncActions(
    'ALL_BOOKS',
    ({ page, perPage }) => ({ page, perPage }),
    ({ data, metadata }) => ({ books: data, metadata })
);

export const [
    fetchMoreAllBooks,
    receiveMoreAllBooks,
    fetchMoreAllBooksError,
] = createAsyncActions(
    'MORE_ALL_BOOKS',
    ({ page, perPage }) => ({ page, perPage }),
    ({ data, metadata }) => ({ books: data, metadata })
);

export const fetchAllBooksWorkflow = (appliedFilters, page, perPage) => {
    const fetchingMore = page && page > 1;
    const fetchPromise = Book.fetchAll(appliedFilters, page, perPage);
    return runAsyncWorkflow(
        {
            fetch: fetchingMore ? fetchMoreAllBooks : fetchAllBooks,
            receive: fetchingMore ? receiveMoreAllBooks : receiveAllBooks,
            error: fetchingMore ? fetchMoreAllBooksError : fetchAllBooksError,
        },
        fetchPromise
    );
};



export const [
    fetchAllRecommendedBooks,
    receiveAllRecommendedBooks,
    fetchAllRecommendedBooksError,
] = createAsyncActions(
    'ALL_RECOMMENDED_BOOKS',
    ({ page, perPage }) => ({ page, perPage }),
    ({ data, metadata }) => ({ books: data, metadata })
);

export const [
    fetchMoreAllRecommendedBooks,
    receiveMoreAllRecommendedBooks,
    fetchMoreAllRecommendedBooksError,
] = createAsyncActions(
    'MORE_ALL_RECOMMENDED_BOOKS',
    ({ page, perPage }) => ({ page, perPage }),
    ({ data, metadata }) => ({ books: data, metadata })
);

export const fetchAllRecommendedBooksWorkflow = (appliedFilters, page, perPage) => {
    const fetchingMore = page && page > 1;
    const fetchPromise = Book.fetchAllRecommended(appliedFilters, page, perPage);
    return runAsyncWorkflow(
        {
            fetch: fetchingMore ? fetchMoreAllRecommendedBooks : fetchAllRecommendedBooks,
            receive: fetchingMore ? receiveMoreAllRecommendedBooks : receiveAllRecommendedBooks,
            error: fetchingMore ? fetchMoreAllRecommendedBooksError : fetchAllRecommendedBooksError,
        },
        fetchPromise
    );
};


export const [
    fetchAllTrendingBooks,
    receiveAllTrendingBooks,
    fetchAllTrendingBooksError,
] = createAsyncActions(
    'ALL_TRENDING_BOOKS',
    ({ page, perPage }) => ({ page, perPage }),
    ({ data, metadata }) => ({ books: data, metadata })
);

export const [
    fetchMoreAllTrendingBooks,
    receiveMoreAllTrendingBooks,
    fetchMoreAllTrendingBooksError,
] = createAsyncActions(
    'MORE_ALL_TRENDING_BOOKS',
    ({ page, perPage }) => ({ page, perPage }),
    ({ data, metadata }) => ({ books: data, metadata })
);

export const fetchAllTrendingBooksWorkflow = (appliedFilters, page, perPage) => {
    const fetchingMore = page && page > 1;
    const fetchPromise = Book.fetchAllTrending(appliedFilters, page, perPage);
    return runAsyncWorkflow(
        {
            fetch: fetchingMore ? fetchMoreAllTrendingBooks : fetchAllTrendingBooks,
            receive: fetchingMore ? receiveMoreAllTrendingBooks : receiveAllTrendingBooks,
            error: fetchingMore ? fetchMoreAllTrendingBooksError : fetchAllTrendingBooksError,
        },
        fetchPromise
    );
};

//favourite clicks handling action while listing all books
export const [
    favouriteAllBooks,
    receiveFavouriteAllBooks,
    favouriteAllBooksError,
] = createAsyncActions(
    'FAV_ALL_BOOKS',
    ({ bookId }) => ({ bookId }),
    ({ ok, bookId }) => ({ ok, bookId })
);

export const favouriteAllBooksWorkflow = bookId => {
    const favBookPromise = Book.toggleFavourite(bookId);

    return runAsyncWorkflow(
        {
            fetch: favouriteAllBooks,
            receive: receiveFavouriteAllBooks,
            error: favouriteAllBooksError,
        },
        favBookPromise,
        { bookId }
    );
};

