import React, { Component } from 'react';
import { connect } from 'react-redux';
import { translate } from 'react-polyglot';
import u from 'updeep';
import LoaderBlock from '../LoaderBlock';
import Pagination from '../Pagination';
import BookCard from '../BookCard';
import Grid from '../Grid';
import { fetchReferencesWorkflow } from '../../redux/referencesActions';
import { recordGaEvents } from '../../redux/googleAnalyticsActions';
import { addSlimNotification } from '../../redux/slimNotificationActions';
import ContentStyler from '../ContentStyler';
import Block from '../Block';
import ResourceCard from '../ResourceCard';
import {
    sortOptions,
} from '../../lib/constants';

import './BooksSearchResultsContainer.scss';

const ResourceGridEl = ({ references, t }) => {
    const resourceEls = references.map(referenceItem => {
        return (
            <div key={referenceItem.id}>
                <ResourceCard
                    id={referenceItem.id}
                    slug={referenceItem.slug}
                    title={referenceItem.title}
                    contentUrl={referenceItem.contentUrl}
                    likesCount={referenceItem.likesCount}
                    readsCount={referenceItem.readsCount}
                    isFavourite={referenceItem.is_favourite}
                    coverImage={referenceItem.thumbUrl}
                    resourceThumbnailGTM="search-resource-thumbnail"
                    resourceTitleGTM="search-resource-title"
                />
            </div>
        );
    });
    // RB changes
    return <Grid variant="4up">{resourceEls}</Grid>;
};

const NoResults = translate()(({ t }) => (
    <span>
        <p>{t('Books.no-result-warning-1')}</p>
        <p>{t('Books.no-result-warning-2')}</p>
        <ul>
            <li>{t('Books.no-result-warning-3')}</li>
            <li>{t('Books.no-result-warning-4')}</li>
            <li>{t('Books.no-result-warning-5')}</li>
            <li>{t('Books.no-result-warning-6')}</li>
        </ul>
    </span>
));

const mapDispatchToProps = {
    fetchReferencesWorkflow,
    recordGaEvents,
    addSlimNotification,
};

const mapStateToProps = ({
    references,
    viewport,
    allFilters: { readFilters },
}) => ({
    references: references.references,
    isFetchingReferences: references.isFetchingReferences,
    isFetchingMoreReferences: references.isFetchingMoreReferences,
    loadedPages: references.loadedPages,
    totalReferencesCount: references.totalReferencesCount,
    hits: references.resourceHits,
    viewport,
    appliedFilters: readFilters,
});

@translate()
@connect(
    mapStateToProps,
    mapDispatchToProps
)
class BooksSearchResultsContainer extends Component {
    constructor(props) {
        super(props);

        const { t } = props;

        this.state = {
            // The filters bar allows searching through available
            // filter values. We store that search text here.
            filtersSearchText: {
                category: '',
                publisher: '',
                level: '',
                language: '',
            },
        };

        this.filtersBarSortOptions = [
            {
                name: t('sort.most-liked'),
                queryValue: sortOptions.mostLiked,
            },
            {
                name: t('sort.most-read'),
                queryValue: sortOptions.mostRead,
            },
        ];

        // FiltersBar uses a SelectBox to display sort options, whereas
        // FiltersPanel uses a RadioGroup. Both these components expect
        // different props to display options. We do the transformation
        // here, before passing the props down.
        this.filtersPanelSortOptions = this.props.translateFiltersBarSortOptionsToFiltersPanelSortOptions(
            this.filtersBarSortOptions
        );

    }

    // Updates filter bar search text for a specified filter.
    updateFiltersSearchText = (key, text) => {
        this.setState(
            u(
                {
                    filtersSearchText: {
                        [key]: text,
                    },
                },
                this.state
            )
        );
    };


    // Convenience methods for updating filter bar search text for
    // our current list of filters.
    updateCategorySearchText = e =>
        this.updateFiltersSearchText('category', e.target.value);
    updatePublisherSearchText = e =>
        this.updateFiltersSearchText('publisher', e.target.value);
    updateLevelSearchText = e =>
        this.updateFiltersSearchText('level', e.target.value);
    updateLanguageSearchText = e =>
        this.updateFiltersSearchText('language', e.target.value);

    onLoadMore = () =>
        this.props.fetchVideosWorkflow(
            this.props.appliedFilters,
            this.props.loadedPages + 1
        );

    renderBookEls() {
        const { books } = this.props;

        return books.map(book => {
            return (
                <BookCard
                    key={book.slug}
                    id={book.id}
                    book={book}
                    faded={book.storyDownloaded}
                    bookImageGTM="books-book-image"
                    bookTitleGTM="books-book-title"
                    bookAuthorGTM="books-book-author"
                    bookIllustratorsGTM="books-book-Illustrators"
                    bookLevelGTM="books-book-Level"
                />
            );
        });
    }

    componentDidMount() {
        const { appliedFilters } = this.props;
        // First fetch available filters, then fetch the books.
        this.props.fetchReferencesWorkflow(appliedFilters);
    }

    render() {
        const {
            t,
            isFetchingReferences,
            references,
            totalReferencesCount,
            hits,
        } = this.props;

        const myLanguage = localStorage.getItem('locale');

        const shouldShowLoadMore =
            this.props.loadedPages &&
            this.props.loadedPages < this.props.totalReferencesCount;

        const resourceTitleEl = this.props.searchQuery ? (
            <h3>
                { myLanguage !== 'ne' 
                    ?
                        `${hits} ${t('Search.results-for-count')} \u2018${this.props.searchQuery}\u2019` 
                    :
                        `\u2018${this.props.searchQuery}\u2019 ${t('Search.results-for-count-1')} ${hits} ${t('Search.results-for-count-2')}`
                }
            </h3>
        ) : (
            [
                <h3>{t('global.resources')}</h3>,
                <h3 className="__normal">({hits})</h3>,
            ]
        );

        return (
            <Block background="transparent">
                <ContentStyler>
                    <div className="__search-result-title">
                        {resourceTitleEl}
                    </div>
                </ContentStyler>
                <div className="book-section">
                    {isFetchingReferences || !references ? (
                        <LoaderBlock />
                    ) : (
                        <div>
                            {references.length > 0 ? (
                                <div>
                                    <ResourceGridEl
                                        references={references}
                                        t={t}
                                    />
                                    {shouldShowLoadMore ? (
                                        <Pagination
                                            onClick={this.onLoadMore}
                                            loading={
                                                this.props
                                                    .isFetchingMoreReferences
                                            }
                                            dataGTM="resources-view-more"
                                        />
                                    ) : null}
                                </div>
                            ) : null}
                            {totalReferencesCount ? null : <NoResults />}
                        </div>
                    )}
                </div>
            </Block>
        );
    }
}

BooksSearchResultsContainer.propTypes = {};

export default BooksSearchResultsContainer;
