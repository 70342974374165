import React from 'react';

const LargeScreenHeader = ({
    classNames,
    baseClassName,
    offline,
    siteNavLinks,
    searchEl,
    searchClass,
    searchModalToggle,
    rightClasses,
    languageClass,
    languageSelectEl,
    menuOpen,
    onMenuToggle,
    userInfoEls,
}) => {
    const centerClasses = {
        [`${baseClassName}__center`]: true,
        [`${baseClassName}__hidden`]: offline,
    };
    return (
        <React.Fragment>
            <div className={classNames(centerClasses)}>{siteNavLinks}</div>
            <div className={classNames(rightClasses)}>
                <div
                    className={searchClass.join(' ')}
                    onClick={searchModalToggle}
                >
                    {searchEl}
                </div>
                <div className={languageClass.join(' ')}>
                    {languageSelectEl}
                </div>
                {userInfoEls}
            </div>
        </React.Fragment>
    );
};

export default LargeScreenHeader;
