export const ROUTES = {
    HOME: '/',
    BOOKS: '/stories',
    BOOK_DETAIL: '/stories/:slug',
    READ_STORY_PAGE: '/stories/:slug/readStoryPage',
    READ_STORY: '/stories/:slug/readStory',
    RECOMMENDED_STORIES: '/stories/recommended',
    TRENDING_STORIES: '/stories/trending',
    GLOBAL_SEARCH: '/search',
    CREDITS_PAGE: '/users/:slug',
    PUBLISHER_PAGE: '/publishers/:slug',
    READING_LISTS: '/readinglists',
    READING_LIST: '/readinglists/:slug',
    MY_READING_LIST: '/myreadinglists',
    MY_READING_LIST_BOOK: '/myreadinglists/:slug',
    MY_READING_LIST_DETAIL: '/myreadinglistsdetail/:slug',
    THEMED_READING_LIST: '/readinglists/themedreadinglists/:slug',
    MANAGE_READING_LIST: '/managereadinglists/*',
    CREATE_READING_LIST: '/myreadinglists/createreadinglist',
    ADD_BOOKS_TO_READING_LIST: '/myreadinglists/addbooks',
    VIDEOS: '/videos',
    VIDEO_DETAIL: '/videodetail/:slug',
    READ_ALOUDS: '/readalouds',
    READ_ALOUDS_DETAIL: '/readaloudsdetail/:slug',
    RESOURCES: '/resources',
    RESOURCE_DETAIL: '/resourcedetail/:slug',
    STORY_UPLOAD_PUBLICATION_FORM: '/details',
    STORY_UPLOAD_UPLOAD_FORM: '/upload',
    STORY_UPLOAD_PREVIEW: '/preview',
    STORY_UPLOAD_PUBLICATION: '/publication',
    NEW_EDITION: '/details/:slug/new-edition',
    EDIT_STORY_PUBLICATION_FORM: '/details/:slug',
    EDIT_STORY_UPLOAD_FORM: '/upload/:slug',
    EDIT_STORY_PREVIEW: '/preview/:slug',
    EDIT_STORY_PUBLICATION: '/publication/:slug',
    MY_FAVOURITES: '/myfavourites',
    OFFLINE_LIBRARY: '/offline',
    RESET_PASSWORD: '/reset-password/:token',
};
