import React, { Component } from 'react';
import './CroppedImage.scss';
import cancel from '../../assets/cancel.svg';
import save from '../../assets/save.svg';
import zoomIn from '../../assets/zoomIn.svg';
import zoomOut from '../../assets/zoomOut.svg';
import Cropper from 'react-easy-crop';
// import 'react-easy-crop/react-easy-crop.css';

import getCroppedImg from './cropImage';

class CroppedImage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            image: '',
            zoom: 1,
            crop: { x: 0, y: 0 },
            aspect: 1 / 1.25,
        };
    }

    componentDidMount() {
        this.setState((state, props) => ({
            image:
                this.props.srcData.getElementsByTagName('img')[0] &&
                this.props.srcData.getElementsByTagName('img')[0].src,
        }));
    }

    onCropChange = (crop) => {
        this.setState({ crop });
    };

    onCropComplete = async (croppedArea, croppedAreaPixels) => {
        const croppedImage = await getCroppedImg(
            this.state.image,
            croppedAreaPixels
        );
        this.props.onCropped(croppedImage);
    };

    onZoomChange = (zoom) => {
        this.setState({ zoom });
    };

    zoomin = (zoom) => {
        this.setState({ zoom: zoom + 0.1 });
    };

    zoomout = (zoom) => {
        if (zoom > 1) {
            this.setState({ zoom: zoom - 0.1 });
        }
    };

    render() {
        const baseClassName = 'crop-image';

        return (
            <div className={`${baseClassName}__crop_wrapper`}>
                <div className={`${baseClassName}__button_wrapper`}>
                    <div
                        className={`${baseClassName}__thumbnail_button`}
                        onClick={() => this.zoomin(this.state.zoom)}
                    >
                        <img src={zoomIn} alt="alt" />
                    </div>
                    <div
                        className={[
                            `${baseClassName}__thumbnail_button`,
                            `${baseClassName}__zoom`,
                        ].join(' ')}
                        onClick={() => this.zoomout(this.state.zoom)}
                    >
                        <img src={zoomOut} alt="alt" />
                    </div>
                    <div
                        className={`${baseClassName}__thumbnail_button`}
                        onClick={this.props.handleSave}
                    >
                        <img src={save} alt="alt" />
                    </div>
                    <div
                        className={`${baseClassName}__thumbnail_button`}
                        onClick={this.props.handleClose}
                    >
                        <img src={cancel} alt="alt" />
                    </div>
                </div>

                <div className={`${baseClassName}__cropped_image`}>
                    {this.state.image && (
                        <Cropper
                            image={this.state.image}
                            crop={this.state.crop}
                            zoom={this.state.zoom}
                            aspect={this.state.aspect}
                            onCropChange={this.onCropChange}
                            onCropComplete={this.onCropComplete}
                            onZoomChange={this.onZoomChange}
                            style={{
                                containerStyle: {
                                    width: '100%',
                                    top: '15%',
                                    backgroundColor: '#FFFFFF',
                                },
                            }}
                        />
                    )}
                </div>
            </div>
        );
    }
}

export default CroppedImage;
