import React, { Component } from 'react';
import locales from '../../i18n';
import './BookPublication.scss';
import { OfflineStory } from '../../api';
import BookUploadHeader from '../BookUploadHeader';
import BookUploadFooter from '../BookUploadFooter';
import DataLoader from '../DataLoader';
import withRouter from '../../util/withRouter'

class BookPublication extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: '',
            loader: true,
        };
    }

    componentDidMount() {
        OfflineStory.fetchOfflineStoryDetail(
            this.props.location.pathname.split('/')[2]
        )
            .then((response) => {
                return response.json();
            })
            .then((response) => {
                this.setState((state, props) => ({
                    data: response.data,
                    loader: false,
                }));
            });
    }

    backPage = () => {
        this.props.history.push(
            `/preview/${this.props.location.pathname.split('/')[2]}`
        );
    };

    arrayData = (value) => {
        const baseClassName = 'book-publication';
        return (
            <span>
                {value.map((item, i) => (
                    <span className={`${baseClassName}__author-name`}>
                        {' '}
                        {item.first_name}
                        <span>{i < value.length - 1 ? ', ' : ''}</span>
                    </span>
                ))}
            </span>
        );
    };

    render() {
        const { data } = this.state;
        const isRightToLeftDirection =
            localStorage.getItem('locale') === 'ar' ||
            localStorage.getItem('locale') === 'ur';
        const locale = localStorage.getItem('locale') || 'en';

        const messages = locales[locale];
        const baseClassName = 'book-publication';
        return (
            <div>
                {this.state.loader ? (
                    <DataLoader />
                ) : (
                    <div className={`${baseClassName}__page_view_container`}>
                        <div></div>
                        <div>
                            <BookUploadHeader
                                bookId={
                                    this.props.location.pathname.split('/')[2]
                                }
                                history={this.props.history}
                                currentPage={4}
                            />
                            <div
                                className={`${baseClassName}__book_view_container`}
                            >
                                <div
                                    className={`${baseClassName}__thumbnail_view`}
                                >
                                    <img
                                        src={
                                            data &&
                                            'data:image/png;base64,'.concat(
                                                data.cover_thumbnail_image
                                            )
                                        }
                                        alt="thumbnail_img"
                                        className={`${baseClassName}__center_image`}
                                    />
                                </div>
                                <div
                                    className={
                                        `${baseClassName}__detail_container ` +
                                        (isRightToLeftDirection
                                            ? `${baseClassName}__mirror`
                                            : '')
                                    }
                                >
                                    <div className={`${baseClassName}__title`}>
                                        {data.title}
                                    </div>
                                    <div
                                        className={`${baseClassName}__level_box`}
                                        style={{
                                            background: data.story_level_colour,
                                        }}
                                    >
                                        {/* <span>{messages['Book.level']}</span> */}
                                        <span> {data.reading_level}</span>
                                    </div>
                                    <div
                                        className={`${baseClassName}__summary`}
                                    >
                                        {data.synopsis}
                                    </div>
                                    <div>
                                        {data && data.authors.length > 0 ? (
                                            <div>
                                                <span
                                                    className={`${baseClassName}__label`}
                                                >
                                                    {messages['global.author']}
                                                </span>
                                                {this.arrayData(data.authors)}
                                            </div>
                                        ) : (
                                            ''
                                        )}
                                        {data &&
                                        data.illustrators.length > 0 ? (
                                            <div>
                                                <span
                                                    className={`${baseClassName}__label`}
                                                >
                                                    {
                                                        messages[
                                                            'global.illustrator'
                                                        ].split('|')[0]
                                                    }
                                                </span>
                                                {this.arrayData(
                                                    data.illustrators
                                                )}
                                            </div>
                                        ) : (
                                            ''
                                        )}
                                        {data && data.translators.length > 0 ? (
                                            <div>
                                                <span
                                                    className={`${baseClassName}__label`}
                                                >
                                                    {
                                                        messages[
                                                            'global.translator'
                                                        ]
                                                    }
                                                </span>
                                                {this.arrayData(
                                                    data.translators
                                                )}
                                            </div>
                                        ) : (
                                            ''
                                        )}
                                        {data && data.publishers.id ? (
                                            <div>
                                                <span
                                                    className={`${baseClassName}__label`}
                                                >
                                                    {
                                                        messages[
                                                            'global.publisher'
                                                        ].split('|')[0]
                                                    }
                                                </span>
                                                <span
                                                    className={`${baseClassName}__author-name`}
                                                >
                                                    {data.publishers.name}
                                                </span>
                                            </div>
                                        ) : (
                                            ''
                                        )}
                                    </div>
                                </div>
                            </div>
                            <BookUploadFooter backPage={this.backPage} />
                        </div>{' '}
                        <div></div>
                    </div>
                )}{' '}
            </div>
        );
    }
}

export default withRouter(BookPublication);
