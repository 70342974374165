import React from 'react';
import PropTypes from 'prop-types';
import './Grid.scss';
import { isRightToLeftDirection } from '../../util/utils';

const baseClassName = 'pb-grid';

const Grid = ({ variant = '1up', children }) => {
    const classNames = [baseClassName];
    const itemEls = [];

    if (variant) {
        classNames.push(`${baseClassName}--${variant}`);
    }

    if (children && children.length && typeof children !== 'string') {
        React.Children.forEach(children, (c, i) => {
            itemEls.push(
                <div
                    className={
                        isRightToLeftDirection
                            ? `${baseClassName}__item-rtl`
                            : `${baseClassName}__item`
                    }
                    key={i}
                >
                    {c}
                </div>
            );
        });
    } else {
        itemEls.push(
            <div
                className={
                    isRightToLeftDirection
                        ? `${baseClassName}__item-rtl`
                        : `${baseClassName}__item`
                }
                key="1"
            >
                {children}
            </div>
        );
    }

    return (
        <div className={classNames.join(' ')}>
            <div className="grid__container">{itemEls}</div>
        </div>
    );
};

Grid.propTypes = {
    variant: PropTypes.oneOf([
        '1up',
        '2up',
        '3up',
        '4up',
        '6up',
        '2up-6up',
        '2up-6up-half',
    ]),
    children: PropTypes.node,
};

export default Grid;
