import React, { Component } from 'react';
import { connect } from 'react-redux';
import OfflineBooks from '../OfflineBooks';
import { unsaveOffline } from '../../redux/offlineBooksActions';
import { recordGaEvents } from '../../redux/googleAnalyticsActions';

import { gaEventCategories, gaEventActions } from '../../lib/constants';

const mapStateToProps = state => {
    return {
        viewport: state.viewport,
        books: state.offlineBooks.books,
        online: state.network.online,
        userEmail: state.user.profile.email,
    };
};

const mapDispatchToProps = {
    unsaveOffline,
    recordGaEvents,
};

@connect(
    mapStateToProps,
    mapDispatchToProps
)
class OfflineBooksContainer extends Component {
    constructor(props) {
        super(props);
        this.onDelete = this.onDelete.bind(this);
        this.state = {
            checkedValues: [],
        };
    }

    onDelete() {
        const { unsaveOffline, books, userEmail, recordGaEvents } = this.props;
        this.state.checkedValues.map(unsaveOffline);

        // Find the selected books for deletion
        const checkedBooks = books.filter(book =>
            this.state.checkedValues.includes(book.id)
        );

        //send delete event to GA
        checkedBooks.forEach(book =>
            recordGaEvents({
                eventCategory: gaEventCategories.offline,
                eventAction: gaEventActions.delete,
                userEmail: userEmail,
                dimension2: book.level,
                dimension3: book.language,
                dimension5: book.slug,
                metric2: -1,
            })
        );

        this.setState({
            checkedValues: [],
        });
    }

    render() {
        const { viewport, books, online } = this.props;
        const { checkedValues } = this.state;
        const myLanguage = localStorage.getItem('locale');

        return (
            <OfflineBooks
                myLanguage={myLanguage}
                viewport={viewport}
                books={books}
                onDelete={this.onDelete}
                online={online}
                checkedValues={checkedValues}
            />
        );
    }
}

export default OfflineBooksContainer;
