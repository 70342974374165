import { createAsyncActions, runAsyncWorkflow } from '../lib/reduxHelpers';

import { References } from '../api';

export const [
    fetchReferences,
    receiveReferences,
    fetchReferencesError,
] = createAsyncActions(
    'REFERENCES',
    () => {},
    ({ ok, data, metadata }) => ({ ok, references: data, metadata })
);

export const [
    fetchMoreReferences,
    receiveMoreReferences,
    fetchMoreReferencesError,
] = createAsyncActions(
    'MORE_REFERENCES',
    ({ page, perPage }) => ({ page, perPage }),
    ({ data, metadata }) => ({ references: data, metadata })
);

export const fetchReferencesWorkflow = (appliedFilters, page, perPage) => {
    const fetchingMore = page && page > 1;
    const fetchPromise = References.fetch(appliedFilters, page, perPage);

    return runAsyncWorkflow(
        {
            fetch: fetchingMore ? fetchMoreReferences : fetchReferences,
            receive: fetchingMore ? receiveMoreReferences : receiveReferences,
            error: fetchingMore
                ? fetchMoreReferencesError
                : fetchReferencesError,
        },
        fetchPromise
    );
};

export const [
    fetchAllReferencesFilters,
    receiveAllReferencesFilters,
    fetchAllReferencesFiltersError,
] = createAsyncActions(
    'ALL_REFERENCES_FILTERS',
    () => ({}),
    ({ data }) => ({ filters: data })
);

export const fetchAllReferencesFiltersWorkflow = () => {
    const fetchPromise = References.fetchAllFilters();

    return runAsyncWorkflow(
        {
            fetch: fetchAllReferencesFilters,
            receive: receiveAllReferencesFilters,
            error: fetchAllReferencesFiltersError,
        },
        fetchPromise
    );
};

export const [
    favouriteReference,
    receiveFavouriteReference,
    favouriteReferenceError,
] = createAsyncActions(
    'FAV_REFERENCES',
    ({ referenceId }) => ({ referenceId }),
    ({ ok, referenceId }) => ({ ok, referenceId })
);

export const favouriteReferenceWorkflow = referenceId => {
    const favReferencePromise = References.toggleFavourite(referenceId);

    return runAsyncWorkflow(
        {
            fetch: favouriteReference,
            receive: receiveFavouriteReference,
            error: favouriteReferenceError,
        },
        favReferencePromise,
        { referenceId }
    );
};

export const [
    fetchFavouriteResources,
    receiveFetchFavouriteResources,
    fetchFavouriteResourcesError,
] = createAsyncActions(
    'FETCH_FAV_RESOURCES',
    () => {},
    ({ ok, data, metadata }) => ({ ok, favResources: data, metadata })
);

export const fetchFavouriteResourcesWorkflow = () => {
    const fetchFavouritePromise = References.fetchFavouriteResources();

    return runAsyncWorkflow(
        {
            fetch: fetchFavouriteResources,
            receive: receiveFetchFavouriteResources,
            error: fetchFavouriteResourcesError,
        },
        fetchFavouritePromise
    );
};

export const [
    fetchReferenceDetail,
    receivefetchReferenceDetail,
    fetchReferenceDetailError,
] = createAsyncActions(
    'FETCH_RESOURCE_DETAIL',
    () => {},
    ({ ok, data }) => ({ ok, resourceDetail: data })
);

export const fetchReferenceDetailWorkflow = slug => {
    const fetchReferenceDetailPromise = References.fetchReferenceDetail(slug);

    return runAsyncWorkflow(
        {
            fetch: fetchReferenceDetail,
            receive: receivefetchReferenceDetail,
            error: fetchReferenceDetailError,
        },
        fetchReferenceDetailPromise
    );
};
