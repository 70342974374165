import React from 'react';
import { translate } from 'react-polyglot';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import Link from '../../Link';

const Level = ({
    t,
    storyLevelColour,
    levelStyles,
    baseClassName,
    onClick,
    links,
    languagePreferences,
    level,
    bookLevelGTM,
    isFromHome,
    isFromReadingList,
    levelClicked
}) => {
    return (
        <div
            style={{ backgroundColor: `${storyLevelColour}` }}
            className={classNames(levelStyles)}
        >
            <span className={`${baseClassName}__level-text`}>
                <Link
                    parentClassName={`${baseClassName}__link`}
                    isInternal={onClick ? true : true}
                    href={links.allBooks(void 0, languagePreferences, level)}
                    dataGTM={bookLevelGTM}
                    onClick={
                        isFromHome || isFromReadingList
                            ? null
                            : () =>
                                  levelClicked(
                                      void 0,
                                      languagePreferences,
                                      level
                                  )
                    }
                >
                    {t(`PublicationForm.level${level}`)}
                </Link>
            </span>
        </div>
    );
};

Level.propTypes = {
  t: PropTypes.func.isRequired,
  storyLevelColour: PropTypes.string,
  levelStyles: PropTypes.string,
  baseClassName: PropTypes.string,
  onClick: PropTypes.func,
  links: PropTypes.shape({
      allBooks: PropTypes.func.isRequired
  }).isRequired,
  languagePreferences: PropTypes.string,
  level: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  bookLevelGTM: PropTypes.string,
  isFromHome: PropTypes.bool,
  isFromReadingList: PropTypes.bool,
  levelClicked: PropTypes.func
};

export default translate()(Level);
