import React, { Component } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import './PostComment.scss';
import Button from '../Button';


class PostComment extends Component {
    constructor(props) {
        super(props);

        this.state = {
            comment: '',
        };
    }
    setComment = (e) => {
        this.setState({ comment: e.target.value });
    };

    postComment = (id, e, source) => {
        if (source === 'fromButton' && this.state.comment) {
            this.setState({ comment: '' });
            this.props.postComment
                ? this.props.postComment(this.state.comment)
                : this.props.postReply(id, this.state.comment);
            document.getElementById('commentTextField').blur();
        } else if (e.which === 13 && this.state.comment) {
            this.setState({ comment: '' });
            this.props.postComment
                ? this.props.postComment(e.target.value)
                : this.props.postReply(id, e.target.value);
            e.target.blur();
        }
    };


    render() {
        const baseClassName = 'pb-disqus-component';
        const { parentClassName, id, t } = this.props;
        const isRightToLeftDirection = localStorage.getItem('locale') === "ar" || localStorage.getItem('locale') === "ur"
        const classes = {
            [baseClassName]: true,
            [parentClassName]: parentClassName,
        };

        return (
            <div className={classNames(classes)}>
                <div className={
                    isRightToLeftDirection
                    ? `${baseClassName}__image-input-wrapper-rtl`
                    : `${baseClassName}__image-input-wrapper`
                }>
                    <div className={`${baseClassName}__input-container`}>
                        <textarea
                            id="commentTextField"
                            className={`${baseClassName}__input`}
                            onChange={(e) => this.setComment(e)}
                            // onKeyPress={(e) => this.postComment(id, e, 'key')}
                            value={this.state.comment}
                        ></textarea>
                    </div>
                </div>
                <Button
                    label={t('comments.post')}
                    variant={"postComment"}
                    onClick={ 
                        () =>
                        this.postComment(
                            id,
                            this.state.comment,
                            'fromButton'
                        )
                    }
                />
                </div>
        );
    }
}

PostComment.propTypes = {
    parentClassName: PropTypes.string,
};

export default PostComment;
