import React, { Component } from 'react';
import './RTRBookReaderContent.scss';
import RTRBookFavourites from '../../../RTRBookFavourites';
import SvgIcon from '../../../SvgIcon';

class RTRBookTitleData extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        //getting rtl using localStorage
        const isRightToLeftDirection = localStorage.getItem('locale') === 'ar' || localStorage.getItem('locale') === 'ur';
        // console.log("isRightToLeftDirection", isRightToLeftDirection);
        return (
            <div className="bookReaderPageWrapper" style={{ height: '80vh' }}>
                <div className="leftBookPage firstPage">
                    <div
                        className="bookCoverPageContainer"
                        style={{ margin: '0px' }}
                    >
                        <div>
                            <h1 className="bookTitle">
                                {this.props.bookData.title}
                            </h1>
                        </div>
                        <div
                            className="bookReadingLevel"
                            style={{
                                background:
                                    this.props.bookData.story_level_colour,
                                width: '6rem',
                                margin: '1rem auto',
                            }}
                        >
                            <span>{this.props.bookData.reading_level}</span>
                        </div>
                        <div>
                            <p className="bookSummary">
                                {this.props.bookData.synopsis.length > 300
                                    ? `${this.props.bookData.synopsis.slice(
                                        0,
                                        300
                                    )}  `
                                    : this.props.bookData.synopsis}
                                {this.props.bookData.synopsis.length > 300 ? (
                                    <div
                                        onClick={this.props.onOpenModal}
                                        className="bookReadMore"
                                        data-toggle="modal"
                                        data-target="#exampleModalLong"
                                    >
                                        {this.props.t('Book.contentReadMore')}
                                    </div>
                                ) : (
                                    ''
                                )}
                            </p>
                        </div>
                        <div>
                            <p className="bookAuthors">
                                <span>
                                    {this.props.t('global.author')}{' '}
                                </span>
                                &nbsp;
                                {this.props.bookData.authors.map(
                                    (author, i) => {
                                        return (
                                            <span
                                                className="bookAuthorsLink"
                                                onClick={(e) =>
                                                    this.props.onOpenBioModal(
                                                        e,
                                                        author
                                                    )
                                                }
                                            >
                                                {`${author.first_name} ${author.last_name}`}
                                                <span>
                                                    {i <
                                                        this.props.bookData.authors
                                                            .length -
                                                        1
                                                        ? ', '
                                                        : ''}
                                                </span>
                                            </span>
                                        );
                                    }
                                )}
                            </p>
                            <p className="bookIllustrators">
                                <span>
                                    {this.props.t('global.illustrator', 1)}{' '}
                                </span>
                                &nbsp;
                                {this.props.bookData.illustrators.map(
                                    (illustrator, i) => {
                                        return (
                                            <span
                                                className="bookIllustratorsLink"
                                                onClick={(e) =>
                                                    this.props.onOpenBioModal(
                                                        e,
                                                        illustrator
                                                    )
                                                }
                                            >
                                                {`${illustrator.first_name} ${illustrator.last_name}`}
                                                <span>
                                                    {i <
                                                        this.props.bookData
                                                            .illustrators.length -
                                                        1
                                                        ? ', '
                                                        : ''}
                                                </span>
                                            </span>
                                        );
                                    }
                                )}
                            </p>
                            {this.props.bookData.translators.length > 0 && (
                                <p className="bookTranslators">
                                    <span>
                                        {this.props.t('global.translator')}{' '}
                                    </span>
                                    &nbsp;
                                    {this.props.bookData.translators.map(
                                        (translator, i) => {
                                            return (
                                                <span
                                                    className="bookTranslatorsLink"
                                                    onClick={(e) =>
                                                        this.props.onOpenBioModal(
                                                            e,
                                                            translator
                                                        )
                                                    }
                                                >
                                                    {`${translator.first_name} ${translator.last_name}`}
                                                    <span>
                                                        {i <
                                                            this.props.bookData
                                                                .translators.length -
                                                            1
                                                            ? ', '
                                                            : ''}
                                                    </span>
                                                </span>
                                            );
                                        }
                                    )}
                                </p>
                            )}
                            <p className="bookPublisherLogo">
                                <img
                                    src={this.props.bookData.publishers.image}
                                    alt={this.props.bookData.publishers.name}
                                    style={{ width: 'auto' }}
                                />
                            </p>
                            <div className={`RTRlikesContainer ${isRightToLeftDirection ? "RTRlikesContainer-rtl" : ""}`}>
                                <span className={`bookLikes ${isRightToLeftDirection ? "bookLikes-rtl" : ""}`}>
                                    {this.props.book &&
                                        this.props.book.likesCount >= 0 &&
                                        this.props.online && (
                                            <div
                                                className=" RTRFavourites"
                                                style={{
                                                    width: '20px',
                                                    height: '20px',
                                                }}
                                            >
                                                <RTRBookFavourites
                                                    t={this.props.t}
                                                    isFavourite={
                                                        this.props.book
                                                            .isFavourite
                                                    }
                                                    favouriteToggleHandler={() => {
                                                        this.props.onFavoriteClicked(
                                                            this.props.book.id
                                                        );
                                                    }}
                                                    isFavouriting={
                                                        this.props.book
                                                            .isFavouriting
                                                    }
                                                    isLoggedIn={
                                                        this.props.isLoggedIn
                                                    }
                                                    logInMsg={this.props.t(
                                                        'global.please-log-in',
                                                        {
                                                            action: this.props.t(
                                                                'global.like',
                                                                1
                                                            ),
                                                            content_type:
                                                                this.props.t(
                                                                    'global.story',
                                                                    1
                                                                ),
                                                        }
                                                    )}
                                                    likes={
                                                        this.props.likesCount
                                                    }
                                                    bookSlug={
                                                        this.props.book.slug
                                                    }
                                                    bookFavorite="book-favorite"
                                                />
                                            </div>
                                        )}
                                </span>
                                <span
                                    className="bookReadCount"
                                    style={{
                                        width: '20px',
                                        height: '15px',
                                    }}
                                >
                                    {/* <img
                                        className={`RTRBookReadCount ${isRightToLeftDirection ? "RTRBookReadCount-rtl" : ""}`}
                                        src={require('../../../../assets/RTRBookReadCount.svg')}
                                        alt="Book Read Count"
                                    /> */}
                                    <SvgIcon 
                                        name={"Read"} 
                                        size="custom-reader-view"
                                    />
                                    <span>
                                        {this.props.bookData['read_count']}
                                    </span>
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default RTRBookTitleData;
