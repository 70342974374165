import React, { Component } from 'react';
import { translate } from 'react-polyglot';
import './RTRBookReaderContent.scss';
import {
    getBookDataWorkflow,
    fetchBookWorkflow,
} from '../../../../redux/bookActions';
import { connect } from 'react-redux';
import Link from '../../../Link';
import ShareMenu from '../../../RTRShareMenu';
import Modal from '../../../Modal';
import RTRBookFavourites from '../../../RTRBookFavourites';
import PageFlipAnimation from '../../../PageFlip/PageFlipAnimation';
import RatingModal from 'react-modal';
import BookRating from '../../../BookRating';
import SvgIcon from '../../../SvgIcon';


const mapDispatchToProps = {
    getBookDataWorkflow,
    fetchBookWorkflow,
};
const mapStateToProps = (state) => {
    return {
        isBookDataFetched: state.book.isBookDataFetched,
    };
};

const customStyles = {
    overlay: {
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        zIndex: 999,
        overflow: 'hidden',
        backgroundColor: 'rgba(0, 0, 0, 0.75)',
    },
    content: {
        position: 'fixed',
        top: '15%',
        left: '15%',
        right: '15%',
        bottom: '15%',
        border: '1px solid #ccc',
        overflow: 'auto',
        WebkitOverflowScrolling: 'touch',
        borderRadius: '0px',
        outline: 'none',
        padding: '0px',
        zIndex: '999',
        overflowY: 'hidden',
        background: '#ffffff',
    },
};

// @translate()
class RTRBookReaderPage extends Component {
    constructor(props) {
        super(props);

        this.state = {
            bookReaderData: {},
            isModalVisible: {
                noSpaceForMoreOfflineBooks: false,
            },
            isBioModalInfo: {
                isBioModalOpen: false,
                first_name: 'ww',
                last_name: 'ww',
                email: '22',
                bio: '',
            },
            modalIsOpen: true
        };
    }
    onCloseModal() {
        this.setState({
            isModalVisible: {
                noSpaceForMoreOfflineBooks: false,
            },
        });
    }
    onOpenModal() {
        this.setState({
            isModalVisible: {
                noSpaceForMoreOfflineBooks: true,
            },
        });
    }
    onOpenBioModal(e, person) {
        this.setState({
            isBioModalInfo: {
                isBioModalOpen: true,
                first_name: person.first_name,
                last_name: person.last_name,
                email: person.email,
                bio: person.bio,
            },
        });
    }
    onCloseBioModal() {
        this.setState({
            isBioModalInfo: {
                isBioModalOpen: false,
                first_name: '',
                last_name: '',
                email: '',
                bio: '',
            },
        });
    }
    closePopup = () => {
        this.setState((state, props) => ({
            modalIsOpen: false,
        }));
    };

    componentWillMount() {
        if (window.innerWidth > 1030) {
            this.props.bookData.pages = [
                { dynamicPage: 1 },
                { dynamicPage: 2 },
                ...this.props.bookData.pages,
                { dynamicPage: 3 },
                { dynamicPage: 4 },
            ];
        }
    }
    render() {
        //rtl
        const isRightToLeftDirection =
            localStorage.getItem('locale') === 'ar' ||
            localStorage.getItem('locale') === 'ur';
        // console.log("isRightToLeftDirection", isRightToLeftDirection)
        const myLanguage = localStorage.getItem('locale');
        let mobilePageStatus = this.props.pageNo;
        let totalNoOfPagesDesktop =
            (this.props.totalPageCount + 3) % 2
                ? (this.props.totalPageCount + 4) / 2
                : (this.props.totalPageCount + 3) / 2;

        return (
            <div className="RTRBookReaderContent">
                {window.innerWidth > 1030 && (
                    <div id="bookReaderPageWrapper">
                        <PageFlipAnimation
                            detailPages={this.props.bookData}
                            prevPage={this.props.prevPage.bind(this)}
                            nextPage={this.props.nextPage.bind(this)}
                            currentPage={this.props.pageNo}
                            t={this.props.t}
                            book={this.props.book}
                            online={this.props.online}
                            onFavoriteClicked={this.props.onFavoriteClicked.bind(
                                this
                            )}
                            likesCount={this.props.likesCount}
                            isLoggedIn={this.props.isLoggedIn}
                            viewport={this.props.viewport}
                            onOpenBioModal={this.onOpenBioModal.bind(this)}
                            onOpenModal={this.onOpenModal.bind(this)}
                            setBookReaderStatusWorkflow={
                                this.props.setBookReaderStatusWorkflow
                            }
                            readAgain={this.props.readAgain.bind(this)}
                            pageNo={this.props.pageNo}
                            isBookRated={this.props.bookData.is_book_rated}
                            history={this.props.history}
                        />

                        {this.state.isBioModalInfo.isBioModalOpen && (
                            <Modal
                                style={{ zIndex: 9999 }}
                                header={'Biography'}
                                footer={''}
                                onClose={this.onCloseBioModal.bind(this)}
                            >
                                <p>
                                    <span>{`Name: ${this.state.isBioModalInfo.first_name} ${this.state.isBioModalInfo.last_name}`}</span>
                                </p>
                                <p>
                                    <span>{`Email: ${this.state.isBioModalInfo.email}`}</span>
                                </p>
                                {this.state.isBioModalInfo.bio && (
                                    <p>
                                        <h3>{`Biography: `}</h3>
                                        <p>{this.state.isBioModalInfo.bio}</p>
                                    </p>
                                )}
                            </Modal>
                        )}

                        {this.state.isModalVisible
                            .noSpaceForMoreOfflineBooks && (
                                <Modal
                                    header={'Book Summary'}
                                    footer={''}
                                    onClose={this.onCloseModal.bind(this)}
                                >
                                    <p>{this.props.bookData.synopsis}</p>
                                </Modal>
                            )}
                    </div>
                )}

                <div className="RTRBookReaderContent-Mobile">
                    <div
                        className="bookReaderPageWrapper"
                        id="bookReaderPageWrapper"
                        onTouchStart={this.props.handleTouchStart}
                        onTouchMove={this.props.handleTouchMove}
                    >
                        {mobilePageStatus === 0 && (
                            <div className="bookPage-Mobile" id="leftBookPage">
                                <div className="bookCoverPageContainer">
                                    <h1 className="bookTitle">
                                        {this.props.bookData.title}
                                    </h1>
                                    <button
                                        className="bookReadingLevel"
                                        style={{
                                            background:
                                                this.props.bookData
                                                    .story_level_colour,
                                            pointerEvents: 'none',
                                        }}
                                    >
                                        <span>{this.props.bookData.reading_level}</span>
                                    </button>
                                    <p className="bookSummary">
                                        {this.props.bookData.synopsis.length >
                                            300
                                            ? `${this.props.bookData.synopsis.slice(
                                                0,
                                                300
                                            )}  `
                                            : this.props.bookData.synopsis}
                                        {this.props.bookData.synopsis.length >
                                            300 ? (
                                            <button
                                                onClick={() => {
                                                    this.onOpenModal();
                                                }}
                                                className="bookReadMore"
                                                data-toggle="modal"
                                                data-target="#exampleModalLong"
                                            >
                                                {this.props.t(
                                                    'Book.contentReadMore'
                                                )}
                                            </button>
                                        ) : (
                                            ''
                                        )}
                                        {this.state.isModalVisible
                                            .noSpaceForMoreOfflineBooks && (
                                                <Modal
                                                    header={'Book Summary'}
                                                    footer={''}
                                                    onClose={this.onCloseModal.bind(
                                                        this
                                                    )}
                                                >
                                                    <p>
                                                        {
                                                            this.props.bookData
                                                                .synopsis
                                                        }
                                                    </p>
                                                </Modal>
                                            )}
                                    </p>
                                    <div>
                                        <p className="bookAuthors">
                                            <span>
                                                {this.props.t(
                                                    'Book.contentAuthor'
                                                )}
                                            </span>
                                            &nbsp;
                                            {this.props.bookData.authors.map(
                                                (author, i) => {
                                                    return (
                                                        <span
                                                            className="bookAuthorsLink"
                                                            onClick={(e) => {
                                                                this.onOpenBioModal(
                                                                    e,
                                                                    author
                                                                );
                                                            }}
                                                        >
                                                            {`${author.first_name} ${author.last_name}`}
                                                            <span>
                                                                {i <
                                                                    this.props
                                                                        .bookData
                                                                        .authors
                                                                        .length -
                                                                    1
                                                                    ? ', '
                                                                    : ''}
                                                            </span>
                                                        </span>
                                                    );
                                                }
                                            )}
                                        </p>
                                        <p className="bookIllustrators">
                                            <span>
                                                {this.props.t(
                                                    'Book.contentIllustrator'
                                                )}
                                            </span>
                                            &nbsp;
                                            {this.props.bookData.illustrators.map(
                                                (illustrator, i) => {
                                                    return (
                                                        <span
                                                            className="bookIllustratorsLink"
                                                            onClick={(e) => {
                                                                this.onOpenBioModal(
                                                                    e,
                                                                    illustrator
                                                                );
                                                            }}
                                                        >
                                                            {`${illustrator.first_name} ${illustrator.last_name}`}
                                                            <span>
                                                                {i <
                                                                    this.props
                                                                        .bookData
                                                                        .illustrators
                                                                        .length -
                                                                    1
                                                                    ? ', '
                                                                    : ''}
                                                            </span>
                                                        </span>
                                                    );
                                                }
                                            )}
                                        </p>
                                        {this.props.bookData.translators
                                            .length > 0 && (
                                                <p className="bookTranslators">
                                                    <span>
                                                        {this.props.t(
                                                            'Book.contentTranslator'
                                                        )}
                                                    </span>
                                                    &nbsp;
                                                    {this.props.bookData.translators.map(
                                                        (translator, i) => {
                                                            return (
                                                                <span
                                                                    className="bookTranslatorsLink"
                                                                    onClick={(
                                                                        e
                                                                    ) => {
                                                                        this.onOpenBioModal(
                                                                            e,
                                                                            translator
                                                                        );
                                                                    }}
                                                                >
                                                                    {`${translator.first_name} ${translator.last_name}`}
                                                                    <span>
                                                                        {i <
                                                                            this.props
                                                                                .bookData
                                                                                .translators
                                                                                .length -
                                                                            1
                                                                            ? ', '
                                                                            : ''}
                                                                    </span>
                                                                </span>
                                                            );
                                                        }
                                                    )}
                                                </p>
                                            )}
                                        <p className="bookPublisherLogo">
                                            <img
                                                src={
                                                    this.props.bookData
                                                        .publishers.image
                                                }
                                                alt={
                                                    this.props.bookData
                                                        .publishers.name
                                                }
                                            />
                                        </p>
                                        {this.state.isBioModalInfo
                                            .isBioModalOpen && (
                                                <Modal
                                                    header={'Biography'}
                                                    footer={''}
                                                    onClose={this.onCloseBioModal.bind(
                                                        this
                                                    )}
                                                >
                                                    <p>
                                                        <span>{`Name: ${this.state.isBioModalInfo.first_name} ${this.state.isBioModalInfo.last_name}`}</span>
                                                    </p>
                                                    <p>
                                                        <span>{`Email: ${this.state.isBioModalInfo.email}`}</span>
                                                    </p>
                                                    {this.state.isBioModalInfo
                                                        .bio && (
                                                            <p>
                                                                <h3>{`Biography: `}</h3>
                                                                <p>
                                                                    {
                                                                        this.state
                                                                            .isBioModalInfo
                                                                            .bio
                                                                    }
                                                                </p>
                                                            </p>
                                                        )}
                                                </Modal>
                                            )}
                                        <div className="RTRlikesContainer">
                                            <span className="bookLikes">
                                                {this.props.book &&
                                                    this.props.book
                                                        .likesCount >= 0 &&
                                                    this.props.online && (
                                                        <div className=" RTRFavourites">
                                                            <RTRBookFavourites
                                                                t={this.props.t}
                                                                isFavourite={
                                                                    this.props
                                                                        .book
                                                                        .isFavourite
                                                                }
                                                                favouriteToggleHandler={() => {
                                                                    this.props.onFavoriteClicked(
                                                                        this
                                                                            .props
                                                                            .book
                                                                            .id
                                                                    );
                                                                }}
                                                                isFavouriting={
                                                                    this.props
                                                                        .book
                                                                        .isFavouriting
                                                                }
                                                                isLoggedIn={
                                                                    this.props
                                                                        .isLoggedIn
                                                                }
                                                                logInMsg={this.props.t(
                                                                    'global.please-log-in',
                                                                    {
                                                                        action: this.props.t(
                                                                            'global.like',
                                                                            1
                                                                        ),
                                                                        content_type:
                                                                            this.props.t(
                                                                                'global.story',
                                                                                1
                                                                            ),
                                                                    }
                                                                )}
                                                                likes={
                                                                    this.props
                                                                        .likesCount
                                                                }
                                                                bookSlug={
                                                                    this.props
                                                                        .book
                                                                        .slug
                                                                }
                                                                bookFavorite="book-favorite"
                                                            />
                                                        </div>
                                                    )}
                                            </span>
                                            <span className="bookReadCount">
                                                {/* <img
                                                    className="RTRBookReadCount"
                                                    src={require('../../../../assets/RTRBookReadCount.svg')}
                                                    alt="Book Read Count"
                                                /> */}
                                                <SvgIcon 
                                                    name={"Read"} 
                                                    size="custom-reader-view"
                                                />
                                                <span>
                                                    {
                                                        this.props.bookData[
                                                        'read_count'
                                                        ]
                                                    }
                                                </span>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}
                        {mobilePageStatus === this.props.totalPageCount + 1 && (
                            <div className="bookPage-Mobile" id="leftBookPage">
                                <div className="bookCoverPageContainer">
                                    <h1 className="bookTitle">
                                        {this.props.bookData.title}
                                    </h1>
                                    <button
                                        className="bookFinishReading"
                                        onClick={() => {
                                            let pageStatus;
                                            if (
                                                (this.props.totalPageCount +
                                                    1) %
                                                2 ===
                                                0
                                            ) {
                                                pageStatus =
                                                    this.props.totalPageCount +
                                                    1;
                                            } else {
                                                pageStatus =
                                                    this.props.totalPageCount;
                                            }
                                            this.props.setBookReaderStatusWorkflow(
                                                this.props.book.id,
                                                pageStatus,
                                                'finish'
                                            );
                                            window.history.back();
                                        }}
                                    >
                                        <span>
                                            {this.props.t(
                                                'Book.contentFinishReading'
                                            )}
                                        </span>
                                    </button>
                                    <p className="bookReadAgain">
                                        <button
                                            onClick={() => {
                                                this.props.setBookReaderStatusWorkflow(
                                                    this.props.book.id,
                                                    0,
                                                    'reset'
                                                );
                                                this.props.readAgain();
                                            }}
                                        >
                                            {this.props.t(
                                                'Book.contentReadAgain'
                                            )}
                                        </button>
                                    </p>
                                    <div className={`bookShareAndLikes ${isRightToLeftDirection ? "bookShareAndLikes-rtl" : ""}`}>
                                        <span className={`bookLikes ${isRightToLeftDirection ? "bookLikes-rtl" : ""}`}>
                                            {this.props.book &&
                                                this.props.book.likesCount >=
                                                0 &&
                                                this.props.online && (
                                                    <div className=" RTRFavourites">
                                                        <RTRBookFavourites
                                                            t={this.props.t}
                                                            isFavourite={
                                                                this.props.book
                                                                    .isFavourite
                                                            }
                                                            favouriteToggleHandler={() => {
                                                                this.props.onFavoriteClicked(
                                                                    this.props
                                                                        .book.id
                                                                );
                                                            }}
                                                            isFavouriting={
                                                                this.props.book
                                                                    .isFavouriting
                                                            }
                                                            isLoggedIn={
                                                                this.props
                                                                    .isLoggedIn
                                                            }
                                                            logInMsg={this.props.t(
                                                                'global.please-log-in',
                                                                {
                                                                    action: this.props.t(
                                                                        'global.like',
                                                                        1
                                                                    ),
                                                                    content_type:
                                                                        this.props.t(
                                                                            'global.story',
                                                                            1
                                                                        ),
                                                                }
                                                            )}
                                                            likes={
                                                                this.props
                                                                    .likesCount
                                                            }
                                                            bookSlug={
                                                                this.props.book
                                                                    .slug
                                                            }
                                                            bookFavorite="book-favorite"
                                                        />
                                                    </div>
                                                )}
                                        </span>
                                        <span className="RTRBookShare">
                                            <ShareMenu
                                                title={''}
                                                href={window.location.href}
                                                desc={''}
                                                landingPageBook={''}
                                                align={() => {
                                                    return true;
                                                }}
                                            />
                                        </span>
                                    </div>
                                    <h2 className="bookThanksHeading">
                                        {this.props.t('Book.contentThankYou')}
                                    </h2>
                                    <p className="bookDedicationText">
                                        {
                                            this.props.bookData[
                                            'dedication_language'
                                            ]
                                        }
                                    </p>
                                    {
                                        this.props.bookData.donor != null ?
                                        <div className="bookSponserImages">
                                            <img
                                                src={this.props.bookData.donor.logo_url}
                                                alt="dedicatorImages"
                                            />
                                        </div> : ""
                                    }
                                </div>
                            </div>
                        )}
                        {mobilePageStatus === this.props.totalPageCount + 2 && (
                            <div style={{width:'100%'}}>
                                <div
                                    className="bookPage-Mobile  closingPage"
                                    id="bookPage-Mobile"
                                >
                                    <div className="closingPageHeader">
                                        <div className="RTRLogo">
                                            
                                            <SvgIcon 
                                                name={"rtr-logo"} 
                                                size="rtr-logo"
                                            />
                                        </div>
                                        {/* <div className="closingDescription">
                                            {this.props.t(
                                                'Book.closingHeaderDescription'
                                            )}
                                        </div> */}
                                    </div>
                                    <div className="closingPageContentData-Mobile">
                                        <div className={isRightToLeftDirection ? "closingPageContentDataTitle-Mobile-Rtl" : "closingPageContentDataTitle-Mobile"}>
                                            {this.props.t('book.storyFinalPageContentTitle')}
                                        </div>
                                        <div className={isRightToLeftDirection ? "closingPageContentDataPara1-Mobile-Rtl" : "closingPageContentDataPara1-Mobile"}>
                                            {this.props.t('book.storyFinalPageContentPara1')}
                                        </div>
                                        <div className={isRightToLeftDirection ? "closingPageContentDataPara2-Mobile-Rtl" : "closingPageContentDataPara2-Mobile"}>
                                            {this.props.t('book.storyFinalPageContentPara2')}
                                        </div>
                                    </div>
                                    <div className="closingPageFooter">
                                        <p className="disclaimerLink">
                                            <span>
                                                {this.props.t(
                                                    'Book.contentFooterDescriptionDisclaimer'
                                                )}
                                            </span>
                                            <Link
                                                className="bookAuthorsLink"
                                                href={'https://literacycloud.org/terms-and-conditions'}
                                            >
                                                {'https://literacycloud.org/terms-and-conditions'}
                                            </Link>
                                        </p>
                                        <p className="bookLicenseContainer">
                                            <img
                                                className="bookLicense"
                                                src={
                                                    this.props.bookData.license_type.image_url != null
                                                        ?
                                                        this.props.bookData.license_type.image_url
                                                        :
                                                        'https://literacycloud.org/system/room-to-read/license_images/7/original/CC_BY_4.png?1595242870'
                                                }
                                                alt="license"
                                            />
                                        </p>
                                        <p className="licenseDescription">
                                            {
                                                this.props.bookData.license_type
                                                    .message
                                            }
                                        </p>
                                    </div>
                                </div>

                            {   
                                this.props.isLoggedIn &&
                                !this.props.bookData.is_book_rated &&
                                window.navigator.onLine &&
                                this.state.modalIsOpen && 
                                <RatingModal
                                    isOpen={this.state.modalIsOpen}
                                    style={customStyles}
                                    onAfterOpen={() => {
                                        document.body.style.overflow = 'hidden';
                                    }}
                                    onAfterClose={() => {
                                        document.body.style.overflow = 'auto';
                                    }}
                                >
                                    <BookRating 
                                        t={this.props.t}
                                        handleClose={this.closePopup}
                                        bookId={this.props.book.id}
                                        bookSlug={this.props.book.slug}
                                        history={this.props.history}
                                    />
                                </RatingModal>
                                
                            }
                            </div>
                        )}
                        {!(mobilePageStatus === 0) &&
                            !(
                                mobilePageStatus ===
                                this.props.totalPageCount + 1 &&
                                mobilePageStatus ===
                                this.props.totalPageCount + 2
                            ) &&
                            !(
                                mobilePageStatus ===
                                this.props.totalPageCount + 1
                            ) &&
                            this.props.bookData.pages[mobilePageStatus - 1] && (
                                <div
                                    className="bookPage-Mobile"
                                    id="bookPage-Mobile"
                                >
                                    <img
                                        className="BookPageImg"
                                        src={
                                            this.props.bookData.pages[
                                                mobilePageStatus - 1
                                            ].url
                                        }
                                        alt={mobilePageStatus}
                                    />
                                </div>
                            )}
                    </div>
                </div>
            </div>
        );
    }
}

export default translate()(
    connect(mapStateToProps, mapDispatchToProps)(RTRBookReaderPage)
);
