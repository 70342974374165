import { useState, useEffect, useCallback } from 'react';

const useIsMobile = () => {
    const [isMobile, setIsMobile] = useState(false);

    const checkIfMobile = useCallback(() => {
        const isMobileDevice = window.screen.width < 768 && window.matchMedia('(orientation: portrait)').matches;
        setIsMobile(isMobileDevice);
    }, []);

    useEffect(() => {
        checkIfMobile();
        window.addEventListener('resize', checkIfMobile);

        return () => window.removeEventListener('resize', checkIfMobile);
    }, [checkIfMobile]);

    return isMobile;
};

export default useIsMobile;
