import React from 'react';
import { translate } from 'react-polyglot';
import './RTRBookReaderPageHeader.scss';
import SvgIcon from '../../../SvgIcon';

const RTRBookReaderPageHeader = (props) => {
    const goBack = () => {
        window.history.back();
    }
    //getting rtl using localStorage
    const isRightToLeftDirection = localStorage.getItem('locale') === 'ar' || localStorage.getItem('locale') === 'ur';
    // console.log("isRightToLeftDirection", isRightToLeftDirection);
    return (
        <div className={`RTRBookReaderPageHeader ${isRightToLeftDirection ? "RTRBookReaderPageHeader-rtl" : ""}`}>
            <span className={`backToBookButton ${isRightToLeftDirection ? "backToBookButton-rtl" : ""}`} onClick={() => {
                goBack()
            }}>
                {' '}
                {/* <img
                    className="RTRBackButtonToBook"
                    src={require('../../../../assets/RTRBackButtonToBook.svg')}
                    alt="Back Button To Book Page"
                /> */}
                <SvgIcon name="reader-view-back-button" size="reader-view-controls" />
                <span className="BackButton">{props.t('Book.headerBackButton')}</span>
            </span>
            <div className={`BookTitle ${isRightToLeftDirection ? "BookTitle-rtl" : ""}`}>
                <span>{props.bookData.title}</span>
            </div>

        </div>
    );
};

export default translate()(RTRBookReaderPageHeader);
