import { createAsyncActions, runAsyncWorkflow } from '../lib/reduxHelpers';

import { User } from '../api';

export const [fetchMe, receiveMe, fetchMeError] = createAsyncActions(
    'ME',
    () => { },
    ({ data }) => {
        //--- For google analytics
        // window.dataLayer.push({
        //     event: 'API Request', // prepend response data with this key/value pair
        //     api_name: 'me', // prepend response data with this key/value pair and add API path
        //     me: {
        //         bookshelf: data.bookshelf,
        //         downloadLimitReached: data.downloadLimitReached,
        //         hasHistory: data.hasHistory,
        //         homePopup: data.homePopup,
        //         id: data.id,
        //         isLoggedIn: data.isLoggedIn,
        //         languagePreferences: data.languagePreferences,
        //         locale: data.locale,
        //         readingLevels: data.readingLevels,
        //         role: data.role,
        //     },
        // });
        return { profile: data };
    }
);

export const fetchMeWorkflow = () => {
    const fetchPromise = User.fetchMe();

    return runAsyncWorkflow(
        {
            fetch: fetchMe,
            receive: receiveMe,
            error: fetchMeError,
        },
        fetchPromise
    );
};

export const [
    fetchLogout,
    receiveLogout,
    fetchLogoutError,
] = createAsyncActions('LOGOUT', () => { }, () => { });

export const logoutWorkflow = () => {
    const fetchPromise = User.logout();

    return runAsyncWorkflow(
        {
            fetch: fetchLogout,
            receive: receiveLogout,
            error: fetchLogoutError,
        },
        fetchPromise,
        {},
        false
    );
};

export const [
    fetchChangeLanguage,
    receiveChangeLanguage,
    changeLanguageError,
] = createAsyncActions(
    'CHANGE_LANGUAGE',
    () => { },
    ({ newLanguage }) => ({ newLanguage })
);

export const changeLanguage = newLanguage => {
    const changeLanguagePromise = User.changeLanguage(newLanguage).then(() => {
        localStorage.setItem('locale', newLanguage);
        window.location.reload();
    });

    return runAsyncWorkflow(
        {
            fetch: fetchChangeLanguage,
            receive: receiveChangeLanguage,
            error: changeLanguageError,
        },
        changeLanguagePromise,
        { newLanguage }
    );
};
export const [
    fetchChangeLanguage1,
    receiveChangeLanguage1,
    changeLanguageError1,
] = createAsyncActions(
    'CHANGE_LANGUAGE',
    () => { },
    ({ brLanguage }) => ({ brLanguage })
);

export const browserLanguage = brLanguage => {
    const browserLanguagePromise = User.changeLanguage(brLanguage);

    return runAsyncWorkflow(
        {
            fetch: fetchChangeLanguage1,
            receive: receiveChangeLanguage1,
            error: changeLanguageError1,
        },
        browserLanguagePromise
    );
};

export const [
    fetchMenuList,
    receiveMenuList,
    menuListError,
] = createAsyncActions(
    'MENU_LIST',
    () => { },
    ({ data }) => ({ data })
);

export const getMenuList = brLanguage => {
    return async (dispatch) => {
        try {
            let menuList = await User.fetchHeaderAndFooterNavLinks(brLanguage);
            menuList = await menuList.json();

            dispatch(receiveMenuList(menuList));
        } catch (error) {
            dispatch(menuListError(error));
        }
    };
};

