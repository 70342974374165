import React from 'react';
import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
import classNames from 'classnames';
import './Link.scss';

// eslint-disable-next-line no-script-url
const noopStr = 'javascript:;';
const disableInternalLinks = process.env.REACT_APP_MODE === 'bookends' || false;

const Link = ({
    isInternal: propIsInternal = false,
    theme = 'default',
    noPadding = false,
    href: propHref,
    children,
    fullWidth,
    parentClassName,
    shouldOpenNewPage,
    title,
    legend,
    disabled,
    normal,
    onClick,
    tabIndex,
    storyCredit,
    favouriteShare,
    saveOffline,
    tags,
    bookRelatedCollection,
    readOtherLanguage,
    translation,
    translationList,
    download,
    lightBlack,
    paddingTop,
    lightBlackResponsive,
    cardLevelOffline,
    paddingTopHome,
    logInButton,
    titleClass,
    dataGTM,
    isExternal,
    offlineLink,
    readingListCard,
    contactUs,
    donateLink,
    filterMobile,
    fullWidthShare,
    readingList,
    audioLink,
    inputRef: propInputRef = () => {}
}) => {
    const baseClassName = 'pb-link';
    let href = propHref || noopStr;

    let isInternal = propIsInternal;
    if (disableInternalLinks || onClick) {
        isInternal = false;
    }

    const handleClick = (e) => {
        if (disabled) {
            e.stopPropagation();
            e.preventDefault();
        } else if (onClick) {
            onClick(e);
        }
    };

    const inputRef = (input) => {
        propInputRef(input);
    };

    const legendEl = legend ? (
        <span className={`${baseClassName}__legend`}>{legend}</span>
    ) : null;

    const classes = {
        [baseClassName]: !donateLink,
        [`${baseClassName}--${theme}`]: theme,
        [`${baseClassName}--no-padding`]: noPadding,
        [`${baseClassName}--full-width`]: fullWidth,
        [`${baseClassName}--disabled`]: disabled,
        [`${baseClassName}--normal`]: normal,
        [`${baseClassName}--story-credit`]: storyCredit,
        [`${baseClassName}--fav-share`]: favouriteShare,
        [`${baseClassName}--save-offline`]: saveOffline,
        [`${baseClassName}--tags`]: tags,
        [`${baseClassName}--book-related-collection`]: bookRelatedCollection,
        [`${baseClassName}--read-other-language`]: readOtherLanguage,
        [`${baseClassName}--translation`]: translation,
        [`${baseClassName}--translationList`]: translationList,
        [`${baseClassName}--light-black`]: lightBlack,
        [`${baseClassName}--light-black-responsive`]: lightBlackResponsive,
        [`${baseClassName}--padding-top`]: paddingTop,
        [`${baseClassName}--card-level-offline`]: cardLevelOffline,
        [`${baseClassName}--padding-top-home`]: paddingTopHome,
        [`${baseClassName}--log-in-button`]: logInButton,
        [`${baseClassName}--offline-link`]: offlineLink,
        [`${baseClassName}--readinglist-card`]: readingListCard,
        [`${baseClassName}--contact-us`]: contactUs,
        [`donate`]: donateLink,
        [parentClassName]: parentClassName,
        [`${baseClassName}--filter-mobile`]: filterMobile,
        [`${baseClassName}--full-width-share-menu`]: fullWidthShare,
        [`${baseClassName}--list-share`]: readingList,
        [`${baseClassName}--audio-link-icon`]: audioLink
    };

    if (isInternal && !disabled) {
        return (
            <RouterLink
                to={href}
                className={classNames(classes)}
                title={title}
                tabIndex={tabIndex}
                data-gtm={dataGTM}
            >
                {children}
                {legendEl}
            </RouterLink>
        );
    } else if (shouldOpenNewPage || isExternal) {
        return (
            <a
                href={href}
                className={classNames(classes)}
                title={title}
                target="_blank"
                rel="noreferrer"
                onClick={handleClick}
                tabIndex={tabIndex}
                download={download || null}
                data-gtm={dataGTM}
                ref={inputRef}
            >
                {children}
                {legendEl}
            </a>
        );
    } else {
        return (
            <a
                href={href}
                className={classNames(classes)}
                title={title}
                onClick={handleClick}
                tabIndex={tabIndex}
                download={download || null}
                data-gtm={dataGTM}
                ref={inputRef}
            >
                {children}
                {legendEl}
            </a>
        );
    }
};

Link.propTypes = {
    isInternal: PropTypes.bool,
    shouldOpenNewPage: PropTypes.bool,
    children: PropTypes.node.isRequired,
    parentClassName: PropTypes.string,
    theme: PropTypes.oneOf([
        'default',
        'light',
        'info',
        'danger',
        'warning',
        'success',
        'dark',
        'offline',
    ]),
    title: PropTypes.string,
    fullWidth: PropTypes.bool,
    legend: PropTypes.string,
    disabled: PropTypes.bool,
    onClick: PropTypes.func,
    tabIndex: PropTypes.string,
    noPadding: PropTypes.bool,
    inputRef: PropTypes.func,
};

export default Link;
