import { handleActions } from 'redux-actions';
import u from 'updeep';
import * as actions from './readAloudsActions';

const initialState = {
    isFetchingFilters: false,
    filters: null,
    readAlouds: [],
    videoDetail: {},
    isFetchingReadAlouds: false,
    isFetchingMoreReadAlouds: false, // for pagination
    loadedPages: 0,
    totalVideosCount: 0,
    hits: 0,
    favouriteReadAlouds: [],
    isFetchingFavouriteReadAlouds: false,
    totalFavouriteReadAloudsCount: 0,
    isFetchingVideoDetail: false,
};

export default handleActions(
    {
        [actions.fetchReadAloudFilters]: (state, action) =>
            u(
                {
                    isFetchingFilters: true,
                },
                state
            ),
        [actions.receiveReadAloudFilters]: (state, action) =>
            u(
                {
                    isFetchingFilters: false,
                    filters: action.payload.filters,
                },
                state
            ),
        [actions.fetchReadAlouds]: (state, action) =>
            u(
                {
                    isFetchingReadAlouds: true
                },
                state
            ),
        [actions.receiveReadAlouds]: (state, action) =>
            u(
                {
                    isFetchingReadAlouds: false,
                    totalVideosCount: action.payload.metadata.totalPages,
                    readAlouds: action.payload.readAlouds,
                    loadedPages: 1,
                    hits: action.payload.metadata.hits,
                },
                state
            ),
        [actions.fetchMoreReadAlouds]: (state, action) =>
            u(
                {
                    isFetchingMoreReadAlouds: true,
                },
                state
            ),

        [actions.receiveMoreReadAlouds]: (state, action) =>
            u(
                {
                    isFetchingMoreReadAlouds: false,
                    readAlouds: state.readAlouds.concat(action.payload.readAlouds),
                    loadedPages: state.loadedPages + 1,
                },
                state
            ),

        [actions.receiveVideoReadCount]: (state, action) => u({}, state),

        [actions.favouriteVideo]: (state, action) => {
            //Get the associated video and set its isFovouriting state
            //Get the index of the video
            let index = state.readAlouds.findIndex(
                video => video.id === action.payload.videoId
            );

            const favVideoIndex = state.favouriteReadAlouds.findIndex(
                video => video.id === action.payload.videoId
            );
            if (index >= 0) {
                return u(
                    {
                        readAlouds: u.updateIn([index, 'isFavouriting'], true),
                    },
                    state
                );
            } else if (favVideoIndex >= 0) {
                return u(
                    {
                        favouriteReadAlouds: u.updateIn(
                            [favVideoIndex, 'isFavouriting'],
                            true
                        ),
                    },
                    state
                );
            } else {
                return state;
            }
        },
        [actions.receiveFavouriteVideo]: (state, action) => {
            //Get the associated video and set its isFovouriting state
            //Get the index of the video
            const index = state.readAlouds.findIndex(
                video => video.id === action.payload.videoId
            );

            const favVideoIndex = state.favouriteReadAlouds.findIndex(
                video => video.id === action.payload.videoId
            );

            if (index >= 0) {
                let favourite = state.readAlouds[index].is_favourite;
                const newLikes = !favourite
                    ? state.readAlouds[index].likesCount + 1
                    : state.readAlouds[index].likesCount - 1;
                const likesTemp = u(
                    {
                        readAlouds: u.updateIn([index, 'likesCount'], newLikes),
                    },
                    state
                );
                let temp = u(
                    {
                        readAlouds: u.updateIn([index, 'isFavouriting'], false),
                    },
                    likesTemp
                );
                return u(
                    {
                        readAlouds: u.updateIn([index, 'is_favourite'], !favourite),
                    },
                    temp
                );
            } else if (favVideoIndex >= 0) {
                let favourite =
                    state.favouriteReadAlouds[favVideoIndex].is_favourite;
                const newLikes = !favourite
                    ? state.favouriteReadAlouds[favVideoIndex].likesCount + 1
                    : state.favouriteReadAlouds[favVideoIndex].likesCount - 1;
                const likesTemp = u(
                    {
                        favouriteReadAlouds: u.updateIn(
                            [favVideoIndex, 'likesCount'],
                            newLikes
                        ),
                    },
                    state
                );
                let temp = u(
                    {
                        favouriteReadAlouds: u.updateIn(
                            [favVideoIndex, 'isFavouriting'],
                            false
                        ),
                    },
                    likesTemp
                );
                return u(
                    {
                        favouriteReadAlouds: favourite
                            ? state.favouriteReadAlouds.filter(
                                favVideo =>
                                    favVideo.id !== action.payload.videoId
                            )
                            : state.favouriteReadAlouds,
                        totalFavouriteReadAloudsCount: favourite
                            ? state.totalFavouriteReadAloudsCount - 1
                            : state.totalFavouriteReadAloudsCount,
                    },
                    temp
                );
            } else {
                return state;
            }
        },

        [actions.fetchFavouriteVideos]: (state, action) =>
            u(
                {
                    isFetchingFavouriteReadAlouds: true,
                },
                state
            ),

        [actions.receiveFetchFavouriteVideos]: (state, action) =>
            u(
                {
                    isFetchingFavouriteReadAlouds: false,
                    totalFavouriteReadAloudsCount: action.payload.metadata.hits,
                    favouriteReadAlouds: action.payload.favVideos,
                },
                state
            ),
        [actions.fetchVideoDetail]: (state, action) =>
            u(
                {
                    isFetchingVideoDetail: true,
                },
                state
            ),

        [actions.receivefetchVideoDetail]: (state, action) =>
            u(
                {
                    isFetchingVideoDetail: false,
                    videoDetail: action.payload.videoDetail,
                },
                state
            ),
        [actions.fetchVideoDetailError]: (state, action) =>
            u(
                {
                    isFetchingVideoDetail: false,
                },
                state
            ),
        [actions.favouriteSimilarReadAloud]: (state, action) => {
            let index = state.videoDetail.similar_videos.findIndex(
                video => video.id === action.payload.videoId
            );

            if (index >= 0) {
                let newState = u(
                    {
                        similar_videos: u.updateIn([index, 'isFavouriting'], true),
                    },
                    state.videoDetail
                );
                let videoDetail = newState;
                let data = { ...state, videoDetail }
                return data;
            } else {
                return state;
            }
        },

        [actions.receivefavouriteSimilarReadAloud]: (state, action) => {
            const index = state.videoDetail.similar_videos.findIndex(
                video => video.id === action.payload.videoId
            );

            if (index >= 0) {

                let favourite = state.videoDetail.similar_videos[index].is_favourite;
                const newLikes = !favourite
                    ? state.videoDetail.similar_videos[index].likesCount + 1
                    : state.videoDetail.similar_videos[index].likesCount - 1;
                const likesTemp = u(
                    {
                        similar_videos: u.updateIn([index, 'likesCount'], newLikes),
                    },
                    state.videoDetail
                );
                let temp = u(
                    {
                        similar_videos: u.updateIn([index, 'isFavouriting'], false),
                    },
                    likesTemp
                );
                const newState = u(
                    {
                        similar_videos: u.updateIn([index, 'is_favourite'], !favourite),
                    },
                    temp
                );
                let videoDetail = newState;
                let data = { ...state, videoDetail }
                return data;

            } else {
                return state;
            }
        }
    },
    initialState
);