import React from 'react';
import PropTypes from 'prop-types';
import Block from '../../Block';
import ContentStyler from '../../ContentStyler';
import SiteNavLink from '../../SiteNavLink';
import { links } from '../../../lib/constants';
import rightArrow from '../../../assets/right-arrow.svg';
import VideoGrid from './VideoGrid';
import '../Home.scss';

const Video = ({
    t,
    videos,
    hasUserHistory,
    viewport,
    videoReadCountWorkflow,
    isRightToLeftDirection,
    isLoggedIn,
}) => {
    if (!videos || videos.length === 0) {
        return null;
    }

    return (
        <div>
            <Block background="transparent">
                <ContentStyler>
                    {videos.length !== 0 ? (
                        <div className="__homepage-recommended-video">
                            {viewport.large ? (
                                <h1 className="font_home_tab">
                                    {hasUserHistory
                                        ? t('Home.book-suggestions-title')
                                        : t('Home.videos-recommended-video')}
                                </h1>
                            ) : (
                                <div className="home">
                                    {hasUserHistory
                                        ? t('Home.book-suggestions-title')
                                        : t('Home.videos-recommended-video')}
                                </div>
                            )}
                            {viewport.large ? (
                                <SiteNavLink
                                    key="videos"
                                    isInternal={isLoggedIn}
                                    variant="bordered"
                                    respondWidth
                                    shouldHide
                                    moreVideos
                                    dataGTM="see-more-videos"
                                    href={
                                        isLoggedIn
                                            ? '/videos'
                                            : links.fetchTabLogin('videos')
                                    }
                                >
                                    {t('global.see-more-videos')}
                                    <img
                                        className={
                                            isRightToLeftDirection
                                                ? '__left-arrow'
                                                : '__right-arrow'
                                        }
                                        src={rightArrow}
                                        alt=""
                                        data-gtm="see-more-videos"
                                    />
                                </SiteNavLink>
                            ) : null}
                        </div>
                    ) : null}
                </ContentStyler>
            </Block>
            <Block
                noHorizontalPadding
                noVerticalPadding
                background="transparent"
            >
                <VideoGrid
                    videos={videos}
                    videoReadCountWorkflow={videoReadCountWorkflow}
                    t={t}
                    isLoggedIn={isLoggedIn}
                />
            </Block>
            {viewport.large ? null : (
                <div className="center-align">
                    <SiteNavLink
                        isInternal={isLoggedIn}
                        variant="bordered"
                        respondWidth
                        shouldHide
                        moreVideos
                        dataGTM="see-more-videos"
                        href={
                            isLoggedIn
                                ? '/videos'
                                : links.fetchTabLogin('videos')
                        }
                    >
                        {t('global.see-more-videos')}
                        <img
                            className={
                                isRightToLeftDirection
                                    ? '__left-arrow'
                                    : '__right-arrow'
                            }
                            src={rightArrow}
                            alt=""
                            data-gtm="see-more-videos"
                        />
                    </SiteNavLink>
                </div>
            )}
        </div>
    );
};

Video.propTypes = {
    t: PropTypes.func.isRequired,
    videos: PropTypes.arrayOf(PropTypes.object).isRequired,
    hasUserHistory: PropTypes.bool.isRequired,
    viewport: PropTypes.shape({
        large: PropTypes.bool.isRequired,
    }).isRequired,
    videoReadCountWorkflow: PropTypes.func.isRequired,
    isRightToLeftDirection: PropTypes.bool.isRequired,
    isLoggedIn: PropTypes.bool.isRequired,
};

export default Video;
