import React, {Component} from 'react'
import SvgIcon from '../SvgIcon';
import Tooltip from 'react-tooltip'
import './InputFieldTitle.scss';

class InputFieldTitle extends Component {
    render(){
        const {
            isRequired,
            isRightToLeftDirection,
            value,
            isSubHeading,
            tooltip,
            tooltipData
        } = this.props
        return(
            <div style={{display:'flex',flexWrap:'wrap',marginBottom:'9px'}}>
                <h4 className={isRightToLeftDirection 
                    ? 'input-field-title-rtl'
                    : 'input-field-title'}
                >
                    {value}
                </h4>
                {
                    isRequired && 
                    <SvgIcon
                        name="label-text"
                        size="custom"
                        labelText
                    />
                }
                {
                    tooltip && 
                    <div style={{position:'relative'}}>
                        <div style={{position:'absolute',top:'-6px',margin:'0 5px'}}
                            data-tip={tooltipData}
                        >
                            <SvgIcon
                                name="info-grey"
                                
                                infoGrey
                            />
                        </div>
                        <Tooltip multiline/>
                    </div>
                   
                    
                }
                {
                    isSubHeading && 
                    <h4 className={isRightToLeftDirection 
                        ? 'input-field-sub-heading-rtl'
                        : 'input-field-sub-heading'}
                    >
                        {isSubHeading}
                    </h4> 
                }
            </div>
        );
    }
}

export default InputFieldTitle 