import React, { Component } from 'react';
import classNames from 'classnames';
import { translate } from 'react-polyglot';
import { connect } from 'react-redux';
import DocumentTitle from 'react-document-title';
import PropTypes from 'prop-types';
import Block from '../Block';
import ContentStyler from '../ContentStyler';
import LoaderBlock from '../LoaderBlock';
import VideoCard from '../VideoCard';
import ResourceCard from '../ResourceCard';
import BookCard from '../BookCard';
import Grid from '../Grid';
import Link from '../Link';

import { fetchFavouriteVideosWorkflow } from '../../redux/videoActions';
import { fetchFavouriteBooksWorkflow } from '../../redux/bookActions';
import { fetchFavouriteResourcesWorkflow } from '../../redux/referencesActions';

import './MyFavouritesContainer.scss';

const mapDispatchToProps = {
    fetchFavouriteVideosWorkflow,
    fetchFavouriteBooksWorkflow,
    fetchFavouriteResourcesWorkflow,
};

const mapStateToProps = state => {
    return {
        favouriteBooks: state.book.favouriteBooks,
        favouriteVideos: state.videos.favouriteVideos,
        favouriteResources: state.references.favouriteResources,
        booksCount: state.book.totalFavouriteBooksCount,
        videosCount: state.videos.totalFavouriteVideosCount,
        resourcesCount: state.references.totalFavouriteResourcesCount,
        isFetchingData:
            state.references.isFetchingFavouriteResources ||
            state.book.isFetchingFavouriteBooks ||
            state.videos.isFetchingFavouriteVideos,
    };
};

@connect(
    mapStateToProps,
    mapDispatchToProps
)
@translate()
class MyFavouritesContainer extends Component {
    // static defaultProps = {}
    constructor(props) {
        super(props)
        this.state = {
            selectedTab: 'books',
            updatedVideosCount: 0,
            updatedResourcesCount: 0
        };
    }

    /* The function will create required cards */
    createRequiredCards = selectedTab => {
        switch (selectedTab) {
            case 'books':
                return this.createBooksCards();
            case 'videos':
                return this.createVideosCards();
            case 'resources':
                return this.createResourcesCards();
            default:
                break;
        }
    };

    /* The function will create video cards */
    createVideosCards = () => {
        const { favouriteVideos } = this.props;
        const myLanguage = localStorage.getItem('locale')

        return favouriteVideos.map(videoItem => {
            return (
                <div key={videoItem.id}>
                    <VideoCard
                        myLanguage={myLanguage}
                        videoID={videoItem.id}
                        videoSlug={videoItem.slug}
                        videoTitle={videoItem.name}
                        videoThumbnail={videoItem.thumbUrl}
                        videoUrl={videoItem.materialUrl}
                        videoDownloadUrl={videoItem.downloadUrl}
                        videoLikes={videoItem.likesCount}
                        videoReads={videoItem.readsCount}
                        videoTime={videoItem.duration}
                        isFavourite={videoItem.is_favourite}
                        videoTitleGTM="my-favorite-video-title"
                        videoThumbnailGTM="my-favorite-video-thumbnail"
                    />
                </div>
            );
        });
    };

    /* The function will create resources cards */
    createResourcesCards = () => {
        const { favouriteResources } = this.props;
        const myLanguage = localStorage.getItem('locale')
        return favouriteResources.map(referenceItem => {
            return (
                <div key={referenceItem.id}>
                    <ResourceCard
                        myLanguage={myLanguage}
                        id={referenceItem.id}
                        slug={referenceItem.slug}
                        title={referenceItem.title}
                        contentUrl={referenceItem.contentUrl}
                        likesCount={referenceItem.likesCount}
                        readsCount={referenceItem.readsCount}
                        isFavourite={referenceItem.is_favourite}
                        coverImage={referenceItem.thumbUrl}
                        resourceThumbnailGTM="my-favorite-resource-thumbnail"
                        resourceTitleGTM="my-favorite-resource-title"
                    />
                </div>
            );
        });
    };

    /* The function will create books cards */
    createBooksCards = () => {
        const { favouriteBooks } = this.props;

        const myLanguage = localStorage.getItem('locale')

        return favouriteBooks.map(book => {
            return (
                <BookCard
                    myLanguage={myLanguage}
                    key={book.slug}
                    id={book.id}
                    book={book}
                    faded={book.storyDownloaded}
                    bookLikes={book.likesCount}
                    isFavouriting={book.isFavouriting}
                    isFavourite={book.is_favourite}
                    enableFavouriting
                    bookImageGTM="my-favorite-book-image"
                    bookTitleGTM="my-favorite-book-title"
                    bookAuthorGTM="my-favorite-book-author"
                    bookIllustratorsGTM="my-favorite-book-illustrators"
                    bookLevelGTM="my-favorite-book-level"
                />
            );
        });
    };

    tabSelectHandler = selectedTab => {
        const {
            fetchFavouriteBooksWorkflow,
            fetchFavouriteResourcesWorkflow,
            fetchFavouriteVideosWorkflow,
        } = this.props;
        this.setState({ selectedTab: selectedTab });
        switch (selectedTab) {
            case 'books':
                fetchFavouriteBooksWorkflow().then(response => {
                    this.setState({
                        updatedVideosCount: response&&response.counts&&response.counts.videos,
                        updatedResourcesCount: response&&response.counts&&response.counts.resources
                    })
                });
                break;
            case 'videos':
                fetchFavouriteVideosWorkflow().then(response => {
                    this.setState({
                        updatedVideosCount: response.counts.videos,
                        updatedResourcesCount: response.counts.resources
                    })
                });
                break;
            case 'resources':
                fetchFavouriteResourcesWorkflow().then(response => {
                    this.setState({
                        updatedVideosCount: response.counts.videos,
                        updatedResourcesCount: response.counts.resources
                    })
                });
                break;
            default:
                break;
        }
    };

    getZeroFavouritesJsx = () => {
        const { booksCount, isFetchingData } = this.props;
        const { selectedTab, updatedVideosCount, updatedResourcesCount } = this.state;

        let href = '';
        let text = '';

        if (selectedTab === 'books' && booksCount <= 0 && !isFetchingData) {
            href = '/stories?sort=Relevance';
            text = 'MyFavouritesContainer.addAFavouriteBook';

        } else if (selectedTab === 'videos' && updatedVideosCount <= 0 && !isFetchingData) {
            href = '/videos';
            text = 'MyFavouritesContainer.addAFavouriteVideo';

        } else if (selectedTab === 'resources' && updatedResourcesCount <= 0 && !isFetchingData) {
            href = '/resources';
            text = 'MyFavouritesContainer.addAFavouriteResource';

        } else {
            return null;
        }

        return (<div className="pb-my-favourites__zero-fav">
            <Link href={href}>
                {this.props.t(text)}
            </Link>
        </div>);

    }

    componentDidMount() {
        this.tabSelectHandler('books');
    }

    componentDidUpdate(prevProps, prevState) {
        if(prevProps.videosCount !== this.props.videosCount) {
            this.setState({
                updatedVideosCount: this.props.videosCount,
            })
        }
        if(prevProps.resourcesCount !== this.props.resourcesCount) {
            this.setState({
                updatedResourcesCount: this.props.resourcesCount,
            })
        }
    }

    render() {
        const baseClassName = 'pb-my-favourites-container';
        const {
            parentClassName,
            t,
            isFetchingData,
            booksCount,
        } = this.props;

        const classes = {
            [baseClassName]: true,
            [parentClassName]: parentClassName,
        };

        const titleEL = (
            <Block offlineSection normalPadding>
                <ContentStyler myFavourite>
                    <div className="__myfavourites-header">
                        <h3>{t('global.my-favorites')}</h3>
                    </div>
                </ContentStyler>
            </Block>
        );

        const { selectedTab, updatedVideosCount, updatedResourcesCount } = this.state;

        const booksTabText = `${t('Book.book-text')} (${booksCount})`;
        const videosTabText = `${t('global.video', 2)} (${updatedVideosCount})`;
        const resourcesTabText = `${t(
            'global.resource',
            2
        )} (${updatedResourcesCount})`;

        const allTabs = [
            <div
                className={
                    selectedTab === 'books'
                        ? '__underlineStyle'
                        : '__inner-navbar'
                }
                onClick={() => this.tabSelectHandler('books')}
            >
                {booksTabText}
            </div>,
            <div
                className={
                    selectedTab === 'videos'
                        ? '__underlineStyle'
                        : '__inner-navbar'
                }
                onClick={() => this.tabSelectHandler('videos')}
            >
                {videosTabText}
            </div>,
            <div
                className={
                    selectedTab === 'resources'
                        ? '__underlineStyle'
                        : '__inner-navbar'
                }
                onClick={() => this.tabSelectHandler('resources')}
            >
                {resourcesTabText}
            </div>,
        ];

        const favouriteTabEl = (
            <div className="__my-favorite-navbar-section">
                <div className="__my-favorite-navbar">{allTabs}</div>
            </div>
        );

        const requiredCardsEl = isFetchingData ? (
            <LoaderBlock />
        ) : (
            <Grid variant="4up">
                {this.createRequiredCards(this.state.selectedTab)}
            </Grid>
        );

        const cardsContainer = (
            <div className="__my-readinglist-container-section">
                <div className="__video-card-section">
                    {this.getZeroFavouritesJsx()}
                    {requiredCardsEl}
                </div>
            </div>
        );

        return (
            <div className={classNames(classes)}>
                <DocumentTitle title={t('global.my-favorites')} />
                {titleEL}
                {favouriteTabEl}
                {cardsContainer}
            </div>
        );
    }
}

MyFavouritesContainer.propTypes = {
    parentClassName: PropTypes.string,
};

export default MyFavouritesContainer;
