import React, { Component } from 'react';
import { translate } from 'react-polyglot';
import './RTRBookReaderPageFooter.scss';
import Modal from '../../../Modal';
import Link from '../../../Link';
import VideoPlayer from '../../../VideoPlayer';
import closeIcon from '../../../../assets/closeIcon.png';
import SvgIcon from '../../../SvgIcon';
import Button from '../../../Button';

class RTRBookReaderPageFooter extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isReadToMeModalVisible: false,
            isReadToMeVideoPlay: false,
            readToMeCount: null,
            zoom: 1,
            pageNo: this.props.pageNo,
            totalPageCount: this.props.totalPageCount,
        };
    }
    readToMeModalOpen = () => {
        if (this.props.book.readAlouds[0]) {
            this.setState({
                isReadToMeModalVisible: true,
                isReadToMeVideoPlay: true,
                readToMeCount: this.props.book.readAlouds[0].readCount + 1,
            });
            const storyVideoId =
                this.props.book.readAlouds[0].coachingMaterialId;
            this.props.readToMeAction(storyVideoId);
        }
    };



    readToMeModalClose = () => {
        this.setState({
            isReadToMeModalVisible: false,
            isReadToMeVideoPlay: false,
        });
    };
    zoomIn() {
        if (this.state.zoom < 1.3) {
            var myImg = document.getElementById('bookReaderPageWrapper');
            this.setState({ zoom: this.state.zoom + 0.05 });
            myImg.style.transform = 'scale(' + (this.state.zoom + 0.05) + ')';
        }
    }
    zoomOut() {
        if (this.state.zoom > 0.7) {
            var myImg = document.getElementById('bookReaderPageWrapper');

            this.setState({ zoom: this.state.zoom - 0.05 });
            myImg.style.transform = 'scale(' + (this.state.zoom - 0.05) + ')';
        }
    }
    fullScreen() {
        let platform = window.navigator.platform,
            macosPlatforms = [
                'Macintosh',
                'MacIntel',
                'MacPPC',
                'Mac68K',
                'iPad',
            ];
        //windowsPlatforms = ['Win32', 'Win64', 'Windows', 'WinCE'];
        var myImg = document.getElementsByClassName('pb-app__content');

        if (macosPlatforms.indexOf(platform) !== -1) {
            myImg[0].webkitRequestFullscreen();
        } else {
            myImg[0].requestFullscreen();
        }
    }
    readToMeModal = () => {
        return (
            <Modal>
                <div className="__close">
                    <Link onClick={() => this.readToMeModalClose()}>
                        <img
                            className="__profile-images"
                            src={closeIcon}
                            alt=""
                        />
                    </Link>
                </div>
                <div>
                    <VideoPlayer
                        name={this.props.book.readAlouds[0].filename}
                        videoSrc={this.props.book.readAlouds[0].url}
                        videoPlay={this.state.isReadToMeVideoPlay}
                    />
                </div>
            </Modal>
        );
    };
    render() {
        //getting rtl using localStorage
        const isRightToLeftDirection = localStorage.getItem('locale') === 'ar' || localStorage.getItem('locale') === 'ur';
        // console.log("isRightToLeftDirection", isRightToLeftDirection);

        var totalNoOfPagesDesktop =
            (this.props.totalPageCount + 4) % 2 === 0
                ? (this.props.totalPageCount + 4) / 2
                : (this.props.totalPageCount + 5) / 2 - 1;
        var progressInProccessDesktop =
            this.props.pageNo / totalNoOfPagesDesktop;
        let mobilePageStatus = this.props.pageNo + 1;
        var progressInProccessMobile =
            mobilePageStatus / (this.props.totalPageCount + 3);
        return (
            <div className="RTRBookReaderPageFooter row">
                <div
                    className="progress progress-Mobile col-12"
                    style={{ height: '10px' }}
                >
                    <div
                        className="progress-bar"
                        role="progressbar"
                        style={{ width: `${progressInProccessMobile * 100}%` }}
                        aria-valuenow={progressInProccessMobile * 100}
                        aria-valuemin="0"
                        aria-valuemax="100"
                    ></div>
                </div>
                <div
                    className={`col-md-2 readToMeDesktop`}
                >
                    {    this.props.book.readAlouds.length > 0 &&
                        <Button
                        iconLeft="reader-view-read-to-me"
                        storyReader
                        fullWidth
                        label={this.props.t(
                            'Book.readToMe'
                        )}
                        variant="primary"
                        onClick={() =>
                            this.readToMeModalOpen()
                        }
                        isRightToLeftDirection={
                            isRightToLeftDirection
                        }
                        /> 
                    }  
                    {this.state.isReadToMeModalVisible && this.readToMeModal()}
                </div>
                <div
                    className={`col-4 ${!this.props.pageNo ? 'col-md-8' : 'col-lg-8'
                        } pageNavigatorBar ${isRightToLeftDirection ? 'pageNavigatorBar-rtl' : 'pageNavigatorBar'} `}
                >
                    <span className={`pageNoIndicator col-3 displayDesktop ${isRightToLeftDirection ? 'pageNoIndicator-rtl' : 'pageNoIndicator'} `}>
                        {`${this.props.pageNo} ${this.props.t(
                        'Book.footerPageTile2'
                    )} ${totalNoOfPagesDesktop}`}</span>
                    <span className="pageNoIndicator col-3 displayMobile">
                        {`${mobilePageStatus} ${this.props.t(
                        'Book.footerPageTile2'
                    )} ${this.props.totalPageCount + 3}`}</span>
                    <div
                        className="progress progress-Desktop col-9"
                        style={{ height: '10px' }}
                    >
                        <div
                            className="progress-bar"
                            role="progressbar"
                            style={{
                                width: `${progressInProccessDesktop * 100}%`,
                            }}
                            aria-valuenow={progressInProccessDesktop * 100}
                            aria-valuemin="0"
                            aria-valuemax="100"
                        ></div>
                    </div>
                </div>
                <div className={`BookMaginifyingControls ${isRightToLeftDirection ? "BookMaginifyingControls-rtl" : "BookMaginifyingControls"}  col-8 col-md-4 col-lg-2`}>
                    <span
                        className={`RTRReadToMeButton-Mobile ${!this.props.pageNo &&
                            this.props.book &&
                            this.props.book.readAlouds &&
                            this.props.book.readAlouds.length > 0
                            ? ''
                            : 'invisible'
                            }`}
                        onClick={() => {
                            this.readToMeModalOpen();
                        }}
                    >
                        {/* <img
                            className="RTRBookReadToMeIcon"
                            src={require('../../../../assets/RTRBookReadToMeIcon.svg')}
                            alt="Read to me Button"
                        /> */}
                        <SvgIcon 
                            name={"reader-view-read-to-me"} 
                            size="reader-view-controls"
                        />
                    </span>
                    <span
                        className="RTRBookZoomInButton"
                        onClick={() => {
                            this.zoomIn();
                        }}
                    >
                        {/* <img
                            className="RTRBookZoomIn"
                            src={require('../../../../assets/RTRBookZoomIn.svg')}
                            alt="Back Button To Book Page"
                        /> */}
                        <SvgIcon 
                            name="reader-view-zoom-in" 
                            size="reader-view-controls"
                        />
                    </span>
                    <span
                        className="RTRBookZoomOutButton"
                        onClick={() => {
                            this.zoomOut();
                        }}
                    >
                        {/* <img
                            className="RTRBookZoomOut"
                            src={require('../../../../assets/RTRBookZoomOut.svg')}
                            alt="Back Button To Book Page"
                        /> */}
                        <SvgIcon 
                            name="reader-view-zoom-out" 
                            size="reader-view-controls"
                        />
                    </span>

                    {window.screen.availWidth > 600 && (
                        <span
                            className={isRightToLeftDirection ? "RTRBookFullScreenViewButton RTRBookFullScreenViewButton-rtl" : "RTRBookFullScreenViewButton"}
                            onClick={() => {
                                this.fullScreen();
                            }}
                        >
                            {/* <img
                                className="RTRBookFullScreenView"
                                src={require('../../../../assets/RTRBookFullScreenView.svg')}
                                alt="Back Button To Book Page"
                            /> */}
                            <SvgIcon 
                                name="reader-view-fullscreen" 
                                size="reader-view-controls"
                            />
                        </span>
                    )}
                </div>
            </div>
        );
    }
}

export default translate()(RTRBookReaderPageFooter);
