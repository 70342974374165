import React, { Component } from 'react';
import Tags from './Tags';
import './Book.scss';

class BookTags extends Component {
    render() {
        const baseClassName = 'pb-book';
        const isRightToLeftDirection = localStorage.getItem('locale') === 'ar' || localStorage.getItem('locale') === 'ur' ;
        const { online, tags, t, storyReaderFinalPage, type } = this.props;

        return (
            <div className="__booktags">
                {!online || !tags || !tags.length ? null : (
                    <div className={ isRightToLeftDirection ? `${baseClassName}__tags-rtl` : `${baseClassName}__tags`}>
                        <div className={storyReaderFinalPage ? `${baseClassName}__ReaderSimilarTopicsTitle` : `${baseClassName}__SimilarTopicsTitle`}>
                            {t('Book.similarTopics')}
                        </div>
                        <Tags tags={tags} baseClassName={baseClassName} type={type}/>
                    </div>
                )}
            </div>
        );
    }
}

export default BookTags;
