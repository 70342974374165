import React, { Component } from 'react';
import classNames from 'classnames';
import { translate } from 'react-polyglot';
import PropTypes from 'prop-types';
import u from 'updeep';
import Block from '../Block';
import BookCard from '../BookCard';
import ContentStyler from '../ContentStyler';
import Grid from '../Grid';
import DeleteModal from './DeleteModal';
import Link from "../Link";
import './OfflineBooks.scss';

@translate()
class OfflineBooks extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isModalVisible: {
                delete: false,
            },
        };

        this.onOpenModal = this.onOpenModal.bind(this);
        this.onCloseModal = this.onCloseModal.bind(this);
    }

    onOpenModal(modalName) {
        this.setState(
            u(
                {
                    isModalVisible: {
                        [modalName]: true,
                    },
                },
                this.state
            )
        );
    }

    onCloseModal(modalName) {
        this.setState(
            u(
                {
                    isModalVisible: {
                        [modalName]: false,
                    },
                },
                this.state
            )
        );
    }

    renderBookEls() {
        const { books, online, myLanguage } = this.props;

        return books.map(book => {
            return (
                <BookCard
                    myLanguage={myLanguage}
                    key={book.id}
                    id={book.id}
                    book={book}
                    shouldDisplayMenu={false}
                    noSrcSet={!online}
                    hideReadCountStat={true}
                    bookLikes={book.likesCount}
                    isFavourite={book.is_favourite}
                    bookImageGTM="books-book-image"
                    bookTitleGTM="books-book-title"
                    bookAuthorGTM="books-book-author"
                    bookIllustratorsGTM="books-book-Illustrators"
                    bookLevelGTM="books-book-Level"
                    padding130={true}
                />
            );
        });
    }

    render() {
        const baseClassName = 'pb-offline-books';

        const {
            t,
            books,
            online,
            viewport,
            onDelete,
            checkedValues,
        } = this.props;

        const isRightToLeftDirection = localStorage.getItem("locale") === "ar" || localStorage.getItem('locale') === 'ur';

        const classes = {
            [baseClassName]: true,
            [`${baseClassName}--offline`]: !online,
        };

        const offlineText = () => {

            if (online && books.length <= 0) {
                return (
                    <div className="offline-link-button">
                        <Link
                            href={'/stories'}
                            isInternal
                            offlineLink
                            dataGTM="addBooksToOfflineLibrary"
                        >
                            {t('OfflineBooks.addBooksToOfflineLibrary')}
                        </Link>
                    </div>
                );

            } else if (!online && books.length <= 0) {
                return (
                    <div className="offline-text">
                        {t('OfflineBooks.addBooksWhenOnline')}
                    </div>
                );
            }
        }

        return (
            <div className={classNames(classes)}>
                <Block offlineSection normalPadding>
                    <ContentStyler offline>
                        <div className="__offline-title">
                            <h3>{t('global.offline-library')}</h3>
                        </div>
                        <div className="offline-books__sub-title">
                            {t('OfflineBookModal.popup')}
                        </div>
                    </ContentStyler>
                </Block>
                {
                process.env.REACT_APP_FEATURE_OFFLINE ? 
                (
                    <Block background="transparent" normalPadding>
                        <ContentStyler>
                            <div className="offline-books__resources-title">
                                <h3>{t('global.book', 2)}</h3>
                                <h3 className={isRightToLeftDirection ? "__total-margin-left" : "__normal"}>({books.length})</h3>
                            </div>
                        </ContentStyler>
                        {
                            ((books.length > 0)
                                ?
                                <div className={`${baseClassName}--offline__grid`}>
                                    <Grid variant="4up">
                                        {this.renderBookEls()}
                                    </Grid>
                                </div>
                                : null)
                        }
                    </Block>
                ) 
                :
                    null
                    }
                {this.state.isModalVisible.delete ? (
                    <DeleteModal
                        onConfirm={onDelete}
                        onClose={() => this.onCloseModal('delete')}
                        viewport={viewport}
                        count={checkedValues.length}
                        baseClassName={baseClassName}
                    />
                ) : null}

                {offlineText()}
            </div>
        );
    }
}

OfflineBooks.propTypes = {
    viewport: PropTypes.object.isRequired,
    t: PropTypes.func.isRequired,
    books: PropTypes.arrayOf(PropTypes.shape(BookCard.propTypes)).isRequired,
    onDelete: PropTypes.func,
    online: PropTypes.bool,
};

export default OfflineBooks;
