import React, { Component } from 'react';
import PropTypes from 'prop-types';
import MyReadingListDetail from '../MyReadingListDetail';

class MyReadingListDetailContainer extends Component {

    render() {
        return (
            <div>
                <MyReadingListDetail history={this.props.history} />
            </div>
        );
    }
}

MyReadingListDetailContainer.propTypes = {
    parentClassName: PropTypes.string,
};

export default MyReadingListDetailContainer;
