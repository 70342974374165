import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { translate } from 'react-polyglot';
import { connect } from 'react-redux';
import DocumentTitle from 'react-document-title';
import './MyReadingListDetail.scss';
import Block from '../Block';
import LoaderBlock from '../LoaderBlock';
import Grid from '../Grid';
import BookCard from '../BookCard';
import ContentStyler from '../ContentStyler';
import {
    deleteReadingListWorkflow,
    fetchReadingListBooksWorkflow,
    manageReadingListActionAsync,
    updateReadingListDetailsWorkflow
} from '../../redux/readingListsActions';
import Link from '../Link';
import { links } from '../../lib/constants';
import Modal from '../Modal';
import NotFoundPage from '../NotFoundPage';
import ShareMenu from '../ShareMenu';
import CreateNewReadingList from '../CreateNewReadingList/CreateNewReadingList';
import { API_URL } from '../../api';

class MyReadingListDetail extends Component {
    constructor(props) {
        super(props);
        // We operate on a copy of the reading list, since it's user editable.
        this.state = {
            bookSlug: window.location.href.split('/').slice(4),
            isDeleteList: false,
            isEditList: false,
            disable: false
        };

    }

    componentDidMount() {
        this.props.fetchReadingListBooksWorkflow(this.state.bookSlug);
    }

    nextPath = (path) => {
        this.props.history.push(path);
    }

    deleteReadingList = list => {
        let currentThis = this;
        console.log("this", currentThis)
        fetch(`${API_URL}/lists/${list.id}`, {
            method: 'DELETE',
            credentials: 'include',
        }).then(response => response.json())
            .then(response => {
                if (response.ok) {
                    setTimeout(function () {
                        currentThis.nextPath("/myreadinglists")
                    }
                        , 2500);
                }
                else {
                    console.log("failed");
                    alert("failed");
                }
            }
            );
    };

    manageReadingList = list => {
        this.props.manageReadingListActionAsync(list);
    };

    openDeleteModal = () => {
        this.setState({
            isDeleteList: true,
        });
    };

    closeDeleteModal = () => {
        this.setState({
            isDeleteList: false,
        });
    };

    handleEditSubmit = (updates = {}) => {
        this.closeEditModal();
        // Send updated reading list details to backend API
        const updatedData = {
            ...updates,
            id: this.props.readingListsBookDetail.id
        }

        this.props.updateReadingListDetailsWorkflow(updatedData).then(result => {
            const { id, title = '' } = result.data.list;
            const newSlug = `${id}-${title.toLowerCase().split(' ').join('-')}`;

            this.props.history.push(`/myreadinglistsdetail/${newSlug}`);    // Update url with the new slug value
            this.props.fetchReadingListBooksWorkflow(newSlug);  // Fetch updated details

        }).catch(err => {
            console.log('Error: Updating title/description of a reading list');
        });
    }

    openEditModal = () => {
        this.setState({
            isEditList: true,
        });
    };

    closeEditModal = () => {
        this.setState({
            isEditList: false,
        });
    };

    render() {
        const { t, readingListsBookDetail, isReadingListBooks } = this.props;

        const isRightToLeftDirection = localStorage.getItem("locale") === "ar" || localStorage.getItem('locale') === 'ur';

        if (isReadingListBooks) {
            return <LoaderBlock />
        }

        if (!isReadingListBooks && readingListsBookDetail.id === undefined) {
            return <NotFoundPage />
        }

        const deleteReadingListModal = (
            <Modal>
                {
                    <div className={isRightToLeftDirection ? "__delete-text-arabic" : "__delete-text"}>
                        {t('myReadingDeleteModal.pre-link')}
                        <span className="__list-title">
                            {readingListsBookDetail.title}
                        </span>
                        {t('myReadingDeleteModal.post-link')}
                    </div>
                }
                <div className="__delete-button-section">
                    <div className={isRightToLeftDirection ? "__cancel-button-arabic" : "__cancel-button"}>
                        <Link isInternal paddingTop dataGTM="no-button">
                            {
                                this.state.disable ?
                                    <span
                                        className="__done-button-disabled"
                                        data-gtm="no-button"
                                    >
                                        {t("global.no")}
                                    </span>
                                    :
                                    <span
                                        className="__done-button"
                                        onClick={this.closeDeleteModal}
                                        data-gtm="no-button"
                                    >
                                        {t("global.no")}
                                    </span>
                            }
                        </Link>
                    </div>
                    <div>
                        {
                            this.state.disable ?
                                <span
                                    className="__done-button-disabled"
                                    data-gtm="yes-button"
                                >
                                    <img src="/public/loader.svg" alt="loader" className="svgLoaderRL" style={{ margin: "0px 5px" }} />
                                    {t("global.yes")}
                                </span>
                                :
                                <span
                                    className="__done-button"
                                    data-gtm="yes-button"
                                    onClick={() => {
                                        this.deleteReadingList(
                                            readingListsBookDetail
                                        );
                                        this.setState({ disable: true });
                                    }
                                    }
                                >
                                    {t("global.yes")}
                                </span>
                        }
                    </div>
                </div>
            </Modal >
        );

        // Handle Edit title/ description of a reading list
        const editReadingListModal = (
            <Modal>
                <div className="__edit-button-section">
                    <CreateNewReadingList
                        edit
                        editSubmit={this.handleEditSubmit}
                        listDetails={readingListsBookDetail}
                    />
                    <span
                        className="edit-cancel-button"
                        onClick={this.closeEditModal}
                        data-gtm="no-button"
                    >
                        X
                    </span>
                </div>
            </Modal >

        );

        return (
            <div className="__themedReadingLists-container">
                <DocumentTitle
                    title={`${t('global.my-reading-lists')} - ${t(
                        'global.site-title'
                    )}`}
                />
                <Block offlineSection>
                    <ContentStyler>
                        <div className={isRightToLeftDirection ? "__themedReadinglist-header-arabic" : "__themedReadinglist-header"}>
                            <Link
                                normal
                                isInternal={true}
                                href={"/readinglists"}
                            >
                                {t("ReadingList.backLink")}
                            </Link>
                        </div>
                    </ContentStyler>
                </Block>
                <div className="__themedReadingLists-section">
                    <div className="__themedReadingListItem-container">
                        <div className="__themed-readinglist-section-header">
                            {

                                !readingListsBookDetail.isShared ?
                                    <div className={isRightToLeftDirection ? "__myReadinglist-action-buttons-arabic" : "__myReadinglist-action-buttons"}>
                                        <div
                                            onClick={() =>
                                                this.manageReadingList(
                                                    readingListsBookDetail
                                                )
                                            }
                                        >
                                            <Link
                                                href={links.managereadinglists(
                                                    readingListsBookDetail.slug
                                                )}
                                                isInternal
                                                paddingTop
                                                dataGTM="manage-reading-list"
                                            >

                                                <img
                                                    style={{ margin: '0 3px 3px 3px',height:'1rem' }}
                                                    src={require('../../assets/settings.svg')}
                                                    alt="Manage Reading List Icon"
                                                />
                                                <span
                                                    className="__action-button-text"
                                                    data-gtm="manage-reading-list"
                                                >
                                                    {t(
                                                        'Readinglist.manage-reading-list'
                                                    )}
                                                </span>
                                            </Link>
                                        </div>
                                        <div
                                            className="__delete-button"
                                            onClick={this.openDeleteModal}
                                        >
                                            <img
                                                style={{ margin: '0 3px 3px 3px',height:'1rem' }}
                                                src={require('../../assets/delete.svg')}
                                                alt="Manage Reading List Icon"
                                            />
                                            <span className="__action-button-text">
                                                {t(
                                                    'Readinglist.delete-reading-list'
                                                )}
                                            </span>
                                        </div>
                                        <div
                                            className="__edit-button"
                                            onClick={this.openEditModal}
                                        >
                                            <img
                                                style={{ margin: '0 3px 3px 3px',height:'1rem' }}
                                                src={require('../../assets/edit.svg')}
                                                alt="Manage Reading List Icon"
                                            />
                                            <span className="__action-button-text">
                                                {t('Readinglist.editFieldData')}
                                            </span>
                                        </div>
                                    </div>
                                    : null
                            }
                            <Block background="transparent">
                                <ContentStyler>
                                    <div className="__header-section">
                                        <div className="__title">
                                            <h3>
                                                {
                                                    readingListsBookDetail.title
                                                }
                                            </h3>
                                            <h3 className="__readinglist-count">
                                                (
                                                {
                                                    readingListsBookDetail.count
                                                }
                                                )
                                            </h3>
                                        </div>
                                    </div>
                                </ContentStyler>
                                {/* Socail Sharing for Reading List */}
                                <div className="__socialShare-container">
                                    <ShareMenu
                                        readingList
                                        align="left"
                                        title={readingListsBookDetail.title}
                                        desc={readingListsBookDetail.description}
                                        href={window.location.href}
                                    />
                                </div>
                                <div className={isRightToLeftDirection ? "__readinglist-header-text-arabic" : "__readinglist-header-text"}>
                                    {readingListsBookDetail.description}
                                </div>
                            </Block>
                            <div>
                                <Grid variant="4up">
                                    {readingListsBookDetail.books &&
                                        readingListsBookDetail.books.map(
                                            bookItem => {
                                                return (
                                                    <div className="__themedBookItem-section">
                                                        <BookCard
                                                            key={
                                                                bookItem.slug
                                                            }
                                                            id={bookItem.id}
                                                            book={bookItem}
                                                            isFavourite={
                                                                bookItem.is_favourite
                                                            }
                                                            bookLikes={bookItem.likesCount}
                                                            isThemedList
                                                            isFavouriting={bookItem.isFavouriting}
                                                            bookImageGTM="manage-book-image"
                                                            bookTitleGTM="manage-book-title"
                                                            bookAuthorGTM="manage-book-author"
                                                            bookIllustratorsGTM="manage-book-illustrators"
                                                            bookLevelGTM="manage-book-level"
                                                        />
                                                    </div>
                                                );
                                            }
                                        )}
                                </Grid>
                            </div>
                        </div>
                    </div>
                </div>
                {
                    this.state.isDeleteList && deleteReadingListModal
                }
                {
                    this.state.isEditList && editReadingListModal
                }
            </div>
        );
    }
}

MyReadingListDetail.propTypes = {
    parentClassName: PropTypes.string,
    fetchReadingListBooksWorkflow: PropTypes.func.isRequired,
};

const mapStateToProps = ({ readingLists }) => {
    return {
        readingListsBookDetail: readingLists.readingListsBookDetail,
        isReadingListBooks: readingLists.isReadingListBooks,
        loadedPages: readingLists.loadedPages,
    };
};

const mapDispatchToProps = {
    fetchReadingListBooksWorkflow,
    deleteReadingListWorkflow,
    manageReadingListActionAsync,
    updateReadingListDetailsWorkflow
};

export default translate()(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(MyReadingListDetail)
);
