import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { translate } from 'react-polyglot';
import { connect } from 'react-redux';
import DocumentTitle from 'react-document-title';
import Link from '../Link';
import Block from '../Block';
import LoaderBlock from '../LoaderBlock';
import Grid from '../Grid';
import BookCard from '../BookCard';
import ContentStyler from '../ContentStyler';
import './ThemedReadingListsDetailContainer.scss';
import { fetchReadingListBooksWorkflow } from '../../redux/readingListsActions';
import NotFoundPage from '../NotFoundPage';
import ShareMenu from '../ShareMenu';
import withRouter from "../../util/withRouter"
class ThemedReadingListsDetailContainer extends Component {
    constructor(props) {
        super(props);
        // We operate on a copy of the reading list, since it's user editable.
        this.state = {
            bookSlug: this.props.params.slug,
        };
    }

    componentWillMount() {
        this.props.fetchReadingListBooksWorkflow(window.location.pathname.split("/")[2]);   
    }

    render() {
        const { t, readingListsBookDetail, isReadingListBooks } = this.props;
        const isRightToLeftDirection = localStorage.getItem('locale') === 'ar' || localStorage.getItem('locale') === 'ur';

        if (isReadingListBooks) {
            return <LoaderBlock />
        }

        if (!isReadingListBooks && readingListsBookDetail.id === undefined) {
            return <NotFoundPage />
        }

        return (
            <div className="__themedReadingLists-container">
                <DocumentTitle
                    title={`${t('global.themed-reading-lists')} - ${t(
                        'global.site-title'
                    )}`}
                />
                <Block offlineSection>
                    <ContentStyler>
                        <div className={isRightToLeftDirection ? "__themedReadinglist-header-arabic" : "__themedReadinglist-header"}>
                            <Link
                                normal
                                isInternal={true}
                                href={"/readinglists"}
                            >
                                {t("ReadingList.backLink")}
                            </Link>
                        </div>
                    </ContentStyler>
                </Block>
                <div className="__themedReadingLists-section">
                    <div className="__themedReadingListItem-container">
                        <div className="__themed-readinglist-section-header">
                            <Block background="transparent">
                                <ContentStyler>
                                    <div className="__header-section">
                                        <div className="__title">
                                            <h3>
                                                {
                                                    readingListsBookDetail.title
                                                }
                                            </h3>
                                            <h3 className="__readinglist-count">
                                                {
                                                    readingListsBookDetail.count
                                                }
                                            </h3>
                                        </div>

                                        <div className="__right-side-header-section">
                                            {/* Socail Sharing for Reading List */}
                                            <div className="__reading-list-share-container">
                                                <ShareMenu
                                                    readingList
                                                    align="left"
                                                    title={readingListsBookDetail.title}
                                                    desc={readingListsBookDetail.description}
                                                    href={window.location.href}
                                                />
                                            </div>

                                            <div className="__readinglist-creater">
                                                <span className="__created-by-text">
                                                    {t(
                                                        'Illustration.created-by'
                                                    )}
                                                </span>
                                                {readingListsBookDetail.organisation ? (
                                                    <span className="__created-by-name">
                                                        {
                                                            readingListsBookDetail
                                                                .organisation
                                                                .name
                                                        }
                                                    </span>
                                                ) : (
                                                        <span className="__created-by-name">
                                                            {
                                                                readingListsBookDetail
                                                                    .publisher.name
                                                            }
                                                        </span>
                                                    )}
                                            </div>
                                        </div>
                                    </div>
                                </ContentStyler>
                                <div className={isRightToLeftDirection ? "__readinglist-header-text-arabic" : "__readinglist-header-text"}>
                                    {readingListsBookDetail.description}
                                </div>
                            </Block>
                            <div>
                                <Grid variant="4up">
                                    {readingListsBookDetail.books &&
                                        readingListsBookDetail.books.map(
                                            bookItem => {
                                                return (
                                                    <div className="__themedBookItem-section">
                                                        <BookCard
                                                            key={
                                                                bookItem.slug
                                                            }
                                                            id={bookItem.id}
                                                            book={bookItem}
                                                            isFavourite={
                                                                bookItem.is_favourite
                                                            }
                                                            isThemedList
                                                            isFavouriting={bookItem.isFavouriting}
                                                            bookLikes={bookItem.likesCount}
                                                            bookImageGTM="themed-book-image"
                                                            bookTitleGTM="themed-book-title"
                                                            bookAuthorGTM="themed-book-author"
                                                            bookIllustratorsGTM="themed-book-Illustrators"
                                                            bookLevelGTM="themed-book-Level"
                                                        />
                                                    </div>
                                                );
                                            }
                                        )}
                                </Grid>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

ThemedReadingListsDetailContainer.propTypes = {
    parentClassName: PropTypes.string,
    fetchReadingListBooksWorkflow: PropTypes.func.isRequired,
};

const mapStateToProps = ({ readingLists }) => {
    return {
        readingListsBookDetail: readingLists.readingListsBookDetail,
        isReadingListBooks: readingLists.isReadingListBooks,
        loadedPages: readingLists.loadedPages,
    };
};

const mapDispatchToProps = {
    fetchReadingListBooksWorkflow
};

export default withRouter(
    translate()(
        connect(
            mapStateToProps,
            mapDispatchToProps
        )(ThemedReadingListsDetailContainer)
    )
);
