import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { translate } from 'react-polyglot';
import Img from '../Img';
import Link from '../Link';
import { fetchReadingListBooksWorkflow } from '../../redux/readingListsActions';
import { imageSrcsetSizes, links } from '../../lib/constants';
import './BookCardSmall.scss';

const mapStateToProps = ({
    book,
    user: { isLoggedIn, profile },
    viewport,
    readingLists,
}) => ({
    userEmail: profile.email,
    isLoggedIn,
    profile,
    viewport,
});


const mapDispatchToProps = {
    fetchReadingListBooksWorkflow,
};

@translate()
@connect(
    mapStateToProps,
    mapDispatchToProps
)
class BookCardSmall extends Component {
    static defaultProps = {
        shouldDisplayMenu: true,
        illustrators: [],
        authors: [],
        originalAuthors: [],
    };

    constructor(props) {
        super(props);
        this.state = {
        };
    }

    handleClick = e => {
        if (this.props.disabled || this.props.loading) {
            e.preventDefault();
            e.stopPropagation();
        } else {
            if (this.props.onClick) {
                // Since 'href' will be always be there for the card
                // disabled the defaults when onClick prop is present.
                e.preventDefault();
                this.props.onClick(e);
            }
        }
    };

    fetchBooks = slug => {
        this.props.fetchReadingListBooksWorkflow(slug);
    };

    render() {
        const {
            readingListItem,
            isMyReadingList,
            t,
            readingListsImage,
            readingListsTitle,
            readingListsViewBooks,
            myLanguage,
        } = this.props;

        const isRightToLeftDirection = localStorage.getItem("locale") === "ar" || localStorage.getItem('locale') === 'ur';

        const {
            title,
            slug,
            coverImage,
            count,
        } = readingListItem;

        const baseClassName = 'pb-book-card-small';

        const bookPath = `/stories/${slug}`;

        const classes = {
            [baseClassName]: true,
        };
        const titleClasses = {
            [`${baseClassName}__title`]: true,
        };

        return (
            <div className={classNames(classes)}>
                <div className={isRightToLeftDirection ? `${baseClassName}__container-arabic` : `${baseClassName}__container`}>
                    <div className={`${baseClassName}__wrapper`}>
                        <Link
                            isInternal={true}
                            parentClassName={`${baseClassName}__link`}
                            href={
                                isMyReadingList
                                    ? links.myReadinglistsDetail(
                                        readingListItem.slug
                                    )
                                    : links.readinglists(readingListItem.slug)
                            }
                            dataGTM={readingListsImage}
                        >
                            <div
                                className={`${baseClassName}__image-wrapper`}
                                href={bookPath}
                                data-gtm={readingListsImage}
                                onClick={() =>
                                    this.fetchBooks(readingListItem.slug)
                                }
                            >
                                <div
                                    className={`${baseClassName}__image`}
                                    data-gtm={readingListsImage}
                                >
                                    {
                                        coverImage && (
                                            <Img
                                                image={coverImage && coverImage}
                                                alt={title}
                                                lazyLoad
                                                sizes={imageSrcsetSizes &&
                                                    imageSrcsetSizes.grid2up6up &&
                                                    imageSrcsetSizes.grid2up6up}
                                                dataGTM={readingListsImage}
                                            />
                                        )
                                    }

                                </div>
                            </div>
                        </Link>
                        <div className={`${baseClassName}__meta-wrapper`}>
                            <div className={`${baseClassName}__meta`}>
                                <div className={classNames(titleClasses)}>
                                    <Link
                                        isInternal={true}
                                        parentClassName={`${baseClassName}__link`}
                                        href={
                                            isMyReadingList
                                                ? links.myReadinglistsDetail(
                                                    readingListItem.slug
                                                )
                                                : links.readinglists(
                                                    readingListItem.slug
                                                )
                                        }
                                        dataGTM={readingListsTitle}
                                    >
                                        <div
                                            className={myLanguage === 'lo' ? `${baseClassName}__book-lo-title` : `${baseClassName}__book-title`}
                                            data-gtm={readingListsTitle}
                                            onClick={() =>
                                                this.fetchBooks(
                                                    readingListItem.slug
                                                )
                                            }
                                        >
                                            {title}
                                        </div>
                                    </Link>
                                </div>
                                <div

                                    className={`${baseClassName}__books-count`}
                                    style={{direction:"rtl"}}

                                >
                                    <span>{count}</span>
                                    <span> {t('Book.book-text')}</span>
                                </div>
                            </div>
                            <div className={`${baseClassName}__book-buttons`}>
                                <Link
                                    isInternal={true}
                                    parentClassName={`${baseClassName}__link`}
                                    href={
                                        isMyReadingList
                                            ? links.myReadinglistsDetail(
                                                readingListItem.slug
                                            )
                                            : links.readinglists(
                                                readingListItem.slug
                                            )
                                    }
                                    dataGTM={readingListsViewBooks}
                                >
                                    <div
                                        className="__book-view"
                                        data-gtm={readingListsViewBooks}
                                        onClick={() =>
                                            this.fetchBooks(
                                                readingListItem.slug
                                            )
                                        }
                                    >
                                        {t('Book.view-books')}
                                    </div>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

BookCardSmall.propTypes = {
    title: PropTypes.string.isRequired,
    language: PropTypes.string.isRequired,
    slug: PropTypes.string.isRequired,
    coverImage: PropTypes.object.isRequired,
    recommended: PropTypes.bool,
    authors: PropTypes.arrayOf(
        PropTypes.shape({
            name: PropTypes.string.isRequired,
            slug: PropTypes.string.isRequired,
        })
    ),
    fontSize: PropTypes.oneOf(['m', 'l']),
    onClick: PropTypes.func,
    disabled: PropTypes.bool,
    loading: PropTypes.bool,
    shouldDisplayMenu: PropTypes.bool,
    offline: PropTypes.bool,
};

export default BookCardSmall;