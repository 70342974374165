import React, { Component } from 'react';
import './DynamicBookDetails.scss';

class DynamicBookDetails extends Component {
    arrayData = (value) => {
        const baseClassName = 'dynamic-book-data';
        return (
            <span>
                {value.map((item, i) => (
                    <span className={`${baseClassName}__author-name`}>
                        {' '}
                        {item.first_name}
                        <span>{i < value.length - 1 ? ', ' : ''}</span>
                    </span>
                ))}
            </span>
        );
    };
    render() {
        const {
            title,
            level,
            synopsis,
            dynamicWrapperClass,
            messages,
            publisher,
            authors,
            illustrators,
            translators,
            levelColour,
        } = this.props;

        const baseClassName = 'dynamic-book-data';
        return (
            <div className={dynamicWrapperClass}>
                <div className={`${baseClassName}__title`}>{title}</div>
                <div
                    className={`${baseClassName}__level_box`}
                    style={{
                        background: `${levelColour}`,
                    }}
                >
                    {/* <span>{messages['Book.level']}</span> */}
                    <span> {level}</span>
                </div>
                <div className={`${baseClassName}__summary`}>{synopsis}</div>
                <div>
                    {authors.length > 0 ? (
                        <div>
                            <span className={`${baseClassName}__label`}>
                                {messages['global.author']}
                            </span>
                            {this.arrayData(authors)}
                        </div>
                    ) : (
                        ''
                    )}
                    {illustrators.length > 0 ? (
                        <div>
                            <span className={`${baseClassName}__label`}>
                                {messages['global.illustrator'].split('|')[0]}
                            </span>
                            {this.arrayData(illustrators)}
                        </div>
                    ) : (
                        ''
                    )}
                    {translators.length > 0 ? (
                        <div>
                            <span className={`${baseClassName}__label`}>
                                {messages['global.translator']}
                            </span>
                            {this.arrayData(translators)}
                        </div>
                    ) : (
                        ''
                    )}
                </div>
                <div>
                    {' '}
                    <img
                        src={publisher.image}
                        alt="publisher_img"
                        width="110px"
                        height="56px"
                    />
                </div>
            </div>
        );
    }
}

export default DynamicBookDetails;
